import axios from 'axios'
import { AuthModel, UserModel } from './_models'
const API_URL = process.env.REACT_APP_API_URL

// const API_URL_NEW = 'https://digitalfactory.co.in/interjet/api' // TESTING API
// const API_URL_NEW = 'https://digitalfactory.co.in/dfi/api' // TESTING API
//const API_URL_NEW = 'https://digitalfactory.co.in/interjet/api' // TESTING API
const API_URL_NEW = 'https://app.signtrusted.com/interjet/api' // LIVE API

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
export const LOGIN_URL = `${API_URL}/login`
export const OTP_VALIDATE_URL = `${API_URL_NEW}/validate`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL_OLD = `${API_URL}/forgot_password`
export const REQUEST_PASSWORD_URL = `${API_URL_NEW}/forgot_password`
export const LOGIN_URL_NEW = `${API_URL_NEW}/login`
export const GET_USER_BY_ACCESSTOKEN_URL_NEW = `${API_URL_NEW}/verify_token`
export const PROFILE_URL = `${API_URL_NEW}/profile`
export const PROFILE_CHECK_URL = `${API_URL_NEW}/profile_check`

//Interjet
export const ONLINE_APPLICATION = `${API_URL_NEW}/online_application`
export const ALL_USER_CREATE = `${API_URL_NEW}/user_account`
export const TERMS_AND_CONDITION = `${API_URL_NEW}/terms_and_condition`
export const TEMPLATE_CREATE = `${API_URL_NEW}/templates`
export const DASHBOARD = `${API_URL_NEW}/functions`
////


// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post<AuthModel>(LOGIN_URL_NEW, {
    email,
    password,
  })
}

export function ProfileUpdates(
  formData: FormData,
  company_name: string,
  company_phone: string,
  company_email: string,
  company_address: string,
  company_additional_add: string,
  company_zipcode: string,
  company_city: string,
  company_state: string,
  company_country: string,
) {
  formData.append('company_name', company_name);
  formData.append('company_phone', company_phone);
  formData.append('company_email', company_email);
  formData.append('company_address', company_address);
  formData.append('company_additional_add', company_additional_add);
  formData.append('company_zipcode', company_zipcode);
  formData.append('company_city', company_city);
  formData.append('company_state', company_state);
  formData.append('company_country', company_country);
  return axios.post<AuthModel>(PROFILE_URL, formData, {
    headers: {
      'Content-Type': 'multipart/form-data', // Important: Set the correct content type for file uploads
    },
  });
}

export function profilestatusCheck(email_check: string) {
  return axios.post(PROFILE_CHECK_URL, {
    email_check
  })
}

export function registerPassword(
  verify_phone: string,
  verify_password: string,
) {
  return axios.post(OTP_VALIDATE_URL, {
    verify_phone,
    verify_password,
  })
}

export function resetPassword(
  password: string,
  password_confirmation: string,
  user_email: string
) {
  return axios.post(REQUEST_PASSWORD_URL, {
    password,
    password_confirmation,
    user_email
  })
}

export function updateNewPassword(
  current_password: string,
  new_password: string,
  user_email: string
) {
  return axios.post(PROFILE_URL, {
    current_password,
    new_password,
    user_email
  })
}

export function requestPassword(email: string) {
  return axios.post(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function getUserByToken(token: string) {
  return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL_NEW, {
    api_token: token,
  })
}

export function Dashboard_stats(dashboard_vendor: string, dashboard_vendor_token: string
) {
  return axios.get(DASHBOARD, {
    params: {
      dashboard_vendor: dashboard_vendor,
      dashboard_vendor_token: dashboard_vendor_token
    },
  });
}

export function Dashboard_vendor_stats(dashboard_vendor: string,
) {
  return axios.get(DASHBOARD, {
    params: {
      dashboard_vendor: dashboard_vendor,
    },
  });
}

export function Individual_POST_Application(
  formData: FormData,
  app_dot_id: string,
  ind_id_proof: string,
  ind_address_proof: string,
  ind_customer_name: string,
  ind_father_name: string,
  ind_gender: string,
  ind_nationality: string,
  ind_dob: string,
  ind_email: string,
  ind_phone_number: string,
  ind_alternate_number: string,
  ind_installation_add: string,
  ind_country: string,
  ind_state: string,
  ind_billing_add: string,
  ind_billing_country: string,
  ind_billing_state: string,
  ind_vendor_name: string,
  ind_vendor_id: string
) {
  formData.append('app_dot_id', app_dot_id);
  formData.append('ind_id_proof', ind_id_proof);
  formData.append('ind_address_proof', ind_address_proof);
  formData.append('ind_customer_name', ind_customer_name);
  formData.append('ind_father_name', ind_father_name);
  formData.append('ind_gender', ind_gender);
  formData.append('ind_nationality', ind_nationality);
  formData.append('ind_dob', ind_dob);
  formData.append('ind_email', ind_email);
  formData.append('ind_phone_number', ind_phone_number);
  formData.append('ind_alternate_number', ind_alternate_number);
  formData.append('ind_installation_add', ind_installation_add);
  formData.append('ind_country', ind_country);
  formData.append('ind_state', ind_state);
  formData.append('ind_billing_add', ind_billing_add);
  formData.append('ind_billing_country', ind_billing_country);
  formData.append('ind_billing_state', ind_billing_state);
  formData.append('ind_vendor_name', ind_vendor_name);
  formData.append('ind_vendor_id', ind_vendor_id);
  return axios.post(ONLINE_APPLICATION, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export function Individual_GET_Application(vendor_applications: string, vendor_app_token: string) {
  return axios.get(ONLINE_APPLICATION, {
    params: {
      vendor_applications: vendor_applications,
      vendor_app_token: vendor_app_token,
    },
  });
}

export function Individual_EDIT_Application(view_application_id: string) {
  return axios.get(ONLINE_APPLICATION, {
    params: {
      view_application_id: view_application_id
    },
  });
}

export function Individual_PUT_Application(
  formData: FormData,
  app_id_upd: string,
  app_dot_id_upd: string,
  app_id_proof_upd: string,
  app_address_proof_upd: string,
  app_customer_name_upd: string,
  app_father_name_upd: string,
  app_gender_upd: string,
  app_nationality_upd: string,
  app_dob_upd: string,
  app_email_id_upd: string,
  app_phone_no_upd: string,
  app_alternate_number_upd: string,
  app_installation_add_upd: string,
  app_country_upd: string,
  app_state_upd: string,
  app_billing_add_upd: string,
  app_billing_country_upd: string,
  app_billing_state_upd: string,
  app_type_upd: string,
  app_status_upd: string,
  app_remarks_upd: string,
  app_approved_user_id: string,
  app_approved_user_name: string,
) {
  formData.append('app_id_upd', app_id_upd);
  formData.append('app_dot_id_upd', app_dot_id_upd);
  formData.append('app_id_proof_upd', app_id_proof_upd);
  formData.append('app_address_proof_upd', app_address_proof_upd);
  formData.append('app_customer_name_upd', app_customer_name_upd);
  formData.append('app_father_name_upd', app_father_name_upd);
  formData.append('app_gender_upd', app_gender_upd);
  formData.append('app_nationality_upd', app_nationality_upd);
  formData.append('app_dob_upd', app_dob_upd);
  formData.append('app_email_id_upd', app_email_id_upd);
  formData.append('app_phone_no_upd', app_phone_no_upd);
  formData.append('app_alternate_number_upd', app_alternate_number_upd);
  formData.append('app_installation_add_upd', app_installation_add_upd);
  formData.append('app_country_upd', app_country_upd);
  formData.append('app_state_upd', app_state_upd);
  formData.append('app_billing_add_upd', app_billing_add_upd);
  formData.append('app_billing_country_upd', app_billing_country_upd);
  formData.append('app_billing_state_upd', app_billing_state_upd);
  formData.append('app_type_upd', app_type_upd);
  formData.append('app_status_upd', app_status_upd);
  formData.append('app_remarks_upd', app_remarks_upd);
  formData.append('app_approved_user_id', app_approved_user_id);
  formData.append('app_approved_user_name', app_approved_user_name);
  return axios.post(ONLINE_APPLICATION, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export function Company_POST_Application(
  formData: FormData,
  app_dot_id: string,
  company_name: string,
  company_type_concern: string,
  company_date_incorporation: string,
  company_email_id: string,
  company_alternate_email_id: string,
  company_phone_number: string,
  company_alter_phone_number: string,
  company_installation_add: string,
  company_installation_country: string,
  company_installation_state: string,
  company_billing_add: string,
  company_billing_country: string,
  company_billing_state: string,
  company_gst: string,
  company_address_proof: string,
  company_id_proof: string,
  company_created_by: string,
  company_vendor_name: string,
  company_vendor_id: string,
) {
  formData.append('app_dot_id', app_dot_id);
  formData.append('company_name', company_name);
  formData.append('company_type_concern', company_type_concern);
  formData.append('company_date_incorporation', company_date_incorporation);
  formData.append('company_email_id', company_email_id);
  formData.append('company_alternate_email_id', company_alternate_email_id);
  formData.append('company_phone_number', company_phone_number);
  formData.append('company_alter_phone_number', company_alter_phone_number);
  formData.append('company_installation_add', company_installation_add);
  formData.append('company_installation_country', company_installation_country);
  formData.append('company_installation_state', company_installation_state);
  formData.append('company_billing_add', company_billing_add);
  formData.append('company_billing_country', company_billing_country);
  formData.append('company_billing_state', company_billing_state);
  formData.append('company_gst', company_gst);
  formData.append('company_address_proof', company_address_proof);
  formData.append('company_id_proof', company_id_proof);
  formData.append('company_created_by', company_created_by);
  formData.append('company_vendor_name', company_vendor_name);
  formData.append('company_vendor_id', company_vendor_id);
  return axios.post(ONLINE_APPLICATION, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export function Company_PUT_Application(
  formData: FormData,
  app_id_upd: string,
  app_dot_id_upd: string,
  company_name_upd: string,
  company_type_concern_upd: string,
  company_incorporation_upd: string,
  company_email_id_upd: string,
  company_alter_email_upd: string,
  company_phone_number_upd: string,
  company_alter_phone_number_upd: string,
  company_installation_add_upd: string,
  company_installation_country_upd: string,
  company_installation_state_upd: string,
  company_billing_add_upd: string,
  company_billing_country_upd: string,
  company_billing_state_upd: string,
  company_gst_upd: string,
  company_address_proof_upd: string,
  company_id_proof_upd: string,
  company_status_upd: string,
  app_type_upd: string,
  company_remarks_upd: string,
  app_approved_user_id: string,
  app_approved_user_name: string,
) {
  formData.append('app_id_upd', app_id_upd);
  formData.append('app_dot_id_upd', app_dot_id_upd);
  formData.append('company_name_upd', company_name_upd);
  formData.append('company_type_concern_upd', company_type_concern_upd);
  formData.append('company_incorporation_upd', company_incorporation_upd);
  formData.append('company_email_id_upd', company_email_id_upd);
  formData.append('company_alter_email_upd', company_alter_email_upd);
  formData.append('company_phone_number_upd', company_phone_number_upd);
  formData.append('company_alter_phone_number_upd', company_alter_phone_number_upd);
  formData.append('company_installation_add_upd', company_installation_add_upd);
  formData.append('company_installation_country_upd', company_installation_country_upd);
  formData.append('company_installation_state_upd', company_installation_state_upd);
  formData.append('company_billing_add_upd', company_billing_add_upd);
  formData.append('company_billing_country_upd', company_billing_country_upd);
  formData.append('company_billing_state_upd', company_billing_state_upd);
  formData.append('company_gst_upd', company_gst_upd);
  formData.append('company_address_proof_upd', company_address_proof_upd);
  formData.append('company_id_proof_upd', company_id_proof_upd);
  formData.append('company_status_upd', company_status_upd);
  formData.append('app_type_upd', app_type_upd);
  formData.append('company_remarks_upd', company_remarks_upd);
  formData.append('app_approved_user_id', app_approved_user_id);
  formData.append('app_approved_user_name', app_approved_user_name);
  return axios.post(ONLINE_APPLICATION, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export function Template_POST(
  formData: FormData,
  template_title: string,
  template_user_id: string,
  template_bgColor: string,
  template_textColor: string,
  template_header: string,
  template_header_content: string,
  template_footer: string,
  template_footer_content_1: string,
  template_footer_content_2: string,
) {
  formData.append('template_title', template_title);
  formData.append('template_user_id', template_user_id);
  formData.append('template_bgColor', template_bgColor);
  formData.append('template_textColor', template_textColor);
  formData.append('template_header', template_header);
  formData.append('template_header_content', template_header_content);
  formData.append('template_footer', template_footer);
  formData.append('template_footer_content_1', template_footer_content_1);
  formData.append('template_footer_content_2', template_footer_content_2);
  return axios.post(TEMPLATE_CREATE, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export function Template_GET(all_templates: number, all_template_user_id: number) {
  return axios.get(TEMPLATE_CREATE, {
    params: {
      all_templates: all_templates,
      all_template_user_id: all_template_user_id
    },
  });
}

export function Template_ACTIVE(template_id_upd: number, template_user_id_upd: number) {
  return axios.post(TEMPLATE_CREATE, {
    template_id_upd,
    template_user_id_upd
  });
}

export function USERS_POST(
  formData: FormData,
  user_name: string,
  user_password: string,
  user_email: string,
  user_mobile: string,
  user_dob: string,
  user_address: string,
  user_role: string,
  user_company_name: string,
  user_app_credit: string,
  user_app_shortcode: string,
  user_created_by: string,
) {
  formData.append('user_name', user_name);
  formData.append('user_password', user_password);
  formData.append('user_email', user_email);
  formData.append('user_mobile', user_mobile);
  formData.append('user_dob', user_dob);
  formData.append('user_address', user_address);
  formData.append('user_role', user_role);
  formData.append('user_company_name', user_company_name);
  formData.append('user_app_credit', user_app_credit);
  formData.append('user_app_shortcode', user_app_shortcode);
  formData.append('user_created_by', user_created_by);
  return axios.post(ALL_USER_CREATE, formData, {
    headers: {
      'Content-Type': 'multipart/form-data', // Important: Set the correct content type for file uploads
    },
  });
}

export function GET_ALL_USERS(all_users: number) {
  return axios.get(ALL_USER_CREATE, {
    params: {
      all_users: all_users
    },
  });
}

export function EDIT_USERS(edit_user_id: string) {
  return axios.get(ALL_USER_CREATE, {
    params: {
      edit_user_id: edit_user_id
    },
  });
}

export function PUT_USERS(
  formData: FormData,
  user_id_upd: string,
  user_name_upd: string,
  user_email_upd: string,
  user_mobile_upd: string,
  user_dob_upd: string,
  user_address_upd: string,
  user_role_upd: string,
  user_app_credit_upd: string,
  user_company_name_upd: string,
  user_status_upd: string,
  user_last_updated_by: string,
) {
  formData.append('user_id_upd', user_id_upd);
  formData.append('user_name_upd', user_name_upd);
  formData.append('user_email_upd', user_email_upd);
  formData.append('user_mobile_upd', user_mobile_upd);
  formData.append('user_dob_upd', user_dob_upd);
  formData.append('user_address_upd', user_address_upd);
  formData.append('user_role_upd', user_role_upd);
  formData.append('user_company_name_upd', user_company_name_upd);
  formData.append('user_app_credit_upd', user_app_credit_upd);
  formData.append('user_status_upd', user_status_upd);
  formData.append('user_last_updated_by', user_last_updated_by);
  return axios.post(ALL_USER_CREATE, formData, {
    headers: {
      'Content-Type': 'multipart/form-data', // Important: Set the correct content type for file uploads
    },
  });
}

export function Application_check_vendor(vendor_credit_check: string) {
  return axios.get(ONLINE_APPLICATION, {
    params: {
      vendor_credit_check: vendor_credit_check,
    },
  })
}

export function POST_Terms_Condition(terms_details: string, terms_user_id: string,) {
  return axios.post(TERMS_AND_CONDITION, {
    terms_details: terms_details,
    terms_user_id: terms_user_id,
  })
}

export function GET_Terms_Condition(v_terms_user_id: number) {
  return axios.get(TERMS_AND_CONDITION, {
    params: {
      v_terms_user_id: v_terms_user_id
    },
  });
}

export function PUT_Terms_Condition(terms_user_id_upd: number, terms_details_upd: string) {
  return axios.post(TERMS_AND_CONDITION, {
    terms_user_id_upd: terms_user_id_upd,
    terms_details_upd: terms_details_upd
  })
}

export function applicationCheck(app_vendor_type: any, app_mode: any) {
  return axios.get(TEMPLATE_CREATE, {

    params: {
      app_vendor_type: app_vendor_type,
      app_mode: app_mode
    },
  })
}
