import { useEffect, useRef, useState } from 'react'
import { KTIcon, toAbsoluteUrl } from '../../../../_metronic/helpers'
import { Company_PUT_Application } from '../core/_requests'
import SignatureCanvas from 'react-signature-canvas';
import Webcam from 'react-webcam';
import { StepperComponent } from '../../../../_metronic/assets/ts/components'
import { Form, ErrorMessage, Field, Formik, FormikValues } from 'formik'
import { CompanyAccountSchema, CompanyAccountEdit } from '../../wizards/components/CreateAccountWizardHelper'
import { EditApplicationDetails } from '../core/_models';
import { useNavigate } from 'react-router-dom';
import Privacy_Policy from './Privacy_Policy';
import Declaration from './Declaration';


interface FrontPageFormProps {
    viewDetails: EditApplicationDetails[]
}

const CompanyEdit: React.FC<FrontPageFormProps> = ({ viewDetails }) => {
    const viewDetailsItem = viewDetails && viewDetails.length > 0 ? viewDetails[0] : null;
    const navigate = useNavigate();
    const IdFrontPhoto = useRef<any>(null);
    const IdBackPhoto = useRef<any>(null);
    const AddressPhoto = useRef<any>(null);
    const AddressBackPhoto = useRef<any>(null);
    // const AddressThirdPhoto = useRef<any>(null);
    const CustomerPhoto = useRef<any>(null);
    const stepperRef = useRef<HTMLDivElement | null>(null)
    const stepper = useRef<StepperComponent | null>(null)
    const [currentSchema, setCurrentSchema] = useState(CompanyAccountSchema[0])
    const [loading, setLoading] = useState(false)
    const [customer_photo, setCustomer_photo] = useState<string | null>(null);
    const [showWarning, setShowWarning] = useState<boolean>(false);
    const [idProof_Front, setIdProof_Front] = useState<string | null>(null);
    const [idProof_Back, setIdProof_Back] = useState<string | null>(null);
    const [addProof_Front, setAddProof_Front] = useState<string | null>(null);
    const [addProof_Back, setAddProof_Back] = useState<string | null>(null);
    // const [addProof_third, setAddProof_third] = useState<string | null>(null);
    const [showModalPrivacy, setShowModalPrivacy] = useState(false);
    const [showWebcamCust, setShowWebcamCust] = useState(false);
    const [showWebcam, setShowWebcam] = useState(false);
    const [showWebcamBack, setShowWebcamBack] = useState(false);
    const [showWebcamAdd, setShowWebcamAdd] = useState(false);
    const [showWebcamAddBack, setShowWebcamAddBack] = useState(false);
    // const [showWebcamAddThird, setShowWebcamAddThird] = useState(false);
    const [selectedOption, setSelectedOption] = useState('');
    const [selectedIdProof, setSelectedIdProof] = useState<string>('');
    const [selectedAddProof, setSelectedAddProof] = useState<string>('');
    const [frontImageError, setFrontImageError] = useState(false)
    const [backImageError, setBackImageError] = useState(false)
    const [address_proof_img, setAddress_proof_img] = useState(false)
    const [address_proofBack_img, setAddress_proofBack_img] = useState(false)
    const [showSignatureWarning, setShowSignatureWarning] = useState<boolean>(false);
    const [privacyWarning, setprivacyWarning] = useState<boolean>(false);
    const [canvasWidth, setCanvasWidth] = useState(600);
    const [showModal, setShowModal] = useState(false);
    const [backImage, setBackImage] = useState(false);
    const [custImage, setCustImage] = useState(false);

    const sigCanvas = useRef<SignatureCanvas>(null);
    const companySign:any = viewDetailsItem?.app_company_auth_sign;
    const customerImage = viewDetailsItem?.app_company_customer_image;
    const idProofFrontImage = viewDetailsItem?.app_company_auth_front_image;
    const idProofBackImage = viewDetailsItem?.app_company_auth_back_image;
    const addProofImage = viewDetailsItem?.app_company_address_image;
    const addProofBackImage = viewDetailsItem?.app_company_address_image_2;

    const initialFormValues: CompanyAccountEdit = {
        app_id_upd: viewDetailsItem?.app_application_id || '',
        app_dot_id_upd: viewDetailsItem?.app_dot_id || '',
        company_name: viewDetailsItem?.app_company_name || '',
        company_type_concern: viewDetailsItem?.app_company_type_concern || '',
        date_incorporation: viewDetailsItem?.app_company_date_incorporation || '',
        email_id: viewDetailsItem?.app_company_email_id || '',
        alternate_email_id: viewDetailsItem?.app_company_alternate_email_id || '',
        company_phone_number: viewDetailsItem?.app_company_phone_no || '',
        company_alter_phone_number: viewDetailsItem?.app_company_alternate_number || '',
        company_installation_add: viewDetailsItem?.app_company_installation_add || '',
        company_installation_country: viewDetailsItem?.app_company_country || '',
        company_installation_state: viewDetailsItem?.app_company_state || '',
        company_billing_add: viewDetailsItem?.app_company_billing_add || '',
        company_billing_country: viewDetailsItem?.app_company_billing_country || '',
        company_billing_state: viewDetailsItem?.app_company_billing_state || '',
        company_gst: viewDetailsItem?.app_company_gst || '',
        company_address_proof: viewDetailsItem?.app_company_address_proof || '',
        company_id_proof: viewDetailsItem?.app_company_id_proof || '',
        app_status_upd: "2" || '',
    }

    const handleFrontImage = () => {
        setShowModal(true);
    };

    const handleBackImage = () => {
        setBackImage(true);
    };

    const handleCustomer = () => {
        setCustImage(true);
    };

    const handleIdProofChange = (idProof: string) => {
        setSelectedIdProof(idProof);
    };

    const handleAddProofChange = (addProof: string) => {
        setSelectedAddProof(addProof);
    }

    const openFileInput = () => {
        const fileInput = document.querySelector('input[name="idFrontImage"]') as HTMLInputElement | null;
        if (fileInput) {
            fileInput.click();
        }
    };

    const openFileInputBack = () => {
        const fileInput = document.querySelector('input[name="idBackImage"]') as HTMLInputElement | null;
        if (fileInput) {
            fileInput.click();
        }
    };

    const openFileInputAddFront = () => {
        const fileInput = document.querySelector('input[name="Add_FrontImage"]') as HTMLInputElement | null;
        if (fileInput) {
            fileInput.click();
        }
    };

    const openFileInputAddBack = () => {
        const fileInput = document.querySelector('input[name="Add_BackImage"]') as HTMLInputElement | null;
        if (fileInput) {
            fileInput.click();
        }
    };

    // const openFileInputAddThird = () => {
    //     const fileInput = document.querySelector('input[name="Add_ThirdImage"]') as HTMLInputElement | null;
    //     if (fileInput) {
    //         fileInput.click();
    //     }
    // };

    const openFileInputCustomer = () => {
        const fileInput = document.querySelector('input[name="CustomerImage"]') as HTMLInputElement | null;
        if (fileInput) {
            fileInput.click();
        }
    };

    const compressImage = async (imageUrl: string): Promise<string> => {
        return new Promise((resolve, reject) => {
            // Load the image into an HTMLImageElement
            const img = new Image();
            img.src = imageUrl;

            // Wait for the image to load before processing
            img.onload = () => {
                // Create a canvas element to draw the image
                const canvas = document.createElement('canvas');

                // Calculate the desired width and height to achieve the target size
                const maxWidth = 800; // Adjust the maximum width as needed
                const maxHeight = 800; // Adjust the maximum height as needed
                const width = img.width;
                const height = img.height;

                // Calculate the scaling factor to fit within the desired dimensions
                const scaleFactor = Math.min(maxWidth / width, maxHeight / height);

                // Set the canvas dimensions based on the scaling factor
                canvas.width = width * scaleFactor;
                canvas.height = height * scaleFactor;

                // Draw the image onto the canvas with the scaled dimensions
                const ctx = canvas.getContext('2d');
                ctx?.drawImage(img, 0, 0, width * scaleFactor, height * scaleFactor);

                // Convert the canvas content to a compressed data URL
                const compressedImageUrl = canvas.toDataURL('image/jpeg', 0.7); // Adjust the quality as needed

                resolve(compressedImageUrl);
            };

            // Handle image load errors
            img.onerror = (error) => {
                reject(error);
            };
        });
    };

    const handleIdProofFront = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files && e.target.files[0];
        if (file) {
            const imageUrl = URL.createObjectURL(file);
            try {
                const compressedImageUrl = await compressImage(imageUrl);
                setIdProof_Front(compressedImageUrl);
                setFrontImageError(false)
            } catch (error) {
                console.error('Error compressing image:', error);
            }
        }
    };

    const handleIdProofBack = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files && e.target.files[0];
        if (file) {
            const imageUrl = URL.createObjectURL(file);
            try {
                const compressedImageUrl = await compressImage(imageUrl);
                setIdProof_Back(compressedImageUrl);
                setBackImageError(false)
            } catch (error) {
                console.error('Error compressing image:', error);
            }
        }
    };

    const handleAddProofFront = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files && e.target.files[0];
        if (file) {
            const imageUrl = URL.createObjectURL(file);
            try {
                const compressedImageUrl = await compressImage(imageUrl);
                setAddProof_Front(compressedImageUrl);
                setAddress_proof_img(false)
            } catch (error) {
                console.error('Error compressing image:', error);
            }
        }
    };

    const handleAddProofBack = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files && e.target.files[0];
        if (file) {
            const imageUrl = URL.createObjectURL(file);
            try {
                const compressedImageUrl = await compressImage(imageUrl);
                setAddProof_Back(compressedImageUrl);
                setAddress_proofBack_img(false)
            } catch (error) {
                console.error('Error compressing image:', error);
            }
        }
    };

    // const handleAddProofThird = async (e: React.ChangeEvent<HTMLInputElement>) => {
    //     const file = e.target.files && e.target.files[0];
    //     if (file) {
    //         const imageUrl = URL.createObjectURL(file);
    //         try {
    //             const compressedImageUrl = await compressImage(imageUrl);
    //             setAddProof_third(compressedImageUrl);
    //         } catch (error) {
    //             console.error('Error compressing image:', error);
    //         }
    //     }
    // };


    const handleCustomerImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files && e.target.files[0];
        if (file) {
            const imageUrl = URL.createObjectURL(file);
            try {
                const compressedImageUrl = await compressImage(imageUrl);
                setCustomer_photo(compressedImageUrl);
                setShowWarning(false);
            } catch (error) {
                console.error('Error compressing image:', error);
            }
        }
    };

    useEffect(() => {
        const handleResize = () => {
            // Adjust canvas width based on device width
            const newWidth = window.innerWidth >= 768 ? 600 : 300;
            setCanvasWidth(newWidth);
        };

        // Attach the resize event listener
        window.addEventListener('resize', handleResize);

        // Initial setup on component mount
        handleResize();

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const clearCanvas = () => {
        if (sigCanvas.current) {
            sigCanvas.current.clear();
        }
    };

    const captureFrontImage = () => {
        if (IdFrontPhoto.current) {
            const capturedPhoto = IdFrontPhoto.current.getScreenshot();
            setIdProof_Front(capturedPhoto);
            setShowWebcam(false);
            setFrontImageError(false)
        }
    };

    const captureBackImage = () => {
        if (IdBackPhoto.current) {
            const capturedPhoto = IdBackPhoto.current.getScreenshot();
            setIdProof_Back(capturedPhoto);
            setShowWebcamBack(false)
            setBackImageError(false)
        }
    };

    const captureAddImage = () => {
        if (AddressPhoto.current) {
            const capturedPhoto = AddressPhoto.current.getScreenshot();
            setAddProof_Front(capturedPhoto);
            setShowWebcamAdd(false)
            setAddress_proof_img(false)
        }
    };

    const captureAddBackImage = () => {
        if (AddressBackPhoto.current) {
            const capturedPhoto = AddressBackPhoto.current.getScreenshot();
            setAddProof_Back(capturedPhoto);
            setShowWebcamAddBack(false)
            setAddress_proofBack_img(false)
        }
    };

    // const captureAddImageThird = () => {
    //     if (AddressThirdPhoto.current) {
    //         const capturedPhoto = AddressThirdPhoto.current.getScreenshot();
    //         setAddProof_third(capturedPhoto);
    //         setShowWebcamAddThird(false)
    //     }
    // };

    const capturePhoto = () => {
        if (CustomerPhoto.current) {
            const capturedPhoto = CustomerPhoto.current.getScreenshot();
            setCustomer_photo(capturedPhoto);
            setShowWebcamCust(false)
            setShowWarning(false);
        }
    };

    const openPrivacymodal = () => {
        setShowModalPrivacy(true);
    };

    const retakeFrontImage = () => {
        setIdProof_Front(null);
        setShowWebcam(true)
    };

    const retakeBackImage = () => {
        setIdProof_Back(null);
        setShowWebcamBack(true)
    };

    const retakeAddImage = () => {
        setAddProof_Front(null);
        setShowWebcamAdd(true)
    };

    const retakeAddBackImage = () => {
        setAddProof_Back(null);
        setShowWebcamAddBack(true)
    };

    // const retakeAddThirdImage = () => {
    //     setAddProof_third(null);
    //     setShowWebcamAddThird(false)
    // };

    const retake = () => {
        setCustomer_photo(null);
        setShowWebcamCust(true)
    };

    const dataURItoBlob = (dataURI: any) => {
        const [mime, data] = dataURI.split(',');
        const binary = atob(data);
        const array = new Uint8Array(binary.length);

        for (let i = 0; i < binary.length; i++) {
            array[i] = binary.charCodeAt(i);
        }

        return new Blob([array], { type: mime });
    };

    const loadStepper = () => {
        stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
    }

    const prevStep = () => {
        if (!stepper.current) {
            return
        }
        stepper.current.goPrev()
        setCurrentSchema(CompanyAccountSchema[stepper.current.currentStepIndex - 1])
    }

    const resizeSignature = (signatureDataURL: string, width: number, height: number) => {
        return new Promise((resolve, reject) => {
            const canvas = document.createElement('canvas');
            canvas.width = width;
            canvas.height = height;

            const ctx = canvas.getContext('2d');
            const img = new Image();
            img.onload = () => {
                if (ctx) {
                    ctx.drawImage(img, 0, 0, width, height);
                    resolve(canvas.toDataURL());
                } else {
                    reject(new Error("Failed to get context from canvas"));
                }
            };
            img.src = signatureDataURL;
        });
    };

    const submitStep = async (values: CompanyAccountEdit, actions: FormikValues) => {
        if (!stepper.current) {
            return
        }
        if (stepper.current.currentStepIndex !== stepper.current.totalStepsNumber) {
            // if (stepper.current.currentStepIndex === 12) {
            //     if ((addProof_Front === null && addProofImage === null) || addProof_Front === null && !addProofImage) {
            //         setAddress_proof_img(true);
            //     } else {
            //         setAddress_proof_img(false);
            //         stepper.current.goNext();
            //     }
            // } else if (stepper.current.currentStepIndex === 14) {
            //     if ((idProof_Front === null && idProofFrontImage === null) || idProof_Front === null && !idProofFrontImage) {
            //         setFrontImageError(true);
            //     } else {
            //         setFrontImageError(false);
            //         stepper.current.goNext();
            //     }
            // } else if (stepper.current.currentStepIndex === 15) {
            //     if ((idProof_Back === null && idProofBackImage === null) || idProof_Back === null && !idProofBackImage) {
            //         setBackImageError(true);
            //     } else {
            //         setBackImageError(false);
            //         stepper.current.goNext();
            //     }
            // } else if (stepper.current.currentStepIndex === 16) {
            //     if (customer_photo === null && customerImage === null || customer_photo === null && !customerImage) {
            //         setShowWarning(true);
            //     } else {
            //         setShowWarning(false);
            //         stepper.current.goNext()
            //         return;
            //     }
            // } else {
            //     stepper.current.goNext()
            // }
            stepper.current.goNext()
        } else {
            if (sigCanvas.current && sigCanvas.current.isEmpty() && companySign === null || sigCanvas.current && sigCanvas.current.isEmpty() && !companySign) {
                setShowSignatureWarning(true);
                return;
            }
            const termsConditionCheckbox = document.getElementById('terms_condition') as HTMLInputElement;
            if (!termsConditionCheckbox.checked) {
                // Show privacy warning if terms and conditions checkbox is not checked
                setprivacyWarning(true);
                return; // Do not proceed with the submission.
            } else {
                // Reset privacy warning if terms and conditions checkbox is checked
                setprivacyWarning(false);
            }
            setLoading(true)
            try {
                const ApplicationData = new FormData();
                if (sigCanvas.current && !sigCanvas.current.isEmpty()) {
                    if (sigCanvas.current) {
                        const signatureDataURL = sigCanvas.current.toDataURL();
                        const resizedDataURL = await resizeSignature(signatureDataURL, 600, 200);
                        const signatureBlob = await (await fetch(resizedDataURL as string)).blob();
                        ApplicationData.append("company_auth_sign_upd", signatureBlob); 
                    }
                } else {
                    const signatureBlob = await (await fetch(companySign)).blob();
                    ApplicationData.append("company_auth_sign_upd", signatureBlob); 
                }
                if (idProof_Front) {
                    ApplicationData.append("company_auth_front_image_upd", dataURItoBlob(idProof_Front));
                }
                if (idProof_Back) {
                    ApplicationData.append("company_auth_back_image_upd", dataURItoBlob(idProof_Back));
                }
                if (addProof_Front) {
                    ApplicationData.append("company_address_image_upd", dataURItoBlob(addProof_Front));
                }
                if (addProof_Back) {
                    ApplicationData.append("company_address_image_2_upd", dataURItoBlob(addProof_Back));
                }
                // if (addProof_third) {
                //     ApplicationData.append("company_address_image_3_upd", dataURItoBlob(addProof_third));
                // }
                if (customer_photo) {
                    ApplicationData.append("company_customer_image_upd", dataURItoBlob(customer_photo));
                }

                const { data: auth } = await Company_PUT_Application(
                    ApplicationData,
                    values.app_id_upd,
                    values.app_dot_id_upd,
                    values.company_name,
                    values.company_type_concern,
                    values.date_incorporation,
                    values.email_id,
                    values.alternate_email_id,
                    values.company_phone_number,
                    values.company_alter_phone_number,
                    values.company_installation_add,
                    values.company_installation_country,
                    values.company_installation_state,
                    values.company_billing_add,
                    values.company_billing_country,
                    values.company_billing_state,
                    values.company_gst,
                    values.company_address_proof,
                    values.company_id_proof,
                    values.app_status_upd,
                    "Company",
                    "Customer Edited",
                    "null",
                    "Customer",
                );
                actions.resetForm()
                setLoading(false)
                navigate('/success')
            }
            catch (error) {
                console.error('Error submitting form data:', error);
            }
        }
        setCurrentSchema(CompanyAccountSchema[stepper.current.currentStepIndex - 1])
    }

    useEffect(() => {
        if (!stepperRef.current) {
            return
        }
        loadStepper()
    }, [stepperRef])

    return (
        <>
            {viewDetailsItem?.app_company_status === '4' ? (
                <>
                    <div className='d-flex flex-row-fluid flex-center bg-body rounded mt-5'>
                        <div className="alert bg-danger d-flex align-items-center text-white" role="alert">
                            <KTIcon iconName='information-2' className='fs-1 text-white' />
                            <div className='fs-3 mb-1 ps-3'>
                                {viewDetailsItem?.app_message ? viewDetailsItem?.app_message : "No Remarks"}
                            </div>
                        </div>
                    </div>
                    <div
                        ref={stepperRef}
                        className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
                        id='kt_create_account_stepper'
                    >
                        <div className='card d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px w-xxl-400px me-9 d-none'>
                            <div className='card-body px-6 px-lg-10 px-xxl-15 py-20'>
                                <div className='stepper-nav'>
                                    <div className='stepper-item current' data-kt-stepper-element='nav'>
                                        <div className='stepper-wrapper'>
                                            <div className='stepper-icon w-40px h-40px'>
                                                <i className='stepper-check fas fa-check'></i>
                                                <span className='stepper-number'>1</span>
                                            </div>
                                            <div className='stepper-label'>
                                                <h3 className='stepper-title'>Account Type</h3>
                                                <div className='stepper-desc fw-semibold'>Setup Your Account Details</div>
                                            </div>
                                        </div>
                                        <div className='stepper-line h-40px'></div>
                                    </div>
                                    <div className='stepper-item' data-kt-stepper-element='nav'>
                                        <div className='stepper-wrapper'>
                                            <div className='stepper-icon w-40px h-40px'>
                                                <i className='stepper-check fas fa-check'></i>
                                                <span className='stepper-number'>2</span>
                                            </div>
                                            <div className='stepper-label'>
                                                <h3 className='stepper-title'>Account Settings</h3>
                                                <div className='stepper-desc fw-semibold'>Setup Your Account Settings</div>
                                            </div>
                                        </div>
                                        <div className='stepper-line h-40px'></div>
                                    </div>
                                    <div className='stepper-item' data-kt-stepper-element='nav'>
                                        <div className='stepper-wrapper'>
                                            <div className='stepper-icon w-40px h-40px'>
                                                <i className='stepper-check fas fa-check'></i>
                                                <span className='stepper-number'>3</span>
                                            </div>
                                            <div className='stepper-label'>
                                                <h3 className='stepper-title'>Business Info</h3>
                                                <div className='stepper-desc fw-semibold'>Your Business Related Info</div>
                                            </div>
                                        </div>
                                        <div className='stepper-line h-40px'></div>
                                    </div>
                                    <div className='stepper-item' data-kt-stepper-element='nav'>
                                        <div className='stepper-wrapper'>
                                            <div className='stepper-icon w-40px h-40px'>
                                                <i className='stepper-check fas fa-check'></i>
                                                <span className='stepper-number'>4</span>
                                            </div>
                                            <div className='stepper-label'>
                                                <h3 className='stepper-title'>Billing Details</h3>
                                                <div className='stepper-desc fw-semibold'>Set Your Payment Methods</div>
                                            </div>
                                        </div>
                                        <div className='stepper-line h-40px'></div>
                                    </div>
                                    <div className='stepper-item' data-kt-stepper-element='nav'>
                                        <div className='stepper-wrapper'>
                                            <div className='stepper-icon w-40px h-40px'>
                                                <i className='stepper-check fas fa-check'></i>
                                                <span className='stepper-number'>5</span>
                                            </div>
                                            <div className='stepper-label'>
                                                <h3 className='stepper-title'>Completed</h3>
                                                <div className='stepper-desc fw-semibold'>Woah, we are here</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='stepper-item' data-kt-stepper-element='nav'>
                                        <div className='stepper-wrapper'>
                                            <div className='stepper-icon w-40px h-40px'>
                                                <i className='stepper-check fas fa-check'></i>
                                                <span className='stepper-number'>6</span>
                                            </div>
                                            <div className='stepper-label'>
                                                <h3 className='stepper-title'>Completed</h3>
                                                <div className='stepper-desc fw-semibold'>Woah, we are here</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='stepper-item' data-kt-stepper-element='nav'>
                                        <div className='stepper-wrapper'>
                                            <div className='stepper-icon w-40px h-40px'>
                                                <i className='stepper-check fas fa-check'></i>
                                                <span className='stepper-number'>7</span>
                                            </div>
                                            <div className='stepper-label'>
                                                <h3 className='stepper-title'>Completed</h3>
                                                <div className='stepper-desc fw-semibold'>Woah, we are here</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='stepper-item' data-kt-stepper-element='nav'>
                                        <div className='stepper-wrapper'>
                                            <div className='stepper-icon w-40px h-40px'>
                                                <i className='stepper-check fas fa-check'></i>
                                                <span className='stepper-number'>8</span>
                                            </div>
                                            <div className='stepper-label'>
                                                <h3 className='stepper-title'>Completed</h3>
                                                <div className='stepper-desc fw-semibold'>Woah, we are here</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='stepper-item' data-kt-stepper-element='nav'>
                                        <div className='stepper-wrapper'>
                                            <div className='stepper-icon w-40px h-40px'>
                                                <i className='stepper-check fas fa-check'></i>
                                                <span className='stepper-number'>9</span>
                                            </div>
                                            <div className='stepper-label'>
                                                <h3 className='stepper-title'>Completed</h3>
                                                <div className='stepper-desc fw-semibold'>Woah, we are here</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='stepper-item' data-kt-stepper-element='nav'>
                                        <div className='stepper-wrapper'>
                                            <div className='stepper-icon w-40px h-40px'>
                                                <i className='stepper-check fas fa-check'></i>
                                                <span className='stepper-number'>9</span>
                                            </div>
                                            <div className='stepper-label'>
                                                <h3 className='stepper-title'>Completed</h3>
                                                <div className='stepper-desc fw-semibold'>Woah, we are here</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='stepper-item' data-kt-stepper-element='nav'>
                                        <div className='stepper-wrapper'>
                                            <div className='stepper-icon w-40px h-40px'>
                                                <i className='stepper-check fas fa-check'></i>
                                                <span className='stepper-number'>10</span>
                                            </div>
                                            <div className='stepper-label'>
                                                <h3 className='stepper-title'>Completed</h3>
                                                <div className='stepper-desc fw-semibold'>Woah, we are here</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='stepper-item' data-kt-stepper-element='nav'>
                                        <div className='stepper-wrapper'>
                                            <div className='stepper-icon w-40px h-40px'>
                                                <i className='stepper-check fas fa-check'></i>
                                                <span className='stepper-number'>11</span>
                                            </div>
                                            <div className='stepper-label'>
                                                <h3 className='stepper-title'>Completed</h3>
                                                <div className='stepper-desc fw-semibold'>Woah, we are here</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='stepper-item' data-kt-stepper-element='nav'>
                                        <div className='stepper-wrapper'>
                                            <div className='stepper-icon w-40px h-40px'>
                                                <i className='stepper-check fas fa-check'></i>
                                                <span className='stepper-number'>12</span>
                                            </div>
                                            <div className='stepper-label'>
                                                <h3 className='stepper-title'>Completed</h3>
                                                <div className='stepper-desc fw-semibold'>Woah, we are here</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='stepper-item' data-kt-stepper-element='nav'>
                                        <div className='stepper-wrapper'>
                                            <div className='stepper-icon w-40px h-40px'>
                                                <i className='stepper-check fas fa-check'></i>
                                                <span className='stepper-number'>13</span>
                                            </div>
                                            <div className='stepper-label'>
                                                <h3 className='stepper-title'>Completed</h3>
                                                <div className='stepper-desc fw-semibold'>Woah, we are here</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='stepper-item' data-kt-stepper-element='nav'>
                                        <div className='stepper-wrapper'>
                                            <div className='stepper-icon w-40px h-40px'>
                                                <i className='stepper-check fas fa-check'></i>
                                                <span className='stepper-number'>14</span>
                                            </div>
                                            <div className='stepper-label'>
                                                <h3 className='stepper-title'>Completed</h3>
                                                <div className='stepper-desc fw-semibold'>Woah, we are here</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='stepper-item' data-kt-stepper-element='nav'>
                                        <div className='stepper-wrapper'>
                                            <div className='stepper-icon w-40px h-40px'>
                                                <i className='stepper-check fas fa-check'></i>
                                                <span className='stepper-number'>15</span>
                                            </div>
                                            <div className='stepper-label'>
                                                <h3 className='stepper-title'>Completed</h3>
                                                <div className='stepper-desc fw-semibold'>Woah, we are here</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='stepper-item' data-kt-stepper-element='nav'>
                                        <div className='stepper-wrapper'>
                                            <div className='stepper-icon w-40px h-40px'>
                                                <i className='stepper-check fas fa-check'></i>
                                                <span className='stepper-number'>16</span>
                                            </div>
                                            <div className='stepper-label'>
                                                <h3 className='stepper-title'>Completed</h3>
                                                <div className='stepper-desc fw-semibold'>Woah, we are here</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='stepper-item' data-kt-stepper-element='nav'>
                                        <div className='stepper-wrapper'>
                                            <div className='stepper-icon w-40px h-40px'>
                                                <i className='stepper-check fas fa-check'></i>
                                                <span className='stepper-number'>17</span>
                                            </div>
                                            <div className='stepper-label'>
                                                <h3 className='stepper-title'>Completed</h3>
                                                <div className='stepper-desc fw-semibold'>Woah, we are here</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='d-flex flex-row-fluid flex-center bg-body rounded'>
                            <Formik
                                validationSchema={currentSchema} initialValues={initialFormValues} onSubmit={submitStep}>
                                {(formikProps) => (
                                    <Form className='py-20 w-100 w-xl-700px px-9' noValidate id='kt_create_account_form'>
                                        <div className='current' data-kt-stepper-element='content'>
                                            <div className='w-100'>
                                                <div className='d-flex flex-column mb-7 fv-row'>
                                                    <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                                                        <span className='required'>Company Name</span>
                                                    </label>
                                                    <Field name='company_name' className='form-control form-control-lg' />
                                                    <ErrorMessage name='company_name' component="div" className="text-danger" />
                                                </div>
                                            </div>
                                        </div>

                                        <div data-kt-stepper-element='content'>
                                            <div className='w-100'>
                                                <div className='d-flex flex-column mb-7 fv-row'>
                                                    <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                                                        <span className='required'>Type of Concern</span>
                                                    </label>
                                                    <div className="form-check form-check-custom form-check-solid mb-5 mt-5">
                                                        <Field
                                                            type="radio"
                                                            name="company_type_concern"
                                                            value="Private Limited"
                                                            id="Private Limited"
                                                            className="form-check-input"
                                                        />
                                                        <label className="form-check-label fw-bolder text-dark-500 text-hover-primary fs-5" htmlFor="Private Limited">Private Limited</label>
                                                    </div>
                                                    <div className='form-check form-check-custom form-check-solid mb-5'>
                                                        <Field
                                                            type="radio"
                                                            name="company_type_concern"
                                                            value="Proprietor"
                                                            id="Proprietor"
                                                            className="form-check-input"
                                                        />
                                                        <label className="form-check-label fw-bolder text-gray-500 text-hover-primary fs-5" htmlFor="Proprietor">Proprietor </label>
                                                    </div>
                                                    <div className='form-check form-check-custom form-check-solid mb-5'>
                                                        <Field
                                                            type="radio"
                                                            name="company_type_concern"
                                                            value="Partner"
                                                            id="Partner"
                                                            className="form-check-input"
                                                        />
                                                        <label className="form-check-label fw-bolder text-gray-500 text-hover-primary fs-5" htmlFor="Partner">Partner </label>
                                                    </div>
                                                    <div className='form-check form-check-custom form-check-solid'>
                                                        <Field
                                                            type="radio"
                                                            name="company_type_concern"
                                                            value="LLP"
                                                            id="LLP"
                                                            className="form-check-input"
                                                        />
                                                        <label className="form-check-label fw-bolder text-gray-500 text-hover-primary fs-5" htmlFor="LLP">LLP</label>
                                                    </div>
                                                    <ErrorMessage name='company_type_concern' component="div" className="text-danger" />
                                                </div>
                                            </div>
                                        </div>

                                        <div data-kt-stepper-element='content'>
                                            <div className='w-100'>
                                                <div className='d-flex flex-column mb-7 fv-row'>
                                                    <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                                                        <span className='required'>Date of Incorporation</span>
                                                    </label>
                                                    <Field type='date' name='date_incorporation' className='form-control form-control-lg' />
                                                    <ErrorMessage name='date_incorporation' component="div" className="text-danger" />
                                                </div>
                                            </div>
                                        </div>

                                        <div data-kt-stepper-element='content'>
                                            <div className='w-100'>
                                                <div className='d-flex flex-column mb-7 fv-row'>
                                                    <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                                                        <span className='required'>Email ID</span>
                                                    </label>
                                                    <Field
                                                        type='text'
                                                        className='form-control'
                                                        name='email_id'
                                                    />
                                                    <ErrorMessage name='email_id' component="div" className="text-danger" />
                                                </div>
                                            </div>
                                        </div>

                                        <div data-kt-stepper-element='content'>
                                            <div className='w-100'>
                                                <div className='d-flex flex-column mb-7 fv-row'>
                                                    <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                                                        <span className=''>Alternate Email ID</span>
                                                    </label>
                                                    <Field type='text' className='form-control' name='alternate_email_id' />
                                                    <ErrorMessage name='alternate_email_id' component="div" className="text-danger" />
                                                </div>
                                            </div>
                                        </div>

                                        <div data-kt-stepper-element='content'>
                                            <div className='w-100'>
                                                <div className='d-flex flex-column mb-7 fv-row'>
                                                    <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                                                        <span className='required'>Phone Number</span>
                                                    </label>
                                                    <Field type='number' className='form-control' name='company_phone_number' />
                                                    <ErrorMessage name='company_phone_number' component="div" className="text-danger" />
                                                </div>
                                            </div>
                                        </div>

                                        <div data-kt-stepper-element='content'>
                                            <div className='w-100'>
                                                <div className='d-flex flex-column mb-7 fv-row'>
                                                    <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                                                        <span className=''>Alternate Phone Number</span>
                                                    </label>
                                                    <Field type='number' className='form-control' name='company_alter_phone_number' />
                                                    <ErrorMessage name='company_alter_phone_number' component="div" className="text-danger" />
                                                </div>
                                            </div>
                                        </div>

                                        <div data-kt-stepper-element='content'>
                                            <div className='w-100'>
                                                <div className='d-flex flex-column mb-7 fv-row'>
                                                    <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                                                        <span className='required'>Installation Address</span>
                                                    </label>
                                                    <Field
                                                        as='textarea'
                                                        className='form-control'
                                                        name='company_installation_add'
                                                    />
                                                    <ErrorMessage name='company_installation_add' component="div" className="text-danger" />
                                                </div>

                                                <div className='d-flex flex-column mb-7 fv-row'>
                                                    <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                                                        <span className='required'>Country</span>
                                                    </label>
                                                    <Field
                                                        type='text'
                                                        className='form-control'
                                                        name='company_installation_country'
                                                    />
                                                    <ErrorMessage name='company_installation_country' component="div" className="text-danger" />
                                                </div>

                                                <div className='fv-row mb-10'>
                                                    <label className='form-label required'>State</label>
                                                    <Field
                                                        as='select'
                                                        name='company_installation_state'
                                                        className='form-select form-select-lg'
                                                    >
                                                        <option value='Pondicherry'>Pondicherry</option>
                                                        <option value='Tamil Nadu'>Tamil Nadu</option>
                                                    </Field>
                                                    <ErrorMessage name='company_installation_state' component="div" className="text-danger" />
                                                </div>

                                                <div className="form-check-solid mt-3 mb-3">
                                                    <label className="form-check-label pe-3">Same as the billing address ?</label>
                                                    <div className="form-check form-check-solid form-check-inline">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id="inlineCheckbox1"
                                                            value="option1"
                                                            checked={selectedOption === 'option1'}
                                                            onChange={() => {
                                                                setSelectedOption('option1');
                                                                formikProps.setFieldValue('company_billing_add', formikProps.values.company_installation_add || '');
                                                                formikProps.setFieldValue('company_billing_country', formikProps.values.company_installation_country || '');
                                                                formikProps.setFieldValue('company_billing_state', formikProps.values.company_installation_state || '');
                                                            }}
                                                        />
                                                        <label className="form-check-label" htmlFor="inlineCheckbox1">
                                                            Yes
                                                        </label>
                                                    </div>

                                                    <div className="form-check form-check-solid form-check-inline">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id="inlineCheckbox2"
                                                            value="option2"
                                                            checked={selectedOption === 'option2'}
                                                            onChange={() => {
                                                                setSelectedOption('option2');
                                                                formikProps.setFieldValue('company_billing_add', '');
                                                                formikProps.setFieldValue('company_billing_country', '');
                                                                formikProps.setFieldValue('company_billing_state', '');
                                                            }}
                                                        />
                                                        <label className="form-check-label" htmlFor="inlineCheckbox2">
                                                            No
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div data-kt-stepper-element='content'>
                                            <div className='w-100'>
                                                <div className='d-flex flex-column mb-7 fv-row'>
                                                    <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                                                        <span className='required'>Billing Address</span>
                                                    </label>
                                                    <Field
                                                        as='textarea'
                                                        className='form-control'
                                                        name='company_billing_add'
                                                    />
                                                    <ErrorMessage name='company_billing_add' component="div" className="text-danger" />
                                                </div>

                                                <div className='d-flex flex-column mb-7 fv-row'>
                                                    <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                                                        <span className='required'>Country</span>
                                                    </label>
                                                    <Field
                                                        type='text'
                                                        className='form-control'
                                                        name='company_billing_country'
                                                    />
                                                    <ErrorMessage name='company_billing_country' component="div" className="text-danger" />
                                                </div>

                                                <div className='fv-row mb-10'>
                                                    <label className='form-label required'>State</label>
                                                    <Field
                                                        as='select'
                                                        name='company_billing_state'
                                                        className='form-select form-select-lg'
                                                    >
                                                        <option value='Pondicherry'>Pondicherry</option>
                                                        <option value='Tamil Nadu'>Tamil Nadu</option>
                                                    </Field>
                                                    <ErrorMessage name='company_billing_state' component="div" className="text-danger" />
                                                </div>
                                            </div>
                                        </div>

                                        <div data-kt-stepper-element='content'>
                                            <div className='w-100'>
                                                <div className='d-flex flex-column mb-7 fv-row'>
                                                    <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                                                        <span className=''>GSTIN</span>
                                                    </label>
                                                    <Field type='text' className='form-control' name='company_gst' />
                                                    <ErrorMessage name='company_gst' component="div" className="text-danger" />
                                                </div>
                                            </div>
                                        </div>

                                        <div data-kt-stepper-element='content'>
                                            <div className='w-100'>
                                                <label className='form-label required mb-3'>Please Select Address Proof</label>
                                                <div className="form-check form-check-custom form-check-solid mb-3">
                                                    <Field
                                                        type="radio"
                                                        name="company_address_proof"
                                                        value="EB Bill"
                                                        id="EB Bill"
                                                        className="form-check-input"
                                                    />
                                                    <label className="form-check-label fw-bolder text-gray-500 text-hover-primary fs-5" htmlFor="EB Bill" onClick={() => handleAddProofChange('EB Bill')}>EB Bill</label>
                                                </div>
                                                <div className='form-check form-check-custom form-check-solid mt-3'>
                                                    <Field
                                                        type="radio"
                                                        name="company_address_proof"
                                                        value="GST"
                                                        id="GST"
                                                        className="form-check-input"
                                                    />
                                                    <label className="form-check-label fw-bolder text-gray-500 text-hover-primary fs-5" htmlFor="GST" onClick={() => handleAddProofChange('GST')}>GST</label>
                                                </div>
                                                <div className='form-check form-check-custom form-check-solid mt-3'>
                                                    <Field
                                                        type="radio"
                                                        name="company_address_proof"
                                                        value="Others"
                                                        id="Others"
                                                        className="form-check-input"
                                                    />
                                                    <label className="form-check-label fw-bolder text-gray-500 text-hover-primary fs-5" htmlFor="Others" onClick={() => handleAddProofChange('Others')}>Others</label>
                                                </div>
                                                <div className='text-danger mt-2'>
                                                    <ErrorMessage name='company_address_proof' />
                                                </div>
                                            </div>
                                        </div>

                                        <div data-kt-stepper-element='content'>
                                            <div className='d-none d-lg-block'>
                                                <div style={{ position: 'relative' }}>
                                                    <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                                                        <span className='required'>{formikProps.values.company_address_proof} Front Image</span>
                                                    </label>
                                                    {addProof_Front ? (
                                                        <img src={addProof_Front} alt="Address Captured" />
                                                    ) : (
                                                        <>
                                                            {showWebcamAdd ? (
                                                                <Webcam
                                                                    audio={false}
                                                                    ref={AddressPhoto}
                                                                    screenshotFormat="image/jpeg"
                                                                    width='50%'
                                                                    style={{ borderRadius: '15px' }}
                                                                />
                                                            ) : (
                                                                addProofImage ? (
                                                                    <div className='d-flex justify-content-center'>
                                                                        <div>
                                                                            <img src={addProofImage} alt="Address Image" className='form_proof_images' />
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    <Webcam
                                                                        audio={false}
                                                                        ref={AddressPhoto}
                                                                        screenshotFormat="image/jpeg"
                                                                        width='50%'
                                                                        style={{ borderRadius: '15px' }}
                                                                    />
                                                                )
                                                            )}
                                                        </>
                                                    )}
                                                    <div className='capture-buttons' style={{ position: 'absolute', bottom: '10px', left: '145px', cursor: 'pointer' }}>
                                                        {addProof_Front ? (
                                                            <img src={toAbsoluteUrl('/media/logos/reset.png')} onClick={retakeAddImage} className='w-40px' />
                                                        ) : (
                                                            <>
                                                                {showWebcamAdd ? (
                                                                    <img src={toAbsoluteUrl('/media/logos/camera.png')} onClick={captureAddImage} className='w-40px' />
                                                                ) : (addProofImage ? (
                                                                    <div className='badge badge-danger'>
                                                                        <img src={toAbsoluteUrl('/media/logos/reset.png')} onClick={() => setShowWebcamAdd(true)} className='w-40px' />
                                                                    </div>
                                                                ) : (
                                                                    <img src={toAbsoluteUrl('/media/logos/camera.png')} onClick={captureAddImage} className='w-40px' />
                                                                )
                                                                )}
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                                {address_proof_img && <div className="text-danger mt-2">Please Capture a {selectedAddProof} Address Photo.</div>}
                                                <h5 className='d-flex align-items-center mt-3' onClick={handleFrontImage}> <KTIcon iconName='question-2' className='fs-1 text-dark me-1' /> How to capture image</h5>
                                            </div>

                                            <div className='d-sm-none d-block w-100'>
                                                {addProofImage || addProof_Front ? (
                                                    <div className='d-flex justify-content-between'>
                                                        <div>
                                                            <h4 className='mb-5'>{formikProps.values.company_address_proof} Front Image</h4>
                                                        </div>
                                                        <div className='badge badge-danger p-3 '>
                                                            <span onClick={openFileInputAddFront} className='fs-4'><i className='fas fa-edit fs-1 pe-3 text-white'></i>Edit Image</span>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <>
                                                        <h4 className='mb-5'>{formikProps.values.company_address_proof} Front Image</h4>
                                                        <div className='camera_image_capture' onClick={openFileInputAddFront}>
                                                            <div>
                                                                <img src={toAbsoluteUrl('/media/logos/camera_capture.png')} alt="Open Camera" className='camera_image' />
                                                                <h5 className='text-center'>Click to capture</h5>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                                <input type="file" accept="image/*" capture={"camera" as boolean | "environment" | undefined}
                                                    onChange={handleAddProofFront}
                                                    style={{ display: 'none' }}
                                                    name="Add_FrontImage"
                                                />
                                                <div className='mt-5'>
                                                    {addProof_Front !== null ? (
                                                        <>
                                                            {addProof_Front && (
                                                                <div className='mt-5'>
                                                                    <img src={addProof_Front} alt="Captured" style={{ maxWidth: '100%' }} />
                                                                </div>
                                                            )}
                                                        </>
                                                    ) : (
                                                        addProofImage && <img src={addProofImage} className='w-100 rounded-3' alt="Address Proof Front" />
                                                    )}
                                                </div>
                                                {address_proof_img && <div className="text-danger mt-2">Please Capture a {selectedAddProof} Address Photo.</div>}
                                                <h5 className='d-flex align-items-center mt-3' onClick={handleFrontImage}> <KTIcon iconName='question-2' className='fs-1 text-dark me-1' /> How to capture image</h5>
                                            </div>
                                        </div>

                                        <div data-kt-stepper-element='content'>
                                            <div className='d-none d-lg-block'>
                                                {/* <div className='d-flex'>
                                                    <div> */}
                                                <div style={{ position: 'relative' }}>
                                                    <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                                                        <span className='required'>{formikProps.values.company_address_proof} Back Image</span>
                                                    </label>
                                                    {addProof_Back ? (
                                                        <img src={addProof_Back} alt="Address Captured" />
                                                    ) : (
                                                        <>
                                                            {showWebcamAddBack ? (
                                                                <Webcam
                                                                    audio={false}
                                                                    ref={AddressBackPhoto}
                                                                    screenshotFormat="image/jpeg"
                                                                    width='50%'
                                                                    style={{ borderRadius: '15px' }}
                                                                />
                                                            ) : (
                                                                addProofBackImage ? (
                                                                    <div className='d-flex justify-content-center'>
                                                                        <div>
                                                                            <img src={addProofBackImage} alt="Address Image" className='form_proof_images' />
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    <Webcam
                                                                        audio={false}
                                                                        ref={AddressBackPhoto}
                                                                        screenshotFormat="image/jpeg"
                                                                        width='50%'
                                                                        style={{ borderRadius: '15px' }}
                                                                    />
                                                                )
                                                            )}
                                                        </>
                                                    )}
                                                    <div className='capture-buttons' style={{ position: 'absolute', bottom: '10px', left: '145px', cursor: 'pointer' }}>
                                                        {addProof_Back ? (
                                                            <img src={toAbsoluteUrl('/media/logos/reset.png')} onClick={retakeAddBackImage} className='w-40px' />
                                                        ) : (
                                                            <>
                                                                {showWebcamAddBack ? (
                                                                    <img src={toAbsoluteUrl('/media/logos/camera.png')} onClick={captureAddBackImage} className='w-40px' />
                                                                ) : (addProofBackImage ? (
                                                                    <div className='badge badge-danger'>
                                                                        <img src={toAbsoluteUrl('/media/logos/reset.png')} onClick={() => setShowWebcamAddBack(true)} className='w-40px' />
                                                                    </div>
                                                                ) : <img src={toAbsoluteUrl('/media/logos/camera.png')} onClick={captureAddBackImage} className='w-40px' />
                                                                )}
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                                {address_proofBack_img && <div className="text-danger mt-2">Please Capture a {selectedAddProof} Back Page.</div>}
                                                <h5 className='d-flex align-items-center mt-3' onClick={handleBackImage}> <KTIcon iconName='question-2' className='fs-1 text-dark me-1' /> How to capture image</h5>
                                                {/* </div> */}

                                                {/* <div className='ms-3'>
                                                        <div style={{ position: 'relative' }}>
                                                            <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                                                                <span className='required'>{formikProps.values.company_address_proof} Third Image</span>
                                                            </label>
                                                            {addProof_third ? (
                                                                <img src={addProof_third} alt="Address Captured" />
                                                            ) : (
                                                                <>
                                                                    {showWebcamAddThird ? (
                                                                        <Webcam
                                                                            audio={false}
                                                                            ref={AddressThirdPhoto}
                                                                            screenshotFormat="image/jpeg"
                                                                            width='100%'
                                                                            style={{ borderRadius: '15px' }}
                                                                        />
                                                                    ) : (
                                                                        addProofBackImage ? (
                                                                            <div className='d-flex justify-content-center'>
                                                                                <div>
                                                                                    <img src={addProofBackImage} alt="Address Image" className='form_proof_images' />
                                                                                </div>
                                                                            </div>
                                                                        ) : (
                                                                            <Webcam
                                                                                audio={false}
                                                                                ref={AddressThirdPhoto}
                                                                                screenshotFormat="image/jpeg"
                                                                                width='100%'
                                                                                style={{ borderRadius: '15px' }}
                                                                            />
                                                                        )
                                                                    )}
                                                                </>
                                                            )}
                                                            <div className='capture-buttons' style={{ position: 'absolute', bottom: '10px', left: '145px', cursor: 'pointer' }}>
                                                                {addProof_third ? (
                                                                    <img src={toAbsoluteUrl('/media/logos/reset.png')} onClick={retakeAddThirdImage} className='w-40px' />
                                                                ) : (
                                                                    <>
                                                                        {showWebcamAddThird ? (
                                                                            <img src={toAbsoluteUrl('/media/logos/camera.png')} onClick={captureAddImageThird} className='w-40px' />
                                                                        ) : (addProofBackImage ? (
                                                                            <div className='badge badge-danger'>
                                                                                <img src={toAbsoluteUrl('/media/logos/reset.png')} onClick={() => setShowWebcamAddThird(true)} className='w-40px' />
                                                                            </div>
                                                                        ) : <img src={toAbsoluteUrl('/media/logos/camera.png')} onClick={captureAddImageThird} className='w-40px' />
                                                                        )}
                                                                    </>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <h5 className='d-flex align-items-center mt-3' onClick={handleBackImage}> <KTIcon iconName='question-2' className='fs-1 text-dark me-1' /> How to capture image</h5>
                                                    </div> */}
                                                {/* </div> */}
                                            </div>

                                            <div className='d-sm-none d-block w-100'>
                                                <div>
                                                    {addProofBackImage || addProof_Back ? (
                                                        <div className='d-flex justify-content-between'>
                                                            <div>
                                                                <h4 className='mb-5'>{formikProps.values.company_address_proof} Back Image</h4>
                                                            </div>
                                                            <div className='badge badge-danger p-3 '>
                                                                <span onClick={openFileInputAddBack} className='fs-4'><i className='fas fa-edit fs-1 pe-3 text-white'></i>Edit Image</span>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <>
                                                            <h4 className='mb-5'>{formikProps.values.company_address_proof} Back Image</h4>

                                                            <div className='camera_image_capture' onClick={openFileInputAddBack}>
                                                                <div>
                                                                    <img src={toAbsoluteUrl('/media/logos/camera_capture.png')} alt="Open Camera" className='camera_image' />
                                                                    <h5 className='text-center'>Click to capture</h5>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                    <input type="file" accept="image/*" capture={"camera" as boolean | "environment" | undefined}
                                                        onChange={handleAddProofBack}
                                                        style={{ display: 'none' }}
                                                        name="Add_BackImage"
                                                    />
                                                    <div className='mt-5'>
                                                        {addProof_Back !== null ? (
                                                            <>
                                                                {addProof_Back && (
                                                                    <div className='mt-5'>
                                                                        <img src={addProof_Back} alt="Captured" style={{ maxWidth: '100%' }} />
                                                                    </div>
                                                                )}
                                                            </>
                                                        ) : (
                                                            addProofBackImage && <img src={addProofBackImage} className='w-100 rounded-3' alt="Address Proof Back" />
                                                        )}
                                                    </div>
                                                    {address_proofBack_img && <div className="text-danger mt-2">Please Capture a {selectedAddProof} Back Page.</div>}
                                                    <h5 className='d-flex align-items-center mt-3' onClick={handleBackImage}> <KTIcon iconName='question-2' className='fs-1 text-dark me-1' /> How to capture image</h5>
                                                </div>
                                                {/* <div className='mt-5'>
                                                    {addProofBackImage || addProof_third ? (
                                                        <div className='d-flex justify-content-between'>
                                                            <div>
                                                                <h4 className='mb-5'>{formikProps.values.company_address_proof} Third Image</h4>
                                                            </div>
                                                            <div className='badge badge-danger p-3 '>
                                                                <span onClick={openFileInputAddThird} className='fs-4'><i className='fas fa-edit fs-1 pe-3 text-white'></i>Edit Image</span>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <>
                                                            <h4 className='mb-5'>{formikProps.values.company_address_proof} Third Image</h4>

                                                            <div className='camera_image_capture' onClick={openFileInputAddThird}>
                                                                <div>
                                                                    <img src={toAbsoluteUrl('/media/logos/camera_capture.png')} alt="Open Camera" className='camera_image' />
                                                                    <h5 className='text-center'>Click to capture</h5>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                    <input type="file" accept="image/*" capture={"camera" as boolean | "environment" | undefined}
                                                        onChange={handleAddProofThird}
                                                        style={{ display: 'none' }}
                                                        name="Add_ThirdImage"
                                                    />
                                                    <div className='mt-5'>
                                                        {addProof_third !== null ? (
                                                            <>
                                                                {addProof_third && (
                                                                    <div className='mt-5'>
                                                                        <img src={addProof_third} alt="Captured" style={{ maxWidth: '100%' }} />
                                                                    </div>
                                                                )}
                                                            </>
                                                        ) : (
                                                            addProofBackImage && <img src={addProofBackImage} className='w-100 rounded-3' alt="Address Proof Back" />
                                                        )}
                                                    </div>
                                                      <h5 className='d-flex align-items-center mt-3' onClick={handleBackImage}> <KTIcon iconName='question-2' className='fs-1 text-dark me-1' /> How to capture image</h5>
                                                </div> */}
                                            </div>
                                        </div>

                                        <div data-kt-stepper-element='content'>
                                            <div className='w-100'>
                                                <div className='mb-0 fv-row'>
                                                    <label className='d-flex align-items-center form-label mb-5'>
                                                        Please Select Id Proof
                                                        <i
                                                            className='fas fa-exclamation-circle ms-2 fs-7'
                                                            data-bs-toggle='tooltip'
                                                            title='Monthly billing will be based on your account plan'
                                                        ></i>
                                                    </label>

                                                    <div className='mb-0'>
                                                        <label className='d-flex flex-stack mb-5 cursor-pointer' onClick={() => handleIdProofChange('Aadhar Card')}>
                                                            <span className='d-flex align-items-center me-2'>
                                                                <span className='symbol symbol-50px me-6'>
                                                                    <span className='symbol-label'>
                                                                        <img src={toAbsoluteUrl('/media/custom/aathar_card.png')} className='w-50px' />
                                                                    </span>
                                                                </span>

                                                                <span className='d-flex flex-column'>
                                                                    <span className='fw-bolder text-gray-800 text-hover-primary fs-5'>
                                                                        Aadhar Card
                                                                    </span>
                                                                </span>
                                                            </span>

                                                            <span className='form-check form-check-custom form-check-solid'>
                                                                <Field className='form-check-input' type='radio' name='company_id_proof' value='Aadhar Card' />
                                                            </span>
                                                        </label>

                                                        <label className='d-flex flex-stack mb-5 cursor-pointer' onClick={() => handleIdProofChange('Voter ID')}>
                                                            <span className='d-flex align-items-center me-2'>
                                                                <span className='symbol symbol-50px me-6'>
                                                                    <span className='symbol-label'>
                                                                        <img src={toAbsoluteUrl('/media/custom/voterid1.png')} className='w-50px' />
                                                                    </span>
                                                                </span>

                                                                <span className='d-flex flex-column'>
                                                                    <span className='fw-bolder text-gray-800 text-hover-primary fs-5'>
                                                                        Voter ID
                                                                    </span>
                                                                </span>
                                                            </span>

                                                            <span className='form-check form-check-custom form-check-solid'>
                                                                <Field className='form-check-input' type='radio' name='company_id_proof' value='Voter ID' />
                                                            </span>
                                                        </label>

                                                        <label className='d-flex flex-stack mb-0 cursor-pointer' onClick={() => handleIdProofChange('Passport')}>
                                                            <span className='d-flex align-items-center me-2'>
                                                                <span className='symbol symbol-50px me-6'>
                                                                    <span className='symbol-label'>
                                                                        <img src={toAbsoluteUrl('/media/custom/passport.png')} className='w-50px' />
                                                                    </span>
                                                                </span>

                                                                <span className='d-flex flex-column'>
                                                                    <span className='fw-bolder text-gray-800 text-hover-primary fs-5'>
                                                                        Passport
                                                                    </span>
                                                                </span>
                                                            </span>

                                                            <span className='form-check form-check-custom form-check-solid'>
                                                                <Field className='form-check-input' type='radio' name='company_id_proof' value='Passport' />
                                                            </span>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className='text-danger mt-2'>
                                                    <ErrorMessage name='company_id_proof' />
                                                </div>
                                            </div>
                                        </div>

                                        <div data-kt-stepper-element='content'>
                                            <div className='d-none d-lg-block'>
                                                <div style={{ position: 'relative' }}>
                                                    <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                                                        <span className='required'>{formikProps.values.company_id_proof}  Front Page</span>
                                                    </label>
                                                    {idProof_Front ? (
                                                        <img src={idProof_Front} alt="ID Proof Front Page" />
                                                    ) : (
                                                        <>
                                                            {showWebcam ? (
                                                                <Webcam
                                                                    audio={false}
                                                                    ref={IdFrontPhoto}
                                                                    screenshotFormat="image/jpeg"
                                                                    width='50%'
                                                                    style={{ borderRadius: '15px' }}
                                                                />
                                                            ) : (
                                                                idProofFrontImage ? (
                                                                    <div className='d-flex justify-content-center'>
                                                                        <div>
                                                                            <img src={idProofFrontImage} alt="ID Proof Front Page" className='form_proof_images' />
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    <Webcam
                                                                        audio={false}
                                                                        ref={IdFrontPhoto}
                                                                        screenshotFormat="image/jpeg"
                                                                        width='50%'
                                                                        style={{ borderRadius: '15px' }}
                                                                    />
                                                                )
                                                            )}
                                                        </>
                                                    )}
                                                    <div className='capture-buttons' style={{ position: 'absolute', bottom: '10px', left: '145px', cursor: 'pointer' }}>
                                                        {idProof_Front ? (
                                                            <img src={toAbsoluteUrl('/media/logos/reset.png')} onClick={retakeFrontImage} className='w-40px' />
                                                        ) : (
                                                            <>
                                                                {showWebcam ? (
                                                                    <img src={toAbsoluteUrl('/media/logos/camera.png')} onClick={captureFrontImage} className='w-40px' />
                                                                ) : (idProofFrontImage ? (
                                                                    <div className='badge badge-danger'>
                                                                        <img src={toAbsoluteUrl('/media/logos/reset.png')} onClick={() => setShowWebcam(true)} className='w-40px' />
                                                                    </div>
                                                                ) : (
                                                                    <img src={toAbsoluteUrl('/media/logos/camera.png')} onClick={captureFrontImage} className='w-40px' />
                                                                )
                                                                )}
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                                {frontImageError && <div className="text-danger mt-2">Please capture {selectedIdProof} front page.</div>}
                                                <h5 className='d-flex align-items-center mt-3' onClick={handleFrontImage}> <KTIcon iconName='question-2' className='fs-1 text-dark me-1' /> How to capture image</h5>
                                            </div>

                                            <div className='d-sm-none d-block w-100'>
                                                {idProofFrontImage || idProof_Front ? (
                                                    <div className='d-flex justify-content-between'>
                                                        <div>
                                                            <h4 className='mb-5'>{formikProps.values.company_id_proof} Front Page</h4>
                                                        </div>
                                                        <div className='badge badge-danger p-3 '>
                                                            <span onClick={openFileInput} className='fs-4'><i className='fas fa-edit fs-1 pe-3 text-white'></i>Edit Image</span>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <>
                                                        <h4 className='mb-5'>{formikProps.values.company_id_proof} Front Page</h4>
                                                        <div className='camera_image_capture' onClick={openFileInput}>
                                                            <div>
                                                                <img src={toAbsoluteUrl('/media/logos/camera_capture.png')} alt="Open Camera" className='camera_image' />
                                                                <h5 className='text-center'>Click to capture</h5>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                                <input type="file" accept="image/*" capture={"camera" as boolean | "environment" | undefined}
                                                    onChange={handleIdProofFront}
                                                    style={{ display: 'none' }}
                                                    name="idFrontImage"
                                                />
                                                <div className='mt-5'>
                                                    {idProof_Front !== null ? (
                                                        <>
                                                            {idProof_Front && (
                                                                <div className='mt-5'>
                                                                    <img src={idProof_Front} alt="Captured" style={{ maxWidth: '100%' }} />
                                                                </div>
                                                            )}
                                                        </>
                                                    ) : (
                                                        idProofFrontImage && <img src={idProofFrontImage} className='w-100 rounded-3' alt="ID Proof Front" />
                                                    )}
                                                </div>
                                                {frontImageError && <div className="text-danger mt-2">Please capture {selectedIdProof} front page.</div>}
                                                <h5 className='d-flex align-items-center mt-3' onClick={handleFrontImage}> <KTIcon iconName='question-2' className='fs-1 text-dark me-1' /> How to capture image</h5>
                                            </div>
                                        </div>

                                        <div data-kt-stepper-element='content'>
                                            <div className='d-none d-lg-block'>
                                                <div style={{ position: 'relative' }}>
                                                    <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                                                        <span className='required'>{formikProps.values.company_id_proof} Back Page</span>
                                                    </label>
                                                    {idProof_Back ? (
                                                        <img src={idProof_Back} alt="ID Proof Back Page" />
                                                    ) : (
                                                        <>
                                                            {showWebcamBack ? (
                                                                <Webcam
                                                                    audio={false}
                                                                    ref={IdBackPhoto}
                                                                    screenshotFormat="image/jpeg"
                                                                    width='50%'
                                                                    style={{ borderRadius: '15px' }}
                                                                />
                                                            ) : (
                                                                idProofBackImage ? (
                                                                    <div className='d-flex justify-content-center'>
                                                                        <div>
                                                                            <img src={idProofBackImage} alt="ID Proof Back Page" className='form_proof_images' />
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    <Webcam
                                                                        audio={false}
                                                                        ref={IdBackPhoto}
                                                                        screenshotFormat="image/jpeg"
                                                                        width='50%'
                                                                        style={{ borderRadius: '15px' }}
                                                                    />
                                                                )
                                                            )}
                                                        </>
                                                    )}
                                                    <div className='capture-buttons' style={{ position: 'absolute', bottom: '10px', left: '145px', cursor: 'pointer' }}>
                                                        {idProof_Back ? (
                                                            <img src={toAbsoluteUrl('/media/logos/reset.png')} onClick={retakeBackImage} className='w-40px' />
                                                        ) : (
                                                            <>
                                                                {showWebcamBack ? (
                                                                    <img src={toAbsoluteUrl('/media/logos/camera.png')} onClick={captureBackImage} className='w-40px' />
                                                                ) : (idProofBackImage ? (
                                                                    <div className='badge badge-danger'>
                                                                        <img src={toAbsoluteUrl('/media/logos/reset.png')} onClick={() => setShowWebcamBack(true)} className='w-40px' />
                                                                    </div>
                                                                ) : (
                                                                    <img src={toAbsoluteUrl('/media/logos/camera.png')} onClick={captureBackImage} className='w-40px' />
                                                                )
                                                                )}
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                                {backImageError && <div className="text-danger mt-2">Please capture {selectedIdProof} back page.</div>}
                                                <h5 className='d-flex align-items-center mt-3' onClick={handleBackImage}> <KTIcon iconName='question-2' className='fs-1 text-dark me-1' /> How to capture image</h5>
                                            </div>

                                            <div className='d-sm-none d-block w-100'>
                                                {idProofBackImage || idProof_Back ? (
                                                    <div className='d-flex justify-content-between'>
                                                        <div>
                                                            <h4 className='mb-5'>{formikProps.values.company_id_proof} Back Page</h4>
                                                        </div>
                                                        <div className='badge badge-danger p-3 '>
                                                            <span onClick={openFileInputBack} className='fs-4'><i className='fas fa-edit fs-1 pe-3 text-white'></i>Edit Image</span>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <>
                                                        <h4 className='mb-5'>{formikProps.values.company_id_proof} Back Page</h4>
                                                        <div className='camera_image_capture' onClick={openFileInputBack}>
                                                            <div>
                                                                <img src={toAbsoluteUrl('/media/logos/camera_capture.png')} alt="Open Camera" className='camera_image' />
                                                                <h5 className='text-center'>Click to capture</h5>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                                <input type="file" accept="image/*" capture={"camera" as boolean | "environment" | undefined}
                                                    onChange={handleIdProofBack}
                                                    style={{ display: 'none' }}
                                                    name="idBackImage"
                                                />
                                                <div className='mt-5'>
                                                    {idProof_Back !== null ? (
                                                        <>
                                                            {idProof_Back && (
                                                                <div className='mt-5'>
                                                                    <img src={idProof_Back} alt="Captured" style={{ maxWidth: '100%' }} />
                                                                </div>
                                                            )}
                                                        </>
                                                    ) : (
                                                        idProofBackImage && <img src={idProofBackImage} className='w-100 rounded-3' alt="ID Proof Back" />
                                                    )}
                                                </div>
                                                {backImageError && <div className="text-danger mt-2">Please capture {selectedIdProof} back page.</div>}
                                                <h5 className='d-flex align-items-center mt-3' onClick={handleBackImage}> <KTIcon iconName='question-2' className='fs-1 text-dark me-1' /> How to capture image</h5>
                                            </div>
                                        </div>

                                        <div data-kt-stepper-element='content'>
                                            <div className='d-none d-lg-block'>
                                                <div style={{ position: 'relative' }}>
                                                    <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                                                        <span className='required'>Your Photo</span>
                                                    </label>
                                                    {customer_photo ? (
                                                        <img src={customer_photo} alt="Customer Image" />
                                                    ) : (
                                                        <>
                                                            {showWebcamCust ? (
                                                                <>
                                                                    <Webcam
                                                                        audio={false}
                                                                        ref={CustomerPhoto}
                                                                        screenshotFormat="image/jpeg"
                                                                        width='50%'
                                                                        style={{ borderRadius: '15px' }}
                                                                    />
                                                                </>
                                                            ) : (
                                                                customerImage ? (
                                                                    <div className='d-flex'>
                                                                        <div>
                                                                            <img src={customerImage} alt="Customer Image" className='form_proof_images' />
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    <Webcam
                                                                        audio={false}
                                                                        ref={CustomerPhoto}
                                                                        screenshotFormat="image/jpeg"
                                                                        width='50%'
                                                                        style={{ borderRadius: '15px' }}
                                                                    />
                                                                )
                                                            )}
                                                        </>
                                                    )}
                                                    <div className='capture-buttons' style={{ position: 'absolute', bottom: '10px', left: '145px', cursor: 'pointer' }}>
                                                        {customer_photo ? (
                                                            <img src={toAbsoluteUrl('/media/logos/reset.png')} onClick={retake} className='w-40px' />
                                                        ) : (
                                                            <>
                                                                {showWebcamCust ? (
                                                                    <img src={toAbsoluteUrl('/media/logos/camera.png')} onClick={capturePhoto} className='w-40px' />
                                                                ) : (customerImage ? (
                                                                    <div className='badge badge-danger'>
                                                                        <img src={toAbsoluteUrl('/media/logos/reset.png')} onClick={() => setShowWebcamCust(true)} className='w-40px' />
                                                                    </div>
                                                                ) : (
                                                                    <img src={toAbsoluteUrl('/media/logos/camera.png')} onClick={capturePhoto} className='w-40px' />
                                                                )
                                                                )}
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                                {showWarning && <div className="text-danger mt-2">Please capture a photo.</div>}
                                                <h5 className='d-flex align-items-center mt-3' onClick={handleCustomer}> <KTIcon iconName='question-2' className='fs-1 text-dark me-1' /> How to capture image</h5>
                                            </div>

                                            <div className='d-sm-none d-block w-100'>
                                                {customerImage || customer_photo ? (
                                                    <div className='d-flex justify-content-between'>
                                                        <div>
                                                            <h4 className='mb-5'>Your Photo</h4>
                                                        </div>
                                                        <div className='badge badge-danger p-3 '>
                                                            <span onClick={openFileInputCustomer} className='fs-4'><i className='fas fa-edit fs-1 pe-3 text-white'></i>Edit Image</span>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <>
                                                        <h4 className='mb-5'>Your Photo</h4>

                                                        <div className='camera_image_capture' onClick={openFileInputCustomer}>
                                                            <div>
                                                                <img src={toAbsoluteUrl('/media/logos/camera_capture.png')} alt="Open Camera" className='camera_image' />
                                                                <h5 className='text-center'>Click to capture</h5>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                                <input type="file" accept="image/*" capture={"camera" as boolean | "environment" | undefined}
                                                    onChange={handleCustomerImage}
                                                    style={{ display: 'none' }}
                                                    name="CustomerImage"
                                                />
                                                <div className='mt-5'>
                                                    {customer_photo !== null ? (
                                                        <>
                                                            {customer_photo && (
                                                                <div className='mt-5'>
                                                                    <img src={customer_photo} alt="customer_image" style={{ maxWidth: '100%' }} />
                                                                </div>
                                                            )}
                                                        </>
                                                    ) : (
                                                        customerImage && <img src={customerImage} className='w-100 rounded-3' alt="Customer Image" />
                                                    )}
                                                </div>
                                                {showWarning && <div className="text-danger mt-2">Please capture a photo.</div>}
                                                <h5 className='d-flex align-items-center mt-3' onClick={handleCustomer}> <KTIcon iconName='question-2' className='fs-1 text-dark me-1' /> How to capture image</h5>
                                            </div>
                                        </div>

                                        <div data-kt-stepper-element='content'>
                                            <div className='w-100'>
                                                <div className='d-flex flex-column mb-7 fv-row'>
                                                    <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                                                        <span className='required'>Authorised Signatory</span>
                                                    </label>
                                                    <div style={{ border: '2px solid black', borderRadius: '5px', padding: '5px', }}>
                                                        <SignatureCanvas
                                                            ref={sigCanvas}
                                                            penColor="blue"
                                                            canvasProps={{ width: canvasWidth, height: 200, className: 'sigCanvas' }}
                                                        />
                                                    </div>
                                                    <span className='btn btn-light-danger mt-5' onClick={clearCanvas}>Clear Signature</span>
                                                </div>
                                                {companySign ?
                                                    <img src={companySign} alt='Auth_Sign_Image' className='w-100 border border-dark' />
                                                    : (showSignatureWarning && <div className="text-danger mt-2">Please provide a signature.</div>)
                                                }

                                                <div>
                                                    <Declaration />
                                                </div>
                                                <div className="form-check form-check-solid mt-3 mb-3">
                                                    <Field
                                                        type="checkbox"
                                                        name="terms_condition"
                                                        value="terms_condition"
                                                        className="form-check-input"
                                                        id="terms_condition"
                                                        onClick={openPrivacymodal}
                                                    />
                                                    <label className="form-check-label text-dark" htmlFor="terms_condition">I Accept Terms & Conditions</label>
                                                </div>
                                                {privacyWarning && <div className="text-danger mt-2">Please accept  terms condition.</div>}
                                            </div>
                                        </div>

                                        <div className='d-flex flex-stack pt-10'>
                                            <div className='mr-2'>
                                                <button
                                                    onClick={prevStep}
                                                    type='button'
                                                    className='btn btn-lg btn-light-primary me-3'
                                                    data-kt-stepper-action='previous'
                                                >
                                                    <KTIcon iconName='arrow-left' className='fs-4 me-1' />
                                                    Back
                                                </button>
                                            </div>
                                            <div>
                                                <button type="submit" className="btn btn-lg btn-primary me-3">
                                                    <span className='indicator-label'>
                                                        {loading && (
                                                            <span className='indicator-progress' style={{ display: 'block' }}>
                                                                Please wait...{' '}
                                                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                            </span>
                                                        )}
                                                        {!loading && stepper.current?.currentStepIndex == null && 'Continue'}
                                                        {!loading && stepper.current?.currentStepIndex !== stepper.current?.totalStepsNumber && 'Continue'}
                                                        {!loading && stepper.current?.currentStepIndex === stepper.current?.totalStepsNumber && stepper.current?.currentStepIndex != null && 'Submit'}
                                                        {!loading && <KTIcon iconName='arrow-right' className='fs-3 ms-2 me-0' />}
                                                    </span>
                                                </button>
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </>
            ) : viewDetailsItem?.app_company_status === '3' ? (
                <div className="d-flex justify-content-center p-10">
                    <div>
                        <img src={toAbsoluteUrl('/media/illustrations/sigma-1/completed.png')} className='w-350px' />
                        <h3 className="text-center mt-5">Your Application is Completed</h3>
                    </div>
                </div>
            ) :
                <div className="d-flex justify-content-center p-10">
                    <div>
                        <img src={toAbsoluteUrl('/media/illustrations/sigma-1/review.png')} className='w-350px' />
                        <h3 className="text-center">Your Application is Under Review</h3>
                    </div>
                </div>
            }

            <div className={`modal${showModalPrivacy ? ' show' : ''}`} role="dialog" style={{ display: showModalPrivacy ? 'block' : 'none' }}>
                <div className="modal-dialog modal-lg modal-dialog-centered" role="document" >
                    <div className="modal-content" >
                        <div className="modal-header">
                            <h5 className="modal-title">Terms & Conditions</h5>
                            <button type="button" className="btn-close" onClick={() => setShowModalPrivacy(false)} aria-label="Close"></button>
                        </div>
                        <div className="modal-body" style={{ overflowY: 'auto', maxHeight: '450px' }}>
                            <Privacy_Policy />
                        </div>
                        <div className="modal-footer">
                            <div className='card-footer d-flex justify-content-end py-6 px-9'>
                                <button type='submit' className='btn btn-primary' disabled={loading} onClick={() => setShowModalPrivacy(false)}>
                                    {!loading && 'Ok'}
                                    {loading && (
                                        <span className='indicator-progress' style={{ display: 'block' }}>
                                            Please wait...{' '}
                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                        </span>
                                    )}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`modal${showModal ? ' show' : ''}`} role="dialog" style={{ display: showModal ? 'block' : 'none' }}>
                <div className="modal-dialog  modal-dialog-centered" role="document" >
                    <div className="modal-content" >
                        <div className="modal-header">
                            <button type="button" className="btn-close" onClick={() => setShowModal(false)} aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="d-flex justify-content-center">
                                <div>
                                    <img src={toAbsoluteUrl('/media/stock/Guideline_image.png')} />
                                </div>
                            </div>
                            <h3>Instructions :</h3>
                            <ul className='fs-4'>
                                <li>Click to capture the front page of
                                    <span className='ms-1'>{!selectedIdProof ? selectedAddProof : selectedIdProof}.</span>
                                </li>
                                <li>Validate and submit the information.</li>
                                <li>Confirm the validated capture.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`modal${backImage ? ' show' : ''}`} role="dialog" style={{ display: backImage ? 'block' : 'none' }}>
                <div className="modal-dialog  modal-dialog-centered" role="document" >
                    <div className="modal-content" >
                        <div className="modal-header">
                            <button type="button" className="btn-close" onClick={() => setBackImage(false)} aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="d-flex justify-content-center">
                                <div>
                                    <img src={toAbsoluteUrl('/media/stock/Guideline_image.png')} />
                                </div>
                            </div>
                            <h3>Instructions :</h3>
                            <ul className='fs-4'>
                                <li>Click to capture the back page of
                                    <span className='ms-1'>{!selectedIdProof ? selectedAddProof : selectedIdProof}.</span>
                                </li>
                                <li>Validate and submit the information.</li>
                                <li>Confirm the validated capture.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`modal${custImage ? ' show' : ''}`} role="dialog" style={{ display: custImage ? 'block' : 'none' }}>
                <div className="modal-dialog  modal-dialog-centered" role="document" >
                    <div className="modal-content" >
                        <div className="modal-header">
                            <button type="button" className="btn-close" onClick={() => setCustImage(false)} aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="d-flex justify-content-center">
                                <div>
                                    <img src={toAbsoluteUrl('/media/stock/Guideline_image(2).png')} />
                                </div>
                            </div>
                            <h3>Instructions :</h3>
                            <ul className='fs-4'>
                                <li>Validate and submit the information.</li>
                                <li>Confirm the validated capture.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export { CompanyEdit }
