import * as Yup from 'yup'

export interface ICreateAccount {
  id_proof: string
  address_proof: string
  customer_name: string
  father_name: string
  gender: string
  nationality: string
  dob: string
  email: string
  phone_number: string
  alternate_number: string
  installation_add: string
  country: string
  state: string
  billing_add: string
  billing_country: string
  billing_city: string
}

export interface ICreateAccountEDIT {
  app_id_upd: string;
  app_dot_id_upd: string;
  id_proof: string;
  address_proof: string;
  customer_name: string;
  father_name: string;
  gender: string;
  nationality: string;
  dob: string;
  email: string;
  phone_number: string;
  alternate_number: string;
  installation_add: string;
  country: string;
  state: string;
  billing_add: string;
  billing_country: string;
  billing_city: string;
  app_status_upd: string;
}

const inits: ICreateAccount = {
  id_proof: '',
  address_proof: '',
  customer_name: '',
  father_name: '',
  gender: '',
  nationality: 'Indian',
  dob: '',
  email: '',
  phone_number: '',
  alternate_number: '',
  installation_add: '',
  country: 'India',
  state: 'Pondicherry',
  billing_add: '',
  billing_country: 'India',
  billing_city: 'Pondicherry',
}

const createAccountSchemas = [
  Yup.object({
    id_proof: Yup.string().required().label('Id Proof'),
  }),
  null,
  null,
  Yup.object({
    address_proof: Yup.string().required().label('Address Proof'),
  }),
  null,
  null,
  Yup.object({
    customer_name: Yup.string().required().label('Name'),
  }),
  Yup.object({
    father_name: Yup.string().required().label('Father Name'),
  }),
  Yup.object({
    gender: Yup.string().required().label('Gender'),
  }),
  Yup.object({
    nationality: Yup.string().required().label('Nationality'),
  }),
  Yup.object({
    dob: Yup.string().required().label('Date Of Birth'),
  }),
  Yup.object({
    email: Yup.string().required().email('Invalid email').label('Email'),
  }),
  Yup.object({
    phone_number: Yup.string().matches(/^[0-9]{10}$/, 'Phone number must be exactly 10 digits').required('Phone Number is required'),
  }),
  Yup.object({
    alternate_number: Yup.string().matches(/^[0-9]{10}$/, 'Alternate number must be exactly 10 digits'),
  }),
  Yup.object({
    installation_add: Yup.string().required().label('Installation Address'),
    country: Yup.string().required().label('Country'),
    state: Yup.string().required().label('state'),
  }),
  Yup.object({
    billing_add: Yup.string().required().label('Billing Address'),
    billing_country: Yup.string().required().label('Country'),
    billing_city: Yup.string().required().label('City'),
  }),
  null,
]

export interface CompanyAccount {
  company_name: string
  company_type_concern: string
  date_incorporation: string
  email_id: string
  alternate_email_id: string
  company_phone_number: string
  company_alter_phone_number: string
  company_installation_add: string
  company_installation_country: string
  company_installation_state: string
  company_billing_add: string
  company_billing_country: string
  company_billing_state: string
  company_gst: string
  company_id_proof: string
  company_address_proof: string
}

export interface CompanyAccountEdit {
  app_id_upd: string;
  app_dot_id_upd: string;
  company_name: string
  company_type_concern: string
  date_incorporation: string
  email_id: string
  alternate_email_id: string
  company_phone_number: string
  company_alter_phone_number: string
  company_id_proof: string
  company_address_proof: string
  company_installation_add: string
  company_installation_country: string
  company_installation_state: string
  company_billing_add: string
  company_billing_country: string
  company_billing_state: string
  company_gst: string
  app_status_upd: string;
}

const CompanyInitial: CompanyAccount = {
  company_name: '',
  company_type_concern: '',
  date_incorporation: '',
  email_id: '',
  alternate_email_id: '',
  company_phone_number: '',
  company_alter_phone_number: '',
  company_installation_add: '',
  company_installation_country: 'India',
  company_installation_state: 'Pondicherry',
  company_billing_add: '',
  company_billing_country: 'India',
  company_billing_state: 'Pondicherry',
  company_gst: '',
  company_id_proof: '',
  company_address_proof: '',
}

const CompanyAccountSchema = [
  Yup.object({
    company_name: Yup.string().required().label('Company Name'),
  }),
  Yup.object({
    company_type_concern: Yup.string().required().label('Type of Concern'),
  }),
  Yup.object({
    date_incorporation: Yup.string().required().label('Date of Incorporation'),
  }),
  Yup.object({
    email_id: Yup.string().required().email('Invalid email').label('Email ID'),
  }),
  Yup.object({
    alternate_email_id: Yup.string().email('Invalid email').label('Alternate Email Id'),
  }),
  Yup.object({
    company_phone_number: Yup.string().matches(/^[0-9]{10}$/, 'Phone number must be exactly 10 digits').required('Phone Number is required'),
  }),
  Yup.object({
    company_alter_phone_number: Yup.string().matches(/^[0-9]{10}$/, 'Phone number must be exactly 10 digits'),
  }),
  Yup.object({
    company_installation_add: Yup.string().required().label('Installation Address'),
    company_installation_country: Yup.string().required().label('Country'),
    company_installation_state: Yup.string().required().label('State'),
  }),
  Yup.object({
    company_billing_add: Yup.string().required().label('Billing Address'),
    company_billing_country: Yup.string().required().label('Country'),
    company_billing_state: Yup.string().required().label('State'),
  }),
  Yup.object({
    company_gst: Yup.string().matches(/^[A-Z0-9]{15}$/, 'GST number must be exactly 15 characters'),
  }),
  Yup.object({
    company_address_proof: Yup.string().required().label('Address Proof'),
  }),
  null,
  null,
  Yup.object({
    company_id_proof: Yup.string().required().label('Id Proof'),
  }),
  null,
  null,
  null,
]

export interface TemplateForm {
  template_title: string
  template_bgColor: string
  template_textColor: string
}

const templateinitial: TemplateForm = {
  template_title: '',
  template_bgColor: '',
  template_textColor: ''
}

const templateSchemas = [
  Yup.object().shape({
    template_bgColor: Yup.string().required('Theme background color is required'),
    template_textColor: Yup.string().required('Theme text color is required'),
  }),
  Yup.object().shape({
    template_title: Yup.string().required('Please enter template title'),
  }),

]

const SettingsValidation = [
  Yup.object({
    onboarding_name: Yup.string().required('Name is required'),
    onboarding_email: Yup.string().email('Invalid email').required('Email is required'),
    onboarding_number: Yup.string().required('Phone number is required'),
  }),
  Yup.object({
    onboarding_address: Yup.string().required('Address is required'),
    onboarding_additional: Yup.string().required('Address is required'),
    onboarding_zipcode: Yup.string().required('Address is required'),
    onboarding_city: Yup.string().required('City is required'),
    onboarding_state: Yup.string().required('State is required'),
    onboarding_country: Yup.string().required('Country is required'),
  }),
  Yup.object({
    onboarding_socialMediaProfile: Yup.string(),
  }),
  Yup.object({
    onboarding_template: Yup.string().required('Template choice is required'),
    onboarding_headingFont: Yup.string().required('Heading font is required'),
    onboarding_paragraphFont: Yup.string().required('Paragraph font is required'),
    onboarding_themeColor: Yup.string().required('Theme color is required'),
  }),
]

export { createAccountSchemas, inits, SettingsValidation, CompanyInitial, CompanyAccountSchema, templateinitial, templateSchemas }
