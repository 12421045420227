import { useRef, useEffect, useState } from 'react'
import { handleImageDrop } from '../../../pages/CompressImage';
import Swal from 'sweetalert2';
import { Individual_POST_Application, GET_ALL_USERS, Application_check_vendor } from '../core/_requests'
import * as Yup from 'yup';
import { Form, ErrorMessage, Field, Formik, FormikValues } from 'formik'
import { useDropzone } from "react-dropzone";
import SignatureCanvas from 'react-signature-canvas';
import { AccountDetails } from '../core/_models';
import CompanyFormAdmin from './CompanyFormAdmin';

interface ICreateAccount {
    app_dot_id: string
    id_proof: string
    address_proof: string
    customer_name: string
    father_name: string
    gender: string
    nationality: string
    dob: string
    email: string
    phone_number: string
    alternate_number: string
    installation_add: string
    country: string
    state: string
    billing_add: string
    billing_country: string
    billing_city: string
}

interface CreditDetails {
    vendor_credit: string;
    app_used: string;
    message: string;
}


const IndividualFormAdmin = ({ setShowModal, fetchApplicationData }: any) => {
    const UserDetails: any = localStorage.getItem('user-details')
    const userDetailsObject = JSON.parse(UserDetails);
    const userRole = userDetailsObject.role;
    const user_id = userDetailsObject?.id ?? null;

    const [selectedAccountType, setSelectedAccountType] = useState('Individual');
    const [loading, setLoading] = useState(false)
    const [credit, setCredit] = useState<CreditDetails | undefined>();
    const [idProof_Front, setIdProof_Front] = useState<File[]>([]);
    const [idProof_Back, setIdProof_Back] = useState<File[]>([]);
    const [addProof_Front, setAddProof_Front] = useState<File[]>([]);
    const [addProof_Back, setAddProof_Back] = useState<File[]>([]);
    const [customer_photo, setCustomer_photo] = useState<File[]>([]);
    const [userData, setUserData] = useState<AccountDetails[]>([]);
    const [selectedType, setSelectedType] = useState(user_id);

    const inits: ICreateAccount = {
        app_dot_id: '',
        id_proof: '',
        address_proof: '',
        customer_name: '',
        father_name: '',
        gender: '',
        nationality: 'Indian',
        dob: '',
        email: '',
        phone_number: '',
        alternate_number: '',
        installation_add: '',
        country: 'India',
        state: 'Pondicherry',
        billing_add: '',
        billing_country: 'India',
        billing_city: 'Pondicherry',
    }

    const validationSchema = Yup.object({
        // id_proof: Yup.string().required('ID Proof is required'),
        // address_proof: Yup.string().required('Address Proof is required'),
        customer_name: Yup.string().required('Enter Your Name is required'),
        // father_name: Yup.string().required('Father/Spouse/Guardian Name is required'),
        // gender: Yup.string().required('Gender is required'),
        // nationality: Yup.string().required('Nationality is required'),
        // dob: Yup.date().required('Date of Birth is required'),
        // email: Yup.string().email('Invalid email').required('Email is required'),
        // phone_number: Yup.string().matches(/^[0-9]{10}$/, 'Phone number must be exactly 10 digits').required('Phone Number is required'),
        // alternate_number: Yup.string().matches(/^[0-9]{10}$/, 'Alternate number must be exactly 10 digits'),
        // installation_add: Yup.string().required('Installation Address is required'),
        // country: Yup.string().required('Country is required'),
        // state: Yup.string().required('State is required'),
        // billing_add: Yup.string().required('Billing Address is required'),
        // billing_country: Yup.string().required('Billing Country is required'),
        // billing_city: Yup.string().required('Billing State is required'),
    });

    const sigCanvas = useRef<SignatureCanvas>(null);

    const AppType: any = localStorage.getItem('vendor_type')

    const handleAccountTypeChange = (event: any) => {
        setSelectedAccountType(event.target.value);
    };

    const clearCanvas = () => {
        if (sigCanvas.current) {
            sigCanvas.current.clear();
        }
    };

    const handleTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedType(event.target.value);
    };

    const fetchUserData = async (all_users: any) => {
        try {
            const response = await GET_ALL_USERS(all_users);
            setUserData(response.data.account_details);
        } catch (error) {
            console.error('Error fetching members:', error);
        }
    };

    useEffect(() => {
        fetchUserData("0");
    }, []);

    const vendorCredit = async (userId: any) => {
        try {
            const response = await Application_check_vendor(userId)
            setCredit(response.data);
        } catch (error) {
            console.error('Error fetching credits:', error);
        }
    }

    useEffect(() => {
        vendorCredit(user_id);
    }, []);

    const { getRootProps: getRootProps1, getInputProps: getInputProps1 } = useDropzone({
        onDrop: acceptedFiles => handleImageDrop(acceptedFiles, setIdProof_Front),
        accept: {
            'image/jpeg': [],
            'image/png': [],
        },
    });

    const { getRootProps: getRootProps2, getInputProps: getInputProps2 } = useDropzone({
        onDrop: acceptedFiles => handleImageDrop(acceptedFiles, setIdProof_Back),
        accept: {
            'image/jpeg': [],
            'image/png': [],
        },
    });

    const { getRootProps: getRootProps3, getInputProps: getInputProps3 } = useDropzone({
        onDrop: acceptedFiles => handleImageDrop(acceptedFiles, setAddProof_Front),
        accept: {
            'image/jpeg': [],
            'image/png': [],
        },
    });

    const { getRootProps: getRootProps4, getInputProps: getInputProps4 } = useDropzone({
        onDrop: acceptedFiles => handleImageDrop(acceptedFiles, setAddProof_Back),
        accept: {
            'image/jpeg': [],
            'image/png': [],
        },
    });

    const { getRootProps: getRootProps5, getInputProps: getInputProps5 } = useDropzone({
        onDrop: acceptedFiles => handleImageDrop(acceptedFiles, setCustomer_photo),
        accept: {
            'image/jpeg': [],
            'image/png': [],
        },
    });

    const submitStep = async (values: any, actions: FormikValues) => {
        setLoading(true)
        try {
            const ApplicationData = new FormData();
            ApplicationData.append("ind_customer_image", customer_photo[0]);
            if (idProof_Front.length > 0) {
                ApplicationData.append("ind_id_proof_front_image", idProof_Front[0]);
            }
            if (idProof_Back.length > 0) {
                ApplicationData.append("ind_id_proof_back_image", idProof_Back[0]);
            }
            if (addProof_Front.length > 0) {
                ApplicationData.append("ind_add_proof_image", addProof_Front[0]);
            }
            if (addProof_Back.length > 0) {
                ApplicationData.append("ind_add_proof_back_image", addProof_Back[0]);
            }
            // if (sigCanvas.current) {
            //     const signatureDataURL = sigCanvas.current.toDataURL();
            //     const signatureBlob = await (await fetch(signatureDataURL)).blob();
            //     ApplicationData.append("ind_auth_sign", signatureBlob);
            // }
            ApplicationData.append('accountType', 'Individual');
            const { data: auth } = await Individual_POST_Application(
                ApplicationData,
                values.app_dot_id,
                values.id_proof,
                values.address_proof,
                values.customer_name,
                values.father_name,
                values.gender,
                values.nationality,
                values.dob,
                values.email,
                values.phone_number,
                values.alternate_number,
                values.installation_add,
                values.country,
                values.state,
                values.billing_add,
                values.billing_country,
                values.billing_city,
                AppType,
                selectedType
            );
            actions.resetForm()
            setIdProof_Front([]);
            setIdProof_Back([])
            setAddProof_Front([])
            setAddProof_Back([])
            setCustomer_photo([]);
            setSelectedType("");
            setLoading(false)
            fetchApplicationData()
            vendorCredit(user_id);
            setShowModal(false)
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Successfully Submitted',
                showConfirmButton: true,
                timer: 3000
            });
        } catch (error) {
            console.error('Error submitting form data:', error);
        }
    }

    return (
        <>
            <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>Choose Account Type</label>
                <div className='col-lg-8'>
                    <select
                        className='form-select form-select-solid'
                        onChange={handleAccountTypeChange}
                        value={selectedAccountType}
                    >
                        <option value="Individual">Individual</option>
                        <option value="Company">Company</option>
                    </select>
                </div>
            </div>

            {selectedAccountType === 'Individual' ? (
                <div>
                    <Formik initialValues={inits} validationSchema={validationSchema} onSubmit={submitStep}>
                        {({values}) => (
                            <Form>
                                {userRole === '1' || userRole === '2' ? (
                                    <>
                                        <div className='row mb-6'>
                                            <label className='col-lg-4 col-form-label fw-bold fs-6'>Select Vendor</label>
                                            <div className='col-lg-8'>
                                                <select
                                                    className='form-select form-select-solid'
                                                    value={selectedType}
                                                    onChange={handleTypeChange}
                                                >
                                                    {userData.map((user) => (
                                                        user.user_role === "1" || user.user_role === "3" ? (
                                                            <option key={user.user_id} value={user.user_id}>
                                                                {user.user_company_name}
                                                            </option>
                                                        ) : null
                                                    ))}
                                                </select>
                                            </div>
                                        </div>

                                        <div className='row mb-6'>
                                            <label className='col-lg-4 col-form-label required fw-bold fs-6'>Customer ID</label>
                                            <div className="col-lg-8 form-group">
                                                <Field type="text" name="app_dot_id" className="form-control" />
                                            </div>
                                        </div>
                                    </>
                                ) : null}

                                <div className='row mb-6'>
                                    <label className='col-lg-4 col-form-label fw-bold fs-6'>Customer Profile</label>
                                    <div className='col-lg-8'>
                                        <div className='d-flex'>
                                            <div className='me-5'>
                                                {customer_photo.map(file => (
                                                    <div key={file.name}>
                                                        <img
                                                            src={typeof file === 'string' ? file : URL.createObjectURL(file)}
                                                            alt={file.name}
                                                            className='h-150px w-150px object-fit-cover rounded-3'
                                                        />
                                                    </div>
                                                ))}
                                            </div>
                                            <div {...getRootProps5({ className: "dropzone" })}>
                                                <input className="input-zone" {...getInputProps5()} />
                                                <div className="text-center">
                                                    <p className="dropzone-content">
                                                        Drag’n’drop or click to select files
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='row mb-6'>
                                    <label className='col-lg-4 col-form-label required fw-bold fs-6'>Id Proof</label>
                                    <div className='col-lg-8 fv-row'>
                                        <Field as="select" name="id_proof" aria-label="Select example" className="form-select" >
                                            <option value=''>Select ID Proof</option>
                                            <option value='Aadhar Card'>Aadhar Card</option>
                                            <option value='Passport'>Passport</option>
                                            <option value='Voter ID'>Voter ID</option>
                                        </Field>
                                        <ErrorMessage name="id_proof" component="div" className="text-danger" />
                                    </div>
                                </div>

                                <div className='row mb-6'>
                                    <label className='col-lg-4 form-label required fw-bold fs-6'>{values.id_proof} Front Page</label>
                                    <div className='col-lg-8'>
                                        <div className='d-flex'>
                                            <div className='me-5'>
                                                {idProof_Front.map(file => (
                                                    <div key={file.name} style={{ margin: '10px' }}>
                                                        <img
                                                            src={URL.createObjectURL(file)}
                                                            alt={file.name}
                                                            className='h-150px w-250px object-fit-cover'
                                                        />
                                                        <p>{file.name}</p>
                                                    </div>
                                                ))}
                                            </div>
                                            <div {...getRootProps1({ className: "dropzone" })}>
                                                <input type="file" id="Front_image" {...getInputProps1()} name="Front_image" />
                                                <div className="text-center">
                                                    <p className="dropzone-content">
                                                        Drag’n’drop or click to select files
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='row mb-6'>
                                    <label className='col-lg-4 form-label required fw-bold fs-6'>{values.id_proof} Back Page</label>
                                    <div className='col-lg-8'>
                                        <div className='d-flex'>
                                            <div className='me-5'>
                                                {idProof_Back.map(file => (
                                                    <div key={file.name} style={{ margin: '10px' }}>
                                                        <img
                                                            src={URL.createObjectURL(file)}
                                                            alt={file.name}
                                                            className='h-150px w-250px object-fit-cover'
                                                        />
                                                        <p>{file.name}</p>
                                                    </div>
                                                ))}
                                            </div>
                                            <div {...getRootProps2({ className: "dropzone" })}>
                                                <input className="input-zone" {...getInputProps2()} name='back_image' />
                                                <div className="text-center">
                                                    <p className="dropzone-content">
                                                        Drag’n’drop or click to select files
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='row mb-6'>
                                    <label className='col-lg-4 col-form-label required fw-bold fs-6'>Address Proof</label>
                                    <div className='col-lg-8 fv-row'>
                                        <Field as="select" name="address_proof" aria-label="Select example" className="form-select" >
                                            <option value=''>Select Address Proof</option>
                                            <option value='EB Bill'>EB Bill</option>
                                            <option value='GAS Bill'>GAS Bill</option>
                                            <option value='GST'>GST</option>
                                            <option value='Rent Agreement'>Rent Agreement</option>
                                        </Field>
                                        <ErrorMessage name="address_proof" component="div" className="text-danger" />
                                    </div>
                                </div>

                                <div className='row mb-6'>
                                    <label className='col-lg-4 form-label required fw-bold fs-6'>{values.address_proof} Front Image</label>
                                    <div className='col-lg-8'>
                                        <div className='d-flex'>
                                            <div className='me-5'>
                                                {addProof_Front.map(file => (
                                                    <div key={file.name} style={{ margin: '10px' }}>
                                                        <img
                                                            src={URL.createObjectURL(file)}
                                                            alt={file.name}
                                                            className='h-150px w-250px object-fit-cover'
                                                        />
                                                        <p>{file.name}</p>
                                                    </div>
                                                ))}
                                            </div>
                                            <div {...getRootProps3({ className: "dropzone" })}>
                                                <input className="input-zone" {...getInputProps3()} />
                                                <div className="text-center">
                                                    <p className="dropzone-content">
                                                        Drag’n’drop or click to select files
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {values.address_proof === 'Rent Agreement' && (
                                    <div className='row mb-6'>
                                        <label className='col-lg-4 form-label required fw-bold fs-6'>{values.address_proof} Back Image</label>
                                        <div className='col-lg-8'>
                                            <div className='d-flex'>
                                                <div className='me-5'>
                                                    {addProof_Back.map(file => (
                                                        <div key={file.name} style={{ margin: '10px' }}>
                                                            <img
                                                                src={URL.createObjectURL(file)}
                                                                alt={file.name}
                                                                className='h-150px w-250px object-fit-cover'
                                                            />
                                                            <p>{file.name}</p>
                                                        </div>
                                                    ))}
                                                </div>
                                                <div {...getRootProps4({ className: "dropzone" })}>
                                                    <input className="input-zone" {...getInputProps4()} />
                                                    <div className="text-center">
                                                        <p className="dropzone-content">
                                                            Drag’n’drop or click to select files
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {/* <div className='row mb-6'>
                                    <label className='col-lg-4 form-label required fw-bold fs-6'>Authorised Signatory</label>
                                    <div className='col-lg-8'>
                                        <div style={{ border: '2px solid black', borderRadius: '5px', padding: '5px' }}>
                                            <SignatureCanvas
                                                ref={sigCanvas}
                                                penColor="blue"
                                                canvasProps={{
                                                    width: 400,
                                                    height: 200,
                                                    className: 'sigCanvas',
                                                    style: { backgroundColor: 'white' },
                                                }}
                                            />
                                        </div>
                                        <span className='badge badge-danger cursor-pointer mt-5' onClick={clearCanvas}>Clear Signature</span>
                                    </div>
                                </div> */}

                                <div className='row mb-6'>
                                    <label className='col-lg-4 col-form-label required fw-bold fs-6'>Enter Your Name</label>
                                    <div className='col-lg-8 fv-row'>
                                        <Field type="text" name="customer_name" className="form-control" />
                                        <ErrorMessage name="customer_name" component="div" className="text-danger" />
                                    </div>
                                </div>

                                <div className='row mb-6'>
                                    <label className='col-lg-4 col-form-label required fw-bold fs-6'>Father /Spouse /Guardian Name</label>
                                    <div className='col-lg-8 fv-row'>
                                        <Field type='text' className='form-control' name='father_name' />
                                        <ErrorMessage name="father_name" component="div" className="text-danger" />
                                    </div>
                                </div>

                                <div className='row mb-6'>
                                    <label className='col-lg-4 col-form-label required fw-bold fs-6'>Gender</label>
                                    <div className='col-lg-8 fv-row'>
                                        <div className="form-check form-check-custom form-check-solid mb-3">
                                            <Field
                                                type="radio"
                                                name="gender"
                                                value="male"
                                                id="male"
                                                className="form-check-input"
                                            />
                                            <label className="form-check-label fw-bolder text-gray-500 text-hover-primary fs-5" htmlFor="male">Male</label>
                                        </div>
                                        <div className='form-check form-check-custom form-check-solid'>
                                            <Field
                                                type="radio"
                                                name="gender"
                                                value="female"
                                                id="female"
                                                className="form-check-input"
                                            />
                                            <label className="form-check-label fw-bolder text-gray-500 text-hover-primary fs-5" htmlFor="female">Female</label>
                                        </div>
                                        <ErrorMessage name="gender" component="div" className="text-danger" />
                                    </div>
                                </div>

                                <div className='row mb-6'>
                                    <label className='col-lg-4 col-form-label required fw-bold fs-6'>Nationality</label>
                                    <div className='col-lg-8 fv-row'>
                                        <div className="form-check form-check-custom form-check-solid mb-3">
                                            <Field
                                                type="radio"
                                                name="nationality"
                                                value="Indian"
                                                id="Indian"
                                                className="form-check-input"
                                            />
                                            <label className="form-check-label fw-bolder text-gray-500 text-hover-primary fs-5" htmlFor="Indian">Indian</label>
                                        </div>
                                        <div className='form-check form-check-custom form-check-solid'>
                                            <Field
                                                type="radio"
                                                name="nationality"
                                                value="French"
                                                id="French"
                                                className="form-check-input"
                                            />
                                            <label className="form-check-label fw-bolder text-gray-500 text-hover-primary fs-5" htmlFor="French">French</label>
                                        </div>
                                        <div className='form-check form-check-custom form-check-solid mt-3'>
                                            <Field
                                                type="radio"
                                                name="nationality"
                                                value="Others"
                                                id="Others"
                                                className="form-check-input"
                                            />
                                            <label className="form-check-label fw-bolder text-gray-500 text-hover-primary fs-5" htmlFor="Others">Others</label>
                                        </div>
                                        <ErrorMessage name="nationality" component="div" className="text-danger" />
                                    </div>
                                </div>

                                <div className='row mb-6'>
                                    <label className='col-lg-4 col-form-label required fw-bold fs-6'>Date Of Birth</label>
                                    <div className='col-lg-8 fv-row'>
                                        <Field type='date' className='form-control' name='dob' />
                                        <ErrorMessage name="dob" component="div" className="text-danger" />
                                    </div>
                                </div>

                                <div className='row mb-6'>
                                    <label className='col-lg-4 col-form-label required fw-bold fs-6'>Email</label>
                                    <div className='col-lg-8 fv-row'>
                                        <Field type='text' className='form-control' name='email' />
                                        <ErrorMessage name="email" component="div" className="text-danger" />
                                    </div>
                                </div>

                                <div className='row mb-6'>
                                    <label className='col-lg-4 col-form-label required fw-bold fs-6'>Phone Number</label>
                                    <div className='col-lg-8 fv-row'>
                                        <Field type='text' className='form-control' name='phone_number' />
                                        <ErrorMessage name="phone_number" component="div" className="text-danger" />
                                    </div>
                                </div>

                                <div className='row mb-6'>
                                    <label className='col-lg-4 form-label fw-bold fs-6'>Alternate Number</label>
                                    <div className='col-lg-8'>
                                        <Field type='number' className='form-control' name='alternate_number' />
                                        <ErrorMessage name="alternate_number" component="div" className="text-danger" />
                                    </div>
                                </div>

                                <div className='row mb-6'>
                                    <label className='col-lg-4 col-form-label required fw-bold fs-6'>Installation Address</label>
                                    <div className='col-lg-8 fv-row'>
                                        <Field type='text' className='form-control' name='installation_add' />
                                        <ErrorMessage name="installation_add" component="div" className="text-danger" />
                                    </div>
                                </div>

                                <div className='row mb-6'>
                                    <label className='col-lg-4 col-form-label required fw-bold fs-6'>Country</label>
                                    <div className='col-lg-8 fv-row'>
                                        <Field type='text' className='form-control' name='country' />
                                        <ErrorMessage name="country" component="div" className="text-danger" />
                                    </div>
                                </div>

                                <div className='row mb-6'>
                                    <label className='col-lg-4 col-form-label required fw-bold fs-6'>State</label>
                                    <div className='col-lg-8 fv-row'>
                                        <Field
                                            as='select'
                                            name='state'
                                            className='form-select'
                                        >
                                            <option value='Pondicherry'>Pondicherry</option>
                                            <option value='Tamil Nadu'>Tamil Nadu</option>
                                        </Field>
                                        <ErrorMessage name="state" component="div" className="text-danger" />
                                    </div>
                                </div>

                                <div className='row mb-6'>
                                    <label className='col-lg-4 col-form-label required fw-bold fs-6'>Billing Address</label>
                                    <div className='col-lg-8 fv-row'>
                                        <Field type='text' className='form-control' name='billing_add' />
                                        <ErrorMessage name="billing_add" component="div" className="text-danger" />
                                    </div>
                                </div>

                                <div className='row mb-6'>
                                    <label className='col-lg-4 col-form-label required fw-bold fs-6'>Country</label>
                                    <div className='col-lg-8 fv-row'>
                                        <Field type='text' className='form-control' name='billing_country' />
                                        <ErrorMessage name="billing_country" component="div" className="text-danger" />
                                    </div>
                                </div>

                                <div className='row mb-6'>
                                    <label className='col-lg-4 col-form-label required fw-bold fs-6'>State</label>
                                    <div className='col-lg-8 fv-row'>
                                        <Field
                                            as='select'
                                            name='billing_city'
                                            className='form-select'
                                        >
                                            <option value='Pondicherry'>Pondicherry</option>
                                            <option value='Tamil Nadu'>Tamil Nadu</option>
                                        </Field>
                                        <ErrorMessage name="billing_city" component="div" className="text-danger" />
                                    </div>
                                </div>

                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" onClick={() => setShowModal(false)}>
                                        Cancel
                                    </button>
                                    <div className='card-footer d-flex justify-content-end py-6 px-9'>
                                        <button type='submit' className='btn btn-primary' disabled={loading}>
                                            {!loading && 'Save Changes'}
                                            {loading && (
                                                <span className='indicator-progress' style={{ display: 'block' }}>
                                                    Please wait...{' '}
                                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                </span>
                                            )}
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            ) : selectedAccountType === 'Company' ? (
                <div>
                    <CompanyFormAdmin setShowModal={setShowModal} fetchApplicationData={fetchApplicationData} />
                </div>
            ) : null}

        </>
    )
}

export default IndividualFormAdmin