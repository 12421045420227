/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef,useState} from 'react'
import {Link} from 'react-router-dom'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {KTIcon} from '../../../helpers'
import {getCSSVariableValue} from '../../../assets/ts/_utils'
import {Dropdown1} from '../../content/dropdown/Dropdown1'
import {useThemeMode} from '../../layout/theme-mode/ThemeModeProvider'
import { useAuth } from '../../../../app/modules/auth'
import { profilestatusCheck } from '../../../../app/modules/auth/core/_requests'

type Props = {
  className: string
  chartColor: string
  chartHeight: string
}


const MixedWidget7: React.FC<Props> = ({className, chartColor, chartHeight}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()
  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    const chart = new ApexCharts(chartRef.current, chartOptions(chartColor, chartHeight,ProfileProgress))
    if (chart) {
      chart.render()
    }

    return chart
  }



const { currentUser, logout } = useAuth()

const [profileEmail,  setprofileEmail] = useState(currentUser?.email || '')
const [ProfileProgress, setProfileProgress] = useState(0);
const [loading, setLoading] = useState(true);


  const updateProfileProgress = async () => {
    try {
      const response = await profilestatusCheck(profileEmail);

      if (response) {

        if (response.data.companyName !== "" && response.data.zipcode !== "" && response.data.pic !== "") {
          setProfileProgress(100);
          //console.log(100);
        } else if (response.data.companyName !== "" && response.data.zipcode !== "") {
          setProfileProgress(75);
          //console.log(75);
        }
        else
        {
          setProfileProgress(50);
        }

      } else {
        console.error('Error:', response);
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false); // Set loading state to false after API call completion
    }
  };

  useEffect(() => {
    updateProfileProgress();
    const chart = refreshChart()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef, mode,ProfileProgress])

return (
    <div className={`card ${className}`}>
       {loading ? (
       <span className="indicator-progres p-5">

       <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
     </span>
    ) : (
      <div>
      {/* begin::Beader */}
      <div className='card-header border-0 py-5'>
      {ProfileProgress!==100 ? (
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Action Needed</span>
          <span className='text-muted fw-semibold fs-7'>Complete your profile setup</span>
        </h3>
      ): <h3 className='card-title align-items-start flex-column'>
      <span className='card-label fw-bold fs-3 mb-1'>Profile Status</span>

    </h3> }
        {/* <div className='card-toolbar'>

          <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <KTIcon iconName='category' className='fs-2' />
          </button>
          <Dropdown1 />

        </div> */}
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className='card-body d-flex flex-column'>
        <div className='flex-grow-1'>
          <div ref={chartRef} className='mixed-widget-4-chart'></div>
          {ProfileProgress===100 ? (
                 <div className='pt-5'>
        <p className='text-center fs-6 pb-5 text-success '>
        <i className="bi bi-check fs-1 text-success"></i> Your Profile is Completed
                 </p>
                 </div>
        ): '' }
        </div>

       {ProfileProgress!=100 ? (
        <div className='pt-5'>
          <p className='text-center fs-6 pb-5 '>
      To get further updates & Notifications. Kindly update your profile soon.
                      </p>
          <Link
              to='/crafted/account/overview'
              className='card border border-2 border-gray-300 border-hover'>
                 <button className={`btn btn-${chartColor} w-100 py-3`}>
                  Take Action
                  </button>
          </Link>
        </div>
       ) :  ''}
      </div>
      {/* end::Body */}
      </div>
      )}
    </div>
  )
}

const chartOptions = (chartColor: string, chartHeight: string,ProfileProgess:number): ApexOptions => {
  const baseColor = getCSSVariableValue('--bs-' + chartColor)
  const lightColor = getCSSVariableValue('--bs-' + chartColor + '-light')
  const labelColor = getCSSVariableValue('--bs-gray-700')

  return {
    series: [ProfileProgess],
    chart: {
      fontFamily: 'inherit',
      height: chartHeight,
      type: 'radialBar',
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: '65%',
        },
        dataLabels: {
          name: {
            show: false,
            fontWeight: '700',
          },
          value: {
            color: labelColor,
            fontSize: '30px',
            fontWeight: '700',
            offsetY: 12,
            show: true,
            formatter: function (val) {
              return val + '%'
            },
          },
        },
        track: {
          background: lightColor,
          strokeWidth: '100%',
        },
      },
    },
    colors: [baseColor],
    stroke: {
      lineCap: 'round',
    },
    labels: ['Progress'],
  }
}

export {MixedWidget7}
