import React, { useState, useEffect, useRef } from 'react';
import { toAbsoluteUrl, KTIcon } from '../../_metronic/helpers'
import { Form, Formik, FormikValues, ErrorMessage, Field } from 'formik'
import {
  Individual_GET_Application, Individual_EDIT_Application, Individual_PUT_Application,
  GET_Terms_Condition, Company_PUT_Application, applicationCheck
} from '../modules/auth/core/_requests'
import { useDropzone } from "react-dropzone";
import { Modal } from 'react-bootstrap';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import html2canvas from 'html2canvas';
import SignatureCanvas from 'react-signature-canvas';
import Webcam from 'react-webcam';
import Swal from 'sweetalert2';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import Pagination from './Pagination';
import { handleImageDrop } from "../pages/CompressImage"
import IndividualFormView from './IndividualFormView';
import CompanyFormView from '../modules/auth/components/CompanyFormView';
import IndividualFormAdmin from '../modules/auth/components/IndividualFormAdmin';


interface EditApplicationDetails {
  app_id: string;
  app_id_proof: string;
  app_id_proof_front_image: string;
  app_id_proof_back_image: string;
  app_address_proof: string;
  app_add_proof_image: string;
  app_add_proof_back_image: string;
  app_auth_sign: string;
  app_customer_name: string;
  app_father_name: string;
  app_gender: string;
  app_nationality: string;
  app_dob: string;
  app_email_id: string;
  app_phone_no: string;
  app_alternate_number: string;
  app_installation_add: string;
  app_country: string;
  app_state: string;
  app_billing_add: string;
  app_billing_country: string;
  app_billing_state: string;
  app_created_on: string;
  app_status: string;
  app_type: string;
  app_customer_image: string;
  app_application_id: string;
  app_company_name: string;
  app_company_type_concern: string;
  app_company_date_incorporation: string;
  app_company_email_id: string;
  app_company_alternate_email_id: string;
  app_company_phone_no: string;
  app_company_auth_front_image: string;
  app_company_auth_back_image: string;
  app_company_address_image: string;
  app_company_alternate_number: string;
  app_company_installation_add: string;
  app_company_country: string;
  app_company_state: string;
  app_company_billing_add: string;
  app_company_billing_country: string;
  app_company_billing_state: string;
  app_company_gst: string;
  app_company_created_on: string;
  app_company_status: string;
  app_company_type: string;
  app_company_customer_image: string;
  app_company_auth_sign: string;
  app_approved_by: string;
  app_approved_image: string;
  app_approved_on: string;
  app_template_header: string;
  app_template_footer: string;
  app_template_bg_color: string;
  app_terms_conditions: string;
  app_template_text_color: string;
  app_vendor_name: string;
  app_company_address_image_2: string;
}

interface ApplicationDetails {
  app_id: string;
  app_dot_id: string;
  app_shortcode: string;
  app_name: string;
  app_phone_no: string;
  app_email_id: string;
  app_type: string;
  app_status: string;
  app_state: string;
  app_created_on: string;
  app_encyp_id: string;
  app_vendor_name: string;
}

interface TermsDetail {
  terms_user_id: string;
  terms_details: string;
  terms_created_on: string;
}

type BackgroundColor = string;

const ApplicationDetails = () => {
  const { app_status } = useParams<{ app_status: string }>();
  const webcamRef = useRef<any>(null);
  const CompanywebcamRef = useRef<any>(null);
  const [indData, setIndData] = useState<ApplicationDetails[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [avatar, setAvatar] = useState<string | null>(null);
  const [avatarCompany, setAvatarCompany] = useState<string | null>(null);
  const [loading, setLoading] = useState(false)
  const [isLoading, setIsLoading] = useState(true);
  const [loadingEdit, setLoadingEdit] = useState(false);
  const [companyloadingEdit, setCompanyloadingEdit] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false)
  const [companyEditModal, setCompanyEditModal] = useState(false)
  const [viewIndModal, setViewIndModal] = useState(false)
  const [viewCusModal, setViewCusModal] = useState(false)
  const [approved_image, setApproved_image] = useState<string | null>(null);
  const [companyApproved_by, setCompanyApproved_by] = useState<string | null>(null);
  const [showWarning, setShowWarning] = useState<boolean>(false);
  const [showWarningCompany, setShowWarningCompany] = useState<boolean>(false);
  const [indCustomer_photo, setIndCustomer_photo] = useState<File[]>([]);
  const [compCustomer_photo, setComCustomer_photo] = useState<File[]>([]);
  const [idProof_Front, setIdProof_Front] = useState<File[]>([]);
  const [idProof_Back, setIdProof_Back] = useState<File[]>([]);
  const [addProof_Front, setAddProof_Front] = useState<File[]>([]);
  const [addProof_Back, setAddProof_Back] = useState<File[]>([]);
  const [showFullScreenModal, setShowFullScreenModal] = useState(false);
  const [companyGST_Img, setCompanyGST_Img] = useState<File[]>([]);
  const [companyAddBack, setCompanyAddBack] = useState<File[]>([]);
  const [companyID_FrontImg, setCompanyID_FrontImg] = useState<File[]>([]);
  const [companyID_BackImg, setCompanyID_BackImg] = useState<File[]>([]);
  const [selectedImage, setSelectedImage] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [filterStatus, setFilterStatus] = useState(app_status)
  const [filterType, setFilterType] = useState('')
  const [applicationData, setApplicationData] = useState<ApplicationDetails[]>([]);
  const [checkAll, setCheckAll] = useState(false)
  const [termsData, setTermsData] = useState<TermsDetail | null>(null);
  const [viewDetails, setViewDetails] = useState<EditApplicationDetails>();
  const [sortColumn, setSortColumn] = useState<string>('');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc' | ''>('');
  const [apptemplate, SetAppTemplate] = useState(null);
  const [footertemplate, SetFooterTemplate] = useState(null);
  const [templateColor, setTemplateColor] = useState<BackgroundColor | undefined>(undefined);
  const [twopageExport, setTwoPageExport] = useState('0');
  const [PrintableArea, setPrintableArea] = useState(false);
  const [ExportFiles, setExportFiles] = useState(false);
  const [activeStep, setActiveStep] = useState<number>(1);
  const [companyStep, setCompanyStep] = useState<number>(1);

  const [initialEditValues, setInitialEditValues] = useState(
    {
      app_id_upd: '',
      app_dot_id_upd: '',
      app_type_upd: '',
      app_id_proof_upd: '',
      app_address_proof_upd: '',
      app_customer_name_upd: '',
      app_father_name_upd: '',
      app_gender_upd: '',
      app_nationality_upd: '',
      app_dob_upd: '',
      app_email_id_upd: '',
      app_phone_no_upd: '',
      app_alternate_number_upd: '',
      app_installation_add_upd: '',
      app_country_upd: '',
      app_state_upd: '',
      app_billing_add_upd: '',
      app_billing_country_upd: '',
      app_billing_state_upd: '',
      app_status_upd: '',
      app_remarks_upd: '',
    }
  )
  const [initialCompanyValues, setInitialCompanyValues] = useState(
    {
      app_id_upd: '',
      app_dot_id_upd: '',
      app_type_upd: '',
      company_name_upd: '',
      company_type_concern_upd: '',
      date_incorporation_upd: '',
      company_email_id_upd: '',
      alternate_email_id_upd: '',
      company_phone_number_upd: '',
      company_alter_phone_number_upd: '',
      company_installation_add_upd: '',
      company_installation_country_upd: '',
      company_installation_state_upd: '',
      company_billing_add_upd: '',
      company_billing_country_upd: '',
      company_billing_state_upd: '',
      company_gst_upd: '',
      company_address_proof_upd: '',
      company_id_proof_upd: '',
      company_status_upd: '',
      app_remarks_upd: '',
    }
  )

  const UserDetails: any = localStorage.getItem('user-details')
  const userDetailsObject = JSON.parse(UserDetails);
  const app_approved_user_id = userDetailsObject.id;
  const app_approved_user_name = userDetailsObject.first_name;
  const userRole = userDetailsObject.role;

  const handleButtonClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const openFullScreenModal = (image: any) => {
    setSelectedImage(image);
    setShowFullScreenModal(true);
  };

  const capturePhoto = () => {
    if (webcamRef.current) {
      const capturedPhoto = webcamRef.current.getScreenshot();
      setApproved_image(capturedPhoto);
      setShowWarning(false);
    }
  };

  const captureCompanyPhoto = () => {
    if (CompanywebcamRef.current) {
      const capturedPhoto = CompanywebcamRef.current.getScreenshot();
      setCompanyApproved_by(capturedPhoto);
      setShowWarningCompany(false);
    }
  };

  const retake = () => {
    setApproved_image(null);
  };

  const retakeCompany = () => {
    setCompanyApproved_by(null);
  };

  const fetchApplicationData = async () => {
    try {
      const response = await Individual_GET_Application(app_approved_user_id, userRole);
      setIndData(response.data.application_details);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching all applications:', error);
    }
  };

  useEffect(() => {
    fetchApplicationData();
  }, []);

  const fetchTermsData = async (v_terms_user_id: any) => {
    try {
      const response = await GET_Terms_Condition(v_terms_user_id);
      setTermsData(response.data.terms_details[0]);
    } catch (error) {
      console.error('Error fetching members:', error);
    }
  };

  useEffect(() => {
    fetchTermsData("1");
  }, []);

  // const shortcode = userDetailsObject.shortcode;

  const generateCopyLink = (appId: any, shortcode: any) => {
    const copyLink = `${window.location.origin}/application/${shortcode}/?type=update&token=${appId}`;
    // Create a temporary textarea element to copy the link to the clipboard
    const textarea = document.createElement('textarea');
    textarea.value = copyLink;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);

    Swal.fire({
      icon: 'success',
      title: `<div style="color: white; padding: 10px;">Link copied to clipboard!</div>`,
      toast: true,
      position: 'top',
      showConfirmButton: false,
      timer: 3000, // You can adjust the timer as needed
      customClass: {
        popup: 'your-custom-toast-class', // Add your custom CSS class here
      },
      iconHtml: '<i class="fas fa-check" style="color: white;"></i>',
    });
  };

  const { getRootProps: getRootProps1, getInputProps: getInputProps1 } = useDropzone({
    onDrop: acceptedFiles => handleImageDrop(acceptedFiles, setIdProof_Front),
    accept: {
      'image/jpeg': [],
      'image/png': [],
    },
  });

  const { getRootProps: getRootProps2, getInputProps: getInputProps2 } = useDropzone({
    onDrop: acceptedFiles => handleImageDrop(acceptedFiles, setIdProof_Back),
    accept: {
      'image/jpeg': [],
      'image/png': [],
    },
  });

  const { getRootProps: getRootProps3, getInputProps: getInputProps3 } = useDropzone({
    onDrop: acceptedFiles => handleImageDrop(acceptedFiles, setAddProof_Front),
    accept: {
      'image/jpeg': [],
      'image/png': [],
    },
  });

  const { getRootProps: getRootProps4, getInputProps: getInputProps4 } = useDropzone({
    onDrop: acceptedFiles => handleImageDrop(acceptedFiles, setAddProof_Back),
    accept: {
      'image/jpeg': [],
      'image/png': [],
    },
  });

  const { getRootProps: getRootProps5, getInputProps: getInputProps5 } = useDropzone({
    onDrop: acceptedFiles => handleImageDrop(acceptedFiles, setCompanyGST_Img),
    accept: {
      'image/jpeg': [],
      'image/png': [],
    },
  });

  const { getRootProps: getRootProps6, getInputProps: getInputProps6 } = useDropzone({
    onDrop: acceptedFiles => handleImageDrop(acceptedFiles, setCompanyID_FrontImg),
    accept: {
      'image/jpeg': [],
      'image/png': [],
    },
  });

  const { getRootProps: getRootProps7, getInputProps: getInputProps7 } = useDropzone({
    onDrop: acceptedFiles => handleImageDrop(acceptedFiles, setCompanyID_BackImg),
    accept: {
      'image/jpeg': [],
      'image/png': [],
    },
  });

  const { getRootProps: getRootProps10, getInputProps: getInputProps10 } = useDropzone({
    onDrop: acceptedFiles => handleImageDrop(acceptedFiles, setCompanyAddBack),
    accept: {
      'image/jpeg': [],
      'image/png': [],
    },
  });

  const customerPhoto = useRef<HTMLInputElement | null>(null);
  const CompanycustomerPhoto = useRef<HTMLInputElement | null>(null);
  const IDFrontPhoto = useRef<HTMLInputElement | null>(null);
  const ComapnyIDFrontPhoto = useRef<HTMLInputElement | null>(null);
  const IDBackPhoto = useRef<HTMLInputElement | null>(null);
  const CompanyIDBackPhoto = useRef<HTMLInputElement | null>(null);
  const AddFrontPhoto = useRef<HTMLInputElement | null>(null);
  const CompanyAddFrontPhoto = useRef<HTMLInputElement | null>(null);
  const AddBackPhoto = useRef<HTMLInputElement | null>(null);
  const CompanyAddBackPhoto = useRef<HTMLInputElement | null>(null);

  const { getRootProps: getRootProps8, getInputProps: getInputProps8 } = useDropzone({
    onDrop: acceptedFiles => handleImageDrop(acceptedFiles, setIndCustomer_photo),
    accept: {
      'image/jpeg': [],
      'image/png': [],
    },
  });

  const { getRootProps: getRootProps9, getInputProps: getInputProps9 } = useDropzone({
    onDrop: acceptedFiles => handleImageDrop(acceptedFiles, setComCustomer_photo),
    accept: {
      'image/jpeg': [],
      'image/png': [],
    },
  });

  // Convert base64 data to binary and set to state variables
  const convertBase64ToBinary = (base64String: any, setStateFunction: any) => {
    const base64StringWithoutScheme = base64String.replace(/^data:image\/(png|jpeg);base64,/, '');
    const binaryString = atob(base64StringWithoutScheme);
    const byteArray = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
      byteArray[i] = binaryString.charCodeAt(i);
    }
    const blob = new Blob([byteArray], { type: 'application/octet-stream' });
    setStateFunction([new File([blob], 'image')]);
  };

  const handleEditApplication = async (view_application_id: any) => {
    setLoadingEdit(true)
    setShowEditModal(true)
    setExportFiles(false)
    try {
      const response = await Individual_EDIT_Application(view_application_id);
      setViewDetails(response.data.app_view_details[0])
      const initialData = response.data.app_view_details[0]
      if (initialData.app_type == 'Individual') {
        const individualImages = [
          { data: initialData.app_id_proof_front_image, setState: setIdProof_Front },
          { data: initialData.app_id_proof_back_image, setState: setIdProof_Back },
          { data: initialData.app_add_proof_image, setState: setAddProof_Front },
          { data: initialData.app_add_proof_back_image, setState: setAddProof_Back },
          { data: initialData.app_customer_image, setState: setIndCustomer_photo },
        ];

        individualImages.forEach(({ data, setState }) => {
          if (data) {
            convertBase64ToBinary(data, setState);
          } else {
            setState([]);
          }
        });
        setInitialEditValues({
          app_id_upd: initialData.app_id,
          app_dot_id_upd: initialData.app_dot_id,
          app_type_upd: initialData.app_type,
          app_id_proof_upd: initialData.app_id_proof,
          app_address_proof_upd: initialData.app_address_proof,
          app_customer_name_upd: initialData.app_customer_name,
          app_father_name_upd: initialData.app_father_name,
          app_gender_upd: initialData.app_gender,
          app_nationality_upd: initialData.app_nationality,
          app_dob_upd: initialData.app_dob,
          app_email_id_upd: initialData.app_email_id,
          app_phone_no_upd: initialData.app_phone_no,
          app_alternate_number_upd: initialData.app_alternate_number,
          app_installation_add_upd: initialData.app_installation_add,
          app_country_upd: initialData.app_country,
          app_state_upd: initialData.app_state,
          app_billing_add_upd: initialData.app_billing_add,
          app_billing_country_upd: initialData.app_billing_country,
          app_billing_state_upd: initialData.app_billing_state,
          app_status_upd: initialData.app_status,
          app_remarks_upd: initialData.app_message,
        })
        setCompanyEditModal(false)
        setActiveStep(1);
      } else {
        const companyImages = [
          { data: initialData.app_company_customer_image, setState: setComCustomer_photo },
          { data: initialData.app_company_address_image, setState: setCompanyGST_Img },
          { data: initialData.app_company_address_image_2, setState: setCompanyAddBack },
          { data: initialData.app_company_auth_front_image, setState: setCompanyID_FrontImg },
          { data: initialData.app_company_auth_back_image, setState: setCompanyID_BackImg },
        ];

        companyImages.forEach(({ data, setState }) => {
          if (data) {
            convertBase64ToBinary(data, setState);
          } else {
            setState([]);
          }
        });
        setInitialCompanyValues({
          app_id_upd: initialData.app_application_id,
          app_dot_id_upd: initialData.app_dot_id,
          app_type_upd: initialData.app_type,
          company_name_upd: initialData.app_company_name,
          company_type_concern_upd: initialData.app_company_type_concern,
          date_incorporation_upd: initialData.app_company_date_incorporation,
          company_email_id_upd: initialData.app_company_email_id,
          alternate_email_id_upd: initialData.app_company_alternate_email_id,
          company_phone_number_upd: initialData.app_company_phone_no,
          company_alter_phone_number_upd: initialData.app_company_alternate_number,
          company_installation_add_upd: initialData.app_company_installation_add,
          company_installation_country_upd: initialData.app_company_country,
          company_installation_state_upd: initialData.app_company_state,
          company_billing_add_upd: initialData.app_company_billing_add,
          company_billing_country_upd: initialData.app_company_billing_country,
          company_billing_state_upd: initialData.app_company_billing_state,
          company_gst_upd: initialData.app_company_gst,
          company_address_proof_upd: initialData.app_company_address_proof,
          company_id_proof_upd: initialData.app_company_id_proof,
          company_status_upd: initialData.app_company_status,
          app_remarks_upd: initialData.app_message,
        })
        setCompanyEditModal(true)
        setShowEditModal(false)
        setCompanyStep(1);
      }
      setLoadingEdit(false)
    } catch (error) {
      console.error('Error fetching members:', error);
    }
  };

  const handleViewApplication = async (view_application_id: any) => {
    setLoadingEdit(true)
    setViewIndModal(true)
    setExportFiles(false)
    try {
      const response = await Individual_EDIT_Application(view_application_id);
      setViewDetails(response.data.app_view_details[0])
      const initialData = response.data.app_view_details[0]
      if (initialData.app_type == 'Individual') {
        setIdProof_Front([initialData.app_id_proof_front_image]);
        setIdProof_Back([initialData.app_id_proof_back_image]);
        setAddProof_Front([initialData.app_add_proof_image]);
        setAddProof_Back([initialData.app_add_proof_back_image]);
        setInitialEditValues({
          app_id_upd: initialData.app_id,
          app_dot_id_upd: initialData.app_dot_id,
          app_type_upd: initialData.app_type,
          app_id_proof_upd: initialData.app_id_proof,
          app_address_proof_upd: initialData.app_address_proof,
          app_customer_name_upd: initialData.app_customer_name,
          app_father_name_upd: initialData.app_father_name,
          app_gender_upd: initialData.app_gender,
          app_nationality_upd: initialData.app_nationality,
          app_dob_upd: initialData.app_dob,
          app_email_id_upd: initialData.app_email_id,
          app_phone_no_upd: initialData.app_phone_no,
          app_alternate_number_upd: initialData.app_alternate_number,
          app_installation_add_upd: initialData.app_installation_add,
          app_country_upd: initialData.app_country,
          app_state_upd: initialData.app_state,
          app_billing_add_upd: initialData.app_billing_add,
          app_billing_country_upd: initialData.app_billing_country,
          app_billing_state_upd: initialData.app_billing_state,
          app_status_upd: initialData.app_status,
          app_remarks_upd: initialData.app_message,
        })
        setViewCusModal(false)
        setActiveStep(1);
      } else {
        setAvatarCompany(initialData.app_company_customer_image);
        setInitialCompanyValues({
          app_id_upd: initialData.app_application_id,
          app_dot_id_upd: initialData.app_dot_id,
          app_type_upd: initialData.app_type,
          company_name_upd: initialData.app_company_name,
          company_type_concern_upd: initialData.app_company_type_concern,
          date_incorporation_upd: initialData.app_company_date_incorporation,
          company_email_id_upd: initialData.app_company_email_id,
          alternate_email_id_upd: initialData.app_company_alternate_email_id,
          company_phone_number_upd: initialData.app_company_phone_no,
          company_alter_phone_number_upd: initialData.app_company_alternate_number,
          company_installation_add_upd: initialData.app_company_installation_add,
          company_installation_country_upd: initialData.app_company_country,
          company_installation_state_upd: initialData.app_company_state,
          company_billing_add_upd: initialData.app_company_billing_add,
          company_billing_country_upd: initialData.app_company_billing_country,
          company_billing_state_upd: initialData.app_company_billing_state,
          company_gst_upd: initialData.app_company_gst,
          company_address_proof_upd: initialData.app_company_address_proof,
          company_id_proof_upd: initialData.app_company_id_proof,
          company_status_upd: initialData.app_company_status,
          app_remarks_upd: initialData.app_message,
        })
        setViewCusModal(true)
        setViewIndModal(false)

        setCompanyStep(1);
      }
      setLoadingEdit(false)
    } catch (error) {
      console.error('Error fetching members:', error);
    }
  };

  const validationSchema = Yup.object({
    ...(activeStep == 1 && {
      app_customer_name_upd: Yup.string().required('Enter Your Name is required'),
      app_father_name_upd: Yup.string().required('Father/Spouse/Guardian Name is required'),
      app_gender_upd: Yup.string().required('Gender is required'),
      app_nationality_upd: Yup.string().required('Nationality is required'),
      app_dob_upd: Yup.date().required('Date of Birth is required'),
    }),

    ...(activeStep == 2 && {
      app_installation_add_upd: Yup.string().required('Installation Address is required'),
      app_country_upd: Yup.string().required('Country is required'),
      app_state_upd: Yup.string().required('State is required'),
      app_billing_add_upd: Yup.string().required('Billing Address is required'),
      app_billing_country_upd: Yup.string().required('Billing Country is required'),
      app_billing_state_upd: Yup.string().required('Billing State is required'),
    }),

    ...(activeStep == 3 && {
      app_email_id_upd: Yup.string().email('Invalid email').required('Email is required'),
      app_phone_no_upd: Yup.string().matches(/^[0-9]{10}$/, 'Phone number must be exactly 10 digits').required('Phone Number is required'),
      app_alternate_number_upd: Yup.string().matches(/^[0-9]{10}$/, 'Phone number must be exactly 10 digits'),
    }),

    ...(activeStep == 4 && {
      app_status_upd: Yup.string().required('Status is required'),
      app_remarks_upd: Yup.string().required('Remarks is required'),
    }),
  });

  const CompanyValidation = Yup.object({
    ...(companyStep == 1 && {
      company_name_upd: Yup.string().required().label('Company Name'),
      company_type_concern_upd: Yup.string().required().label('Type of Concern'),
      date_incorporation_upd: Yup.string().required().label('Date of Incorporation'),
    }),
    ...(companyStep == 2 && {
      company_address_proof_upd: Yup.string().required().label('Address'),
      company_installation_add_upd: Yup.string().required().label('Installation Address'),
      company_installation_country_upd: Yup.string().required().label('Country'),
      company_installation_state_upd: Yup.string().required().label('State'),
      company_billing_add_upd: Yup.string().required().label('Billing Address'),
      company_billing_country_upd: Yup.string().required().label('Country'),
      company_billing_state_upd: Yup.string().required().label('State'),
    }),
    ...(companyStep == 3 && {
      company_email_id_upd: Yup.string().required().email('Invalid email').label('Email ID'),
      alternate_email_id_upd: Yup.string().email('Invalid email').label('Alternate Email Id'),
      company_phone_number_upd: Yup.string().matches(/^[0-9]{10}$/, 'Phone number must be exactly 10 digits').required('Phone Number is required'),
      company_alter_phone_number_upd: Yup.string().matches(/^[0-9]{10}$/, 'Phone number must be exactly 10 digits'),
      company_gst_upd: Yup.string().matches(/^[A-Z0-9]{15}$/, 'GST number must be exactly 15 characters'),
    }),
    ...(companyStep == 4 && {
      app_remarks_upd: Yup.string().required('Remarks is required'),
    })
  })

  const dataURItoBlob = (dataURI: any) => {
    const [mime, data] = dataURI.split(',');
    const binary = atob(data);
    const array = new Uint8Array(binary.length);
    for (let i = 0; i < binary.length; i++) {
      array[i] = binary.charCodeAt(i);
    }
    return new Blob([array], { type: mime });
  };

  const handleUpdateApplication = async (values: any, actions: FormikValues) => {
    if (!approved_image) {
      setShowWarning(true); // Display warning if the user tries to submit without capturing a photo
      return;
    }
    try {
      setLoading(true)
      const ApplicationData = new FormData();
      indCustomer_photo.map((file) => {
        ApplicationData.append(`app_customer_image_upd`, file);
      });
      idProof_Front.map((file) => {
        ApplicationData.append(`app_id_proof_front_image_upd`, file);
      });
      idProof_Back.map((file) => {
        ApplicationData.append(`app_id_proof_back_image_upd`, file);
      });
      addProof_Front.map((file) => {
        ApplicationData.append(`app_add_proof_image_upd`, file);
      });
      addProof_Back.map((file) => {
        ApplicationData.append(`app_add_proof_back_image_upd`, file);
      });
      if (approved_image) {
        ApplicationData.append("app_approved_by_image", dataURItoBlob(approved_image));
      }
      const { data: auth } = await Individual_PUT_Application(
        ApplicationData,
        values.app_id_upd,
        values.app_dot_id_upd,
        values.app_id_proof_upd,
        values.app_address_proof_upd,
        values.app_customer_name_upd,
        values.app_father_name_upd,
        values.app_gender_upd,
        values.app_nationality_upd,
        values.app_dob_upd,
        values.app_email_id_upd,
        values.app_phone_no_upd,
        values.app_alternate_number_upd,
        values.app_installation_add_upd,
        values.app_country_upd,
        values.app_state_upd,
        values.app_billing_add_upd,
        values.app_billing_country_upd,
        values.app_billing_state_upd,
        "Individual",
        values.app_status_upd,
        values.app_remarks_upd,
        app_approved_user_id,
        app_approved_user_name,
      )
      actions.resetForm();
      setShowEditModal(false)
      setActiveStep(1);
      setApproved_image(null);
      setTimeout(() => {
        setLoading(false)
        fetchApplicationData();
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Successfully Submitted',
          showConfirmButton: true,
          timer: 3000
        });
      }, 500);
    } catch (error) {
      console.error('Error fetching members:', error);
    }
  }

  const handleUpdateCompany = async (values: any, actions: FormikValues) => {
    if (!companyApproved_by) {
      setShowWarningCompany(true); // Display warning if the user tries to submit without capturing a photo
      return;
    }
    try {
      setLoading(true)
      const ApplicationData = new FormData();
      compCustomer_photo.map((file) => {
        ApplicationData.append(`company_customer_image_upd`, file);
      });
      companyGST_Img.map((file) => {
        ApplicationData.append(`company_address_image_upd`, file);
      });
      companyAddBack.map((file) => {
        ApplicationData.append(`company_address_image_2_upd`, file);
      });
      companyID_FrontImg.map((file) => {
        ApplicationData.append(`company_auth_front_image_upd`, file);
      });
      companyID_BackImg.map((file) => {
        ApplicationData.append(`company_auth_back_image_upd`, file);
      });
      if (companyApproved_by) {
        ApplicationData.append("app_approved_by_image", dataURItoBlob(companyApproved_by));
      }
      const { data: auth } = await Company_PUT_Application(
        ApplicationData,
        values.app_id_upd,
        values.app_dot_id_upd,
        values.company_name_upd,
        values.company_type_concern_upd,
        values.date_incorporation_upd,
        values.company_email_id_upd,
        values.alternate_email_id_upd,
        values.company_phone_number_upd,
        values.company_alter_phone_number_upd,
        values.company_installation_add_upd,
        values.company_installation_country_upd,
        values.company_installation_state_upd,
        values.company_billing_add_upd,
        values.company_billing_country_upd,
        values.company_billing_state_upd,
        values.company_gst_upd,
        values.company_address_proof_upd,
        values.company_id_proof_upd,
        values.company_status_upd,
        "Company",
        values.app_remarks_upd,
        app_approved_user_id,
        app_approved_user_name,
      )
      actions.resetForm();
      setCompanyEditModal(false)
      setCompanyStep(1)
      setCompanyApproved_by(null);
      setTimeout(() => {
        setLoading(false)
        fetchApplicationData();
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Successfully Submitted',
          showConfirmButton: true,
          timer: 3000
        });
      }, 500);
    } catch (error) {
      console.error('Error fetching members:', error);
    }
  }

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    const filtered = indData?.filter((app: any) => {
      const appData = `${app.app_id} ${app.app_name} ${app.app_type} ${app.app_phone_no} ${app.app_email_id} ${app.app_dot_id}`.toLowerCase();
      return appData.includes(searchQuery.toLowerCase());
    });
    setApplicationData(filtered);
  }, [searchQuery, indData]);

  useEffect(() => {
    const filteredOrders = indData.filter((item) => {
      if (!filterStatus && !filterType) {
        return true;
      }
      return (
        (!filterStatus || item.app_status == filterStatus) &&
        (!filterType || item.app_type == filterType)
      );
    });
    setApplicationData(filteredOrders);
  }, [filterStatus, filterType, indData]);

  const handleFilterChange = (e: any) => {
    setFilterStatus(e.target.value);
  };

  const handleFilterTypeChange = (e: any) => {
    setFilterType(e.target.value);
  };

  const sortByColumn = (column: string) => {
    const sortedData = [...applicationData];

    if (sortColumn == column && (sortOrder == 'asc' || sortOrder == '')) {
      sortedData.sort((a: any, b: any) => a[column].localeCompare(b[column]));
      setSortOrder('desc');
    } else {
      sortedData.sort((a: any, b: any) => b[column].localeCompare(a[column]));
      setSortOrder('asc');
    }

    setSortColumn(column);
    setApplicationData(sortedData);
  };

  const vendor_type = 'interjet';
  const type = 'new';

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (vendor_type) {
          const response = await applicationCheck(vendor_type, type);

          if (response.data.message == 'success') {
            SetAppTemplate(response.data.app_template[0].template_header_logo1)
            SetFooterTemplate(response.data.app_template[0].template_header_logo2)
            setTemplateColor(response.data.app_template[0].template_bg_color)
          }
        }

      } catch (error) {
        console.error('API Error:', error);
      }
    };
    fetchData();
  }, [vendor_type, type]);

  const itemsPerPage = 10;
  const totalItems = indData.length;
  const filterDataTotal = applicationData.length
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const filterTotalPage = Math.ceil(filterDataTotal / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const ApplicationDetails = applicationData.slice(startIndex, startIndex + itemsPerPage);

  const [selectedCheckboxes, setSelectedCheckboxes] = useState<any[]>([]);

  const handlePDFDetails = (appEncypId: string) => {
    setSelectedCheckboxes((prevSelectedCheckboxes) => {
      const index = prevSelectedCheckboxes.indexOf(appEncypId);
      if (index == -1) {
        return [...prevSelectedCheckboxes, appEncypId] as any[];
      } else {
        const updatedSelection = [...prevSelectedCheckboxes];
        updatedSelection.splice(index, 1);
        return updatedSelection as any[];
      }
    });
  };

  const handleCheckAll = (isChecked: any) => {
    setCheckAll(isChecked);
    const checkboxes = document.querySelectorAll('.widget-9-check');

    // Create an array to hold the selected checkboxes
    const updatedSelectedCheckboxes: any[] = [];

    checkboxes.forEach((checkbox) => {
      const inputCheckbox = checkbox as HTMLInputElement;
      inputCheckbox.checked = isChecked;

      // Update the selectedCheckboxes array based on the isChecked value
      if (isChecked) {
        updatedSelectedCheckboxes.push(inputCheckbox.value);
      }
    });

    // Update the selectedCheckboxes state with the updated array
    setSelectedCheckboxes(updatedSelectedCheckboxes);
  };

  const pdfContentRef = useRef(null);

  const handleExport2PagesClick = () => {
    const checkboxes = document.querySelectorAll('.widget-9-check:checked');

    if (checkboxes.length == 0) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'No applications are selected for export',
      });
    } else {
      setTwoPageExport('1');
      handleExportInd();
      setExportFiles(true);
    }
  };


  const handleExportDefault = async () => {
    const checkboxes = document.querySelectorAll('.widget-9-check:checked');

    if (checkboxes.length == 0) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'No applications are selected for export',
      });
    } else {
      setTwoPageExport('0');

      handleExportInd();
      setExportFiles(true);
    }
  };

  const handleExportDefaultShort = (appId: any) => {

    setSelectedCheckboxes([appId])
    handleExportInd();
    setExportFiles(true);
  };

  interface jsPDFWithHTML extends jsPDF {
    fromHTML(html: string, x: number, y: number, settings?: object): void;
  }

  useEffect(() => {
    // Check if viewDetails is not undefined and app_application_id is truthy
    if (viewDetails && (viewDetails.app_application_id || viewDetails.app_id) && ExportFiles) {
      // Trigger the PDF generation logic here
      generatePDF_NEW();
    }
    // Make sure to handle any dependencies in the dependency array of useEffect if needed
  }, [viewDetails]);

  const generatePDF_NEW = async () => {

    let isExportCompleted = false;

    const loadingSwal = Swal.fire({
      title: 'Exporting PDFs',
      html: 'Please wait...',
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      const pdfContentElement = document.getElementById('pdf_content');
      const termsDiv = document.getElementById('terms_and_conditions');

      // Check if the elements are present before proceeding
      if (pdfContentElement) {
        const pdf = new jsPDF() as jsPDFWithHTML;

        // Generate PDF from main content
        const mainContentCanvas = await html2canvas(pdfContentElement);
        const mainContentImgData = mainContentCanvas.toDataURL('image/png');
        const mainContentImgWidth = 190;
        const mainContentImgHeight = (mainContentCanvas.height * mainContentImgWidth) / mainContentCanvas.width;
        pdf.addImage(mainContentImgData, 'PNG', 10, 10, mainContentImgWidth, mainContentImgHeight);

        if (termsDiv) {
          const termsCanvas = await html2canvas(termsDiv);
          const termsImgData = termsCanvas.toDataURL('image/png');
          const termsImgWidth = 190;
          const termsImgHeight = (termsCanvas.height * termsImgWidth) / termsCanvas.width;
          pdf.addPage();
          pdf.addImage(termsImgData, 'PNG', 10, 10, termsImgWidth, termsImgHeight);
          if (viewDetails?.app_created_on) {
            pdf.setFontSize(10);

            pdf.text('Date:' + viewDetails?.app_created_on, 10, pdf.internal.pageSize.height - 30);
            pdf.text('Place:' + viewDetails?.app_billing_state, 10, pdf.internal.pageSize.height - 23);

          }

          if (viewDetails?.app_company_created_on) {
            pdf.setFontSize(10);
            pdf.text('Date:' + viewDetails?.app_company_created_on, 10, pdf.internal.pageSize.height - 30);
            pdf.text('Place:' + viewDetails?.app_company_billing_state, 10, pdf.internal.pageSize.height - 23);
          }

          if (viewDetails?.app_auth_sign) {
            const imageWidth = 50; // Set your desired width
            const imageHeight = 20;
            const xCoordinate = pdf.internal.pageSize.width - imageWidth - 10;
            pdf.addImage(viewDetails?.app_auth_sign, 'JPEG', xCoordinate, pdf.internal.pageSize.height - 50, imageWidth, imageHeight);
            pdf.text('Customer Authorized Signatory', xCoordinate, pdf.internal.pageSize.height - 20);
          }
          if (viewDetails?.app_company_auth_sign) {
            const imageWidth = 50; // Set your desired width
            const imageHeight = 20;
            const xCoordinate = pdf.internal.pageSize.width - imageWidth - 10;
            pdf.addImage(viewDetails?.app_company_auth_sign, 'JPEG', xCoordinate, pdf.internal.pageSize.height - 50, imageWidth, imageHeight);
            pdf.text('Customer Authorized Signatory', xCoordinate, pdf.internal.pageSize.height - 20);
          }
        }

        if (twopageExport == '0') {
          pdf.addPage();
          // Set initial Y coordinate
          let currentY = 50;

          // Display ID Proof Front Page Image
          if (viewDetails?.app_id_proof_front_image) {
            pdf.text('ID Proof Front', 10, currentY);
            pdf.addImage(viewDetails.app_id_proof_front_image, 'JPEG', 10, currentY + 10, 100, 50);
            currentY += 70; // Set the gap between images
          }


          // Display ID Proof Back Page Image
          if (viewDetails?.app_id_proof_back_image) {
            pdf.text('ID Proof Back', 10, currentY);
            pdf.addImage(viewDetails.app_id_proof_back_image, 'JPEG', 10, currentY + 10, 100, 50);
            currentY += 70; // Set the gap between images
          }

          if (viewDetails?.app_company_auth_front_image) {
            pdf.text('ID Proof Front', 10, currentY);
            pdf.addImage(viewDetails.app_company_auth_front_image, 'JPEG', 10, currentY + 10, 100, 50);
            currentY += 70; // Set the gap between images
          }


          if (viewDetails?.app_company_auth_back_image) {
            pdf.text('ID Proof Back', 10, currentY);
            pdf.addImage(viewDetails.app_company_auth_back_image, 'JPEG', 10, currentY + 10, 100, 50);
            currentY += 70; // Set the gap between images
          }

          pdf.addPage();

          let addcurrentY = 50;

          if (viewDetails?.app_add_proof_image) {
            pdf.text('Address Proof Front', 10, addcurrentY);
            pdf.addImage(viewDetails.app_add_proof_image, 'JPEG', 10, addcurrentY + 10, 100, 50);
            // No need to update addcurrentY after the last image if there are no more images
            addcurrentY += 70;
          }

          if (viewDetails?.app_add_proof_back_image) {
            pdf.text('Address Proof Back', 10, addcurrentY);
            pdf.addImage(viewDetails.app_add_proof_back_image, 'JPEG', 10, addcurrentY + 10, 100, 50);
            addcurrentY += 70;
          }

          if (viewDetails?.app_company_address_image) {
            pdf.text('Address Proof Front', 10, addcurrentY);
            pdf.addImage(viewDetails.app_company_address_image, 'JPEG', 10, addcurrentY + 10, 100, 50);
            addcurrentY += 70; // Set the gap between images
          }

          if (viewDetails?.app_company_address_image_2) {
            pdf.text('Address Proof Back', 10, addcurrentY);
            pdf.addImage(viewDetails.app_company_address_image_2, 'JPEG', 10, addcurrentY + 10, 100, 50);
            addcurrentY += 70; // Set the gap between images
          }

        }

        const totalPages = pdf.internal.pages.length;

        for (let i = 1; i <= totalPages; i++) {
          pdf.setPage(i);
          const imageWidth = 208; // Set your desired width
          const imageHeight = 30;
          const imageHeightFooter = 15;
          const pageWidth = pdf.internal.pageSize.getWidth();
          const xCoordinate = (pageWidth - imageWidth) / 2;

          if (viewDetails?.app_template_header) {
            const headerImage = viewDetails?.app_template_header as any;
            const footerImage = viewDetails?.app_template_footer as any;
            pdf.addImage(headerImage, 'JPEG', xCoordinate, 0, imageWidth, imageHeight);
            pdf.addImage(footerImage, 'JPEG', xCoordinate, pdf.internal.pageSize.height - 15, imageWidth, imageHeightFooter);
          }
        }

        pdf.save('Application.pdf');
        setExportFiles(false);
        // Display success message
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Successfully Exported',
          showConfirmButton: true,
          timer: 3000,
        });

        setPrintableArea(false);
      } else {
        console.error('pdf_content or terms_and_conditions element not found.');
        // Handle the case where the elements are not found
      }
    } catch (error) {
      console.error('Error during PDF generation:', error);
      // Handle errors for specific checkbox as needed
    }
  };

  const handleExportInd = async () => {
    try {
      for (const appEncypId of selectedCheckboxes) {
        // Make the API call to retrieve data
        const response = await Individual_EDIT_Application(appEncypId);

        if (response && response.data) {
          setViewDetails(response.data.app_view_details[0]);
          // Other data processing...
          setPrintableArea(true);
        } else {
          console.error('Error or data not available for appEncypId:', appEncypId);
          // Handle the case where the API call did not return the expected data
        }
      }
    } catch (error) {
      console.error('Error during PDF generation:', error);
      // Handle errors for specific checkbox as needed
    }
  }

  return (
    <>
      <div className='card'>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Application Details</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>Total {applicationData.length} Customers</span>
          </h3>
          <div className='card-toolbar'>
            <div className='me-5'>
              <select className='form-select form-select-solid' onChange={handleFilterTypeChange}
                value={filterType}>
                <option value="">Application Type</option>
                <option value="Individual">Individual</option>
                <option value="Company">Company</option>
              </select>
            </div>
            <div className='me-5'>
              <select
                className='form-select form-select-solid'
                onChange={handleFilterChange}
                value={filterStatus}
              >
                <option value="">Application Status</option>
                <option value="2">Customer Edited</option>
                <option value="1">New Application</option>
                <option value="0">Rejected</option>
                <option value="3">Approved</option>
                <option value="4">Pending in Review</option>
              </select>
            </div>
            {userRole == '1' || userRole == '2' ? (
              <div className="btn-group me-2">
                <button type="button" className="btn btn-danger dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                  <KTIcon iconName='file-down' className='fs-2' />
                  Export
                </button>
                <ul className="dropdown-menu">
                  <li><button className="dropdown-item" onClick={handleExportDefault}>Export Default</button></li>
                  <li><button className="dropdown-item" onClick={handleExport2PagesClick}>Export 2 Pages </button></li>
                  <li><button className="dropdown-item" onClick={handleExportDefault}>Export DOT</button></li>
                </ul>
              </div>
            ) : null}
            <button className='btn btn-primary me-5' onClick={handleButtonClick}>
              <KTIcon iconName='plus' className='fs-2' />
              Add New
            </button>
            <div className="d-flex align-items-center position-relative">
              <div className="input-group">
                <input type="search" placeholder='Search anything...' value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} className="form-control w-50" />
                <button type="button" className="btn btn-primary">
                  <i className="fas fa-search"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className='card-body py-3'>
          {isLoading ? (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '200px' }}>
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (ApplicationDetails.length > 0 ? (
            <div className='table-responsive'>
              <table className='table align-middle gs-0 gy-4'>
                <thead className='table_heading'>
                  <tr className='fw-bold fs-6 bg-light-primary'>
                    {userRole == '1' || userRole == '2' ? (
                      <th className='w-25px ps-4 rounded-start'>
                        <div className='form-check form-check-sm form-check-custom form-check-solid'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            data-kt-check='true'
                            data-kt-check-target='widget-9-check'
                            onChange={(e) => handleCheckAll(e.target.checked)}
                          />
                        </div>
                      </th>
                    ) : null}
                    <th className='ps-4' onClick={() => sortByColumn('app_id')}>
                      Id
                      {sortColumn == 'app_id' && sortOrder == 'asc' && <KTIcon iconName='up' className='fs-2' />}
                      {sortColumn == 'app_id' && sortOrder == 'desc' && <KTIcon iconName='down' className='fs-2' />}
                    </th>
                    {userRole == '1' || userRole == '2' ? (
                      <th>Cus-ID</th>
                    ) : null}
                    <th onClick={() => sortByColumn('app_type')}>
                      Type
                      {sortColumn == 'app_type' && sortOrder == 'asc' && <KTIcon iconName='up' className='fs-2' />}
                      {sortColumn == 'app_type' && sortOrder == 'desc' && <KTIcon iconName='down' className='fs-2' />}
                    </th>
                    <th onClick={() => sortByColumn('app_name')}>
                      Name
                      {sortColumn == 'app_name' && sortOrder == 'asc' && <KTIcon iconName='up' className='fs-2' />}
                      {sortColumn == 'app_name' && sortOrder == 'desc' && <KTIcon iconName='down' className='fs-2' />}
                    </th>
                    {userRole == '1' || userRole == '2' ? (
                      <th>
                        Vendor
                      </th>
                    ) : null}
                    <th onClick={() => sortByColumn('app_phone_no')}>
                      Phone No
                      {sortColumn == 'app_phone_no' && sortOrder == 'asc' && <KTIcon iconName='up' className='fs-2' />}
                      {sortColumn == 'app_phone_no' && sortOrder == 'desc' && <KTIcon iconName='down' className='fs-2' />}
                    </th>
                    <th onClick={() => sortByColumn('app_email_id')}>
                      Email
                      {sortColumn == 'app_email_id' && sortOrder == 'asc' && <KTIcon iconName='up' className='fs-2' />}
                      {sortColumn == 'app_email_id' && sortOrder == 'desc' && <KTIcon iconName='down' className='fs-2' />}
                    </th>
                    <th onClick={() => sortByColumn('app_state')}>
                      State
                      {sortColumn == 'app_state' && sortOrder == 'asc' && <KTIcon iconName='up' className='fs-2' />}
                      {sortColumn == 'app_state' && sortOrder == 'desc' && <KTIcon iconName='down' className='fs-2' />}
                    </th>
                    <th onClick={() => sortByColumn('app_status')}>
                      Status
                      {sortColumn == 'app_status' && sortOrder == 'asc' && <KTIcon iconName='up' className='fs-2' />}
                      {sortColumn == 'app_status' && sortOrder == 'desc' && <KTIcon iconName='down' className='fs-2' />}
                    </th>
                    <th onClick={() => sortByColumn('app_created_on')}>
                      Created On
                      {sortColumn == 'app_created_on' && sortOrder == 'asc' && <KTIcon iconName='up' className='fs-2' />}
                      {sortColumn == 'app_created_on' && sortOrder == 'desc' && <KTIcon iconName='down' className='fs-2' />}
                    </th>
                    <th className='pe-4 rounded-end'>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {ApplicationDetails.map((app, index) => (
                    <tr key={index}>
                      {userRole == '1' || userRole == '2' ? (
                        <td className='ps-4'>
                          <div className='form-check form-check-sm form-check-custom form-check-solid'>
                            <input
                              className='form-check-input widget-9-check'
                              type='checkbox'
                              value={app.app_encyp_id}
                              checked={selectedCheckboxes.includes(app.app_encyp_id)}
                              onChange={() => handlePDFDetails(app.app_encyp_id)}
                            />
                          </div>
                        </td>
                      ) : null}
                      <td className='text-dark fw-bold text-hover-primary fs-6  text-center'>{app.app_id}</td>
                      {userRole == '1' || userRole == '2' ? (
                        <td className='text-dark fw-bold text-hover-primary fs-6  text-center'>{app.app_dot_id}</td>
                      ) : null}
                      <td className='text-dark fw-bold text-hover-primary fs-6'>
                        {app.app_type == "Company" ? (
                          <span className='badge bg-primary text-white'>Company</span>
                        ) : app.app_type == "Individual" ? (
                          <span className='badge bg-danger text-white'>Individual</span>
                        ) : null}
                      </td>
                      <td className='text-dark fw-bold text-hover-primary fs-6'>{app.app_name}</td>
                      {userRole == '1' || userRole == '2' ? (
                        <td className='text-dark fw-bold text-hover-primary fs-6'>{app.app_vendor_name}</td>
                      ) : null}
                      <td className='text-dark fw-bold text-hover-primary fs-6'>{app.app_phone_no}</td>
                      <td className='text-dark fw-bold text-hover-primary fs-6'>{app.app_email_id}</td>
                      <td className='text-dark fw-bold text-hover-primary fs-6'>{app.app_state}</td>
                      <td className='text-dark fw-bold text-hover-primary fs-6'>
                        {app.app_status == "1" ? (
                          <span className='badge bg-warning text-white'>New Application</span>
                        ) : app.app_status == "2" ? (
                          <span className='badge bg-danger text-white'>Customer Edited</span>
                        ) : app.app_status == "3" ? (
                          <span className="badge bg-success text-white">Approved</span>
                        ) : app.app_status == "0" ? (
                          <span className='badge bg-danger text-white'>Rejected</span>
                        ) : app.app_status == "4" ? (
                          <span className='badge bg-info text-white'>Pending in Review</span>
                        ) : null}
                      </td>
                      <td className='text-dark fw-bold text-hover-primary fs-6'>{app.app_created_on}</td>
                      <td style={{ paddingTop: "10px" }}>
                        <div className="dropdown">
                          <button className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary" type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
                            <i className="bi bi-three-dots fs-5"></i>
                          </button>
                          <ul className="dropdown-menu" aria-labelledby="dropdownMenu2">
                            {app.app_status == "3" && userRole == '1' ? (
                              <li>
                                <button
                                  className="dropdown-item"
                                  type="button"
                                  onClick={() => handleEditApplication(app.app_encyp_id)}
                                >
                                  Edit / Approve
                                </button>
                              </li>
                            ) : (
                              (app.app_status !== "3" && (userRole == '1' || userRole == '2')) ? (
                                <li>
                                  <button
                                    className="dropdown-item"
                                    type="button"
                                    onClick={() => handleEditApplication(app.app_encyp_id)}
                                  >
                                    Edit / Approve
                                  </button>
                                </li>
                              ) : null
                            )}
                            <li>
                              <button
                                className="dropdown-item"
                                type="button"
                                onClick={() => handleViewApplication(app.app_encyp_id)}
                              >
                                View Application
                              </button>
                            </li>
                            <li>
                              <button
                                className="dropdown-item"
                                type="button"
                                onClick={() => generateCopyLink(app.app_encyp_id, app.app_shortcode)}
                              >
                                Copy
                              </button>
                            </li>
                            {userRole == '1' || userRole == '2' ? (
                              <li>
                                <button
                                  className="dropdown-item"
                                  type="button"
                                  onClick={() => handleExportDefaultShort(app.app_encyp_id)}
                                >
                                  Export
                                </button>

                              </li>
                            ) : ('')}
                          </ul>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {filterStatus || filterType || searchQuery ? (
                <Pagination currentPage={currentPage} totalPages={filterTotalPage} handlePageChange={handlePageChange} />
              ) : (
                <Pagination currentPage={currentPage} totalPages={totalPages} handlePageChange={handlePageChange} />
              )}
            </div>
          ) :
            <div className="d-flex justify-content-center p-10">
              <div>
                <img src={toAbsoluteUrl('/media/stock/coming_soon.png')} className='w-350px' />
                <h3 className="text-center">No applications found</h3>
              </div>
            </div>
          )}
        </div>
      </div>

      {userRole != 3 && PrintableArea ? (
        <div>
          <div ref={pdfContentRef} id="pdf_content">
            <br></br>
            <br></br>
            <div className='row mt-20'>

              <p className='fw-bold text-center text-white fs-5 mt-20 p-1' style={{ backgroundColor: templateColor }}>Customer Application</p>

              <div className='col-md-10'>
                <div className="fs-5 text-gray-800 text-hover-primary fw-bold">1.Tille/Name of the Customer Company/Firm/Organization (SUBNAME FIRST)</div>
                <div className="p-2 fs-6 text-gray-900 fw-bold" style={{ border: `2px solid ${templateColor}` }}>{viewDetails?.app_customer_name} {viewDetails?.app_company_name}</div>

                <div className='d-flex align-items-center mt-5'>
                  <div className="fs-5 text-gray-800 text-hover-primary fw-bold">
                    2. Name of the Father Group/ Proprinter Partner(s)
                  </div>
                  <div className="p-2 fs-6 text-gray-900 fw-bold ms-5" style={{ width: '62%', border: `2px solid ${templateColor}` }}>
                    {viewDetails?.app_father_name}
                    {viewDetails?.app_company_type_concern}
                  </div>
                </div>

                <div className='d-flex mt-10'>
                  <div className="fs-5 text-gray-800 text-hover-primary fw-bold">3.Gender : </div>
                  <div className="mb-10">
                    <div className="form-check form-check-custom form-check-solid">
                      <input readOnly className="form-check-input ms-2" type="checkbox" name="gender" value="male" id="flexCheckDefault" checked={viewDetails?.app_gender == 'male'} />
                      <label className="fs-6 text-gray-900 fw-bold ps-5" htmlFor="male">
                        Male
                      </label>
                    </div>
                  </div>
                  <div className="mb-10">
                    <div className="form-check form-check-custom form-check-solid">
                      <input readOnly className="form-check-input ms-5" name="gender" type="checkbox" checked={viewDetails?.app_gender == 'female'} value="female" id="flexCheckDefault" />
                      <label className="fs-6 text-gray-900 fw-bold ps-5" htmlFor="female">
                        Female
                      </label>
                    </div>
                  </div>
                  <div className="fs-5 text-gray-800 text-hover-primary fw-bold ms-5">4.Nationality</div>
                  <div className="fs-6 text-gray-900 fw-bold ms-5 pdf_nationality">{viewDetails?.app_nationality}</div>
                </div>

                <div className="d-flex mt-10 align-items-center">
                  <div className="fs-5 text-gray-800 text-hover-primary fw-bold"> {viewDetails?.app_company_date_incorporation ? '5. Date of Incorporation' : '5. Date Of Birth'}</div>
                  <div className="p-2 fs-6 text-gray-900 fw-bold ms-5" style={{ width: "35%", border: `2px solid ${templateColor}` }}>{viewDetails?.app_dob} {viewDetails?.app_company_date_incorporation}</div>
                  <div className="fs-5 text-gray-800 text-hover-primary fw-bold ms-5">6.Email</div>
                  <div className="p-2 fs-6 text-gray-900 fw-bold ms-5" style={{ width: "44%", border: `2px solid ${templateColor}` }}>{viewDetails?.app_email_id} {viewDetails?.app_company_email_id}</div>
                </div>
              </div>
              <div className='col-md-2'>
                <div className="fs-5 text-gray-800 text-hover-primary fw-bold">Date</div>
                <div className="p-2 fs-6 text-gray-900 fw-bold" style={{ border: `2px solid ${templateColor}` }}>{viewDetails?.app_created_on} {viewDetails?.app_company_created_on}</div>
                <img src={viewDetails?.app_customer_image ? viewDetails?.app_customer_image : viewDetails?.app_company_customer_image} className='w-100 mt-15' alt='customer_image' />

              </div>
            </div>

            <div className='row mt-10'>
              <div className='col-md-1'>
                <div className="fs-5 text-gray-800 text-hover-primary fw-bold">7.GST IN</div>
              </div>
              <div className='col-md-4'>
                <div className="p-2 fs-6 text-gray-900 fw-bold ms-5 w-100" style={{ border: `2px solid ${templateColor}` }}>{viewDetails?.app_company_gst}</div>
              </div>

              <div className='col-md-2'>
                <div className="fs-5 text-gray-800 text-hover-primary fw-bold ms-5">8.Account Type</div>
              </div>
              <div className='col-md-5'>
                <div className="p-2 fs-6 text-gray-900 fw-bold ms-5 w-w-85" style={{ border: `2px solid ${templateColor}` }}>{viewDetails?.app_type}</div>
              </div>
            </div>

            <div className='row mt-10'>
              <div className='col-md-1'>
                <div className="fs-5 text-gray-800 text-hover-primary fw-bold">9.Phone Number</div>
              </div>
              <div className='col-md-4'>
                <div className="p-2 fs-6 text-gray-900 fw-bold ms-5 w-100" style={{ border: `2px solid ${templateColor}` }}>{viewDetails?.app_phone_no} {viewDetails?.app_company_phone_no}</div>
              </div>

              <div className='col-md-2'>
                <div className="fs-5 text-gray-800 text-hover-primary fw-bold ms-5">10.Alternate Number</div>
              </div>
              <div className='col-md-5'>
                <div className="p-2 fs-6 text-gray-900 fw-bold ms-5 w-85" style={{ border: `2px solid ${templateColor}` }}>{viewDetails?.app_alternate_number} {viewDetails?.app_company_alternate_number}</div>
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-md-6">
                <div style={{ border: `2px solid ${templateColor}` }}>
                  <p className='fw-bold text-center text-white fs-5 p-1' style={{ backgroundColor: templateColor }}>11.Billing Address</p>
                  <h5 className='fw-bold text-muted ms-2'>Address : <span className='fw-bold fs-6 text-dark'>{viewDetails?.app_billing_add} {viewDetails?.app_company_billing_add}</span></h5>
                  <h5 className='fw-bold text-muted ms-2'>Country : <span className='fw-bold fs-6 text-dark'>{viewDetails?.app_billing_country} {viewDetails?.app_company_billing_country}</span></h5>
                  <h5 className='fw-bold text-muted ms-2'>State : <span className='fw-bold fs-6 text-dark'>{viewDetails?.app_billing_state} {viewDetails?.app_company_billing_state}</span></h5>
                </div>
              </div>
              <div className="col-md-6">
                <div style={{ border: `2px solid ${templateColor}` }}>
                  <p className='fw-bold text-center text-white fs-5 p-1' style={{ backgroundColor: templateColor }}>12.Installation Address</p>
                  <h5 className='fw-bold text-muted ms-2'>Address : <span className='fw-bold fs-6 text-dark'>{viewDetails?.app_installation_add} {viewDetails?.app_company_installation_add}</span></h5>
                  <h5 className='fw-bold text-muted ms-2'>Country : <span className='fw-bold fs-6 text-dark'>{viewDetails?.app_country} {viewDetails?.app_company_country}</span></h5>
                  <h5 className='fw-bold text-muted ms-2'>State : <span className='fw-bold fs-6 text-dark'>{viewDetails?.app_state} {viewDetails?.app_company_state}</span></h5>
                </div>
              </div>
            </div>
            {viewDetails && viewDetails.app_id_proof !== undefined ? (
              <div>
                <p className='fw-bold text-center text-white fs-5 mt-5 p-1' style={{ backgroundColor: templateColor }}>13.Proof of Identity {'- ' + viewDetails?.app_id_proof}</p>

                <div className='d-flex justify-content-center mt-5'>
                  <img src={viewDetails?.app_id_proof_front_image} alt="ID Proof Front" className='w-75px height-75px' />
                  <img src={viewDetails?.app_id_proof_back_image} alt="ID Proof Back" className='w-75px height-75px ms-5' />
                </div>

                <p className='fw-bold text-center text-white fs-5 p-1 mt-5' style={{ backgroundColor: templateColor }}>14.Proof of Address  {'- ' + viewDetails?.app_address_proof}</p>

                <div className='d-flex justify-content-center mt-5'>
                  <img src={viewDetails?.app_add_proof_image} alt="Address Proof" className='w-75px height-75px' />
                  <img src={viewDetails?.app_add_proof_back_image} alt="Address Proof" className='w-75px height-75px ms-5' />
                </div>
              </div>

            ) :
              <div>
                <p className='fw-bold text-center text-white fs-5 mt-5 p-1' style={{ backgroundColor: templateColor }}>13.Proof of Identity </p>

                <div className='d-flex justify-content-center mt-5'>


                  <img src={viewDetails && viewDetails.app_company_auth_front_image} alt="ID Proof Front" className='w-75px height-75px' />
                  <img src={viewDetails && viewDetails.app_company_auth_back_image} alt="ID Proof Back" className='w-75px height-75px ms-5' />
                </div>

                <p className='fw-bold text-center text-white fs-5 p-1 mt-5' style={{ backgroundColor: templateColor }}>14.Proof of Address</p>

                <div className='d-flex justify-content-center mt-5'>
                  <img src={viewDetails?.app_company_address_image} alt="Address Proof Front" className='w-75px height-75px' />
                  <img src={viewDetails?.app_company_address_image_2} alt="Address Proof Back" className='w-75px height-75px ms-5' />
                </div>
              </div>

            }

            <div className="row mt-5">
              <div className="col-md-6">
                <p className='pdf_declaration'>
                  <span className='fs-4'>Declaration: </span>I hereby declare that information given above is true to the best of my knowledge & I will abide by the prevailing Telegraph Act/Rules framed there under & Tariffs as amended from time to time.
                  In the event of any dispute concerning any telecom line,apparatus or appliance, bill etc., between me/us & {viewDetails?.app_vendor_name}, the matter will be refered to the sole Arbitrator, appointed by a
                  nominated authority in {viewDetails?.app_vendor_name} & shall be governed by the provisions of the Arbitration and Conciliation Act, 1996.
                </p>
              </div>
              <div className="col-md-6">
                <div className='rounded text-center' style={{ border: `2px solid ${templateColor}` }}>
                  <img src={viewDetails?.app_company_auth_sign ? viewDetails?.app_company_auth_sign : viewDetails?.app_auth_sign} alt="sign" className='w-50' />
                  <div className="fs-5 text-gray-800 text-hover-primary fw-bold">Customer Authorized Signatory</div>
                </div>
              </div>
            </div>

            <div className='mt-5'>
              <p className='fw-bold text-center text-white fs-5 p-1' style={{ backgroundColor: templateColor }}>For Office Use Only</p>
              <span className='fs-5'>
                I/ We hereby undertake and confirm that I/We have seen the Customer and Certify that - (a) The Customer Subscription Form has been duly filled in by the applicant.
                (b) The photograph submitted has been matched with the applicant. (c) The form has been personally signed by the applicant in my presence. (d) Proof of Identity and Address has
                been collected. (e) Original proof of Identity and address has been matched and verified with the self attested documents submitted by the applicant. (f) Details of Customer Subscription form match with all documents.<br /><br />
                I/ We have activated the connection only after verifying that customer has filled the Subscription form and submitted<br /> copies of the required documentary proof.
              </span>
            </div>

            <div className="row mt-5">
              <div className="col-md-6">
                <div className='d-flex mt-5'>
                  <div className="fw-bold text-dark fs-3">Account Manager Name</div>
                  <div className="fs-6 text-gray-900 fw-bold ms-5 mb-0 pdf_nationality">{viewDetails?.app_approved_by}</div>
                </div>
                <div className='d-flex'>
                  <div className="fw-bold text-dark fs-3">Date</div>
                  <div className="fs-6 text-gray-900 fw-bold ms-5 mb-0 pdf_nationality">{viewDetails?.app_approved_on}</div>
                </div>
              </div>
              <div className="col-md-6">
                <div className='rounded w-100 text-center' style={{ border: `2px solid ${templateColor}` }}>
                  <img src={viewDetails?.app_approved_image} alt="approved_by" className='w-150px' />
                  <div className="fs-5 text-gray-800 text-hover-primary text-center fw-bold">Approved By</div>
                </div>
              </div>
            </div>

          </div>
          <div id="terms_and_conditions">
            <br></br>
            <br></br>
            <br></br>
            <div className="mt-20">
              <br></br>
              <br></br>
              <h3>TERMS AND CONDITIONS</h3>
              <p style={{ fontSize: '17px' }} dangerouslySetInnerHTML={{ __html: viewDetails?.app_terms_conditions || '' }} />
            </div>

          </div>
        </div>
      ) : ('')}

      <div className={`modal${showModal ? ' show' : ''}`} role="dialog" style={{ display: showModal ? 'block' : 'none' }}>
        <div className="modal-dialog modal-lg modal-dialog-centered" role="document" >
          <div className="modal-content" >
            <div className="modal-header">
              <h5 className="modal-title">Create New Application</h5>
              <button type="button" className="btn-close" onClick={handleCloseModal} aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <IndividualFormAdmin setShowModal={setShowModal} fetchApplicationData={fetchApplicationData} />
            </div>
          </div>
        </div>
      </div>

      {showEditModal && (
        <div className="modal" role="dialog" style={{ display: 'block' }}>
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-body" >
                {loadingEdit ? (
                  <div className="d-flex justify-content-center align-items-center" style={{ height: '200px' }}>
                    <div className="spinner-border text-primary" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="d-flex justify-content-around mb-10 fs-5 fw-bold">
                      <div className={`step ${activeStep == 1 ? 'active' : ''}`} style={{ borderBottom: activeStep == 1 ? '2px solid #28a745' : 'none' }}>
                        1.Basic Information
                      </div>
                      <div className={`step ${activeStep == 2 ? 'active' : ''}`} style={{ borderBottom: activeStep == 2 ? '2px solid #28a745' : 'none' }}>
                        2.Address Proof Details
                      </div>
                      <div className={`step ${activeStep == 3 ? 'active' : ''}`} style={{ borderBottom: activeStep == 3 ? '2px solid #28a745' : 'none' }}>
                        3.Communication Details
                      </div>
                      <div className={`step ${activeStep == 4 ? 'active' : ''}`} style={{ borderBottom: activeStep == 4 ? '2px solid #28a745' : 'none' }}>
                        4.Verify & Completed
                      </div>
                      <div>
                        <button type="button" className="btn-close" onClick={() => setShowEditModal(false)} aria-label="Close"></button>
                      </div>
                    </div>
                    <Formik initialValues={initialEditValues} validationSchema={validationSchema} onSubmit={handleUpdateApplication}>
                      {({ isValid }) => (
                        <Form>
                          <div className="form-group">
                            <input type="hidden" name="app_id_upd" className="form-control" />
                          </div>

                          {activeStep == 1 && (
                            <>
                              <div className='row'>
                                <div className="col-md-6 pe-10 edit_form_border">
                                  <div className='mb-6'>
                                    <label className='form-label required fw-bold fs-6'>Customer Profile</label>
                                    <i className='fas fa-edit fs-3 pe-3 text-dark' onClick={() => {
                                      customerPhoto.current?.click();
                                    }}></i>
                                    {indCustomer_photo.map(file => (
                                      <div key={file.name}>
                                        <img
                                          src={typeof file == 'string' ? file : URL.createObjectURL(file)}
                                          alt={file.name}
                                          className='w-50 rounded-3 mb-5'
                                        />
                                      </div>
                                    ))}

                                    <div {...getRootProps8({ className: "dropzone" })} style={{ display: "none" }}>
                                      <input className="input-zone" {...getInputProps8()}
                                        ref={(el) => { customerPhoto.current = el }} />
                                      <div className="text-center">
                                        <p className="dropzone-content">
                                          Drag’n’drop or click to select files
                                        </p>
                                      </div>
                                    </div>
                                  </div>

                                  <div className='mb-6'>
                                    <label className='form-label required fw-bold fs-6'>Enter Your Name</label>
                                    <Field type="text" name="app_customer_name_upd" className="form-control form-control-sm" />
                                    <ErrorMessage name="app_customer_name_upd" component="div" className="text-danger" />
                                  </div>

                                  <div className='mb-6'>
                                    <label className='form-label required fw-bold fs-6'>Father /Spouse /Guardian Name</label>
                                    <Field type='text' className='form-control form-control-sm' name='app_father_name_upd' />
                                    <ErrorMessage name="app_father_name_upd" component="div" className="text-danger" />
                                  </div>

                                  <div className='mb-6'>
                                    <label className='form-label required fw-bold fs-6'>Gender</label>
                                    <div className='d-flex'>
                                      <div className="form-check form-check-custom form-check-solid">
                                        <Field
                                          type="radio"
                                          name="app_gender_upd"
                                          value="male"
                                          id="male"
                                          className="form-check-input"
                                        />
                                        <label className="form-check-label fw-bolder text-gray-500 text-hover-primary fs-5" htmlFor="male">Male</label>
                                      </div>
                                      <div className='form-check form-check-custom form-check-solid ms-3'>
                                        <Field
                                          type="radio"
                                          name="app_gender_upd"
                                          value="female"
                                          id="female"
                                          className="form-check-input"
                                        />
                                        <label className="form-check-label fw-bolder text-gray-500 text-hover-primary fs-5" htmlFor="female">Female</label>
                                      </div>
                                      <ErrorMessage name="app_gender_upd" component="div" className="text-danger" />
                                    </div>
                                  </div>

                                  <div className='mb-6'>
                                    <label className='form-label required fw-bold fs-6'>Nationality</label>
                                    <div className='d-flex'>
                                      <div className="form-check form-check-custom form-check-solid">
                                        <Field
                                          type="radio"
                                          name="app_nationality_upd"
                                          value="Indian"
                                          id="Indian"
                                          className="form-check-input"
                                        />
                                        <label className="form-check-label fw-bolder text-gray-500 text-hover-primary fs-5" htmlFor="Indian">Indian</label>
                                      </div>
                                      <div className='form-check form-check-custom form-check-solid ms-3'>
                                        <Field
                                          type="radio"
                                          name="app_nationality_upd"
                                          value="French"
                                          id="French"
                                          className="form-check-input"
                                        />
                                        <label className="form-check-label fw-bolder text-gray-500 text-hover-primary fs-5" htmlFor="French">French</label>
                                      </div>
                                      <div className='form-check form-check-custom form-check-solid ms-3'>
                                        <Field
                                          type="radio"
                                          name="app_nationality_upd"
                                          value="Others"
                                          id="Others"
                                          className="form-check-input"
                                        />
                                        <label className="form-check-label fw-bolder text-gray-500 text-hover-primary fs-5" htmlFor="Others">Others</label>
                                      </div>
                                      <ErrorMessage name="app_nationality _upd" component="div" className="text-danger" />
                                    </div>
                                  </div>

                                  <div className='mb-6'>
                                    <label className='form-label required fw-bold fs-6'>Date Of Birth</label>
                                    <Field type='date' className='form-control form-control-sm' name='app_dob_upd' />
                                    <ErrorMessage name="app_dob_upd" component="div" className="text-danger" />
                                  </div>
                                </div>

                                <div className='col-md-6 ps-10'>
                                  <div className='mb-6'>
                                    <label className='form-label required fw-bold fs-6'>Id Proof</label>
                                    <Field as="select" name="app_id_proof_upd" aria-label="Select example" className="form-select form-select-sm" >
                                      <option value='Aadhar Card'>Aadhar Card</option>
                                      <option value='Passport'>Passport</option>
                                      <option value='Voter ID'>Voter ID</option>
                                    </Field>
                                    <ErrorMessage name="app_id_proof_upd" component="div" className="text-danger" />
                                  </div>

                                  <div className='mb-6'>
                                    <label className='form-label required fw-bold fs-6'>Front Page</label>
                                    <i className='fas fa-edit fs-3 pe-3 text-dark' onClick={() => {
                                      IDFrontPhoto.current?.click();
                                    }}></i>
                                    {idProof_Front.map(file => (
                                      <div key={file.name} style={{ height: "300px", overflowY: "scroll" }}>
                                        <img
                                          src={typeof file == 'string' ? file : URL.createObjectURL(file)}
                                          alt={file.name}
                                          className='w-75 rounded-3 mb-5 object-fit-fill'
                                          onClick={() => openFullScreenModal(file)}
                                        />
                                      </div>
                                    ))}
                                    <div {...getRootProps1({ className: "dropzone" })} style={{ display: "none" }}>
                                      <input type="file" id="Front_image" {...getInputProps1()} name="Front_image"
                                        ref={(el) => {
                                          IDFrontPhoto.current = el;
                                        }}
                                      />
                                      <div className="text-center">
                                        <p className="dropzone-content">
                                          Drag’n’drop or click to select files
                                        </p>
                                      </div>
                                    </div>
                                  </div>

                                  <div className='mb-6'>
                                    <label className='form-label required fw-bold fs-6'>Back Page</label>
                                    <i className='fas fa-edit fs-3 pe-3 text-dark' onClick={() => {
                                      IDBackPhoto.current?.click();
                                    }}></i>
                                    {idProof_Back.map(file => (
                                      <div key={file.name} style={{ height: "300px", overflowY: "scroll" }}>
                                        <img
                                          src={typeof file == 'string' ? file : URL.createObjectURL(file)}
                                          alt={file.name}
                                          className='w-75 rounded-3 mb-5 object-fit-fill'
                                          onClick={() => openFullScreenModal(file)}
                                        />
                                      </div>
                                    ))}
                                    <div {...getRootProps2({ className: "dropzone" })} style={{ display: "none" }}>
                                      <input className="input-zone" {...getInputProps2()} name='back_image'
                                        ref={(el) => {
                                          IDBackPhoto.current = el;
                                        }}
                                      />
                                      <div className="text-center">
                                        <p className="dropzone-content">
                                          Drag’n’drop or click to select files
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}

                          {activeStep == 2 && (
                            <>
                              <div className='row mb-6'>
                                <label className='col-lg-4 form-label required fw-bold fs-6'>Address Proof</label>
                                <div className='col-lg-8'>
                                  <Field as="select" name="app_address_proof_upd" aria-label="Select example" className="form-select" >
                                    <option value='EB Bill'>EB Bill</option>
                                    <option value='GAS Bill'>GAS Bill</option>
                                    <option value='GST'>GST</option>
                                    <option value='Rent Agreement'>Rent Agreement</option>
                                  </Field>
                                  <ErrorMessage name="app_address_proof_upd" component="div" className="text-danger" />
                                </div>
                              </div>

                              <div className='row mb-6'>
                                <div className='col-md-6 mb-6'>
                                  <label className='form-label required fw-bold fs-6'>Address Proof Front Page</label>
                                  <i className='fas fa-edit fs-3 pe-3 text-dark' onClick={() => {
                                    AddFrontPhoto.current?.click();
                                  }}></i>
                                  <div {...getRootProps3({ className: "dropzone" })} style={{ display: "none" }}>
                                    <input className="input-zone" {...getInputProps3()}
                                      ref={(el) => {
                                        // Assign a ref to the input element
                                        AddFrontPhoto.current = el;
                                      }}
                                    />
                                    <div className="text-center">
                                      <p className="dropzone-content">
                                        Drag’n’drop or click to select files
                                      </p>
                                    </div>
                                  </div>
                                  {addProof_Front.map(file => (
                                    <div key={file.name} style={{ height: "300px", overflowY: "scroll" }}>
                                      <img
                                        src={typeof file == 'string' ? file : URL.createObjectURL(file)}
                                        alt={file.name}
                                        className='w-75 rounded-3 mt-5 object-fit-fill'
                                        onClick={() => openFullScreenModal(file)}
                                      />
                                    </div>
                                  ))}
                                </div>

                                <div className='col-md-6 mb-6'>
                                  <label className='form-label required fw-bold fs-6'>Address Proof Back Page</label>
                                  <i className='fas fa-edit fs-3 pe-3 text-dark' onClick={() => {
                                    AddBackPhoto.current?.click();
                                  }}></i>
                                  <div {...getRootProps4({ className: "dropzone" })} style={{ display: "none" }}>
                                    <input className="input-zone" {...getInputProps4()}
                                      ref={(el) => {
                                        // Assign a ref to the input element
                                        AddBackPhoto.current = el;
                                      }}
                                    />
                                    <div className="text-center">
                                      <p className="dropzone-content">
                                        Drag’n’drop or click to select files
                                      </p>
                                    </div>
                                  </div>
                                  {addProof_Back.map(file => (
                                    <div key={file.name} style={{ height: "300px", overflowY: "scroll" }}>
                                      <img
                                        src={typeof file == 'string' ? file : URL.createObjectURL(file)}
                                        alt={file.name}
                                        className='w-75 rounded-3 mt-5 object-fit-fill'
                                        onClick={() => openFullScreenModal(file)}
                                      />
                                    </div>
                                  ))}
                                </div>
                              </div>

                              <h4 className='text-center'>Installation Address</h4>

                              <div className='row'>

                                <div className='col-md-4 mb-6'>
                                  <label className='form-label required fw-bold fs-6'>Address</label>
                                  <Field type='text' className='form-control form-control-sm' name='app_installation_add_upd' />
                                  <ErrorMessage name="app_installation_add_upd" component="div" className="text-danger" />
                                </div>

                                <div className='col-md-4 mb-6'>
                                  <label className='form-label required fw-bold fs-6'>State</label>
                                  <Field
                                    as='select'
                                    name='app_state_upd'
                                    className='form-select form-select-sm'
                                  >
                                    <option value='Pondicherry'>Pondicherry</option>
                                    <option value='Tamil Nadu'>Tamil Nadu</option>
                                  </Field>
                                  <ErrorMessage name="app_state_upd" component="div" className="text-danger" />
                                </div>

                                <div className='col-md-4 mb-6'>
                                  <label className='form-label required fw-bold fs-6'>Country</label>
                                  <Field type='text' className='form-control form-control-sm' name='app_country_upd' />
                                  <ErrorMessage name="app_country_upd" component="div" className="text-danger" />
                                </div>
                              </div>

                              <h4 className='text-center'>Billing Address</h4>
                              <div className='row'>
                                <div className='col-md-4 mb-6'>
                                  <label className='form-label required fw-bold fs-6'>Address</label>
                                  <Field type='text' className='form-control form-control-sm' name='app_billing_add_upd' />
                                  <ErrorMessage name="app_billing_add_upd" component="div" className="text-danger" />
                                </div>

                                <div className='col-md-4 mb-6'>
                                  <label className='form-label required fw-bold fs-6'>State</label>
                                  <Field
                                    as='select'
                                    name='app_billing_state_upd'
                                    className='form-select form-select-sm'
                                  >
                                    <option value='Pondicherry'>Pondicherry</option>
                                    <option value='Tamil Nadu'>Tamil Nadu</option>
                                  </Field>
                                  <ErrorMessage name="app_billing_state_upd" component="div" className="text-danger" />
                                </div>

                                <div className='col-md-4 mb-6'>
                                  <label className='form-label required fw-bold fs-6'>Country</label>
                                  <Field type='text' className='form-control form-control-sm' name='app_billing_country_upd' />
                                  <ErrorMessage name="app_billing_country_upd" component="div" className="text-danger" />
                                </div>
                              </div>
                            </>
                          )}

                          {activeStep == 3 && (
                            <>
                              <div className='row mb-6'>
                                <label className='col-lg-4 form-label required fw-bold fs-6'>Email</label>
                                <div className='col-lg-8'>
                                  <Field type='text' className='form-control' name='app_email_id_upd' />
                                  <ErrorMessage name="app_email_id_upd" component="div" className="text-danger" />
                                </div>
                              </div>

                              <div className='row mb-6'>
                                <label className='col-lg-4 form-label required fw-bold fs-6'>Phone Number</label>
                                <div className='col-lg-8'>
                                  <Field type='text' className='form-control' name='app_phone_no_upd' />
                                  <ErrorMessage name="app_phone_no_upd" component="div" className="text-danger" />
                                </div>
                              </div>

                              <div className='row mb-6'>
                                <label className='col-lg-4 form-label fw-bold fs-6'>Alternate Number</label>
                                <div className='col-lg-8'>
                                  <Field type='text' className='form-control' name='app_alternate_number_upd' />
                                  <ErrorMessage name="app_alternate_number_upd" component="div" className="text-danger" />
                                </div>
                              </div>
                            </>
                          )}

                          {activeStep == 4 && (
                            <>
                              <div className='row mb-6'>
                                <label className='col-lg-4 form-label required fw-bold fs-6'>Authorised Signatory</label>
                                <div className="col-lg-8">
                                  {viewDetails && viewDetails.app_auth_sign ?
                                    <div>
                                      <img src={viewDetails && viewDetails.app_auth_sign} className='w-250px border border-dark' alt='Auth_Sign_Image' />
                                    </div>
                                    : <span className='badge bg-danger text-white'>No sign image found</span>}
                                </div>
                              </div>


                              <div className='row mb-6'>
                                <label className='col-lg-4 form-label required fw-bold fs-6'>Customer ID</label>
                                <div className="col-lg-8 form-group">
                                  <Field type="text" name="app_dot_id_upd" className="form-control" />
                                </div>
                              </div>

                              <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Remarks</label>
                                <div className='col-lg-8'>
                                  <Field
                                    as='textarea'
                                    name="app_remarks_upd"
                                    className='form-control'
                                  />
                                  <ErrorMessage name='app_remarks_upd' component="div" className="text-danger" />
                                </div>
                              </div>

                              <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Status</label>
                                <div className='col-lg-8 d-flex'>
                                  <div className="form-check form-check-custom form-check-solid">
                                    <Field
                                      type="radio"
                                      name="app_status_upd"
                                      value="4"
                                      id="Pending in Review"
                                      className="form-check-input"
                                    />
                                    <label className="form-check-label fw-bolder text-gray-500 text-hover-primary fs-5" htmlFor="Pending in Review">Pending in Review</label>
                                  </div>
                                  <div className='form-check form-check-custom form-check-solid ms-3'>
                                    <Field
                                      type="radio"
                                      name="app_status_upd"
                                      value="3"
                                      id="Approved"
                                      className="form-check-input"
                                    />
                                    <label className="form-check-label fw-bolder text-gray-500 text-hover-primary fs-5" htmlFor="Approved">Approved</label>
                                  </div>
                                  <div className='form-check form-check-custom form-check-solid ms-3'>
                                    <Field
                                      type="radio"
                                      name="app_status_upd"
                                      value="0"
                                      id="Rejected"
                                      className="form-check-input"
                                    />
                                    <label className="form-check-label fw-bolder text-gray-500 text-hover-primary fs-5" htmlFor="Rejected">Rejected</label>
                                  </div>
                                  <ErrorMessage name="app_nationality _upd" component="div" className="text-danger" />
                                </div>
                              </div>

                              <div className='row'>
                                <div className='col-md-4'>
                                  <label className='form-label required fw-bold fs-6'>Approved by</label>
                                </div>
                                <div className='col-md-4'>
                                  <div style={{ position: 'relative' }}>
                                    {approved_image ? (
                                      <img src={approved_image} alt="Captured" />
                                    ) : (
                                      <>
                                        <Webcam
                                          audio={false}
                                          ref={webcamRef}
                                          screenshotFormat="image/jpeg"
                                          width='100%'
                                          style={{ borderRadius: '15px' }}
                                        />
                                      </>
                                    )}
                                    <div className='capture-buttons' style={{ position: 'absolute', bottom: '10px', left: '160px', cursor: 'pointer' }}>
                                      {approved_image ? (
                                        <img src={toAbsoluteUrl('/media/logos/reset.png')} onClick={retake} className='w-50px' />
                                      ) : (
                                        <img src={toAbsoluteUrl('/media/logos/camera.png')} onClick={capturePhoto} className='w-50px' />
                                      )}
                                    </div>
                                  </div>
                                  {showWarning && <div className="text-danger mt-2">Please capture a photo.</div>}
                                </div>
                                <div className='col-md-4'>

                                </div>
                              </div>
                            </>
                          )}

                          <div className="modal-footer">
                            {activeStep == 1 ? null : (
                              <button
                                onClick={() => setActiveStep((prev) => Math.max(prev - 1))}
                                type='button'
                                className='btn btn-lg btn-light-primary me-3'
                                data-kt-stepper-action='previous'
                              >
                                <KTIcon iconName='arrow-left' className='fs-4 me-1' />
                                Back
                              </button>
                            )}

                            <button
                              type="submit"
                              disabled={!isValid}
                              className="btn btn-lg btn-primary me-3"
                              onClick={(e) => {
                                if (activeStep !== 4) {
                                  e.preventDefault(); // Prevent the default form submission 
                                  setActiveStep((prev) => Math.min(prev + 1, 4));
                                  // Additional actions for form submission on the last step
                                } else {
                                  // Proceed to the next step
                                  setActiveStep((prev) => Math.min(prev + 1, 4));
                                }
                              }}
                            >
                              {loading ? (
                                <>
                                  Please wait... <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </>
                              ) : (
                                <>
                                  {activeStep == 4 ? 'Submit' : 'Next'}<KTIcon iconName='arrow-right' className='fs-3 ms-2 me-0' />
                                </>
                              )}
                            </button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </>
                )}
              </div>
            </div >
          </div >
        </div >
      )}

      {companyEditModal && (
        <div className="modal" role="dialog" style={{ display: 'block' }}>
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-body">
                {companyloadingEdit ? (
                  <div className="d-flex justify-content-center align-items-center" style={{ height: '200px' }}>
                    <div className="spinner-border text-primary" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="d-flex justify-content-around mb-10 fs-5 fw-bold">
                      <div className={`step ${companyStep == 1 ? 'active' : ''}`} style={{ borderBottom: companyStep == 1 ? '2px solid #28a745' : 'none' }}>
                        1.Company Details
                      </div>
                      <div className={`step ${companyStep == 2 ? 'active' : ''}`} style={{ borderBottom: companyStep == 2 ? '2px solid #28a745' : 'none' }}>
                        2.Address Details
                      </div>
                      <div className={`step ${companyStep == 3 ? 'active' : ''}`} style={{ borderBottom: companyStep == 3 ? '2px solid #28a745' : 'none' }}>
                        3.Communication Details
                      </div>
                      <div className={`step ${companyStep == 4 ? 'active' : ''}`} style={{ borderBottom: companyStep == 4 ? '2px solid #28a745' : 'none' }} >
                        4.Verify & Completed
                      </div>
                      <div>
                        <button type="button" className="btn-close" onClick={() => setCompanyEditModal(false)} aria-label="Close"></button>
                      </div>
                    </div>
                    <Formik initialValues={initialCompanyValues} validationSchema={CompanyValidation} onSubmit={handleUpdateCompany}>
                      {({ setFieldValue, isValid }) => (
                        <Form>
                          <div className="form-group">
                            <input type="hidden" name="app_id_upd" className="form-control" />
                          </div>

                          {companyStep == 1 && (
                            <>
                              <div className='row'>
                                <div className="col-md-6 pe-10 edit_form_border">

                                  <div className='mb-5'>
                                    <label className='form-label required fw-bold fs-6'>Customer Profile</label>
                                    <i className='fas fa-edit fs-3 ms-3 text-dark' onClick={() => {
                                      CompanycustomerPhoto.current?.click();
                                    }}></i>
                                    {compCustomer_photo.map(file => (
                                      <div key={file.name}>
                                        <img
                                          src={typeof file == 'string' ? file : URL.createObjectURL(file)}
                                          alt={file.name}
                                          className='w-50 rounded-3 mb-5'
                                        />
                                      </div>
                                    ))}

                                    <div {...getRootProps9({ className: "dropzone" })} style={{ display: "none" }}>
                                      <input className="input-zone" {...getInputProps9()} ref={(el) => { CompanycustomerPhoto.current = el }} />
                                      <div className="text-center">
                                        <p className="dropzone-content">
                                          Drag’n’drop or click to select files
                                        </p>
                                      </div>
                                    </div>
                                  </div>

                                  <div className='mb-6'>
                                    <label className='form-label fw-bold fs-6'>Company Name</label>
                                    <Field name='company_name_upd' className='form-control form-control-sm' />
                                    <ErrorMessage name='company_name_upd' component="div" className="text-danger" />
                                  </div>

                                  <div className='mb-6'>
                                    <label className='form-label required fw-bold fs-6'>Type of Concern</label>
                                    <Field as="select" name="company_type_concern_upd" aria-label="Select example" className="form-select form-select-sm" >
                                      <option value='Private Limited'>Private Limited</option>
                                      <option value='Proprietor'>Proprietor</option>
                                      <option value='Partner'>Partner</option>
                                      <option value='LLP'>LLP</option>
                                    </Field>
                                    <ErrorMessage name="company_type_concern_upd" component="div" className="text-danger" />
                                  </div>

                                  <div className='mb-6'>
                                    <label className='form-label required fw-bold fs-6'>Date of Incorporation</label>
                                    <Field type='date' name='date_incorporation_upd' className='form-control form-control-sm' />
                                    <ErrorMessage name="date_incorporation_upd" component="div" className="text-danger" />
                                  </div>
                                </div>

                                <div className='col-md-6 ps-10'>
                                  <div className='mb-6'>
                                    <label className='form-label required fw-bold fs-6'>Id Proof</label>
                                    <Field as="select" name="company_id_proof_upd" aria-label="Select example" className="form-select form-select-sm" >
                                      <option value='Aadhar Card'>Aadhar Card</option>
                                      <option value='Passport'>Passport</option>
                                      <option value='Voter ID'>Voter ID</option>
                                    </Field>
                                    <ErrorMessage name="company_id_proof_upd" component="div" className="text-danger" />
                                  </div>
                                  <div className='mb-6'>
                                    <label className='form-label required fw-bold fs-6'>Id Proof Front Page</label>
                                    <i className='fas fa-edit fs-3 pe-3 text-dark' onClick={() => {
                                      ComapnyIDFrontPhoto.current?.click();
                                    }}></i>
                                    {companyID_FrontImg.map(file => (
                                      <div key={file.name} style={{ height: "300px", overflowY: "scroll" }}>
                                        <img
                                          src={typeof file == 'string' ? file : URL.createObjectURL(file)}
                                          alt={file.name}
                                          className='w-75 rounded-3 mb-5 object-fit-fill'
                                          onClick={() => openFullScreenModal(file)}
                                        />
                                      </div>
                                    ))}
                                    <div {...getRootProps6({ className: "dropzone" })} style={{ display: "none" }}>
                                      <input type="file" id="Front_image" {...getInputProps6()} name="Company_Front_image"
                                        ref={(el) => {
                                          ComapnyIDFrontPhoto.current = el;
                                        }}
                                      />
                                      <div className="text-center">
                                        <p className="dropzone-content">
                                          Drag’n’drop or click to select files
                                        </p>
                                      </div>
                                    </div>
                                  </div>

                                  <div className='mb-6'>
                                    <label className='form-label required fw-bold fs-6'>Id Proof Back Page</label>
                                    <i className='fas fa-edit fs-3 pe-3 text-dark' onClick={() => {
                                      CompanyIDBackPhoto.current?.click();
                                    }}></i>
                                    {companyID_BackImg.map(file => (
                                      <div key={file.name} style={{ height: "300px", overflowY: "scroll" }}>
                                        <img
                                          src={typeof file == 'string' ? file : URL.createObjectURL(file)}
                                          alt={file.name}
                                          className='w-75 rounded-3 mb-5 object-fit-fill'
                                          onClick={() => openFullScreenModal(file)}
                                        />
                                      </div>
                                    ))}
                                    <div {...getRootProps7({ className: "dropzone" })} style={{ display: "none" }}>
                                      <input className="input-zone" {...getInputProps7()} name='back_image'
                                        ref={(el) => {
                                          CompanyIDBackPhoto.current = el;
                                        }}
                                      />
                                      <div className="text-center">
                                        <p className="dropzone-content">
                                          Drag’n’drop or click to select files
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}

                          {companyStep == 2 && (
                            <>

                              <div className='row mb-6'>
                                <label className='form-label required fw-bold fs-6'>Address Proof</label>
                                <Field as="select" name="company_address_proof_upd" aria-label="Select example" className="form-select form-select-sm" >
                                  <option value='EB Bill'>EB Bill</option>
                                  <option value='GST'>GST</option>
                                  <option value='Rent Agreement'>Others</option>
                                </Field>
                                <ErrorMessage name="company_address_proof_upd" component="div" className="text-danger" />
                              </div>


                              <div className='row mb-6'>
                                <div className='col-md-6 mb-6'>
                                  <label className='form-label required fw-bold fs-6'>Address Proof Front Image</label>
                                  <i className='fas fa-edit fs-3 pe-3 text-dark' onClick={() => {
                                    CompanyAddFrontPhoto.current?.click();
                                  }}></i>
                                  {companyGST_Img.map(file => (
                                    <div key={file.name} style={{ height: "300px", overflowY: "scroll" }}>
                                      <img
                                        src={typeof file == 'string' ? file : URL.createObjectURL(file)}
                                        alt={file.name}
                                        className='w-75 rounded-3 mb-5 object-fit-fill'
                                        onClick={() => openFullScreenModal(file)}
                                      />
                                    </div>
                                  ))}
                                  <div {...getRootProps5({ className: "dropzone" })} style={{ display: "none" }}>
                                    <input className="input-zone" {...getInputProps5()} name='GST_image'
                                      ref={(el) => {
                                        CompanyAddFrontPhoto.current = el;
                                      }}
                                    />
                                    <div className="text-center">
                                      <p className="dropzone-content">
                                        Drag’n’drop or click to select files
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                <div className='col-md-6 mb-6'>
                                  <label className='form-label required fw-bold fs-6'>Address Proof Back Page</label>
                                  <i className='fas fa-edit fs-3 pe-3 text-dark' onClick={() => {
                                    CompanyAddBackPhoto.current?.click();
                                  }}></i>
                                  <div {...getRootProps10({ className: "dropzone" })} style={{ display: "none" }}>
                                    <input className="input-zone" {...getInputProps10()}
                                      ref={(el) => {
                                        // Assign a ref to the input element
                                        CompanyAddBackPhoto.current = el;
                                      }}
                                    />
                                    <div className="text-center">
                                      <p className="dropzone-content">
                                        Drag’n’drop or click to select files
                                      </p>
                                    </div>
                                  </div>
                                  {companyAddBack.map(file => (
                                    <div key={file.name} style={{ height: "300px", overflowY: "scroll" }}>
                                      <img
                                        src={typeof file === 'string' ? file : URL.createObjectURL(file)}
                                        alt={file.name}
                                        className='w-75 rounded-3 mt-5 object-fit-fill'
                                        onClick={() => openFullScreenModal(file)}
                                      />
                                    </div>
                                  ))}
                                </div>
                              </div>

                              <h4 className='text-center'>Installation Address</h4>

                              <div className='row'>

                                <div className='col-md-4 mb-6'>
                                  <label className='form-label required fw-bold fs-6'>Installation Address</label>
                                  <Field type='text' className='form-control form-control-sm' name='company_installation_add_upd' />
                                  <ErrorMessage name="company_installation_add_upd" component="div" className="text-danger" />
                                </div>

                                <div className='col-md-4 mb-6'>
                                  <label className='form-label required fw-bold fs-6'>State</label>
                                  <Field
                                    as='select'
                                    name='company_installation_state_upd'
                                    className='form-select form-select-sm'
                                  >
                                    <option value='Pondicherry'>Pondicherry</option>
                                    <option value='Tamil Nadu'>Tamil Nadu</option>
                                  </Field>
                                  <ErrorMessage name="company_installation_state_upd" component="div" className="text-danger" />
                                </div>

                                <div className='col-md-4 mb-6'>
                                  <label className='form-label required fw-bold fs-6'>Country</label>
                                  <Field type='text' className='form-control form-control-sm' name='company_installation_country_upd' />
                                  <ErrorMessage name="company_installation_country_upd" component="div" className="text-danger" />
                                </div>
                              </div>

                              <h4 className='text-center'>Billing Address</h4>
                              <div className='row'>
                                <div className='col-md-4 mb-6'>
                                  <label className='form-label required fw-bold fs-6'>Billing Address</label>
                                  <Field type='text' className='form-control form-control-sm' name='company_billing_add_upd' />
                                  <ErrorMessage name="company_billing_add_upd" component="div" className="text-danger" />
                                </div>

                                <div className='col-md-4 mb-6'>
                                  <label className='form-label required fw-bold fs-6'>State</label>
                                  <Field
                                    as='select'
                                    name='company_billing_state_upd'
                                    className='form-select form-select-sm'
                                  >
                                    <option value='Pondicherry'>Pondicherry</option>
                                    <option value='Tamil Nadu'>Tamil Nadu</option>
                                  </Field>
                                  <ErrorMessage name="company_billing_state_upd" component="div" className="text-danger" />
                                </div>

                                <div className='col-md-4 mb-6'>
                                  <label className='form-label required fw-bold fs-6'>Country</label>
                                  <Field type='text' className='form-control form-control-sm' name='company_billing_country_upd' />
                                  <ErrorMessage name="company_billing_country_upd" component="div" className="text-danger" />
                                </div>
                              </div>
                            </>


                          )}

                          {companyStep == 3 && (
                            <>
                              <div className='row mb-6'>
                                <label className='col-lg-4 form-label required fw-bold fs-6'>Email ID</label>
                                <div className='col-lg-8'>
                                  <Field type='text' className='form-control' name='company_email_id_upd' />
                                  <ErrorMessage name="company_email_id_upd" component="div" className="text-danger" />
                                </div>
                              </div>

                              <div className='row mb-6'>
                                <label className='col-lg-4 form-label fw-bold fs-6'>Alternate Email ID</label>
                                <div className='col-lg-8'>
                                  <Field type='text' className='form-control' name='alternate_email_id_upd' />
                                  <ErrorMessage name="alternate_email_id_upd" component="div" className="text-danger" />
                                </div>
                              </div>

                              <div className='row mb-6'>
                                <label className='col-lg-4 form-label required fw-bold fs-6'>Phone Number</label>
                                <div className='col-lg-8'>
                                  <Field type='number' className='form-control' name='company_phone_number_upd' />
                                  <ErrorMessage name="company_phone_number_upd" component="div" className="text-danger" />
                                </div>
                              </div>

                              <div className='row mb-6'>
                                <label className='col-lg-4 form-label fw-bold fs-6'>Alternate Number</label>
                                <div className='col-lg-8'>
                                  <Field type='number' className='form-control' name='company_alter_phone_number_upd' />
                                  <ErrorMessage name="company_alter_phone_number_upd" component="div" className="text-danger" />
                                </div>
                              </div>

                              <div className='row mb-6'>
                                <label className='col-lg-4 form-label fw-bold fs-6'>GSTIN</label>
                                <div className='col-lg-8'>
                                  <Field type='text' className='form-control' name='company_gst_upd'
                                    onChange={(e: any) => {
                                      const uppercaseValue = e.target.value.toUpperCase();
                                      setFieldValue('company_gst_upd', uppercaseValue);
                                    }}
                                  />
                                  <ErrorMessage name="company_gst_upd" component="div" className="text-danger" />
                                </div>
                              </div>
                            </>
                          )}

                          {companyStep == 4 && (
                            <>
                              <div className='row mb-6'>
                                <label className='col-lg-4 form-label required fw-bold fs-6'>Authorised Signatory</label>
                                <div className='col-lg-8'>
                                  {viewDetails && viewDetails.app_company_auth_sign ?
                                    <img src={viewDetails && viewDetails.app_company_auth_sign} className='w-250px border border-dark' alt='Auth_Sign_Image' />
                                    : <span className='badge bg-danger text-white'>No sign image found</span>}
                                </div>
                              </div>

                              <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Customer ID</label>
                                <div className="col-lg-8 form-group">
                                  <Field type="text" name="app_dot_id_upd" className="form-control" />
                                </div>
                              </div>

                              <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Remarks</label>
                                <div className='col-lg-8 fv-row'>
                                  <Field
                                    as='textarea'
                                    name="app_remarks_upd"
                                    className='form-control'
                                  />
                                  <ErrorMessage name='app_remarks_upd' component="div" className="text-danger" />
                                </div>
                              </div>

                              <div className='row mt-6'>
                                <label className='col-lg-4 form-label required fw-bold fs-6'>Status</label>
                                <div className='col-lg-8 d-flex'>
                                  <div className="form-check form-check-custom form-check-solid">
                                    <Field
                                      type="radio"
                                      name="company_status_upd"
                                      value="4"
                                      id="Pending in Review"
                                      className="form-check-input"
                                    />
                                    <label className="form-check-label fw-bolder text-gray-500 text-hover-primary fs-5" htmlFor="Pending in Review">Pending in Review</label>
                                  </div>
                                  <div className='form-check form-check-custom form-check-solid ms-3'>
                                    <Field
                                      type="radio"
                                      name="company_status_upd"
                                      value="3"
                                      id="Approved"
                                      className="form-check-input"
                                    />
                                    <label className="form-check-label fw-bolder text-gray-500 text-hover-primary fs-5" htmlFor="Approved">Approved</label>
                                  </div>
                                  <div className='form-check form-check-custom form-check-solid ms-3'>
                                    <Field
                                      type="radio"
                                      name="company_status_upd"
                                      value="0"
                                      id="Rejected"
                                      className="form-check-input"
                                    />
                                    <label className="form-check-label fw-bolder text-gray-500 text-hover-primary fs-5" htmlFor="Rejected">Rejected</label>
                                  </div>
                                </div>

                              </div>
                              <div className='row mt-6'>
                                <div className='col-md-4'>
                                  <label className='form-label required fw-bold fs-6'>Approved by</label>
                                </div>
                                <div className='col-md-4'>
                                  <div style={{ position: 'relative' }}>
                                    {companyApproved_by ? (
                                      <img src={companyApproved_by} alt="Captured" />
                                    ) : (
                                      <>
                                        <Webcam
                                          audio={false}
                                          ref={CompanywebcamRef}
                                          screenshotFormat="image/jpeg"
                                          width='100%'
                                          style={{ borderRadius: '15px' }}
                                        />
                                      </>
                                    )}
                                    <div className='capture-buttons' style={{ position: 'absolute', bottom: '10px', left: '160px', cursor: 'pointer' }}>
                                      {companyApproved_by ? (
                                        <img src={toAbsoluteUrl('/media/logos/reset.png')} onClick={retakeCompany} className='w-50px' />
                                      ) : (
                                        <img src={toAbsoluteUrl('/media/logos/camera.png')} onClick={captureCompanyPhoto} className='w-50px' />
                                      )}
                                    </div>
                                  </div>
                                  {showWarningCompany && <div className="text-danger mt-2">Please capture a photo.</div>}
                                </div>
                                <div className='col-md-4'>
                                </div>
                              </div>
                            </>
                          )}

                          <div className="modal-footer">
                            {companyStep == 1 ? null : (
                              <button
                                onClick={() => setCompanyStep((prev) => Math.max(prev - 1))}
                                type='button'
                                className='btn btn-lg btn-light-primary me-3'
                                data-kt-stepper-action='previous'
                              >
                                <KTIcon iconName='arrow-left' className='fs-4 me-1' />
                                Back
                              </button>
                            )}

                            <button
                              type="submit"
                              disabled={!isValid}
                              className="btn btn-lg btn-primary me-3"
                              onClick={(e) => {
                                if (companyStep !== 4) {
                                  e.preventDefault(); // Prevent the default form submission
                                  setCompanyStep((prev) => Math.min(prev + 1, 4));
                                  // Additional actions for form submission on the last step
                                } else {
                                  // Proceed to the next step
                                  setCompanyStep((prev) => Math.min(prev + 1, 4));
                                }
                              }}
                            >
                              {loading ? (
                                <>
                                  Please wait... <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </>
                              ) : (
                                <>
                                  {companyStep == 4 ? 'Submit' : 'Next'}
                                </>
                              )}
                            </button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </>
                )}
              </div>
            </div>
          </div>
        </div >
      )}

      {viewIndModal && (
        <IndividualFormView setViewIndModal={setViewIndModal} loadingEdit={loadingEdit} initialEditValues={initialEditValues}
          openFullScreenModal={openFullScreenModal} idProof_Front={idProof_Front}
          idProof_Back={idProof_Back} addProof_Front={addProof_Front} addProof_Back={addProof_Back} viewDetails={viewDetails}
          activeStep={activeStep} setActiveStep={setActiveStep} />
      )}

      {viewCusModal && (
        <CompanyFormView setViewCusModal={setViewCusModal} loadingEdit={loadingEdit} initialCompanyValues={initialCompanyValues}
          viewDetails={viewDetails} openFullScreenModal={openFullScreenModal}
          companyStep={companyStep} setCompanyStep={setCompanyStep} />
      )}

      <Modal show={showFullScreenModal} onHide={() => setShowFullScreenModal(false)} size="xl">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          {selectedImage && (
            <img
              src={typeof selectedImage == 'string' ? selectedImage : URL.createObjectURL(selectedImage)}
              alt='Full Screen Image'
              style={{ width: '100%', height: '100%' }}
            />
          )}
        </Modal.Body>
      </Modal>
    </>
  )
}
export default ApplicationDetails