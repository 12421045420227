import React from 'react'
import { Form, Formik } from 'formik'
import { KTIcon } from '../../_metronic/helpers'

const IndividualFormView = ({ setViewIndModal, loadingEdit, initialEditValues,
    openFullScreenModal, viewDetails, activeStep, setActiveStep }: any) => {

    const handleUpdateApplication = () => {
        setViewIndModal(false)
        setActiveStep(1)
    }

    return (
        <>
            <div className="modal" role="dialog" style={{ display: 'block' }}>
                <div className="modal-dialog modal-xl" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h3 className="modal-title">View Individual Form Details :</h3>
                            <button type="button" className="btn-close" onClick={() => setViewIndModal(false)} aria-label="Close"></button>
                        </div>
                        <div className="modal-body" style={{ overflowY: 'auto', maxHeight: '600px' }}>
                            {loadingEdit ? (
                                <div className="d-flex justify-content-center align-items-center" style={{ height: '200px' }}>
                                    <div className="spinner-border text-primary" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                            ) : (
                                <>
                                    <Formik initialValues={initialEditValues} onSubmit={handleUpdateApplication}>
                                        {({ values }) => (
                                            <Form>
                                                <div className="form-group">
                                                    <input type="hidden" name="app_id_upd" className="form-control" />
                                                </div>

                                                {activeStep === 1 && (
                                                    <>
                                                        <div className="card mb-3" style={{ maxWidth: "540px" }}>
                                                            <div className="row g-0">
                                                                <div className="col-md-4">
                                                                    {viewDetails?.app_customer_image ? (
                                                                        <img src={viewDetails?.app_customer_image}
                                                                            onClick={() => openFullScreenModal(viewDetails && viewDetails.app_customer_image)}
                                                                            className='w-150px border border-dark' alt='customer_image' />
                                                                    ) : <span className='badge bg-danger text-white'>No customer image found</span>
                                                                    }
                                                                </div>
                                                                <div className="col-md-8">
                                                                    <div className="card-body">
                                                                        <h5 className="card-title">Name: {values.app_customer_name_upd}</h5>
                                                                        <h6 className="card-text">Father /Spouse /Guardian Name: {values.app_father_name_upd}</h6>
                                                                        <h6 className="card-text">Gender: {values.app_gender_upd}</h6>
                                                                        <h6 className="card-text">Nationality: {values.app_nationality_upd}</h6>
                                                                        <h6 className="card-text">Date Of Birth: {values.app_dob_upd}</h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className='mb-6'>
                                                            <label className='form-label fs-6'>Id Proof -</label>
                                                            <span className='badge bg-primary text-white ms-5 p-3'>{values.app_id_proof_upd}</span>
                                                        </div>

                                                        <div className='row'>
                                                            <div className='col-md-6'>
                                                                <label className='form-label fs-6'>Id Proof Front Page</label>
                                                                <div style={{ height: "300px", overflowY: "scroll" }}>
                                                                    {viewDetails && viewDetails.app_id_proof_front_image ? (
                                                                        <img src={viewDetails && viewDetails.app_id_proof_front_image}
                                                                            onClick={() => openFullScreenModal(viewDetails && viewDetails.app_id_proof_front_image)}
                                                                            className='w-100 object-fit-fill border border-dark' alt='id_proof_front' />
                                                                    ) : (
                                                                        <span className='badge bg-danger text-white'>No proof image found</span>
                                                                    )}
                                                                </div>
                                                            </div>

                                                            <div className='col-md-6'>
                                                                <label className='form-label fs-6'>Id Proof Back Page</label>
                                                                <div style={{ height: "300px", overflowY: "scroll" }}>
                                                                    {viewDetails && viewDetails.app_id_proof_back_image ? (
                                                                        <img src={viewDetails && viewDetails.app_id_proof_back_image}
                                                                            onClick={() => openFullScreenModal(viewDetails && viewDetails.app_id_proof_back_image)}
                                                                            className='w-100  object-fit-fill border border-dark' alt='id_proof_back' />
                                                                    ) : (
                                                                        <span className='badge bg-danger text-white'>No proof image found</span>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}

                                                {activeStep === 2 && (
                                                    <>
                                                        <div className='row mb-2'>
                                                            <div className='col-md-6'>
                                                                <div className='bg-light-info p-5 rounded-3'>
                                                                    <h4 className='text-uppercase'>Installation Address</h4>
                                                                    <div className='mb-0'>
                                                                        <label className='form-label fs-6'>Address:</label>
                                                                        <span className='ms-2 fs-4 fw-bold'>{values.app_installation_add_upd}</span>
                                                                    </div>
                                                                    <div className='mb-0'>
                                                                        <label className='form-label fs-6'>State:</label>
                                                                        <span className='ms-2 fs-4 fw-bold'>{values.app_state_upd}</span>
                                                                    </div>
                                                                    <div className='mb-0'>
                                                                        <label className='form-label fs-6'>Country:</label>
                                                                        <span className='ms-2 fs-4 fw-bold'>{values.app_country_upd}</span>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className='col-md-6'>
                                                                <div className='bg-light-danger p-5 rounded-3 ms-5'>
                                                                    <h4 className='text-uppercase'>Billing Address</h4>
                                                                    <div className='mb-0'>
                                                                        <label className='form-label fs-6'>Address:</label>
                                                                        <span className='ms-2 fs-4 fw-bold'>{values.app_billing_add_upd}</span>
                                                                    </div>

                                                                    <div className='mb-0'>
                                                                        <label className='form-label fs-6'>State:</label>
                                                                        <span className='ms-2 fs-4 fw-bold'>{values.app_billing_state_upd}</span>
                                                                    </div>

                                                                    <div className='mb-0'>
                                                                        <label className='form-label fs-6'>Country:</label>
                                                                        <span className='ms-2 fs-4 fw-bold'>{values.app_billing_country_upd}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className='mb-3'>
                                                            <label className='form-label fs-6'>Address Proof -</label>
                                                            <span className='ms-5 badge bg-primary text-white'>{values.app_address_proof_upd}</span>
                                                        </div>

                                                        <div className='row'>
                                                            <div className='col-md-6'>
                                                                <label className='form-label fs-6'>Address Proof Front Page</label>
                                                                <div style={{ height: "300px", overflowY: "scroll" }}>
                                                                    {viewDetails && viewDetails.app_add_proof_image ? (
                                                                        <img src={viewDetails && viewDetails.app_add_proof_image}
                                                                            onClick={() => openFullScreenModal(viewDetails && viewDetails.app_add_proof_image)}
                                                                            className='w-100  object-fit-fill border border-dark' alt='address_proof_front' />
                                                                    ) : (
                                                                        <span className='badge bg-danger text-white'>No address image found</span>
                                                                    )}
                                                                </div>
                                                            </div>

                                                            <div className='col-md-6'>
                                                                <label className='form-label fs-6'>Address Proof Back Page</label>
                                                                <div style={{ height: "300px", overflowY: "scroll" }}>
                                                                    {viewDetails && viewDetails.app_add_proof_back_image ? (
                                                                        <img src={viewDetails && viewDetails.app_add_proof_back_image}
                                                                            onClick={() => openFullScreenModal(viewDetails && viewDetails.app_add_proof_back_image)}
                                                                            className='w-100  object-fit-fill border border-dark' alt='address_proof_back' />
                                                                    ) : (
                                                                        <span className='badge bg-danger text-white'>No address image found</span>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}

                                                {activeStep === 3 && (
                                                    <>
                                                        <div className='row mb-6'>
                                                            <label className='col-lg-2 form-label fs-6'>Email</label>
                                                            <div className='col-lg-10'>
                                                                <p className='ms-2 fs-4 fw-bold'><span className='me-2'>:</span>{values.app_email_id_upd}</p>
                                                            </div>
                                                        </div>

                                                        <div className='row mb-6'>
                                                            <label className='col-lg-2 form-label fs-6'>Phone Number</label>
                                                            <div className='col-lg-10'>
                                                                <span className='ms-2 fs-4 fw-bold'><span className='me-2'>:</span>{values.app_phone_no_upd}</span>
                                                            </div>
                                                        </div>

                                                        <div className='row mb-6'>
                                                            <label className='col-lg-2 form-label fs-6'>Alternate Number</label>
                                                            <div className='col-lg-10'>
                                                                <span className='ms-2 fs-4 fw-bold'><span className='me-2'>:</span>{values.app_alternate_number_upd ? values.app_alternate_number_upd : "-"}</span>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}

                                                {activeStep === 4 && (
                                                    <>
                                                        <div className='row mb-6'>
                                                            <label className='col-md-2 form-label fs-6'>Customer ID</label>
                                                            <div className="col-md-10 form-group">
                                                                <span className='ms-2 fs-4 fw-bold'><span className='me-2'>:</span>{values.app_dot_id_upd}</span>
                                                            </div>
                                                        </div>

                                                        <div className='row mb-6'>
                                                            <label className='col-md-2 col-form-label fs-6'>Remarks</label>
                                                            <div className='col-md-10'>
                                                                <span className='ms-2 fs-4 fw-bold'><span className='me-2'>:</span>{values.app_message_upd ? values.app_message_upd : "-"}</span>
                                                            </div>
                                                        </div>

                                                        <div className='row mb-6'>
                                                            <label className='col-md-2 form-label fs-6'>Status</label>
                                                            <div className='col-md-10'>
                                                                <span className='me-2'>:</span>
                                                                {values.app_status_upd === "1" ? (
                                                                    <span className='badge bg-warning text-white'>New</span>
                                                                ) : values.app_status_upd === "2" ? (
                                                                    <span className='badge bg-danger text-white'>Customer Edited</span>
                                                                ) : values.app_status_upd === "3" ? (
                                                                    <span className="badge bg-success text-white">Completed</span>
                                                                ) : values.app_status_upd === "0" ? (
                                                                    <span className='badge bg-danger text-white'>Cancelled</span>
                                                                ) : values.app_status_upd === "4" ? (
                                                                    <span className='badge bg-info text-white'>Review</span>
                                                                ) : null}
                                                            </div>
                                                        </div>

                                                        <div className='row mb-6'>
                                                            <label className='col-md-2 form-label fs-6'>Authorised Signatory</label>
                                                            <div className="col-md-10">
                                                                {viewDetails && viewDetails.app_auth_sign ?
                                                                    <div>
                                                                        <img src={viewDetails && viewDetails.app_auth_sign} className='w-250px border border-dark' alt='Auth_Sign_Image' />
                                                                    </div>
                                                                    : <span className='badge bg-danger text-white'>No sign image found</span>}
                                                            </div>
                                                        </div>

                                                        <div className='row'>
                                                            <div className='col-md-2'>
                                                                <label className='form-label fs-6'>Approved by</label>
                                                            </div>
                                                            <div className='col-md-6'>
                                                                {viewDetails?.app_approved_image ? (
                                                                    <img src={viewDetails?.app_approved_image} className='h-100px rounded-3' alt="Approved Image" />
                                                                ) : <span className='badge bg-danger text-white'>No one approved</span>
                                                                }
                                                            </div>
                                                        </div>
                                                    </>
                                                )}

                                                <div className="modal-footer">
                                                    {activeStep === 1 ? null : (
                                                        <button
                                                            onClick={() => setActiveStep((prev: any) => Math.max(prev - 1))}
                                                            type='button'
                                                            className='btn btn-lg btn-light-primary me-3'
                                                            data-kt-stepper-action='previous'
                                                        >
                                                            <KTIcon iconName='arrow-left' className='fs-4 me-1' />
                                                            Back
                                                        </button>
                                                    )}

                                                    <button
                                                        type="submit"
                                                        className="btn btn-lg btn-primary me-3"
                                                        onClick={(e) => {
                                                            if (activeStep !== 4) {
                                                                e.preventDefault();
                                                                setActiveStep((prev: any) => Math.min(prev + 1, 4));
                                                            } else {
                                                                setActiveStep((prev: any) => Math.min(prev + 1, 4));
                                                            }
                                                        }}
                                                    >
                                                        {activeStep === 4 ? 'Close' : 'Next'}<KTIcon iconName='arrow-right' className='fs-3 ms-2 me-0' />
                                                    </button>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div >

        </>
    )
}

export default IndividualFormView