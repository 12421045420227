import React from 'react'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { Template } from '../core/_models';

interface FrontPageFormProps {
  apptemplate: Template[];
}


const ApplicationHeader: React.FC<FrontPageFormProps> = ({ apptemplate }) => {


  return (
    <>
      {apptemplate.map((template) => (
        <>
          <div key={template.template_id}>
            <img src={toAbsoluteUrl(template.template_header_logo1)} className='w-100' />
          </div>
          <div style={{ backgroundColor: template.template_bg_color || 'transparent' }} className='mt-5 d-flex justify-content-center rounded-0 align-items-center p-3'>
            <span style={{ color: template.template_text_color || 'transparent' }} className='text-center fs-4'>Online Application Form</span>
          </div>
        </>
      ))}
    </>
  )
}

export default ApplicationHeader