import { useEffect, useState } from 'react'
import { applicationCheck } from '../core/_requests';
import { useParams, useLocation } from 'react-router-dom';

interface TermsDetail {
    terms_user_id: string;
    terms_conditions: string;
    terms_created_on: string; 
    terms_company_name: string; 
}
const Declaration = () => {
    const [termsData, setTermsData] = useState<TermsDetail>();   
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const { vendor_type } = useParams<{ vendor_type: string }>();
    const type = queryParams.get('type'); 
    useEffect(() => {
        const fetchData = async () => {
            try {
                if (vendor_type) {
                    const response = await applicationCheck(vendor_type, type);
                    if (response.data.message == 'success') {
                        setTermsData(response.data.app_template[0])
                    }
                }
            } catch (error) {
                console.error('API Error:', error);
            }
        };
        fetchData();
    }, [vendor_type, type]);

    return (
        <div>
            <p className='pdf_declaration mt-5'>
                <span className='fs-4'>Declaration: </span>I hereby declare that information given above is true to the best of my knowledge & I will abide by the prevailing Telegraph Act/Rules framed there under & Tariffs as amended from time to time.
                In the event of any dispute concerning any telecom line,apparatus or appliance, bill etc., between me/us & {termsData?.terms_company_name}, the matter will be refered to the sole Arbitrator, appointed by a
                nominated authority in {termsData?.terms_company_name} & shall be governed by the provisions of the Arbitration and Conciliation Act, 1996.
            </p>
        </div>
    )
}

export default Declaration