import React, { useEffect, useState, useRef } from 'react'
import ApexCharts, { ApexOptions } from 'apexcharts'
import { getCSSVariableValue } from '../../../assets/ts/_utils'
import { toAbsoluteUrl } from '../../../helpers';

interface ApplicationStats {
  day: string;
  total_application: string;
}

interface ApplicationStatistics {
  total_application: string;
  pending_application: string;
  completed_application: string;
  progress_application: string;
  cancelled_application: string;
  application_stats: ApplicationStats[];
}

interface ChartsWidget6Props {
  className: string;
  stats: ApplicationStatistics | undefined;
}

const ChartsWidget6: React.FC<ChartsWidget6Props> = ({ className, stats }) => {

  const chartRef = useRef<HTMLDivElement | null>(null)
  const [applicationDates, setApplicationDates] = useState<string[]>([]);
  const [totalApplication, setTotalApplication] = useState<string[]>([]);
  const [newApplication, setNewApplication] = useState<string[]>([]);
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    const totalApplicationsSet: number[] = [];
    if (stats && stats.application_stats) {
      const days = stats.application_stats.map((stat) => stat.day);
       const totalApplications = stats.application_stats.map((stat) => stat.total_application);
      setApplicationDates(days);
      stats.application_stats.forEach(stat => {

        // Parse stat.total_application to ensure it's a number
        const totalApplicationNumber = parseFloat(stat.total_application);
        if (!isNaN(totalApplicationNumber)) { // Check if the parsed value is not NaN
            totalApplicationsSet.push(totalApplicationNumber);
        }
    });

      const total = totalApplicationsSet.reduce((acc, currentValue) => acc + currentValue, 0);

      setTotalApplication(totalApplications);
     setNewApplication([total.toString()]);
      setLoading(false)
    }
  }, [stats]);

  useEffect(() => {
    if (chartRef.current && applicationDates.length > 0) {
      const chart = new ApexCharts(chartRef.current, getChartOptions(applicationDates, totalApplication));
      chart.render();
      return () => {
        chart.destroy();
      };
    }
  }, [chartRef, applicationDates, totalApplication]);

  const LoadingSpinner = () => (
    <div className="d-flex justify-content-center align-items-center" style={{ height: '200px' }}>
      <div className="spinner-border text-primary" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );

  return (
    <div className={`card ${className}`}>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Recent Application</span>
          <span className='text-muted fw-semibold fs-7'>More than {newApplication} new application</span>
        </h3>
      </div>

      <div className='card-body'>
        {loading ? (
          <LoadingSpinner />
        ) : (
          stats?.application_stats.length === 0 ? (
            <div className="d-flex justify-content-center">
            <div>
              <img src={toAbsoluteUrl('/media/stock/coming_soon.png')} className='w-300px' />
              <h3 className="text-center">No application found</h3>
            </div>
          </div>
          ) : (
            <div ref={chartRef} id='kt_charts_widget_6_chart' style={{ height: '350px' }}></div>
          )
        )}
      </div>

    </div>
  )
}

export { ChartsWidget6 }

function getChartOptions(applicationDates: string[], totalApplication: string[]): ApexOptions {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')

  const baseColor = getCSSVariableValue('--bs-primary')
  const baseLightColor = getCSSVariableValue('--bs-primary-light')
  const secondaryColor = getCSSVariableValue('--bs-info')

  return {
    series: [
      {
        name: 'Total Applications',
        type: 'bar',
        data: totalApplication.map(Number), // Convert totalApplication strings to numbers
      },
    ],
    chart: {
      fontFamily: 'inherit',
      stacked: true,
      height: 350,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 5,
        columnWidth: '12%',
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: applicationDates,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      max: Math.max(...totalApplication.map(Number)) + 10, // Adjust max based on data
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    fill: {
      opacity: 1,
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return + val + ''
        },
      },
    },
    colors: [baseColor, secondaryColor, baseLightColor],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
    },
  }
}
