import React, { useEffect, useRef, useState } from 'react'
import { Step1 } from './steps/Step1'
import { Step5 } from './steps/Step5'
import { KTIcon } from '../../../../_metronic/helpers'
import { Template_POST } from '../../auth/core/_requests'
import Swal from 'sweetalert2';
import { StepperComponent } from '../../../../_metronic/assets/ts/components'
import { Form, Formik, FormikValues } from 'formik'
import { templateSchemas, TemplateForm, templateinitial } from './CreateAccountWizardHelper'

const Horizontal = ({ setShowCard, setShowTabs, fetchUserData }: any) => {
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [loading, setLoading] = useState(false)
  const [currentSchema, setCurrentSchema] = useState(templateSchemas[0])
  const [initValues] = useState<TemplateForm>(templateinitial)
  const [isSubmitButton, setSubmitButton] = useState(false)
  const [header_image, setHeader_image] = useState<File[]>([]);
  const [footer_image, setFooter_image] = useState<File[]>([]);
  const [header_image_error, setHeader_image_error] = useState(false)
  const [footer_image_error, setFooter_image_error] = useState(false)

  const UserDetails: any = localStorage.getItem('user-details')
  const userDetailsObject = JSON.parse(UserDetails);
  const userId = userDetailsObject.id;

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const prevStep = (values: TemplateForm) => {
    if (!stepper.current) {
      return
    }
    // if (stepper.current.currentStepIndex > 1) {
    //   setLogo2([]);
    // }
    // if (stepper.current.currentStepIndex === 4) {
    //   values.footer_content_2 = '';
    // }
    stepper.current.goPrev()
    setCurrentSchema(templateSchemas[stepper.current.currentStepIndex - 1])
    setSubmitButton(stepper.current.currentStepIndex === stepper.current.totalStepsNumber)
  }

  const submitStep = async (values: TemplateForm, actions: FormikValues) => {
    if (!stepper.current) {
      return
    }
    if (stepper.current.currentStepIndex !== stepper.current.totalStepsNumber) {
      // if (stepper.current.currentStepIndex === 0) {
      //   if (header_image[0] && footer_image[0]) {
      //     stepper.current.goNext()
      //   }
      //   else {
      //     setHeader_image_error(header_image[0] ? false : true)
      //     setFooter_image_error(footer_image[0] ? false : true)
      //   }
      // }
      stepper.current.goNext()
    }
    else {
      setLoading(true)
      try {
        const ApplicationData = new FormData();
        if (header_image.length > 0) {
          ApplicationData.append("template_header_logo1", header_image[0]);
        }
        if (footer_image.length > 0) {
          ApplicationData.append("template_header_logo2", footer_image[0]);
        }
        const { data: auth } = await Template_POST(
          ApplicationData,
          values.template_title,
          userId,
          values.template_bgColor,
          values.template_textColor,
          '',
          '',
          '',
          '',
          '',
        )
      } catch (error) {
        console.error('Error submitting form data:', error);
      }
      stepper.current.goto(1)
      actions.resetForm()
      setHeader_image([]);
      setFooter_image([]);
      fetchUserData('0', userId);
      setLoading(false)
      setShowCard(true)
      setShowTabs(false)
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Successfully Created',
        showConfirmButton: true,
        timer: 3000,
      })
    }
    setSubmitButton(stepper.current.currentStepIndex === stepper.current.totalStepsNumber)
    setCurrentSchema(templateSchemas[stepper.current.currentStepIndex - 1])
  }

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
  }, [stepperRef])

  return (
    <div className='card'>
      <div className='card-body'>
        <div ref={stepperRef} className='stepper stepper-links d-flex flex-column'>
          <div className='stepper-nav'>
            <div className='stepper-item current' data-kt-stepper-element='nav'>
              <h3 className='stepper-title'>Choose Image</h3>
            </div>

            <div className='stepper-item' data-kt-stepper-element='nav'>
              <h3 className='stepper-title'>Preview</h3>
            </div>
          </div>
          <Formik
            validationSchema={currentSchema}
            initialValues={initValues}
            onSubmit={submitStep}>
            {({ values }) => (
              <Form className='mx-auto w-100' id='kt_create_account_form'>
                <div className='current' data-kt-stepper-element='content'>
                  <Step1 setHeader_image={setHeader_image} header_image={header_image} footer_image={footer_image}
                    setFooter_image={setFooter_image} setHeader_image_error={setHeader_image_error} header_image_error={header_image_error}
                    setFooter_image_error={setFooter_image_error} footer_image_error={footer_image_error}
                  />
                </div>

                <div data-kt-stepper-element='content'>
                  <Step5
                    header={header_image}
                    footer={footer_image}
                    values={values}
                  />
                </div>

                <div className='d-flex flex-stack pt-10'>
                  <div className='mr-2'>
                    <button
                      onClick={() => prevStep(values)}
                      type='button'
                      className='btn btn-lg btn-light-primary me-3'
                      data-kt-stepper-action='previous'
                    >
                      <KTIcon iconName='arrow-left' className='fs-4 me-1' />
                      Back
                    </button>
                  </div>

                  <div>
                    <button type='submit' className='btn btn-lg btn-primary me-3'>
                      <span className='indicator-label'>
                        {loading && (
                          <span className='indicator-progress' style={{ display: 'block' }}>
                            Please wait...{' '}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                        {!isSubmitButton ? <>
                          Continue <KTIcon iconName='arrow-right' className='fs-3 ms-2 me-0' />
                        </> : ''}
                        {!loading && isSubmitButton && 'Submit'}
                        {/* <KTIcon iconName='arrow-right' className='fs-3 ms-2 me-0' /> */}
                      </span>
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  )
}

export { Horizontal }