import { FC } from 'react'
import { Link } from 'react-router-dom'
import { useAuth } from '../../../../app/modules/auth' 
import { KTIcon } from '../../../helpers'

const HeaderUserMenu: FC = () => {
  const { logout } = useAuth()
    
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >

      <div className='menu-item px-5'>
       <Link to={'/crafted/account/overview'} className='menu-link px-5'>
       <KTIcon iconName='user' className='fs-2 me-3'/>   My Profile
        </Link>
      </div>
 
      <div className='menu-item px-5 my-1'>
        <Link
          to="/crafted/account/reset-password" className='menu-link px-5'>
         <KTIcon iconName='shield-tick' className='fs-2 me-3'/> Reset Password
        </Link>
      </div>

      <div className='menu-item px-5'>
        <a onClick={logout} className='menu-link px-5'>
        <KTIcon iconName='lock-2' className='fs-2 me-3'/> Sign Out
        </a>
      </div>
    </div>
  )
}

export { HeaderUserMenu }
