import React, { useState } from 'react'
import { toAbsoluteUrl } from '../../../helpers';

interface ActivityLog {
  app_log_id: string;
  log_message: string;
  log_app_id: string;
  log_by: string;
  log_submitted_on: string;
}

interface ApplicationStatistics {
  activity_log?: ActivityLog[]; // Make activity_log optional
}
type LISTProps = {
  className: string;
  stats: ApplicationStatistics | undefined;
  loading:boolean
};

const ListsWidget5: React.FC<LISTProps> = ({ className, stats,loading }) => {
    
  const getColorForIndex = (index: number) => {
    const colors = ['#50CD89', '#7239EA', '#F1416C', '#FFC700']; // Primary, Danger, Success, Warning
    return colors[index % colors.length];
  };

  const LoadingSpinner = () => (
    <div className="d-flex justify-content-center align-items-center" style={{ height: '200px' }}>
      <div className="spinner-border text-primary" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );

  return (
    <div className={`${className}`}>
      <div className='pt-5'>

        {loading ? (
          <LoadingSpinner />
        ) : (
          stats?.activity_log?.length === 0 ? (
            <div className="d-flex justify-content-center">
              <div>
                <img src={toAbsoluteUrl('/media/stock/coming_soon.png')} className='w-300px' />
                <h3 className="text-center">No log found</h3>
              </div>
            </div>
          ) : (
            stats?.activity_log?.map((log, index) => (
              <div className='timeline-label'>
                <div className='timeline-item' key={index}>
                  <div className='timeline-label fw-bold text-gray-800 fs-6'>{log.log_submitted_on.slice(11, 17)}</div>
                  <div className='timeline-badge'>
                    <i className='fa fa-genderless fs-5' style={{ color: getColorForIndex(index) }}></i>
                  </div>
                  <div className='timeline-content fw-bold text-gray-800 ps-3 pb-5'>
                    Application ID : <span className='badge bg-primary fs-9 text-white'># {log.log_app_id}</span> <br />
                    Message : <span>{log.log_message}</span> <br />
                    Update by : <span>  {log.log_by} </span>
                  </div>
                </div>
              </div>
           ))))}

      </div>
    </div>
  );
};

export { ListsWidget5 };