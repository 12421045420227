import { useState, useEffect } from 'react'
import { KTIcon } from '../../../../_metronic/helpers'
import { IndividualForm } from './IndividualForm';
import { CompanyForm } from './CompanyForm';
import Swal from 'sweetalert2';


const ApplicationForm = ({ widthClass }: any) => {
  const [selectedOption, setSelectedOption] = useState('');
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [backButton, setBackButton] = useState(true);
  const [isCameraEnabled, setIsCameraEnabled] = useState<boolean | null>(null);

  const handleButtonClick = (option: any) => {
    if (isCameraEnabled === true) {
      setSelectedOption(option);
      setIsButtonClicked(true);
      setBackButton(backButton);
    } else {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Error',
        text: 'Camera is not enabled. Please turn on your camera in your settings.',
        showConfirmButton: true,
        timer: 3000
      });
    }
  };

  const handleBackButtonClick = () => {
    setSelectedOption('');
    setIsButtonClicked(false);
  };

  useEffect(() => {
    // Check if the camera is enabled
    const checkCameraStatus = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ video: true });
        if (stream) {
          stream.getTracks().forEach(track => track.stop());
          setIsCameraEnabled(true);
        }
      } catch (error) {
        setIsCameraEnabled(false);
      }
    };

    checkCameraStatus();

    // Add event listener for changes in the camera status
    const handleCameraStatusChange = () => {
      checkCameraStatus();
    };

    navigator.mediaDevices.addEventListener('devicechange', handleCameraStatusChange);

    return () => {
      navigator.mediaDevices.removeEventListener('devicechange', handleCameraStatusChange);
    };
  }, []);

  return (
    <>
      {!isButtonClicked && (
        <>
          <div className='d-flex flex-center ps-5 pe-5' style={{ height: '500px' }}>
            <div>
              <div className='pb-lg-15'>
                <h2 className='fw-bolder d-flex align-items-center text-dark'>
                  Choose Account Type
                  <i
                    className='fas fa-exclamation-circle ms-2 fs-7'
                    data-bs-toggle='tooltip'
                  ></i>
                </h2>
              </div>

              <div className='row'>
                <div className='col-lg-6'>
                  <input type='radio'
                    className='btn-check'
                    name='accountType'
                    value='Individual'
                    id='kt_create_account_form_account_type_personal'
                  />
                  <label
                    onClick={() => handleButtonClick('Individual')}
                    className='btn btn-outline btn-outline-dashed btn-outline-default p-15 d-flex align-items-center mb-10 w-lg-350px'
                    htmlFor='kt_create_account_form_account_type_personal '
                  >
                    <KTIcon iconName='user-square' className='fs-3x me-5' />
                    <span className='d-block fw-bold text-start'>
                      <span className='text-dark fw-bolder d-block fs-4 mb-2'>Individual</span> 
                    </span>
                  </label>
                </div>

                <div className='col-lg-6'>
                  <input type='radio'
                    className='btn-check'
                    name='accountType'
                    value='Company'
                    id='kt_create_account_form_account_type_corporate'
                  />
                  <label
                    onClick={() => handleButtonClick('Company')}
                    className='btn btn-outline btn-outline-dashed btn-outline-default p-15 d-flex align-items-center w-lg-350px'
                    htmlFor='kt_create_account_form_account_type_corporate'
                  >
                    <KTIcon iconName='briefcase' className='fs-3x me-5' />
                    <span className='d-block fw-bold text-start'>
                      <span className='text-dark fw-bolder d-block fs-4 mb-2'>Company</span> 
                    </span>
                  </label>
                </div>
              </div>

            </div>
          </div>
        </>
      )}

      {isButtonClicked && (
        <div className='d-flex flex-center'>
          <div className={widthClass === 'w-100' ? widthClass : 'w-75 mt-10'}>
            {backButton && (
              <button className='btn btn-light-primary' onClick={handleBackButtonClick}> <KTIcon iconName='arrow-left' className='fs-4 me-1' />Back</button>
            )}
          </div>
        </div>
      )}

      {isButtonClicked && (
        <>
          {selectedOption === 'Individual' && <IndividualForm setBackButton={setBackButton} setIsButtonClicked={setIsButtonClicked} />}
          {selectedOption === 'Company' && <CompanyForm setBackButton={setBackButton} setIsButtonClicked={setIsButtonClicked} />}
        </>
      )}

    </>
  )
}

export { ApplicationForm }


// import { useState, useEffect } from 'react';
// import { KTIcon } from '../../../../_metronic/helpers';
// import { IndividualForm } from './IndividualForm';
// import { CompanyForm } from './CompanyForm';
// import Swal from 'sweetalert2';

// const ApplicationForm = ({ widthClass }: any) => {
//   const [selectedOption, setSelectedOption] = useState('');
//   const [isButtonClicked, setIsButtonClicked] = useState(false);
//   const [backButton, setBackButton] = useState(true);
//   const [isCameraEnabled, setIsCameraEnabled] = useState<boolean | null>(null);

 
//   const handleButtonClick = (option: any) => {
//     if (isCameraEnabled === true) {
//       setSelectedOption(option);
//       setIsButtonClicked(true);
//       setBackButton(backButton);
//     } else {
//       Swal.fire({
//         position: 'center',
//         icon: 'error',
//         title: 'Error',
//         text: 'Camera is not enabled. Please turn on your camera in your settings.',
//         showConfirmButton: true,
//         timer: 3000
//       });
//     }
//   };

//   const handleBackButtonClick = () => {
//     setSelectedOption('');
//     setIsButtonClicked(false);
//   };

//   const checkCameraStatus = async () => {
//     try {
//       const stream = await navigator.mediaDevices.getUserMedia({ video: true });
//       if (stream) {
//         stream.getTracks().forEach(track => track.stop());
//         setIsCameraEnabled(true);
//       }
//     } catch (error) {
//       setIsCameraEnabled(false);
//     }
//   };

//   useEffect(() => {
//     // Check camera status initially
//     checkCameraStatus();

//     // Set up a timer to periodically check camera status
//     const intervalId = setInterval(() => {
//       checkCameraStatus();
//     }, 5000); // Adjust the interval as needed (e.g., every 5 seconds)

//     return () => {
//       // Clear the interval when the component is unmounted
//       clearInterval(intervalId);
//     };
//   }, []);
//   return (
//     <>
//       {!isButtonClicked && (
//         <>
//           <div className='d-flex flex-center' style={{ height: '500px' }}>
//             <div>
//               <div className='pb-lg-15'>
//                 <h2 className='fw-bolder d-flex align-items-center text-dark'>
//                   Choose Account Type
//                   <i
//                     className='fas fa-exclamation-circle ms-2 fs-7'
//                     data-bs-toggle='tooltip'
//                   ></i>
//                 </h2>
//               </div>

//               <div className='row'>
//                 <div className='col-lg-6'>
//                   <input type='radio'
//                     className='btn-check'
//                     name='accountType'
//                     value='Individual'
//                     id='kt_create_account_form_account_type_personal'
//                   />
//                   <label
//                     onClick={() => handleButtonClick('Individual')}
//                     className='btn btn-outline btn-outline-dashed btn-outline-default p-15 d-flex align-items-center mb-10 w-lg-350px'
//                     htmlFor='kt_create_account_form_account_type_personal '
//                   >
//                     <KTIcon iconName='user-square' className='fs-3x me-5' />
//                     <span className='d-block fw-bold text-start'>
//                       <span className='text-dark fw-bolder d-block fs-4 mb-2'>Individual</span>
//                       {/* <span className='text-gray-400 fw-bold fs-6'>
//                           If you need more info, please check it out
//                         </span> */}
//                     </span>
//                   </label>
//                 </div>

//                 <div className='col-lg-6'>
//                   <input type='radio'
//                     className='btn-check'
//                     name='accountType'
//                     value='Company'
//                     id='kt_create_account_form_account_type_corporate'
//                   />
//                   <label
//                     onClick={() => handleButtonClick('Company')}
//                     className='btn btn-outline btn-outline-dashed btn-outline-default p-15 d-flex align-items-center w-lg-350px'
//                     htmlFor='kt_create_account_form_account_type_corporate'
//                   >
//                     <KTIcon iconName='briefcase' className='fs-3x me-5' />
//                     <span className='d-block fw-bold text-start'>
//                       <span className='text-dark fw-bolder d-block fs-4 mb-2'>Company</span>
//                       {/* <span className='text-gray-400 fw-bold fs-6'>
//                           Create corporate account to mane users
//                         </span> */}
//                     </span>
//                   </label>
//                 </div>
//               </div>

//             </div>
//           </div>
//         </>
//       )}

//       {isButtonClicked && (
//         <div className='d-flex flex-center pb-10'>
//           <div className={widthClass === 'w-100' ? widthClass : 'w-75 mt-20'}>
//             {backButton && (
//               <button className='btn btn-light-primary' onClick={handleBackButtonClick}> <KTIcon iconName='arrow-left' className='fs-4 me-1' />Back</button>
//             )}
//           </div>
//         </div>
//       )}
      
//       {isButtonClicked && (
//         <>
//           {selectedOption === 'Individual' && <IndividualForm setBackButton={setBackButton} setIsButtonClicked={setIsButtonClicked} />}
//           {selectedOption === 'Company' && <CompanyForm setBackButton={setBackButton} setIsButtonClicked={setIsButtonClicked} />}
//         </>
//       )}

//     </>
//   )
// }

// export { ApplicationForm }