import React from 'react'
import { Form, Formik } from 'formik'
import { KTIcon } from '../../../../_metronic/helpers'


const CompanyFormView = ({ setViewCusModal, loadingEdit, initialCompanyValues,
    openFullScreenModal, viewDetails, companyStep, setCompanyStep }: any) => {

    const handleUpdateCompany = () => {
        setViewCusModal(false)
        setCompanyStep(1)
    }

    return (
        <>
            <div className="modal" role="dialog" style={{ display: 'block' }}>
                <div className="modal-dialog modal-xl" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h3 className="modal-title">View Company Form Details :</h3>
                            <button type="button" className="btn-close" onClick={() => setViewCusModal(false)} aria-label="Close"></button>
                        </div>
                        <div className="modal-body" style={{ overflowY: 'auto', maxHeight: '600px' }}>
                            {loadingEdit ? (
                                <div className="d-flex justify-content-center align-items-center" style={{ height: '200px' }}>
                                    <div className="spinner-border text-primary" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                            ) : (
                                <>
                                    <Formik initialValues={initialCompanyValues} onSubmit={handleUpdateCompany}>
                                        {({ values }) => (
                                            <Form>
                                                <div className="form-group">
                                                    <input type="hidden" name="app_id_upd" className="form-control" />
                                                </div>

                                                {companyStep === 1 && (
                                                    <>
                                                        <div className="card mb-3" style={{ maxWidth: "540px" }}>
                                                            <div className="row g-0">
                                                                <div className="col-md-4">
                                                                    {viewDetails?.app_company_customer_image ? (
                                                                        <img src={viewDetails?.app_company_customer_image}
                                                                            onClick={() => openFullScreenModal(viewDetails && viewDetails.app_company_customer_image)}
                                                                            className='w-150px border border-dark' alt='customer_image' />
                                                                    ) : <span className='badge bg-danger text-white'>No customer image found</span>
                                                                    }
                                                                </div>
                                                                <div className="col-md-8">
                                                                    <div className="card-body">
                                                                        <h5 className="card-title">Company Name: {values.company_name_upd}</h5>
                                                                        <h6 className="card-text">Type of Concern: {values.company_type_concern_upd}</h6>
                                                                        <h6 className="card-text">Date of Incorporation: {values.date_incorporation_upd}</h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className='mb-6'>
                                                            <label className='form-label fs-6'>Id Proof -</label>
                                                            <span className='badge bg-primary text-white ms-5 p-3'>{values.company_id_proof_upd}</span>
                                                        </div>

                                                        <div className='row'>
                                                            <div className='col-md-6'>
                                                                <label className='form-label fs-6'>Id Proof Front Page</label>
                                                                <div style={{ height: "300px", overflowY: "scroll" }}>
                                                                    {viewDetails && viewDetails.app_company_auth_front_image ? (
                                                                        <img
                                                                            src={viewDetails.app_company_auth_front_image}
                                                                            onClick={() => openFullScreenModal(viewDetails.app_company_auth_front_image)}
                                                                            className='w-100 object-fit-fill border border-dark'
                                                                            alt='id_proof_front'
                                                                        />
                                                                    ) : (
                                                                        <span className='badge bg-danger text-white'>No proof image found</span>
                                                                    )}
                                                                </div>
                                                            </div>

                                                            <div className='col-md-6'>
                                                                <label className='form-label fs-6'>Id Proof Back Page</label>
                                                                <div style={{ height: "300px", overflowY: "scroll" }}>
                                                                    {viewDetails && viewDetails.app_company_auth_back_image ? (
                                                                        <img src={viewDetails && viewDetails.app_company_auth_back_image}
                                                                            onClick={() => openFullScreenModal(viewDetails && viewDetails.app_company_auth_back_image)}
                                                                            className='w-100 object-fit-fill border border-dark' alt='id_proof_back' />
                                                                    ) : (
                                                                        <span className='badge bg-danger text-white'>No proof image found</span>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}

                                                {companyStep === 2 && (
                                                    <>
                                                        <div className='row mb-2'>
                                                            <div className='col-md-6'>
                                                                <div className='bg-light-info p-5 rounded-3'>
                                                                    <h4 className='text-uppercase'>Installation Address</h4>
                                                                    <div className='mb-0'>
                                                                        <label className='form-label fs-6'>Address:</label>
                                                                        <span className='ms-2 fs-4 fw-bold'>{values.company_installation_add_upd}</span>
                                                                    </div>
                                                                    <div className='mb-0'>
                                                                        <label className='form-label fs-6'>State:</label>
                                                                        <span className='ms-2 fs-4 fw-bold'>{values.company_installation_state_upd}</span>
                                                                    </div>
                                                                    <div className='mb-0'>
                                                                        <label className='form-label fs-6'>Country:</label>
                                                                        <span className='ms-2 fs-4 fw-bold'>{values.company_installation_country_upd}</span>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className='col-md-6'>
                                                                <div className='bg-light-danger p-5 rounded-3 ms-5'>
                                                                    <h4 className='text-uppercase'>Billing Address</h4>
                                                                    <div className='mb-0'>
                                                                        <label className='form-label fs-6'>Address:</label>
                                                                        <span className='ms-2 fs-4 fw-bold'>{values.company_billing_add_upd}</span>
                                                                    </div>

                                                                    <div className='mb-0'>
                                                                        <label className='form-label fs-6'>State:</label>
                                                                        <span className='ms-2 fs-4 fw-bold'>{values.company_billing_state_upd}</span>
                                                                    </div>

                                                                    <div className='mb-0'>
                                                                        <label className='form-label fs-6'>Country:</label>
                                                                        <span className='ms-2 fs-4 fw-bold'>{values.company_billing_country_upd}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className='mb-3'>
                                                            <label className='form-label fs-6'>Address Proof -</label>
                                                            <span className='ms-5 badge bg-primary text-white'>{values.company_address_proof_upd}</span>
                                                        </div>

                                                        <div className='row'>
                                                            <div className='col-md-6'>
                                                                <label className='form-label fs-6'>Address Proof Front Page</label>
                                                                <div style={{ height: "300px", overflowY: "scroll" }}>
                                                                    {viewDetails && viewDetails.app_company_address_image ? (
                                                                        <img src={viewDetails && viewDetails.app_company_address_image}
                                                                            onClick={() => openFullScreenModal(viewDetails && viewDetails.app_company_address_image)}
                                                                            className='w-100 object-fit-fill border border-dark' alt='address_proof' />
                                                                    ) : (
                                                                        <span className='badge bg-danger text-white'>No address image found</span>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className='col-md-6'>
                                                                <label className='form-label fs-6'>Address Proof Back Page</label>
                                                                <div style={{ height: "300px", overflowY: "scroll" }}>
                                                                    {viewDetails && viewDetails.app_company_address_image_2 ? (
                                                                        <img src={viewDetails && viewDetails.app_company_address_image_2}
                                                                            onClick={() => openFullScreenModal(viewDetails && viewDetails.app_company_address_image_2)}
                                                                            className='w-100 object-fit-fill border border-dark' alt='address_proof' />
                                                                    ) : (
                                                                        <span className='badge bg-danger text-white'>No address image found</span>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}

                                                {companyStep === 3 && (
                                                    <>

                                                        <div className='row mb-6'>
                                                            <label className='col-lg-2 form-label fs-6'>Email ID</label>
                                                            <div className='col-lg-10'>
                                                                <p className='ms-2 fs-4 fw-bold'><span className='me-2'>:</span>{values.company_email_id_upd}</p>
                                                            </div>
                                                        </div>

                                                        <div className='row mb-6'>
                                                            <label className='col-lg-2 form-label fs-6'>Alternate Email ID</label>
                                                            <div className='col-lg-10'>
                                                                <p className='ms-2 fs-4 fw-bold'><span className='me-2'>:</span>{values.alternate_email_id_upd}</p>
                                                            </div>
                                                        </div>

                                                        <div className='row mb-6'>
                                                            <label className='col-lg-2 form-label fs-6'>Phone Number</label>
                                                            <div className='col-lg-10'>
                                                                <span className='ms-2 fs-4 fw-bold'><span className='me-2'>:</span>{values.company_phone_number_upd}</span>
                                                            </div>
                                                        </div>

                                                        <div className='row mb-6'>
                                                            <label className='col-lg-2 form-label fs-6'>Alternate Number</label>
                                                            <div className='col-lg-10'>
                                                                <span className='ms-2 fs-4 fw-bold'><span className='me-2'>:</span>{values.company_alter_phone_number_upd ? values.app_alternate_number_upd : "-"}</span>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}

                                                {companyStep === 4 && (
                                                    <>

                                                        <div className='row mb-6'>
                                                            <label className='col-md-2 form-label fs-6'>Customer ID</label>
                                                            <div className="col-md-10 form-group">
                                                                <span className='ms-2 fs-4 fw-bold'><span className='me-2'>:</span>{values.app_dot_id_upd}</span>
                                                            </div>
                                                        </div>

                                                        <div className='row mb-6'>
                                                            <label className='col-md-2 col-form-label fs-6'>Remarks</label>
                                                            <div className='col-md-10'>
                                                                <span className='ms-2 fs-4 fw-bold'><span className='me-2'>:</span>{values.app_message_upd ? values.app_message_upd : "-"}</span>
                                                            </div>
                                                        </div>

                                                        <div className='row mb-6'>
                                                            <label className='col-md-2 form-label fs-6'>Status</label>
                                                            <div className='col-md-10'>
                                                                <span className='me-2'>:</span>
                                                                {values.company_status_upd === "1" ? (
                                                                    <span className='badge bg-warning text-white'>New</span>
                                                                ) : values.company_status_upd === "2" ? (
                                                                    <span className='badge bg-danger text-white'>Customer Edited</span>
                                                                ) : values.company_status_upd === "3" ? (
                                                                    <span className="badge bg-success text-white">Completed</span>
                                                                ) : values.company_status_upd === "0" ? (
                                                                    <span className='badge bg-danger text-white'>Cancelled</span>
                                                                ) : values.company_status_upd === "4" ? (
                                                                    <span className='badge bg-info text-white'>Review</span>
                                                                ) : null}
                                                            </div>
                                                        </div>

                                                        <div className='row mb-6'>
                                                            <label className='col-md-2 form-label fs-6'>Authorised Signatory</label>
                                                            <div className="col-md-10">
                                                                {viewDetails && viewDetails.app_company_auth_sign ?
                                                                    <img src={viewDetails && viewDetails.app_company_auth_sign} className='w-250px border border-dark' alt='Auth_Sign_Image' />
                                                                    : <span className='badge bg-danger text-white'>No sign image found</span>}
                                                            </div>
                                                        </div>

                                                        <div className='row'>
                                                            <div className='col-md-2'>
                                                                <label className='form-label fs-6'>Approved by</label>
                                                            </div>
                                                            <div className='col-md-6'>
                                                                {viewDetails?.app_approved_image ? (
                                                                    <img src={viewDetails?.app_approved_image} className='w-200px rounded-3' alt="Approved Image" />
                                                                ) : <span className='badge bg-danger text-white'>No one approved</span>
                                                                }
                                                            </div>
                                                        </div>
                                                    </>
                                                )}

                                                <div className="modal-footer">
                                                    {companyStep === 1 ? null : (
                                                        <button
                                                            onClick={() => setCompanyStep((prev: any) => Math.max(prev - 1))}
                                                            type='button'
                                                            className='btn btn-lg btn-light-primary me-3'
                                                            data-kt-stepper-action='previous'
                                                        >
                                                            <KTIcon iconName='arrow-left' className='fs-4 me-1' />
                                                            Back
                                                        </button>
                                                    )}

                                                    <button
                                                        type="submit"
                                                        className="btn btn-lg btn-primary me-3"
                                                        onClick={(e) => {
                                                            if (companyStep !== 4) {
                                                                e.preventDefault(); // Prevent the default form submission 
                                                                setCompanyStep((prev: any) => Math.min(prev + 1, 4));
                                                                // Additional actions for form submission on the last step
                                                            } else {
                                                                // Proceed to the next step
                                                                setCompanyStep((prev: any) => Math.min(prev + 1, 4));
                                                            }
                                                        }}
                                                    >
                                                        {companyStep === 4 ? 'Close' : 'Next'}

                                                    </button>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default CompanyFormView