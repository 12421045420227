import { useEffect, useState } from 'react'
import { useParams, useLocation, Navigate } from 'react-router-dom';
import { applicationCheck } from '../core/_requests'

interface TermsDetail {
    terms_user_id: string;
    terms_conditions: string;
    terms_created_on: string;
}
const Privacy_Policy = () => {
    const [termsData, setTermsData] = useState<TermsDetail[]>([]);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const { vendor_type } = useParams<{ vendor_type: string }>();
    const type = queryParams.get('type'); 
    useEffect(() => {
        const fetchData = async () => {
            try {
                if (vendor_type) {
                    const response = await applicationCheck(vendor_type, type);
                    if (response.data.message == 'success') {
                        setTermsData(response.data.app_template)
                    }
                }
            } catch (error) {
                console.error('API Error:', error);
            }
        };
        fetchData();
    }, [vendor_type, type]);

    return (
        <>
            {termsData.map((termsDetail, index) => (
                <p key={index} dangerouslySetInnerHTML={{ __html: termsDetail.terms_conditions }} />
            ))}
        </>
    )
}

export default Privacy_Policy