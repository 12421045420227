import React from 'react';
import ApplicationHeader from './ApplicationHeader'; 
import ApplicationFooter from './ApplicationFooter';
import { Template, EditApplicationDetails } from '../core/_models';
import ApplicationEdit from './ApplicationEdit';
import { CompanyEdit } from './CompanyEdit';

interface FrontPageFormProps {
  apptemplate: Template[];
  viewDetails: EditApplicationDetails[];
}

const FrontPageEdit: React.FC<FrontPageFormProps> = ({ apptemplate, viewDetails }) => { 
  return (
    <>
      <div className="container mt-5">
        <div className='row'>
          <div className='col-md-10 offset-md-1 bg-white rounded-3 p-0 shadow-sm rounded'>
            <ApplicationHeader apptemplate={apptemplate} />
            {viewDetails.map((item, index) => (
              item.app_type === 'Individual' ? (
                <ApplicationEdit key={index} viewDetails={viewDetails} />
              ) : (
                <CompanyEdit key={index} viewDetails={viewDetails} />
              )
            ))}
          </div>
        </div>
        <div className='row'>
          <div className='col-md-10 offset-md-1 bg-white rounded-3 p-0'>
            <ApplicationFooter apptemplate={apptemplate} />
          </div>
        </div>
      </div>
    </>
  );
}

export default FrontPageEdit;
