import React, { useState, useEffect } from 'react'
import { useParams, useLocation, Navigate } from 'react-router-dom';
import { applicationCheck, Individual_EDIT_Application } from '../core/_requests'
import FrontPageForm from './FrontPageForm'
import { Error404_FRONT } from '../../errors/components/Error404_Front';
import { Template, EditApplicationDetails } from '../core/_models';
import ApplicationEdit from './ApplicationEdit';
import FrontPageEdit from './FrontPageEdit';



const NewApplication = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const { vendor_type } = useParams<{ vendor_type: string }>();
  const type = queryParams.get('type');
  const application_id = queryParams.get('token');
  const [viewDetails, setViewDetails] = useState<EditApplicationDetails[]>([]);
  const [apptemplate, SetAppTemplate] = useState<Template[]>([]);
  const [loading, Setisloading] = useState(true);

  const AppType = localStorage.setItem("vendor_type", vendor_type as string)

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (vendor_type) {
          const response = await applicationCheck(vendor_type, type);
          if (application_id) {
            const response1 = await Individual_EDIT_Application(application_id);
            setViewDetails(response1.data.app_view_details)
          }

          if (response.data.message == 'success') {
            SetAppTemplate(response.data.app_template)
          }
        }
        Setisloading(false);
      } catch (error) {
        console.error('API Error:', error);
      }
    };
    fetchData();
  }, [vendor_type, type]);

  return (
    <>

      {loading ? (
        <div className='position-absolute p-30 top-50 start-50 translate-middle text-center'>
          <h3> <i className="spinner-border spinner-border-sm"></i> Please Wait...</h3>
          <p> Your Application is getting Ready</p>
        </div>
      ) :
        (vendor_type && type === "new" && apptemplate.length === 1 ? (
          <FrontPageForm apptemplate={apptemplate} />
        ) : (
          (vendor_type && type === "update" && apptemplate.length === 1 ? (
            <FrontPageEdit apptemplate={apptemplate} viewDetails={viewDetails} />
          ) : (
            <Navigate to='/error/application' />
          ))
        )
        )}
    </>

  )
}

export default NewApplication