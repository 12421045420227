import React from 'react'
import { useIntl } from 'react-intl'
import { SidebarMenuItem } from './SidebarMenuItem'
import { SidebarMenuItemWithSub } from './SidebarMenuItemWithSub'

const SidebarMenuMain = () => {
  const intl = useIntl()
  const UserDetails: any = localStorage.getItem('user-details')
  const userDetailsObject = JSON.parse(UserDetails);
  const userRole = userDetailsObject.role;


  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='element-11'
        title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
        fontIcon='bi-app-indicator'
      />

      <SidebarMenuItem to="/application-details"
        fontIcon='bi-layers'
        title="Application Details"
        icon='people' />

      {userRole == "1" || userRole == "3" ? (
        <>
          <SidebarMenuItem to="/template"
            fontIcon='bi-layers'
            title="Template"
            icon='design-frame' />

          <SidebarMenuItem to="/terms-condition"
            fontIcon='bi-layers'
            title="Terms & Conditions"
            icon='document' />
        </>
      ) : null}

      {userRole == "1"
        ? (
          <SidebarMenuItem to="/account-creation"
            fontIcon='bi-layers'
            title="Account Creation"
            icon='profile-user' />
        ) : null}

      <SidebarMenuItem to="/crafted/account/overview"
        fontIcon='bi-layers'
        title="My Account"
        icon='profile-circle' />

      {/* <SidebarMenuItemWithSub
        to='crafted/application/'
        title='Application'
        icon='people'
        fontIcon='bi-layers'
      >
        <SidebarMenuItem to='application/new' title='New Application' hasBullet={true} />
        <SidebarMenuItem to='application/existing-application' title='Existing Application' hasBullet={true} />
      </SidebarMenuItemWithSub> */}

    </>
  )
}

export { SidebarMenuMain }
