import React from 'react'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'

const LeftSide = () => {
    return (
        <>
            <div className='pb-lg-10 p-10 w-100 d-none d-lg-block'>
                <img src={toAbsoluteUrl('/media/auth/agency.png')} className='mx-auto mw-100 w-150px w-lg-300px mb-5 d-flex flex-column flex-center' alt='Metronic' />
                <p className='text-white fw-bold fs-2qx text-center mb-5'>Fast, Efficient and Productive</p>
                <p className='fs-base text-center fw-semibold login_paragraph'>In this kind of post, the blogger introduces a person they’ve interviewed
                    and provides some background information about the interviewee and their
                    work following this is a transcript of the interview.In this kind of post, the blogger introduces a person they’ve interviewed and provides some background</p>
            </div>

            <div className='d-flex flex-column  pb-lg-10 p-10 d-sm-none d-block'>
                <img src={toAbsoluteUrl('/media/auth/agency.png')} className='mx-auto mw-100 w-150px w-lg-300px mb-10 mt-10' alt='Metronic' />
                <h1 className='text-white fs-2qx fw-bold text-center'>Fast, Efficient and Productive</h1>
                <p className='text-white fs-base text-center fw-semibold pb-10'>In this kind of post, the blogger introduces a person they’ve interviewed
                    and provides some background information about the interviewee and their
                    work following this is a transcript of the interview.</p>
            </div>
        </>
    )
}

export default LeftSide