/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import { getUserByToken, login } from '../core/_requests'
import { useAuth } from '../core/Auth'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import LeftSide from './LeftSide'
import Swal from 'sweetalert2'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email address')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  email: '',
  password: '',
}

export function Login() {
  const [loading, setLoading] = useState(false)
  const { saveAuth, setCurrentUser } = useAuth()

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      try {
        const response: any = await login(values.email, values.password) 
        if (response.data === 'Failed') {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Please Check Email Password',
          });
        } else {
          saveAuth(response.data)
        }
        const loginStatus = response.data.api_token; 
        if (loginStatus) {
          const { data: user } = await getUserByToken(response.data.api_token)
          setCurrentUser(user)

        } else {
          saveAuth(undefined)
          setStatus('The login details are incorrect')
          setSubmitting(false)
          setLoading(false)
        }

      } catch (error) {
        console.error(error)
        saveAuth(undefined)
        setStatus('The login details are incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return (
    <>
      <div className='login_content'>
        <img src={toAbsoluteUrl('/media/patterns/sign_in.png')} className='login_bg' alt='Sign Trusted' />
        <div className="d-flex justify-content-center p-10">
          <img src={toAbsoluteUrl('/media/logos/Interjetlogoblack.svg')} alt='Sign Trusted' />
        </div>
        <div className="login_form">
          <form
            className='form w-100'
            onSubmit={formik.handleSubmit}
            noValidate
            id='kt_login_signin_form'
          >
            <div className="text-center mb-7">
              <img src={toAbsoluteUrl('/media/auth/add-friend.png')} className='login_signin_img' alt='Sign Trusted' />
              <h1 className="text-dark fw-bolder">
                Sign In
              </h1>
            </div>
            <div className='fv-row mb-8'>
              <input
                placeholder='Enter Your Email'
                {...formik.getFieldProps('email')}
                className={clsx(
                  'form-control',
                  { 'is-invalid': formik.touched.email && formik.errors.email },
                  {
                    'is-valid': formik.touched.email && !formik.errors.email,
                  }
                )}
                type='text'
                name='email'
                autoComplete='off'
              />
              {formik.touched.email && formik.errors.email && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.email}</span>
                  </div>
                </div>
              )}
            </div>

            <div className='fv-row mb-3'>
              <input
                type='password'
                autoComplete='off'
                placeholder='Enter Your Password'
                {...formik.getFieldProps('password')}
                className={clsx(
                  'form-control',
                  {
                    'is-invalid': formik.touched.password && formik.errors.password,
                  },
                  {
                    'is-valid': formik.touched.password && !formik.errors.password,
                  }
                )}
              />
              {formik.touched.password && formik.errors.password && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.password}</span>
                  </div>
                </div>
              )}
            </div>

            {/* <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
              <div></div>
              <Link to='/auth/forgot-password' className='link-primary'>
                Forgot Password ?
              </Link>
            </div> */}
            <div className='d-grid mb-8 mt-8'>
              <button
                type='submit'
                id='kt_sign_in_submit'
                className='btn btn-primary'
                disabled={formik.isSubmitting || !formik.isValid}
              >
                {!loading && <span className='indicator-label'>Continue</span>}
                {loading && (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>

  )
}