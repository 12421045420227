import React, { useState, useEffect } from 'react';
import { KTIcon, toAbsoluteUrl } from '../../_metronic/helpers';
import { Form, ErrorMessage, Field, Formik, FormikValues } from 'formik'
import { useDropzone } from "react-dropzone";
import * as Yup from 'yup'
import Swal from 'sweetalert2';
import { USERS_POST, GET_ALL_USERS, EDIT_USERS, PUT_USERS } from '../modules/auth/core/_requests';
import Pagination from './Pagination';
import { AccountDetails } from '../modules/auth';
import { handleImageDrop } from './CompressImage';


const AccountCreation = () => {
  const [showModal, setShowModal] = useState(false);
  const [company_logo, setCompany_logo] = useState<File[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(false)
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setUserData] = useState<AccountDetails[]>([]);
  const [showEditModal, setShowEditModal] = useState(false)
  const [currentPage, setCurrentPage] = useState(1);
  const [loadingEdit, setLoadingEdit] = useState(false);
  const [initialEditValues, setInitialEditValues] = useState(
    {
      user_id_upd: '',
      user_name_upd: '',
      user_email_upd: '',
      user_mobile_upd: '',
      user_dob_upd: '',
      user_address_upd: '',
      user_role_upd: '',
      user_status_upd: '',
      app_credit_upd: '',
      company_name_upd: '',
    }
  )

  const UserDetails: any = localStorage.getItem('user-details')
  const userDetailsObject = JSON.parse(UserDetails);
  const userId = userDetailsObject.id;
  const approved_user_name = userDetailsObject.first_name;

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const handleButtonClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const { getRootProps: getRootProps1, getInputProps: getInputProps1 } = useDropzone({
    onDrop: acceptedFiles => handleImageDrop(acceptedFiles, setCompany_logo),
    accept: {
      'image/jpeg': [],
      'image/png': [],
    },
  });

  const initialValues = {
    profile: null,
    full_name: '',
    password: '',
    email_id: '',
    mobile_number: '',
    dob: '',
    address: '',
    role: '',
    app_credit: '',
    app_shortcode: '',
    account_created_by: '',
  };

  const validationSchema = Yup.object().shape({
    full_name: Yup.string().required('Full Name is required'),
    password: Yup.string().required('Password is required'),
    email_id: Yup.string().email('Invalid email').required('Email is required'),
    mobile_number: Yup.string().matches(/^[0-9]{10}$/, 'Phone number must be exactly 10 digits').required('Mobile Number is required'),
    address: Yup.string().required('Address is required'),
    role: Yup.string().required('Role is required'),
    company_name: Yup.string().test('conditional-required', 'Company name is required', function (value) {
      const roleType = this.parent.role;
      if (roleType == '3') {
        return !!value;
      }
      return true; // Not required for other pickup types
    }),
    app_credit: Yup.string().test('conditional-required', 'Application Credit is required', function (value) {
      const roleType = this.parent.role;
      if (roleType == '3') {
        return !!value;
      }
      return true; // Not required for other pickup types
    }),
    app_shortcode: Yup.string().test('conditional-required', 'Application Shortcode is required', function (value) {
      const roleType = this.parent.role;
      if (roleType == '3') {
        return !!value;
      }
      return true; // Not required for other pickup types
    }),
  });

  const handleSubmit = async (values: any, actions: FormikValues) => {
    setLoading(true);
    const Accountcreate = new FormData();
    Accountcreate.append("user_pic", company_logo[0]);

    try {
      const response = await USERS_POST(
        Accountcreate,
        values.full_name,
        values.password,
        values.email_id,
        values.mobile_number,
        values.dob,
        values.address,
        values.role,
        values.company_name,
        values.app_credit,
        values.app_shortcode,
        userId
      );
      // Check if the response indicates that the email ID is already registered
      if (response.data == "Email id Already Registered") {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Email ID Already Registered',
          showConfirmButton: true,
          timer: 3000,
        });
        setLoading(false);
      } else if (response.data == "Shortcode Taken") {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Shortcode Already Taken',
          showConfirmButton: true,
          timer: 3000,
        });
        setLoading(false);
      } else {
        // Registration was successful
        setTimeout(() => {
          setLoading(false);
          fetchUserData("0");
          setShowModal(false);
          setCompany_logo([]);
          actions.resetForm();
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Successfully Submitted',
            showConfirmButton: true,
            timer: 3000,
          });
        }, 500);
      }
    } catch (error) {
      // Handle other errors here
      console.error('Error submitting profile:', error);
    }
  };


  const fetchUserData = async (all_users: any) => {
    try {
      const response = await GET_ALL_USERS(all_users);
      setUserData(response.data.account_details);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching members:', error);
    }
  };

  useEffect(() => {
    fetchUserData("0");
  }, []);

  const handleEditAccount = async (user_id: any) => {
    try {
      setLoadingEdit(true)
      setShowEditModal(true)
      const response = await EDIT_USERS(user_id);
      const initialData = response.data.account_details[0]
      setCompany_logo([response.data.account_details[0].user_pic]);
      setInitialEditValues({
        user_id_upd: initialData.user_id,
        user_name_upd: initialData.user_name,
        user_email_upd: initialData.user_email,
        user_mobile_upd: initialData.user_mobile,
        user_dob_upd: initialData.user_dob,
        user_address_upd: initialData.user_address,
        user_role_upd: initialData.user_role,
        app_credit_upd: initialData.user_credit,
        company_name_upd: initialData.user_company_name,
        user_status_upd: initialData.user_status,
      })
    } catch (error) {
      console.error('Error fetching members:', error);
    } finally {
      setLoadingEdit(false);
    }
  };

  const validationEditSchema = Yup.object().shape({
    user_name_upd: Yup.string().required('Full Name is required'),
    user_email_upd: Yup.string().email('Invalid email').required('Email is required'),
    user_mobile_upd: Yup.string().matches(/^[0-9]{10}$/, 'Phone number must be exactly 10 digits').required('Mobile Number is required'),
    user_address_upd: Yup.string().required('Address is required'),
    user_role_upd: Yup.string().required('Role is required'),
    user_status_upd: Yup.string().required('Status is required'),
  });

  const handleUpdateAccount = async (values: any, actions: FormikValues) => {
    setLoading(true)
    const UserData = new FormData();
    UserData.append("user_pic_upd", company_logo[0]);
    try {
      const response = await PUT_USERS(
        UserData,
        values.user_id_upd,
        values.user_name_upd,
        values.user_email_upd,
        values.user_mobile_upd,
        values.user_dob_upd,
        values.user_address_upd,
        values.user_role_upd,
        values.app_credit_upd,
        values.company_name_upd,
        values.user_status_upd,
        approved_user_name
      )
      setTimeout(() => {
        setLoading(false)
        fetchUserData("0");
        setShowEditModal(false);
        setCompany_logo([]);
        actions.resetForm()
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Successfully Submitted',
          showConfirmButton: true,
          timer: 3000
        });
      }, 500);
    } catch (error) {
      console.error('Error submitting form data:', error);
    }

  }

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };
  const filteredUserDetails = userData.filter((user) => {
    const searchTerm = searchQuery.toLowerCase();
    const userId = user.user_id && typeof user.user_id === 'string' ? user.user_id.toLowerCase() : '';
    const userName = user.user_name && typeof user.user_name === 'string' ? user.user_name.toLowerCase() : '';
    return (
      userId.includes(searchTerm) ||
      userName.includes(searchTerm) ||
      (user.user_role == '1' && "admin".includes(searchTerm)) ||
      (user.user_role == '3' && "vendor".includes(searchTerm)) ||
      (user.user_role == '2' && "employee".includes(searchTerm))
    );
  });


  const itemsPerPage = 10;
  const totalItems = userData.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const userDetails = filteredUserDetails.slice(startIndex, startIndex + itemsPerPage);

  const generateCopyLink = (shortcode: string) => {
    const copyLink = `${window.location.origin}/application/${shortcode}/?type=new`;
    // Create a temporary textarea element to copy the link to the clipboard
    const textarea = document.createElement('textarea');
    textarea.value = copyLink;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);

    Swal.fire({
      icon: 'success',
      title: `<div style="color: white; padding: 10px;">Link copied to clipboard!</div>`,
      toast: true,
      position: 'top',
      showConfirmButton: false,
      timer: 3000, // You can adjust the timer as needed
      customClass: {
        popup: 'your-custom-toast-class', // Add your custom CSS class here
      },
      iconHtml: '<i class="fas fa-check" style="color: white;"></i>',
    });
  };


  return (
    <>
      <div className='card'>
        <div className='card-header border-0'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Account Creation</span>
          </h3>
          <div className='card-toolbar'>
            <div className="d-flex align-items-center position-relative me-5">
              <div className="input-group">
                <input type="search" placeholder='Search anything...' className="form-control w-50" value={searchQuery} onChange={handleSearchChange} />
                <button type="button" className="btn btn-primary">
                  <i className="fas fa-search"></i>
                </button>
              </div>
            </div>
            <button className='btn btn-primary' onClick={handleButtonClick}>
              <KTIcon iconName='plus' className='fs-2' />
              Add Member
            </button>
          </div>
        </div>
        <div className='card-body py-3'>
       <>
          {isLoading ? (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '200px' }}>
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (userDetails.length > 0 ? (
            <div className='table-responsive'>
              <table className='table align-middle gs-0 gy-4'>
                <thead>
                  <tr className='fw-bold fs-6 bg-light-primary'>
                    <th className='ps-4 rounded-start'>Id</th>
                    <th>Name</th>
                    <th>Role</th>
                    <th>Company Name</th>
                    <th>Short Code</th>
                    <th>Application Credit</th>
                    <th>Email Id</th>
                    <th>Number</th>
                    <th>Status</th>
                    <th className=' pe-4 rounded-end'>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {userDetails.map((user, index) => (
                    <tr key={index}>
                      <td className='text-dark fw-bold text-hover-primary fs-6 ps-4 text-center'>{user.user_id}</td>
                      <td className='text-dark fw-bold text-hover-primary fs-6'>{user.user_name}</td>
                      <td className='text-dark fw-bold text-hover-primary fs-6'>
                        {user.user_role == "1" ? (
                          <span className='badge bg-info text-white'>Admin</span>
                        ) : user.user_role == "3" ? (
                          <span className='badge bg-dark text-white'>Vendor</span>
                        ) : user.user_role == "2" ? (
                          <span className="badge bg-primary text-white">Employee</span>
                        ) : null}
                      </td>
                      <td className='text-dark fw-bold text-hover-primary fs-6'>{user.user_company_name ? user.user_company_name : "-"}</td>
                      <td className='text-dark fw-bold text-hover-primary fs-6 cursor-pointer' onClick={() => generateCopyLink(user.user_shortcode)}>
                        {user.user_shortcode ? user.user_shortcode : "-"}
                        {user.user_role !== "2" ? <KTIcon iconName='copy' className='fs-2' /> : ""}
                      </td>
                      <td className='text-dark fw-bold text-hover-primary fs-6'>{user.user_credit ? user.user_credit : "-"}</td>
                      <td className='text-dark fw-bold text-hover-primary fs-6'>{user.user_email}</td>
                      <td className='text-dark fw-bold text-hover-primary fs-6'>{user.user_mobile}</td>
                      <td className='text-dark fw-bold text-hover-primary fs-6'>{user.user_status == '1' ? (
                        <span className='badge bg-success text-white'>Active</span>
                      ) : <span className='badge bg-danger text-white'>Deactivate</span>}
                      </td>
                      <td style={{ paddingTop: "10px" }}>
                        <div className="dropdown">
                          <button className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary" type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
                            <i className="bi bi-three-dots fs-5"></i>
                          </button>
                          <ul className="dropdown-menu" aria-labelledby="dropdownMenu2">
                            <li>
                              <button
                                className="dropdown-item"
                                type="button"
                                onClick={() => handleEditAccount(user.user_id)}
                              >
                                Edit
                              </button>
                            </li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                handlePageChange={handlePageChange}
              />
            </div>
          ) :
            <div className="d-flex justify-content-center p-10">
              <div>
                <img src={toAbsoluteUrl('/media/stock/coming_soon.png')} className='w-350px' />
                <h3 className="text-center">No users found</h3>
              </div>
            </div>
          )}
          </>
        </div>

      </div>

      <div className={`modal${showModal ? ' show' : ''}`} role="dialog" style={{ display: showModal ? 'block' : 'none' }}>
        <div className="modal-dialog modal-lg modal-dialog-centered" role="document" >
          <div className="modal-content" >
            <div className="modal-header">
              <h5 className="modal-title">Create New Member</h5>
              <button type="button" className="btn-close" onClick={handleCloseModal} aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
                {({ values }) => (
                  <Form>
                    <div className='form-group'>
                      <label className='form-label required fw-bold fs-6'>Company Logo</label>
                      <div className='d-flex'>
                        <div className='me-5'>
                          {company_logo.map(file => (
                            <div key={file.name}>
                              <img
                                src={typeof file == 'string' ? file : URL.createObjectURL(file)}
                                alt={file.name}
                                className='w-100px'
                              />
                            </div>
                          ))}
                        </div>
                        <div {...getRootProps1({ className: "dropzone" })}>
                          <input type="file" id="company_logo" {...getInputProps1()} name="company_logo" />
                          <div className="text-center">
                            <p className="dropzone-content">
                              Drag’n’drop or click to select files
                            </p>
                          </div>
                        </div>
                      </div>

                    </div>

                    <div className="form-group">
                      <label className='form-label required mt-3'>Role</label>
                      {/* <div className="form-check form-check-custom form-check-solid mb-3">
                        <Field
                          type="radio"
                          name="role"
                          value="1"
                          id="Admin"
                          className="form-check-input"
                        />
                        <label className="form-check-label fw-bolder text-gray-500 text-hover-primary fs-5" htmlFor="Admin">Admin</label>
                      </div> */}
                      <div className='form-check form-check-custom form-check-solid mb-3'>
                        <Field
                          type="radio"
                          name="role"
                          value="3"
                          id="Vendor"
                          className="form-check-input"
                        />
                        <label className="form-check-label fw-bolder text-gray-500 text-hover-primary fs-5" htmlFor="Vendor">Vendor</label>
                      </div>

                      <div className='form-check form-check-custom form-check-solid mb-3'>
                        <Field
                          type="radio"
                          name="role"
                          value="2"
                          id="Employee"
                          className="form-check-input"
                        />
                        <label className="form-check-label fw-bolder text-gray-500 text-hover-primary fs-5" htmlFor="Employee">Employee</label>
                      </div>
                      <ErrorMessage name="role" component="div" className="text-danger" />

                      {values.role == "3" && (
                        <>
                          <div className="form-group mb-3">
                            <label htmlFor="company_name" className='form-label required mt-3'>Company Name</label>
                            <Field type="text" name="company_name" className="form-control" />
                            <ErrorMessage name="company_name" component="div" className="text-danger" />
                          </div>
                          <div className="form-group mb-3">
                            <label className="form-label required mt-3">Application Credit</label>
                            <Field
                              type="number"
                              name="app_credit"
                              className="form-control"
                            />
                            <ErrorMessage name="app_credit" component="div" className="text-danger" />
                          </div>
                          <div className="form-group mb-3">
                            <label className="form-label required mt-3">Application Shortcode</label>
                            <Field
                              type="text"
                              name="app_shortcode"
                              className="form-control"
                            />
                            <ErrorMessage name="app_shortcode" component="div" className="text-danger" />
                          </div>
                        </>
                      )}
                    </div>

                    <div className="form-group">
                      <label htmlFor="full_name" className='form-label required mb-3'>Full Name</label>
                      <Field type="text" name="full_name" className="form-control" />
                      <ErrorMessage name="full_name" component="div" className="text-danger" />
                    </div>

                    <div className="form-group">
                      <label htmlFor="password" className='form-label required mt-3'>Password</label>
                      <Field type="password" name="password" className="form-control" />
                      <ErrorMessage name="password" component="div" className="text-danger" />
                    </div>

                    <div className="form-group">
                      <label className="form-label required mt-3">Email Id</label>
                      <Field
                        type="text"
                        name="email_id"
                        className="form-control"
                      />
                      <ErrorMessage name="email_id" component="div" className="text-danger" />
                    </div>

                    <div className="form-group">
                      <label className="form-label required mt-3">Mobile Number</label>
                      <Field
                        type="number"
                        name="mobile_number"
                        className="form-control"
                      />
                      <ErrorMessage name="mobile_number" component="div" className="text-danger" />
                    </div>

                    <div className="form-group">
                      <label className="form-label mt-3">Date of Birth</label>
                      <Field
                        type="date"
                        name="dob"
                        className="form-control"
                      />
                      <ErrorMessage name="dob" component="div" className="text-danger" />
                    </div>

                    <div className="form-group">
                      <label className="form-label required mt-3">Address</label>
                      <Field
                        as='textarea'
                        className='form-control form-control-solid'
                        placeholder=''
                        name='address'
                      />
                      <div className='text-danger mt-2'>
                        <ErrorMessage name='address' />
                      </div>
                    </div>

                    <div className="form-group">
                      <Field type="hidden" name="account_created_by" className="form-control w-50" />
                    </div>

                    <div className="modal-footer">
                      <button type="button" className="btn btn-secondary" onClick={handleCloseModal} disabled={loading}>
                        Cancel
                      </button>
                      <div className='d-flex justify-content-end'>
                        <button type='submit' className='btn btn-primary' disabled={loading}>
                          {!loading && 'Submit'}
                          {loading && (
                            <span className='indicator-progress' style={{ display: 'block' }}>
                              Please wait...{' '}
                              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                          )}
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>

      {showEditModal && (
        <div className="modal" role="dialog" style={{ display: 'block' }}>
          <div className="modal-dialog modal-lg modal-dialog-scrollable" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Edit Member :</h4>
                <button type="button" className="btn-close" onClick={() => setShowEditModal(false)} aria-label="Close"></button>
              </div>
              <div className="modal-body">
                {loadingEdit ? (
                  <div className="d-flex justify-content-center align-items-center" style={{ height: '200px' }}>
                    <div className="spinner-border text-primary" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <>
                    <Formik
                      initialValues={initialEditValues}
                      validationSchema={validationEditSchema}
                      onSubmit={handleUpdateAccount}>
                      {({ values }) => (
                        <Form>
                          <div className="form-group">
                            <input type="hidden" name="user_id_upd" className="form-control" />
                          </div>

                          <div className='form-group'>
                            <label className='form-label required fw-bold fs-6'>Company Logo</label>
                            <div className='d-flex'>
                              <div className='me-5'>
                                {company_logo.map(file => (
                                  <div key={file.name}>
                                    <img
                                      src={typeof file == 'string' ? file : URL.createObjectURL(file)}
                                      alt={file.name}
                                      className='w-100px'
                                    />
                                  </div>
                                ))}
                              </div>
                              <div {...getRootProps1({ className: "dropzone" })}>
                                <input type="file" id="company_logo" {...getInputProps1()} name="company_logo" />
                                <div className="text-center">
                                  <p className="dropzone-content">
                                    Drag’n’drop or click to select files
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="form-group">
                            <label htmlFor="user_name_upd" className='form-label required mb-3'>Full Name</label>
                            <Field type="text" name="user_name_upd" className="form-control" />
                            <ErrorMessage name="user_name_upd" component="div" className="text-danger" />
                          </div>

                          <div className="form-group">
                            <label className="form-label required mt-3">Email Id</label>
                            <Field
                              type="text"
                              name="user_email_upd"
                              className="form-control"
                            />
                            <ErrorMessage name="user_email_upd" component="div" className="text-danger" />
                          </div>

                          <div className="form-group">
                            <label className="form-label required mt-3">Mobile Nummber</label>
                            <Field
                              type="number"
                              name="user_mobile_upd"
                              className="form-control"
                            />
                            <ErrorMessage name="user_mobile_upd" component="div" className="text-danger" />
                          </div>

                          <div className="form-group">
                            <label className="form-label mt-3">Date of Birth</label>
                            <Field
                              type="date"
                              name="user_dob_upd"
                              className="form-control"
                            />
                            <ErrorMessage name="user_dob_upd" component="div" className="text-danger" />
                          </div>

                          <div className="form-group">
                            <label className="form-label required mt-3">Address</label>
                            <Field
                              as='textarea'
                              className='form-control form-control-solid'
                              name='user_address_upd'
                            />
                            <div className='text-danger mt-2'>
                              <ErrorMessage name='user_address_upd' />
                            </div>
                          </div>

                          <div className="form-group">
                            <label className='form-label required mt-3'>Role</label>
                            {/* <div className="form-check form-check-custom form-check-solid mb-3">
                              <Field
                                type="radio"
                                name="user_role_upd"
                                value="1"
                                id="Admin"
                                className="form-check-input"
                              />
                              <label className="form-check-label fw-bolder text-gray-500 text-hover-primary fs-5" htmlFor="Admin">Admin</label>
                            </div> */}
                            <div className='form-check form-check-custom form-check-solid mb-3'>
                              <Field
                                type="radio"
                                name="user_role_upd"
                                value="3"
                                id="Vendor"
                                className="form-check-input"
                              />
                              <label className="form-check-label fw-bolder text-gray-500 text-hover-primary fs-5" htmlFor="Vendor">Vendor</label>
                            </div>
                            <div className='form-check form-check-custom form-check-solid'>
                              <Field
                                type="radio"
                                name="user_role_upd"
                                value="2"
                                id="Employee"
                                className="form-check-input"
                              />
                              <label className="form-check-label fw-bolder text-gray-500 text-hover-primary fs-5" htmlFor="Employee">Employee</label>
                            </div>
                            <ErrorMessage name="user_role_upd" component="div" className="text-danger" />

                            {values.user_role_upd == "3" && (
                              <>
                                <div className="form-group mb-3">
                                  <label htmlFor="company_name_upd" className='form-label required mt-3'>Company Name</label>
                                  <Field type="text" name="company_name_upd" className="form-control" />
                                  <ErrorMessage name="company_name_upd" component="div" className="text-danger" />
                                </div>
                                <div className="form-group mb-3">
                                  <label className="form-label required mt-3">Application Credit</label>
                                  <Field
                                    type="text"
                                    name="app_credit_upd"
                                    className="form-control"
                                  />
                                  <ErrorMessage name="app_credit_upd" component="div" className="text-danger" />
                                </div>
                              </>
                            )}
                          </div>

                          <div className="form-group">
                            <label className="form-label required mt-3">Status</label>
                            <Field
                              as='select'
                              name='user_status_upd'
                              className='form-select'
                            >
                              <option value='1'>Active</option>
                              <option value='2'>Deactivate</option>
                            </Field>
                            <ErrorMessage name="user_status_upd" component="div" className="text-danger" />
                          </div>

                          <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={() => setShowEditModal(false)} disabled={loading}>
                              Cancel
                            </button>
                            <div className='d-flex justify-content-end'>
                              <button type='submit' className='btn btn-primary' disabled={loading}>
                                {!loading && 'Submit'}
                                {loading && (
                                  <span className='indicator-progress' style={{ display: 'block' }}>
                                    Please wait...{' '}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                  </span>
                                )}
                              </button>
                            </div>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </>
                )}
              </div>
            </div>
          </div>
        </div >
      )}
    </>
  );
};

export default AccountCreation;
