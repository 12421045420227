
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Navigate, Route, Routes } from 'react-router-dom'
import SuccessPage from '../../auth/components/SuccessPage' 
import NewApplication from '../../auth/components/Application_New'


const FrontendPage = () => (
  <Routes>
    <Route index element={<Navigate to='/application/signtrusted/?type=new' />} />
    <Route path="application/:vendor_type/" element={<NewApplication />} />
    <Route path="/success" element={<SuccessPage />} />
  </Routes>
)

export { FrontendPage }
