import React from 'react'
import ApplicationHeader from './ApplicationHeader'
import { ApplicationForm } from './ApplicationForm'
import ApplicationFooter from './ApplicationFooter'
import {Template} from '../core/_models';
import { CompanyForm } from './CompanyForm';

interface FrontPageFormProps {
  apptemplate: Template[];
}

const FrontPageForm: React.FC<FrontPageFormProps> = ({ apptemplate }) => {
  return (
    <>

      <div className="container mt-5">
        <div className='row' >
        <div className='col-md-10 offset-md-1 bg-white vh-50 rounded-3 p-0 shadow-sm rounded' >
          <ApplicationHeader apptemplate={apptemplate}/>
          <ApplicationForm/>
          </div>
        </div>
      <div className='row'>
          <div className='col-md-10 offset-md-1 bg-white rounded-3 p-0'>
           <ApplicationFooter apptemplate={apptemplate}/>
          </div>
        </div>
      </div>
 
      </>

    )
}

export default FrontPageForm