import { useEffect, useState } from 'react'
import { Formik, Field, Form, ErrorMessage, FormikValues } from 'formik';
import * as Yup from 'yup';
import ReactQuill from 'react-quill';
import 'quill/dist/quill.snow.css';
import Swal from 'sweetalert2';
import { POST_Terms_Condition, GET_Terms_Condition,PUT_Terms_Condition } from '../modules/auth/core/_requests';

interface TermsDetail {
  terms_user_id: string;
  terms_details: string;
  terms_created_on: string;
  terms_company_name: string;
}

const TermsCondition = () => {
  const [editorContent, setEditorContent] = useState('');
  const [eventEditorContent, setEventEditorContent] = useState('');
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [termsData, setTermsData] = useState<TermsDetail[]>([]);
  const [initialEditValues, setInitialEditValues] = useState(
    {
      terms_user_id: '',
      terms_details_upd: '',
    }
  )

  const UserDetails: any = localStorage.getItem('user-details')
  const userDetailsObject = JSON.parse(UserDetails);
  const userId = userDetailsObject.id;

  var toolbaroptions = [[{ 'header': 1 }, { 'header': 2 }], ['bold', 'italic'], ['underline', 'strike'],
  [{ 'color': ['#F00', '#0F0', '#00F', '#000', '#FFF',] }], [{ 'list': 'ordered' }, { 'list': 'bullet' }, { align: 'center' }]]

  const module = {
    toolbar: toolbaroptions
  }

  const initialValues = {
    terms_details: '',
  };

  const validationSchema = Yup.object().shape({
    terms_details: Yup.string().required('Terms & Conditions is required'),
  });

  const handleSubmit = async (values: any, actions: FormikValues) => {
    setLoading(true)
    try {
      const response = await POST_Terms_Condition(
        values.terms_details,
        userId
        // values.account_created_by,
      );
      setTimeout(() => {
        setLoading(false)
        actions.resetForm()
        fetchTermsData(userId);
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Successfully Submitted',
          showConfirmButton: true,
          timer: 3000
        });
      }, 500);
    } catch (error) {
      console.error('Error submitting profile:', error);
    }
  };

  const fetchTermsData = async (userId: any) => {
    try {
      const response = await GET_Terms_Condition(userId);
      setTermsData(response.data.terms_details);
      if (response.data.terms_details) {
        setIsLoading(false);
        const initialData = response.data.terms_details[0]
        setInitialEditValues({
          terms_user_id: initialData.terms_user_id,
          terms_details_upd: initialData.terms_details,
        })
        setEventEditorContent(initialData.terms_details);
      }
    } catch (error) {
      console.error('Error fetching members:', error);
    }
  };

  // const fetchTermsData = async (userId: any) => {
  //   try {
  //     const response = await GET_Terms_Condition(userId);
  //     if (response && response.data && response.data.terms_details && response.data.terms_details.length > 0) {
  //       const initialData = response.data.terms_details[0];
  //       setTermsData(response.data.terms_details);
  //       setIsLoading(false);
  //       setInitialEditValues({
  //         terms_user_id: initialData.terms_user_id,
  //         terms_details_upd: initialData.terms_details,
  //       });
  //       setEventEditorContent(initialData.terms_details);
  //     } else {
  //       setIsLoading(false); 
  //     }
  //   } catch (error) {
  //     console.error('Error fetching members:', error);
  //   }
  // };

  useEffect(() => {
    fetchTermsData(userId);
  },[]);

  const handleButtonClick = () => {
    setShowModal(true);
  };

  const validation = Yup.object().shape({
    terms_details_upd: Yup.string().required('Terms & Conditions is required'),
  });

  const handleSubmitUpdate = async (values: any, actions: FormikValues) => {
    setLoading(true)
    try {
        const response = await PUT_Terms_Condition(
            values.terms_user_id,
            values.terms_details_upd,
        );
        actions.resetForm();
        fetchTermsData(userId);
        setTimeout(() => {
            setLoading(false)
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Successfully Updated',
                showConfirmButton: true,
                timer: 3000
            });
        }, 500);
        setShowModal(false)
    }
    catch (error) {
        console.error('Error submitting form data:', error);
    }
}

  return (
    <>


      <div className="row">
        <div className='col-md-8 offset-md-2 card p-8'>
          {isLoading ? (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '200px' }}>
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (termsData.length > 0 ? (
            <>
              {showModal ? (
                <div>
                  <Formik initialValues={initialEditValues} validationSchema={validation} onSubmit={handleSubmitUpdate}>
                    {({ setFieldValue }) => (
                      <Form>
                        <div className="form-group">
                          <Field type="hidden" name="terms_user_id" className="form-control" />
                        </div>
                        <div className="form-group mb-15 mt-5">
                          <label className='form-label required'>Terms & Conditions</label>
                          <ReactQuill
                            value={eventEditorContent}
                            className='h-auto'
                            modules={module}
                            placeholder="Write something..."
                            onChange={(content) => {
                              setEventEditorContent(content);
                              setFieldValue('terms_details_upd', content);
                            }}
                          />
                        </div>
                        {/* <div className='text-danger mt-2'>
                        <ErrorMessage name='terms_details' />
                      </div> */}
                        <div className='d-flex justify-content-end pt-10'>
                          <button type='submit' className='btn btn-primary' disabled={loading}>
                            {!loading && 'Submit'}
                            {loading && (
                              <span className='indicator-progress' style={{ display: 'block' }}>
                                Please wait...{' '}
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                              </span>
                            )}
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              ) : (
                <>
                  <div className='d-flex justify-content-between'>
                    <h3 className=''>Terms & Conditions Details :</h3>
                    <div>
                      <button className='btn btn-sm btn-primary' onClick={handleButtonClick}>
                        Edit
                      </button>
                    </div>
                  </div>
                  <hr className='mb-5'/>
                  <p dangerouslySetInnerHTML={{ __html: initialEditValues.terms_details_upd }} />
                </>
              )}
            </>
          ) :
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
              {({ setFieldValue }) => (
                <Form>
                  <div className="form-group mb-15 mt-5">
                    <label className='form-label required'>Terms & Conditions</label>
                    <ReactQuill
                      value={editorContent}
                      className='h-150px'
                      modules={module}
                      placeholder="Write something..."
                      onChange={(content) => {
                        setEditorContent(content);
                        setFieldValue('terms_details', content);
                      }}
                    />
                  </div>
                  <div className='text-danger mt-2'>
                    <ErrorMessage name='terms_details' />
                  </div>
                  <div className='d-flex justify-content-end pt-10'>
                    <button type='submit' className='btn btn-primary' disabled={loading}>
                      {!loading && 'Submit'}
                      {loading && (
                        <span className='indicator-progress' style={{ display: 'block' }}>
                          Please wait...{' '}
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </div>
      </div>
    </>
  )
}

export default TermsCondition