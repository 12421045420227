import { useRef, useEffect, useState } from 'react'
import { handleImageDrop } from '../../../pages/CompressImage';
import Swal from 'sweetalert2';
import * as Yup from 'yup';
import { useDropzone } from "react-dropzone";
import { Company_POST_Application, GET_ALL_USERS } from '../core/_requests'
import { Form, ErrorMessage, Field, Formik, FormikValues } from 'formik'
import SignatureCanvas from 'react-signature-canvas';
import { AccountDetails } from '../core/_models';

interface CompanyAccount {
    app_dot_id: string
    company_name: string
    company_type_concern: string
    date_incorporation: string
    email_id: string
    alternate_email_id: string
    company_phone_number: string
    company_alter_phone_number: string
    company_installation_add: string
    company_installation_country: string
    company_installation_state: string
    company_billing_add: string
    company_billing_country: string
    company_billing_state: string
    company_gst: string
    company_address_proof: string
    company_id_proof: string
}

const CompanyFormAdmin = ({ setShowModal, fetchApplicationData }: any) => {

    const UserDetails: any = localStorage.getItem('user-details')
    const userDetailsObject = JSON.parse(UserDetails);
    const user_id = userDetailsObject?.id ?? null;
    const userRole = userDetailsObject.role;

    const [loading, setLoading] = useState(false)
    const [selectedType, setSelectedType] = useState(user_id);
    const [userData, setUserData] = useState<AccountDetails[]>([]);
    const [idProof_Front, setIdProof_Front] = useState<File[]>([]);
    const [idProof_Back, setIdProof_Back] = useState<File[]>([]);
    const [addProof_Front, setAddProof_Front] = useState<File[]>([]);
    const [customer_photo, setCustomer_photo] = useState<File[]>([]);


    const sigCanvas = useRef<SignatureCanvas>(null);
    const AppType: any = localStorage.getItem('vendor_type')

    const { getRootProps: getRootProps1, getInputProps: getInputProps1 } = useDropzone({
        onDrop: acceptedFiles => handleImageDrop(acceptedFiles, setIdProof_Front),
        accept: {
            'image/jpeg': [],
            'image/png': [],
        },
    });

    const { getRootProps: getRootProps2, getInputProps: getInputProps2 } = useDropzone({
        onDrop: acceptedFiles => handleImageDrop(acceptedFiles, setIdProof_Back),
        accept: {
            'image/jpeg': [],
            'image/png': [],
        },
    });

    const { getRootProps: getRootProps3, getInputProps: getInputProps3 } = useDropzone({
        onDrop: acceptedFiles => handleImageDrop(acceptedFiles, setAddProof_Front),
        accept: {
            'image/jpeg': [],
            'image/png': [],
        },
    });

    const { getRootProps: getRootProps5, getInputProps: getInputProps5 } = useDropzone({
        onDrop: acceptedFiles => handleImageDrop(acceptedFiles, setCustomer_photo),
        accept: {
            'image/jpeg': [],
            'image/png': [],
        },
    });

    const clearCanvas = () => {
        if (sigCanvas.current) {
            sigCanvas.current.clear();
        }
    };

    const handleTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedType(event.target.value);
    };

    const fetchUserData = async (all_users: any) => {
        try {
            const response = await GET_ALL_USERS(all_users);
            setUserData(response.data.account_details);
        } catch (error) {
            console.error('Error fetching members:', error);
        }
    };

    useEffect(() => {
        fetchUserData("0");
    }, []);

    const CompanyInitial: CompanyAccount = {
        app_dot_id: '',
        company_name: '',
        company_type_concern: '',
        date_incorporation: '',
        email_id: '',
        alternate_email_id: '',
        company_phone_number: '',
        company_alter_phone_number: '',
        company_installation_add: '',
        company_installation_country: 'India',
        company_installation_state: 'Pondicherry',
        company_billing_add: '',
        company_billing_country: 'India',
        company_billing_state: 'Pondicherry',
        company_gst: '',
        company_id_proof: '',
        company_address_proof: '',
    }

    const CompanyValidation = Yup.object({
        company_name: Yup.string().required().label('Company Name'),
        // company_type_concern: Yup.string().required().label('Type of Concern'),
        // date_incorporation: Yup.string().required().label('Date of Incorporation'),
        // email_id: Yup.string().required().email('Invalid email').label('Email ID'),
        // alternate_email_id: Yup.string().email('Invalid email').label('Alternate Email Id'),
        // company_phone_number: Yup.string().matches(/^[0-9]{10}$/, 'Phone number must be exactly 10 digits').required('Phone Number is required'),
        // company_alter_phone_number: Yup.string().matches(/^[0-9]{10}$/, 'Phone number must be exactly 10 digits'),
        // company_installation_add: Yup.string().required().label('Installation Address'),
        // company_installation_country: Yup.string().required().label('Country'),
        // company_installation_state: Yup.string().required().label('State'),
        // company_billing_add: Yup.string().required().label('Billing Address'),
        // company_billing_country: Yup.string().required().label('Country'),
        // company_billing_state: Yup.string().required().label('State'),
        // company_gst: Yup.string().matches(/^[A-Z0-9]{15}$/, 'GST number must be exactly 15 characters'),
        // company_address_proof: Yup.string().required().label('Address Proof'),
        // company_id_proof: Yup.string().required().label('Id Proof'),
    })

    const submitStep = async (values: any, actions: FormikValues) => {
        setLoading(true)
        try {
            const ApplicationData = new FormData();

            ApplicationData.append("company_customer_image", customer_photo[0]);
            if (idProof_Front.length > 0) {
                ApplicationData.append("company_auth_front_image", idProof_Front[0]);
            }
            if (idProof_Back.length > 0) {
                ApplicationData.append("company_auth_back_image", idProof_Back[0]);
            }
            if (addProof_Front.length > 0) {
                ApplicationData.append("company_address_image", addProof_Front[0]);
            }
            // if (sigCanvas.current) {
            //     const signatureDataURL = sigCanvas.current.toDataURL();
            //     const signatureBlob = await (await fetch(signatureDataURL)).blob();
            //     ApplicationData.append("company_auth_sign", signatureBlob);
            // }
            ApplicationData.append('accountType', 'Company');
            const { data: auth } = await Company_POST_Application(
                ApplicationData,
                values.app_dot_id,
                values.company_name,
                values.company_type_concern,
                values.date_incorporation,
                values.email_id,
                values.alternate_email_id,
                values.company_phone_number,
                values.company_alter_phone_number,
                values.company_installation_add,
                values.company_installation_country,
                values.company_installation_state,
                values.company_billing_add,
                values.company_billing_country,
                values.company_billing_state,
                values.company_gst,
                values.company_address_proof,
                values.company_id_proof,
                user_id,
                AppType,
                selectedType
            );
            actions.resetForm()
            setIdProof_Front([]);
            setIdProof_Back([])
            setAddProof_Front([])
            setCustomer_photo([])
            setSelectedType('')
            setLoading(false)
            fetchApplicationData()
            setShowModal(false)
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Successfully Submitted',
                showConfirmButton: true,
                timer: 3000
            });
        }
        catch (error) {
            console.error('Error submitting form data:', error);
        }
    }

    return (
        <>
            <div>
                <Formik initialValues={CompanyInitial} validationSchema={CompanyValidation} onSubmit={submitStep}>
                    {({ setFieldValue, values }) => (
                        <Form>
                            {userRole === '1' || userRole === '2' ? (
                                <>
                                    <div className='row mb-6'>
                                        <label className='col-lg-4 col-form-label fw-bold fs-6'>Select Vendor</label>
                                        <div className='col-lg-8'>
                                            <select
                                                className='form-select form-select-solid'
                                                value={selectedType}
                                                onChange={handleTypeChange}
                                            >
                                                {userData.map((user) => (
                                                    user.user_role === "1" || user.user_role === "3" ? (
                                                        <option key={user.user_id} value={user.user_id}>
                                                            {user.user_company_name}
                                                        </option>
                                                    ) : null
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className='row mb-6'>
                                        <label className='col-lg-4 col-form-label required fw-bold fs-6'>Customer ID</label>
                                        <div className="col-lg-8 form-group">
                                            <Field type="text" name="app_dot_id" className="form-control" />
                                        </div>
                                    </div>
                                </>
                            ) : null}

                            <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label fw-bold fs-6'>Customer Profile</label>
                                <div className='col-lg-8'>
                                    <div className='d-flex'>
                                        <div className='me-5'>
                                            {customer_photo.map(file => (
                                                <div key={file.name}>
                                                    <img
                                                        src={typeof file === 'string' ? file : URL.createObjectURL(file)}
                                                        alt={file.name}
                                                        className='w-150px h-150px object-fit-cover rounded-3'
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                        <div {...getRootProps5({ className: "dropzone" })}>
                                            <input className="input-zone" {...getInputProps5()} />
                                            <div className="text-center">
                                                <p className="dropzone-content">
                                                    Drag’n’drop or click to select files
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label fw-bold fs-6'>Company Name</label>
                                <div className='col-lg-8'>
                                    <Field name='company_name' className='form-control form-control-lg' />
                                    <ErrorMessage name='company_name' component="div" className="text-danger" />
                                </div>
                            </div>

                            <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Type of Concern</label>
                                <div className='col-lg-8 fv-row'>
                                    <Field as="select" name="company_type_concern" aria-label="Select example" className="form-select" >
                                        <option value=''>Select Type</option>
                                        <option value='Private Limited'>Private Limited</option>
                                        <option value='Proprietor'>Proprietor</option>
                                        <option value='Partner'>Partner</option>
                                        <option value='LLP'>LLP</option>
                                    </Field>
                                    <ErrorMessage name="company_type_concern" component="div" className="text-danger" />
                                </div>
                            </div>

                            {/* <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Authorised Signatory</label>
                                <div className='col-lg-8 fv-row'>
                                    <div style={{ border: '2px solid black', borderRadius: '5px', padding: '5px' }}>
                                        <SignatureCanvas
                                            ref={sigCanvas}
                                            penColor="blue"
                                            canvasProps={{
                                                width: 400,
                                                height: 200,
                                                className: 'sigCanvas',
                                                style: { backgroundColor: 'white' },
                                            }}
                                        />
                                    </div>
                                    <span className='badge badge-danger cursor-pointer mt-5' onClick={clearCanvas}>Clear Signature</span>
                                </div>
                            </div> */}

                            <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Date of Incorporation</label>
                                <div className='col-lg-8 fv-row'>
                                    <Field type='date' name='date_incorporation' className='form-control form-control-lg' />
                                    <ErrorMessage name="date_incorporation" component="div" className="text-danger" />
                                </div>
                            </div>

                            <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Email ID</label>
                                <div className='col-lg-8 fv-row'>
                                    <Field type='text' className='form-control' name='email_id' />
                                    <ErrorMessage name="email_id" component="div" className="text-danger" />
                                </div>
                            </div>

                            <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label fw-bold fs-6'>Alternate Email ID</label>
                                <div className='col-lg-8 fv-row'>
                                    <Field type='text' className='form-control' name='alternate_email_id' />
                                    <ErrorMessage name="alternate_email_id" component="div" className="text-danger" />
                                </div>
                            </div>

                            <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Phone Number</label>
                                <div className='col-lg-8 fv-row'>
                                    <Field type='number' className='form-control' name='company_phone_number' />
                                    <ErrorMessage name="company_phone_number" component="div" className="text-danger" />
                                </div>
                            </div>

                            <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label fw-bold fs-6'>Alternate Number</label>
                                <div className='col-lg-8 fv-row'>
                                    <Field type='number' className='form-control' name='company_alter_phone_number' />
                                    <ErrorMessage name="company_alter_phone_number" component="div" className="text-danger" />
                                </div>
                            </div>

                            <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Installation Address</label>
                                <div className='col-lg-8 fv-row'>
                                    <Field type='text' className='form-control' name='company_installation_add' />
                                    <ErrorMessage name="company_installation_add" component="div" className="text-danger" />
                                </div>
                            </div>

                            <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Country</label>
                                <div className='col-lg-8 fv-row'>
                                    <Field type='text' className='form-control' name='company_installation_country' />
                                    <ErrorMessage name="company_installation_country" component="div" className="text-danger" />
                                </div>
                            </div>

                            <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label required fw-bold fs-6'>State</label>
                                <div className='col-lg-8 fv-row'>
                                    <Field
                                        as='select'
                                        name='company_installation_state'
                                        className='form-select'
                                    >
                                        <option value='Pondicherry'>Pondicherry</option>
                                        <option value='Tamil Nadu'>Tamil Nadu</option>
                                    </Field>
                                    <ErrorMessage name="company_installation_state" component="div" className="text-danger" />
                                </div>
                            </div>

                            <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Billing Address</label>
                                <div className='col-lg-8 fv-row'>
                                    <Field type='text' className='form-control' name='company_billing_add' />
                                    <ErrorMessage name="company_billing_add" component="div" className="text-danger" />
                                </div>
                            </div>

                            <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Country</label>
                                <div className='col-lg-8 fv-row'>
                                    <Field type='text' className='form-control' name='company_billing_country' />
                                    <ErrorMessage name="company_billing_country" component="div" className="text-danger" />
                                </div>
                            </div>

                            <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label required fw-bold fs-6'>State</label>
                                <div className='col-lg-8 fv-row'>
                                    <Field
                                        as='select'
                                        name='company_billing_state'
                                        className='form-select'
                                    >
                                        <option value='Pondicherry'>Pondicherry</option>
                                        <option value='Tamil Nadu'>Tamil Nadu</option>
                                    </Field>
                                    <ErrorMessage name="company_billing_state" component="div" className="text-danger" />
                                </div>
                            </div>

                            <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label fw-bold fs-6'>Company GST Number</label>
                                <div className='col-lg-8 fv-row'>
                                    <Field type='text' className='form-control' name='company_gst'
                                        onChange={(e: any) => {
                                            const uppercaseValue = e.target.value.toUpperCase();
                                            setFieldValue('company_gst', uppercaseValue);
                                        }}
                                    />
                                    <ErrorMessage name="company_gst" component="div" className="text-danger" />
                                </div>
                            </div>

                            <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Address Proof</label>
                                <div className='col-lg-8 fv-row'>
                                    <Field as="select" name="company_address_proof" aria-label="Select example" className="form-select" >
                                        <option value=''>Select Address Proof</option>
                                        <option value='EB Bill'>EB Bill</option>
                                        <option value='GST'>GST</option>
                                        <option value='Others'>Others</option>
                                    </Field>
                                    <ErrorMessage name="company_address_proof" component="div" className="text-danger" />
                                </div>
                            </div>

                            <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label required fw-bold fs-6'>{values.company_address_proof} Front Image</label>
                                <div className='col-lg-8'>
                                    <div className='d-flex'>
                                        <div className='me-5'>
                                            {addProof_Front.map(file => (
                                                <div key={file.name} style={{ margin: '10px' }}>
                                                    <img
                                                        src={URL.createObjectURL(file)}
                                                        alt={file.name}
                                                        className='h-150px w-250px  object-fit-cover rounded-3'
                                                    />
                                                    <p>{file.name}</p>
                                                </div>
                                            ))}
                                        </div>
                                        <div {...getRootProps3({ className: "dropzone" })}>
                                            <input className="input-zone" {...getInputProps3()} />
                                            <div className="text-center">
                                                <p className="dropzone-content">
                                                    Drag’n’drop or click to select files
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='row mb-6'>
                                <label className='col-lg-4 form-label required fw-bold fs-6'>Id Proof</label>
                                <div className='col-lg-8'>
                                    <Field as="select" name="company_id_proof" aria-label="Select example" className="form-select" >
                                        <option value=''>Select ID Proof</option>
                                        <option value='Aadhar Card'>Aadhar Card</option>
                                        <option value='Passport'>Passport</option>
                                        <option value='Voter ID'>Voter ID</option>
                                    </Field>
                                    <ErrorMessage name="company_id_proof" component="div" className="text-danger" />
                                </div>
                            </div>

                            <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label required fw-bold fs-6'>{values.company_id_proof} Front Page</label>
                                <div className='col-lg-8'>
                                    <div className='d-flex'>
                                        <div className='me-5'>
                                            {idProof_Front.map(file => (
                                                <div key={file.name} style={{ margin: '10px' }}>
                                                    <img
                                                        src={URL.createObjectURL(file)}
                                                        alt={file.name}
                                                        className='h-150px w-250px  object-fit-cover rounded-3'
                                                    />
                                                    <p>{file.name}</p>
                                                </div>
                                            ))}
                                        </div>
                                        <div {...getRootProps1({ className: "dropzone" })}>
                                            <input type="file" id="Front_image" {...getInputProps1()} name="Front_image" />
                                            <div className="text-center">
                                                <p className="dropzone-content">
                                                    Drag’n’drop or click to select files
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label required fw-bold fs-6'>{values.company_id_proof} Back Page</label>
                                <div className='col-lg-8'>
                                    <div className='d-flex'>
                                        <div className='me-5'>
                                            {idProof_Back.map(file => (
                                                <div key={file.name} style={{ margin: '10px' }}>
                                                    <img
                                                        src={URL.createObjectURL(file)}
                                                        alt={file.name}
                                                        className='h-150px w-250px  object-fit-cover rounded-3'
                                                    />
                                                    <p>{file.name}</p>
                                                </div>
                                            ))}
                                        </div>
                                        <div {...getRootProps2({ className: "dropzone" })}>
                                            <input className="input-zone" {...getInputProps2()} name='back_image' />
                                            <div className="text-center">
                                                <p className="dropzone-content">
                                                    Drag’n’drop or click to select files
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={() => setShowModal(false)}>
                                    Cancel
                                </button>
                                <div className='card-footer d-flex justify-content-end py-6 px-9'>
                                    <button type='submit' className='btn btn-primary' disabled={loading}>
                                        {!loading && 'Save Changes'}
                                        {loading && (
                                            <span className='indicator-progress' style={{ display: 'block' }}>
                                                Please wait...{' '}
                                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                            </span>
                                        )}
                                    </button>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </>
    )
}

export default CompanyFormAdmin