import { useEffect, useRef, useState } from 'react'
import { KTIcon, toAbsoluteUrl } from '../../../../_metronic/helpers'
import { Company_POST_Application } from '../core/_requests'
import Webcam from 'react-webcam';
import SignatureCanvas from 'react-signature-canvas';
import { StepperComponent } from '../../../../_metronic/assets/ts/components'
import { Form, ErrorMessage, Field, Formik, FormikValues } from 'formik'
import { CompanyAccountSchema, CompanyAccount, CompanyInitial } from '../../wizards/components/CreateAccountWizardHelper'
import { useNavigate } from 'react-router-dom';
import Privacy_Policy from './Privacy_Policy';
import Declaration from './Declaration';

const CompanyForm = ({ setBackButton, setIsButtonClicked }: any) => {
    const navigate = useNavigate();
    const CustomerPhoto = useRef<any>(null);
    const IdFrontPhoto = useRef<any>(null);
    const IdBackPhoto = useRef<any>(null);
    const AddressPhoto = useRef<any>(null);
    const AddressBackPhoto = useRef<any>(null);
    const AddressThirdPhoto = useRef<any>(null);
    const stepperRef = useRef<HTMLDivElement | null>(null)
    const stepper = useRef<StepperComponent | null>(null)
    const [currentSchema, setCurrentSchema] = useState(CompanyAccountSchema[0])
    const [initValues] = useState<CompanyAccount>(CompanyInitial)
    const [showModalPrivacy, setShowModalPrivacy] = useState(false);
    const [loading, setLoading] = useState(false)
    const [customer_photo, setCustomer_photo] = useState<string | null>(null);
    const [showWarning, setShowWarning] = useState<boolean>(false);
    const [privacyWarning, setprivacyWarning] = useState<boolean>(false);
    const [showSignatureWarning, setShowSignatureWarning] = useState<boolean>(false);
    const [idProof_Front, setIdProof_Front] = useState<string | null>(null);
    const [idProof_Back, setIdProof_Back] = useState<string | null>(null);
    const [addProof_Front, setAddProof_Front] = useState<string | null>(null);
    const [addProof_Back, setAddProof_Back] = useState<string | null>(null);
    const [addProof_third, setAddProof_third] = useState<string | null>(null);
    const [frontImageError, setFrontImageError] = useState(false)
    const [backImageError, setBackImageError] = useState(false)
    const [address_proof_img, setAddress_proof_img] = useState(false)
    const [address_proofBack_img, setAddress_proofBack_img] = useState(false)
    const [selectedIdProof, setSelectedIdProof] = useState<string>('');
    const [selectedAddProof, setSelectedAddProof] = useState<string>('');
    const [selectedOption, setSelectedOption] = useState('');
    const [canvasWidth, setCanvasWidth] = useState(600);
    const [showModal, setShowModal] = useState(false);
    const [backImage, setBackImage] = useState(false);
    const [custImage, setCustImage] = useState(false);

    const UserDetails: any = localStorage.getItem('user-details')
    const userDetailsObject = JSON.parse(UserDetails);
    const app_approved_user_id = userDetailsObject?.id ?? null;
    const sigCanvas = useRef<SignatureCanvas>(null);

    const AppType: any = localStorage.getItem('vendor_type')

    const handleFrontImage = () => {
        setShowModal(true);
    };

    const handleBackImage = () => {
        setBackImage(true);
    };

    const handleCustomer = () => {
        setCustImage(true);
    };

    const openFileInput = () => {
        const fileInput = document.querySelector('input[name="idFrontImage"]') as HTMLInputElement | null;
        if (fileInput) {
            fileInput.click();
        }
    };

    const openFileInputBack = () => {
        const fileInput = document.querySelector('input[name="idBackImage"]') as HTMLInputElement | null;
        if (fileInput) {
            fileInput.click();
        }
    };

    const openFileInputAddFront = () => {
        const fileInput = document.querySelector('input[name="Add_FrontImage"]') as HTMLInputElement | null;
        if (fileInput) {
            fileInput.click();
        }
    };

    const openFileInputCustomer = () => {
        const fileInput = document.querySelector('input[name="CustomerImage"]') as HTMLInputElement | null;
        if (fileInput) {
            fileInput.click();
        }
    };

    const openFileInputAddBack = () => {
        const fileInput = document.querySelector('input[name="Add_BackImage"]') as HTMLInputElement | null;
        if (fileInput) {
            fileInput.click();
        }
    };

    const openFileInputAddThird = () => {
        const fileInput = document.querySelector('input[name="Add_ThirdImage"]') as HTMLInputElement | null;
        if (fileInput) {
            fileInput.click();
        }
    };

    const compressImage = async (imageUrl: string): Promise<string> => {
        return new Promise((resolve, reject) => {
            // Load the image into an HTMLImageElement
            const img = new Image();
            img.src = imageUrl;

            // Wait for the image to load before processing
            img.onload = () => {
                // Create a canvas element to draw the image
                const canvas = document.createElement('canvas');

                // Calculate the desired width and height to achieve the target size
                const maxWidth = 800; // Adjust the maximum width as needed
                const maxHeight = 800; // Adjust the maximum height as needed
                const width = img.width;
                const height = img.height;

                // Calculate the scaling factor to fit within the desired dimensions
                const scaleFactor = Math.min(maxWidth / width, maxHeight / height);

                // Set the canvas dimensions based on the scaling factor
                canvas.width = width * scaleFactor;
                canvas.height = height * scaleFactor;

                // Draw the image onto the canvas with the scaled dimensions
                const ctx = canvas.getContext('2d');
                ctx?.drawImage(img, 0, 0, width * scaleFactor, height * scaleFactor);

                // Convert the canvas content to a compressed data URL
                const compressedImageUrl = canvas.toDataURL('image/jpeg', 0.7); // Adjust the quality as needed

                resolve(compressedImageUrl);
            };

            // Handle image load errors
            img.onerror = (error) => {
                reject(error);
            };
        });
    };

    const handleIdProofFront = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files && e.target.files[0];
        if (file) {
            const imageUrl = URL.createObjectURL(file);
            try {
                const compressedImageUrl = await compressImage(imageUrl);
                setIdProof_Front(compressedImageUrl);
                setFrontImageError(false)
            } catch (error) {
                console.error('Error compressing image:', error);
            }
        }
    };

    const handleIdProofBack = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files && e.target.files[0];
        if (file) {
            const imageUrl = URL.createObjectURL(file);
            try {
                const compressedImageUrl = await compressImage(imageUrl);
                setIdProof_Back(compressedImageUrl);
                setBackImageError(false)
            } catch (error) {
                console.error('Error compressing image:', error);
            }
        }
    };

    const handleAddProofFront = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files && e.target.files[0];
        if (file) {
            const imageUrl = URL.createObjectURL(file);
            try {
                const compressedImageUrl = await compressImage(imageUrl);
                setAddProof_Front(compressedImageUrl);
                setAddress_proof_img(false)
            } catch (error) {
                console.error('Error compressing image:', error);
            }
        }
    };

    const handleCustomerImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files && e.target.files[0];
        if (file) {
            const imageUrl = URL.createObjectURL(file);
            try {
                const compressedImageUrl = await compressImage(imageUrl);
                setCustomer_photo(compressedImageUrl);
                setShowWarning(false);
            } catch (error) {
                console.error('Error compressing image:', error);
            }
        }
    };

    useEffect(() => {
        const handleResize = () => {
            // Adjust canvas width based on device width
            const newWidth = window.innerWidth >= 768 ? 600 : 300;
            setCanvasWidth(newWidth);
        };

        // Attach the resize event listener
        window.addEventListener('resize', handleResize);

        // Initial setup on component mount
        handleResize();

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const clearCanvas = () => {
        if (sigCanvas.current) {
            sigCanvas.current.clear();
        }
    };

    const openPrivacymodal = () => {
        setShowModalPrivacy(true);
    };

    const handleIdProofChange = (idProof: string) => {
        setSelectedIdProof(idProof);
    };

    const handleAddProofChange = (addProof: string) => {
        setSelectedAddProof(addProof);
    };

    const handleAddProofBack = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files && e.target.files[0];
        if (file) {
            const imageUrl = URL.createObjectURL(file);
            try {
                const compressedImageUrl = await compressImage(imageUrl);
                setAddProof_Back(compressedImageUrl);
                setAddress_proofBack_img(false)
            } catch (error) {
                console.error('Error compressing image:', error);
            }
        }
    };

    const handleAddProofThird = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files && e.target.files[0];
        if (file) {
            const imageUrl = URL.createObjectURL(file);
            try {
                const compressedImageUrl = await compressImage(imageUrl);
                setAddProof_third(compressedImageUrl);
            } catch (error) {
                console.error('Error compressing image:', error);
            }
        }
    };

    const dataURItoBlob = async (dataURI: any) => {
        if (dataURI.startsWith('blob:')) {
            // Fetch the Blob data from the Blob URL
            const response = await fetch(dataURI);
            return await response.blob();
        } else {
            // Process standard data URI
            const [mime, data] = dataURI.split(',');
            const binary = atob(data);
            const array = new Uint8Array(binary.length);

            for (let i = 0; i < binary.length; i++) {
                array[i] = binary.charCodeAt(i);
            }

            return new Blob([array], { type: mime });
        }
    };

    const captureFrontImage = () => {
        if (IdFrontPhoto.current) {
            const capturedPhoto = IdFrontPhoto.current.getScreenshot();
            setIdProof_Front(capturedPhoto);
            setFrontImageError(false)
        }
    };

    const captureBackImage = () => {
        if (IdBackPhoto.current) {
            const capturedPhoto = IdBackPhoto.current.getScreenshot();
            setIdProof_Back(capturedPhoto);
            setBackImageError(false)
        }
    };

    const captureAddImage = () => {
        if (AddressPhoto.current) {
            const capturedPhoto = AddressPhoto.current.getScreenshot();
            setAddProof_Front(capturedPhoto);
            setAddress_proof_img(false)
        }
    };

    const captureAddImageBack = () => {
        if (AddressBackPhoto.current) {
            const capturedPhoto = AddressBackPhoto.current.getScreenshot();
            setAddProof_Back(capturedPhoto);
            setAddress_proofBack_img(false)
        }
    };

    const captureAddImageThird = () => {
        if (AddressThirdPhoto.current) {
            const capturedPhoto = AddressThirdPhoto.current.getScreenshot();
            setAddProof_third(capturedPhoto);
        }
    };


    const retakeFrontImage = () => {
        setIdProof_Front(null);
    };

    const retakeBackImage = () => {
        setIdProof_Back(null);
    };

    const retakeAddImage = () => {
        setAddProof_Front(null);
    };

    const retakeAddBackImage = () => {
        setAddProof_Back(null);
    };

    const retakeAddThirdImage = () => {
        setAddProof_third(null);
    };

    const capturePhoto = () => {
        if (CustomerPhoto.current) {
            const capturedPhoto = CustomerPhoto.current.getScreenshot();
            setCustomer_photo(capturedPhoto);
            setShowWarning(false);
        }
    };

    const retake = () => {
        setCustomer_photo(null);
    };

    const loadStepper = () => {
        stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
    }

    const prevStep = () => {
        if (!stepper.current) {
            return
        }
        if (stepper.current.currentStepIndex === 2) {
            setBackButton(true);
        }
        stepper.current.goPrev()
        setCurrentSchema(CompanyAccountSchema[stepper.current.currentStepIndex - 1])
    }

    const resizeSignature = (signatureDataURL: string, width: number, height: number) => {
        return new Promise((resolve, reject) => {
            const canvas = document.createElement('canvas');
            canvas.width = width;
            canvas.height = height;

            const ctx = canvas.getContext('2d');
            const img = new Image();
            img.onload = () => {
                if (ctx) {
                    ctx.drawImage(img, 0, 0, width, height);
                    resolve(canvas.toDataURL());
                } else {
                    reject(new Error("Failed to get context from canvas"));
                }
            };
            img.src = signatureDataURL;
        });
    };

    const submitStep = async (values: CompanyAccount, actions: FormikValues) => {
        if (!stepper.current) {
            return
        }
        if (stepper.current.currentStepIndex === 1) {
            setBackButton(false);
        }
        if (stepper.current.currentStepIndex !== stepper.current.totalStepsNumber) { 
            // if (stepper.current.currentStepIndex === 12) {
            //     if (!addProof_Front) {
            //         setAddress_proof_img(true);
            //     } else {
            //         setAddress_proof_img(false);
            //         stepper.current.goNext();
            //     }
            // } else if (stepper.current.currentStepIndex === 15) {
            //     if (!idProof_Front) {
            //         setFrontImageError(true);
            //     } else {
            //         setFrontImageError(false);
            //         stepper.current.goNext();
            //     }
            // } else if (stepper.current.currentStepIndex === 16) {
            //     if (!idProof_Back) {
            //         setBackImageError(true);
            //     } else {
            //         setBackImageError(false);
            //         stepper.current.goNext();
            //     }
            // } else if (stepper.current.currentStepIndex === 17) {
            //     if (!customer_photo) {

            //         setShowWarning(true);
            //     } else {
            //         setShowWarning(false);
            //         stepper.current.goNext()
            //     }
            // } else {
            //     stepper.current.goNext()
            // }
            stepper.current.goNext()
        } else {
            // if (sigCanvas.current && sigCanvas.current.isEmpty()) {
            //     setShowSignatureWarning(true);
            //     return;
            // }
            const termsConditionCheckbox = document.getElementById('terms_condition') as HTMLInputElement;
            if (!termsConditionCheckbox.checked) { 
                setprivacyWarning(true);
                return;  
            } else { 
                setprivacyWarning(false);
            }
            setLoading(true)
            try {
                const ApplicationData = new FormData();
                if (sigCanvas.current && !sigCanvas.current.isEmpty()) {
                    const signatureDataURL = sigCanvas.current.toDataURL();
                    const resizedDataURL = await resizeSignature(signatureDataURL, 600, 200);
                    const signatureBlob = await (await fetch(resizedDataURL as string)).blob();
                    ApplicationData.append("company_auth_sign", signatureBlob);
                }

                if (idProof_Front) {
                    const frontImageBlob = await dataURItoBlob(idProof_Front);
                    if (frontImageBlob) {
                        ApplicationData.append("company_auth_front_image", frontImageBlob);
                    }
                }

                if (idProof_Back) {
                    const backImageBlob = await dataURItoBlob(idProof_Back);
                    if (backImageBlob) {
                        ApplicationData.append("company_auth_back_image", backImageBlob);
                    }
                }

                if (addProof_Front) {
                    const addImageBlob = await dataURItoBlob(addProof_Front);
                    if (addImageBlob) {
                        ApplicationData.append("company_address_image", addImageBlob);
                    }
                }
                if (addProof_Back) {
                    const addBackBlob = await dataURItoBlob(addProof_Back);
                    if (addBackBlob) {
                        ApplicationData.append("company_address_image_2", addBackBlob);
                    }
                }
                if (addProof_third) {
                    const addThirdBlob = await dataURItoBlob(addProof_third);
                    if (addThirdBlob) {
                        ApplicationData.append("company_address_image_3", addThirdBlob);
                    }
                }

                if (customer_photo) {
                    const customerImageBlob = await dataURItoBlob(customer_photo);
                    if (customerImageBlob) {
                        ApplicationData.append("company_customer_image", customerImageBlob);
                    }
                }

                ApplicationData.append('accountType', 'Company');
                const { data: auth } = await Company_POST_Application(
                    ApplicationData,
                    "",
                    values.company_name,
                    values.company_type_concern,
                    values.date_incorporation,
                    values.email_id,
                    values.alternate_email_id,
                    values.company_phone_number,
                    values.company_alter_phone_number,
                    values.company_installation_add,
                    values.company_installation_country,
                    values.company_installation_state,
                    values.company_billing_add,
                    values.company_billing_country,
                    values.company_billing_state,
                    values.company_gst,
                    values.company_address_proof,
                    values.company_id_proof,
                    app_approved_user_id,
                    AppType,
                    "null",
                );
                setIsButtonClicked(false);
                setCustomer_photo(null);
                actions.resetForm()
                setLoading(false)
                navigate('/success')
            }
            catch (error) {
                console.error('Error submitting form data:', error);
            }
        }
        setCurrentSchema(CompanyAccountSchema[stepper.current.currentStepIndex - 1])
    }

    useEffect(() => {
        if (!stepperRef.current) {
            return
        }
        loadStepper()
    }, [stepperRef])

    return (
        <>
            <div
                ref={stepperRef}
                className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
                id='kt_create_account_stepper'
            >
                <div className='card d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px w-xxl-400px me-9 d-none'>
                    <div className='card-body px-6 px-lg-10 px-xxl-15 py-20'>
                        <div className='stepper-nav'>
                            <div className='stepper-item current' data-kt-stepper-element='nav'>
                                <div className='stepper-wrapper'>
                                    <div className='stepper-icon w-40px h-40px'>
                                        <i className='stepper-check fas fa-check'></i>
                                        <span className='stepper-number'>1</span>
                                    </div>
                                    <div className='stepper-label'>
                                        <h3 className='stepper-title'>Account Type</h3>
                                        <div className='stepper-desc fw-semibold'>Setup Your Account Details</div>
                                    </div>
                                </div>
                                <div className='stepper-line h-40px'></div>
                            </div>
                            <div className='stepper-item' data-kt-stepper-element='nav'>
                                <div className='stepper-wrapper'>
                                    <div className='stepper-icon w-40px h-40px'>
                                        <i className='stepper-check fas fa-check'></i>
                                        <span className='stepper-number'>2</span>
                                    </div>
                                    <div className='stepper-label'>
                                        <h3 className='stepper-title'>Account Settings</h3>
                                        <div className='stepper-desc fw-semibold'>Setup Your Account Settings</div>
                                    </div>
                                </div>
                                <div className='stepper-line h-40px'></div>
                            </div>
                            <div className='stepper-item' data-kt-stepper-element='nav'>
                                <div className='stepper-wrapper'>
                                    <div className='stepper-icon w-40px h-40px'>
                                        <i className='stepper-check fas fa-check'></i>
                                        <span className='stepper-number'>3</span>
                                    </div>
                                    <div className='stepper-label'>
                                        <h3 className='stepper-title'>Business Info</h3>
                                        <div className='stepper-desc fw-semibold'>Your Business Related Info</div>
                                    </div>
                                </div>
                                <div className='stepper-line h-40px'></div>
                            </div>
                            <div className='stepper-item' data-kt-stepper-element='nav'>
                                <div className='stepper-wrapper'>
                                    <div className='stepper-icon w-40px h-40px'>
                                        <i className='stepper-check fas fa-check'></i>
                                        <span className='stepper-number'>4</span>
                                    </div>
                                    <div className='stepper-label'>
                                        <h3 className='stepper-title'>Billing Details</h3>
                                        <div className='stepper-desc fw-semibold'>Set Your Payment Methods</div>
                                    </div>
                                </div>
                                <div className='stepper-line h-40px'></div>
                            </div>
                            <div className='stepper-item' data-kt-stepper-element='nav'>
                                <div className='stepper-wrapper'>
                                    <div className='stepper-icon w-40px h-40px'>
                                        <i className='stepper-check fas fa-check'></i>
                                        <span className='stepper-number'>5</span>
                                    </div>
                                    <div className='stepper-label'>
                                        <h3 className='stepper-title'>Completed</h3>
                                        <div className='stepper-desc fw-semibold'>Woah, we are here</div>
                                    </div>
                                </div>
                            </div>
                            <div className='stepper-item' data-kt-stepper-element='nav'>
                                <div className='stepper-wrapper'>
                                    <div className='stepper-icon w-40px h-40px'>
                                        <i className='stepper-check fas fa-check'></i>
                                        <span className='stepper-number'>6</span>
                                    </div>
                                    <div className='stepper-label'>
                                        <h3 className='stepper-title'>Completed</h3>
                                        <div className='stepper-desc fw-semibold'>Woah, we are here</div>
                                    </div>
                                </div>
                            </div>
                            <div className='stepper-item' data-kt-stepper-element='nav'>
                                <div className='stepper-wrapper'>
                                    <div className='stepper-icon w-40px h-40px'>
                                        <i className='stepper-check fas fa-check'></i>
                                        <span className='stepper-number'>7</span>
                                    </div>
                                    <div className='stepper-label'>
                                        <h3 className='stepper-title'>Completed</h3>
                                        <div className='stepper-desc fw-semibold'>Woah, we are here</div>
                                    </div>
                                </div>
                            </div>
                            <div className='stepper-item' data-kt-stepper-element='nav'>
                                <div className='stepper-wrapper'>
                                    <div className='stepper-icon w-40px h-40px'>
                                        <i className='stepper-check fas fa-check'></i>
                                        <span className='stepper-number'>8</span>
                                    </div>
                                    <div className='stepper-label'>
                                        <h3 className='stepper-title'>Completed</h3>
                                        <div className='stepper-desc fw-semibold'>Woah, we are here</div>
                                    </div>
                                </div>
                            </div>
                            <div className='stepper-item' data-kt-stepper-element='nav'>
                                <div className='stepper-wrapper'>
                                    <div className='stepper-icon w-40px h-40px'>
                                        <i className='stepper-check fas fa-check'></i>
                                        <span className='stepper-number'>9</span>
                                    </div>
                                    <div className='stepper-label'>
                                        <h3 className='stepper-title'>Completed</h3>
                                        <div className='stepper-desc fw-semibold'>Woah, we are here</div>
                                    </div>
                                </div>
                            </div>
                            <div className='stepper-item' data-kt-stepper-element='nav'>
                                <div className='stepper-wrapper'>
                                    <div className='stepper-icon w-40px h-40px'>
                                        <i className='stepper-check fas fa-check'></i>
                                        <span className='stepper-number'>9</span>
                                    </div>
                                    <div className='stepper-label'>
                                        <h3 className='stepper-title'>Completed</h3>
                                        <div className='stepper-desc fw-semibold'>Woah, we are here</div>
                                    </div>
                                </div>
                            </div>
                            <div className='stepper-item' data-kt-stepper-element='nav'>
                                <div className='stepper-wrapper'>
                                    <div className='stepper-icon w-40px h-40px'>
                                        <i className='stepper-check fas fa-check'></i>
                                        <span className='stepper-number'>10</span>
                                    </div>
                                    <div className='stepper-label'>
                                        <h3 className='stepper-title'>Completed</h3>
                                        <div className='stepper-desc fw-semibold'>Woah, we are here</div>
                                    </div>
                                </div>
                            </div>
                            <div className='stepper-item' data-kt-stepper-element='nav'>
                                <div className='stepper-wrapper'>
                                    <div className='stepper-icon w-40px h-40px'>
                                        <i className='stepper-check fas fa-check'></i>
                                        <span className='stepper-number'>11</span>
                                    </div>
                                    <div className='stepper-label'>
                                        <h3 className='stepper-title'>Completed</h3>
                                        <div className='stepper-desc fw-semibold'>Woah, we are here</div>
                                    </div>
                                </div>
                            </div>
                            <div className='stepper-item' data-kt-stepper-element='nav'>
                                <div className='stepper-wrapper'>
                                    <div className='stepper-icon w-40px h-40px'>
                                        <i className='stepper-check fas fa-check'></i>
                                        <span className='stepper-number'>12</span>
                                    </div>
                                    <div className='stepper-label'>
                                        <h3 className='stepper-title'>Completed</h3>
                                        <div className='stepper-desc fw-semibold'>Woah, we are here</div>
                                    </div>
                                </div>
                            </div>
                            <div className='stepper-item' data-kt-stepper-element='nav'>
                                <div className='stepper-wrapper'>
                                    <div className='stepper-icon w-40px h-40px'>
                                        <i className='stepper-check fas fa-check'></i>
                                        <span className='stepper-number'>13</span>
                                    </div>
                                    <div className='stepper-label'>
                                        <h3 className='stepper-title'>Completed</h3>
                                        <div className='stepper-desc fw-semibold'>Woah, we are here</div>
                                    </div>
                                </div>
                            </div>
                            <div className='stepper-item' data-kt-stepper-element='nav'>
                                <div className='stepper-wrapper'>
                                    <div className='stepper-icon w-40px h-40px'>
                                        <i className='stepper-check fas fa-check'></i>
                                        <span className='stepper-number'>14</span>
                                    </div>
                                    <div className='stepper-label'>
                                        <h3 className='stepper-title'>Completed</h3>
                                        <div className='stepper-desc fw-semibold'>Woah, we are here</div>
                                    </div>
                                </div>
                            </div>
                            <div className='stepper-item' data-kt-stepper-element='nav'>
                                <div className='stepper-wrapper'>
                                    <div className='stepper-icon w-40px h-40px'>
                                        <i className='stepper-check fas fa-check'></i>
                                        <span className='stepper-number'>15</span>
                                    </div>
                                    <div className='stepper-label'>
                                        <h3 className='stepper-title'>Completed</h3>
                                        <div className='stepper-desc fw-semibold'>Woah, we are here</div>
                                    </div>
                                </div>
                            </div>
                            <div className='stepper-item' data-kt-stepper-element='nav'>
                                <div className='stepper-wrapper'>
                                    <div className='stepper-icon w-40px h-40px'>
                                        <i className='stepper-check fas fa-check'></i>
                                        <span className='stepper-number'>16</span>
                                    </div>
                                    <div className='stepper-label'>
                                        <h3 className='stepper-title'>Completed</h3>
                                        <div className='stepper-desc fw-semibold'>Woah, we are here</div>
                                    </div>
                                </div>
                            </div>
                            <div className='stepper-item' data-kt-stepper-element='nav'>
                                <div className='stepper-wrapper'>
                                    <div className='stepper-icon w-40px h-40px'>
                                        <i className='stepper-check fas fa-check'></i>
                                        <span className='stepper-number'>17</span>
                                    </div>
                                    <div className='stepper-label'>
                                        <h3 className='stepper-title'>Completed</h3>
                                        <div className='stepper-desc fw-semibold'>Woah, we are here</div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className='d-flex flex-row-fluid flex-center bg-body rounded'>
                    <Formik
                        validationSchema={currentSchema}
                        initialValues={initValues} onSubmit={submitStep}>
                        {(formikProps) => (
                            <Form className='py-20 w-100 w-xl-700px px-9' noValidate id='kt_create_account_form'>
                                <div className='current' data-kt-stepper-element='content'>
                                    <div className='w-100'>
                                        <div className='d-flex flex-column mb-7 fv-row'>
                                            <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                                                <span className='required'>Company Name</span>
                                            </label>
                                            <Field name='company_name' className='form-control form-control-lg' />
                                            <ErrorMessage name='company_name' component="div" className="text-danger" />
                                        </div>
                                    </div>
                                </div>

                                <div data-kt-stepper-element='content'>
                                    <div className='w-100'>
                                        <div className='d-flex flex-column mb-7 fv-row'>
                                            <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                                                <span className='required'>Type of Concern</span>
                                            </label>
                                            <div className="form-check form-check-custom form-check-solid mb-5 mt-5">
                                                <Field
                                                    type="radio"
                                                    name="company_type_concern"
                                                    value="Private Limited"
                                                    id="Private Limited"
                                                    className="form-check-input"
                                                />
                                                <label className="form-check-label fw-bolder text-dark-500 text-hover-primary fs-5" htmlFor="Private Limited">Private Limited</label>
                                            </div>
                                            <div className='form-check form-check-custom form-check-solid mb-5'>
                                                <Field
                                                    type="radio"
                                                    name="company_type_concern"
                                                    value="Proprietor"
                                                    id="Proprietor"
                                                    className="form-check-input"
                                                />
                                                <label className="form-check-label fw-bolder text-gray-500 text-hover-primary fs-5" htmlFor="Proprietor">Proprietor </label>
                                            </div>
                                            <div className='form-check form-check-custom form-check-solid mb-5'>
                                                <Field
                                                    type="radio"
                                                    name="company_type_concern"
                                                    value="Partner"
                                                    id="Partner"
                                                    className="form-check-input"
                                                />
                                                <label className="form-check-label fw-bolder text-gray-500 text-hover-primary fs-5" htmlFor="Partner">Partner </label>
                                            </div>
                                            <div className='form-check form-check-custom form-check-solid'>
                                                <Field
                                                    type="radio"
                                                    name="company_type_concern"
                                                    value="LLP"
                                                    id="LLP"
                                                    className="form-check-input"
                                                />
                                                <label className="form-check-label fw-bolder text-gray-500 text-hover-primary fs-5" htmlFor="LLP">LLP</label>
                                            </div>
                                            <ErrorMessage name='company_type_concern' component="div" className="text-danger" />
                                        </div>
                                    </div>
                                </div>

                                <div data-kt-stepper-element='content'>
                                    <div className='w-100'>
                                        <div className='d-flex flex-column mb-7 fv-row'>
                                            <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                                                <span className='required'>Date of Incorporation</span>
                                            </label>
                                            <Field type='date' name='date_incorporation' className='form-control form-control-lg' />
                                            <ErrorMessage name='date_incorporation' component="div" className="text-danger" />
                                        </div>
                                    </div>
                                </div>

                                <div data-kt-stepper-element='content'>
                                    <div className='w-100'>
                                        <div className='d-flex flex-column mb-7 fv-row'>
                                            <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                                                <span className='required'>Email ID</span>
                                            </label>
                                            <Field type='text' className='form-control' name='email_id' />
                                            <ErrorMessage name='email_id' component="div" className="text-danger" />
                                        </div>
                                    </div>
                                </div>

                                <div data-kt-stepper-element='content'>
                                    <div className='w-100'>
                                        <div className='d-flex flex-column mb-7 fv-row'>
                                            <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                                                <span className=''>Alternate Email ID</span>
                                            </label>
                                            <Field type='text' className='form-control' name='alternate_email_id' />
                                            <ErrorMessage name='alternate_email_id' component="div" className="text-danger" />
                                        </div>
                                    </div>
                                </div>

                                <div data-kt-stepper-element='content'>
                                    <div className='w-100'>
                                        <div className='d-flex flex-column mb-7 fv-row'>
                                            <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                                                <span className='required'>Phone Number</span>
                                            </label>
                                            <Field type='number' className='form-control' name='company_phone_number' />
                                            <ErrorMessage name='company_phone_number' component="div" className="text-danger" />
                                        </div>
                                    </div>
                                </div>

                                <div data-kt-stepper-element='content'>
                                    <div className='w-100'>
                                        <div className='d-flex flex-column mb-7 fv-row'>
                                            <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                                                <span className=''>Alternate Phone Number</span>
                                            </label>
                                            <Field type='number' className='form-control' name='company_alter_phone_number' />
                                            <ErrorMessage name='company_alter_phone_number' component="div" className="text-danger" />
                                        </div>
                                    </div>
                                </div>

                                <div data-kt-stepper-element='content'>
                                    <div className='w-100'>
                                        <div className='d-flex flex-column mb-7 fv-row'>
                                            <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                                                <span className='required'>Installation Address</span>
                                            </label>
                                            <Field as='textarea' className='form-control' name='company_installation_add' />
                                            <ErrorMessage name='company_installation_add' component="div" className="text-danger" />
                                        </div>

                                        <div className='d-flex flex-column mb-7 fv-row'>
                                            <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                                                <span className='required'>Country</span>
                                            </label>
                                            <Field type='text' className='form-control' name='company_installation_country' />
                                            <ErrorMessage name='company_installation_country' component="div" className="text-danger" />
                                        </div>

                                        <div className='fv-row mb-10'>
                                            <label className='form-label required'>State</label>
                                            <Field as='select' name='company_installation_state' className='form-select form-select-lg form-select-solid'  >
                                                <option value='Pondicherry'>Pondicherry</option>
                                                <option value='Tamil Nadu'>Tamil Nadu</option>
                                            </Field>
                                            <ErrorMessage name='company_installation_state' component="div" className="text-danger" />
                                        </div>

                                        <div className="form-check-solid mt-3 mb-3">
                                            <label className="form-check-label pe-3">Same as the billing address ?</label>
                                            <div className="form-check form-check-solid form-check-inline">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    id="inlineCheckbox1"
                                                    value="option1"
                                                    checked={selectedOption === 'option1'}
                                                    onChange={() => {
                                                        setSelectedOption('option1');
                                                        formikProps.setFieldValue('company_billing_add', formikProps.values.company_installation_add || '');
                                                        formikProps.setFieldValue('company_billing_country', formikProps.values.company_installation_country || '');
                                                        formikProps.setFieldValue('company_billing_state', formikProps.values.company_installation_state || '');
                                                    }}
                                                />
                                                <label className="form-check-label" htmlFor="inlineCheckbox1">
                                                    Yes
                                                </label>
                                            </div>

                                            <div className="form-check form-check-solid form-check-inline">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    id="inlineCheckbox2"
                                                    value="option2"
                                                    checked={selectedOption === 'option2'}
                                                    onChange={() => {
                                                        setSelectedOption('option2');
                                                        formikProps.setFieldValue('company_billing_add', '');
                                                        formikProps.setFieldValue('company_billing_country', '');
                                                        formikProps.setFieldValue('company_billing_state', '');
                                                    }}
                                                />
                                                <label className="form-check-label" htmlFor="inlineCheckbox2">
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div data-kt-stepper-element='content'>
                                    <div className='w-100'>
                                        <div className='d-flex flex-column mb-7 fv-row'>
                                            <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                                                <span className='required'>Billing Address</span>
                                            </label>
                                            <Field
                                                as='textarea'
                                                className='form-control'
                                                placeholder=''
                                                name='company_billing_add'
                                            />
                                            <ErrorMessage name='company_billing_add' component="div" className="text-danger" />
                                        </div>

                                        <div className='d-flex flex-column mb-7 fv-row'>
                                            <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                                                <span className='required'>Country</span>
                                            </label>
                                            <Field
                                                type='text'
                                                className='form-control'
                                                placeholder=''
                                                name='company_billing_country'
                                            />
                                            <ErrorMessage name='company_billing_country' component="div" className="text-danger" />
                                        </div>

                                        <div className='fv-row mb-10'>
                                            <label className='form-label required'>State</label>
                                            <Field
                                                as='select'
                                                name='company_billing_state'
                                                className='form-select form-select-lg form-select-solid'
                                            >
                                                <option value='Pondicherry'>Pondicherry</option>
                                                <option value='Tamil Nadu'>Tamil Nadu</option>
                                            </Field>
                                            <ErrorMessage name='company_billing_state' component="div" className="text-danger" />
                                        </div>
                                    </div>
                                </div>

                                <div data-kt-stepper-element='content'>
                                    <div className='w-100'>
                                        <div className='d-flex flex-column mb-7 fv-row'>
                                            <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                                                <span className=''>Company GST Number</span>
                                            </label>
                                            <Field
                                                type='text'
                                                className='form-control'
                                                placeholder=''
                                                name='company_gst'
                                                onChange={(e: any) => {
                                                    const uppercaseValue = e.target.value.toUpperCase();
                                                    formikProps.setFieldValue('company_gst', uppercaseValue);
                                                }}
                                            />
                                            <div className='text-danger mt-2'>
                                                <ErrorMessage name='company_gst' />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div data-kt-stepper-element='content'>
                                    <div className='w-100'>
                                        <label className='form-label required mb-3'>Please Select Address Proof</label>
                                        <div className="form-check form-check-custom form-check-solid mb-3">
                                            <Field
                                                type="radio"
                                                name="company_address_proof"
                                                value="EB Bill"
                                                id="EB Bill"
                                                className="form-check-input"
                                            />
                                            <label onClick={() => handleAddProofChange('EB Bill')} className="form-check-label fw-bolder text-gray-500 text-hover-primary fs-5" htmlFor="EB Bill">EB Bill</label>
                                        </div>
                                        <div className='form-check form-check-custom form-check-solid mt-3'>
                                            <Field
                                                type="radio"
                                                name="company_address_proof"
                                                value="GST"
                                                id="GST"
                                                className="form-check-input"
                                            />
                                            <label onClick={() => handleAddProofChange('GST')} className="form-check-label fw-bolder text-gray-500 text-hover-primary fs-5" htmlFor="GST">GST</label>
                                        </div>
                                        <div className='form-check form-check-custom form-check-solid mt-3'>
                                            <Field
                                                type="radio"
                                                name="company_address_proof"
                                                value="Others"
                                                id="Others"
                                                className="form-check-input"
                                            />
                                            <label onClick={() => handleAddProofChange('Others')} className="form-check-label fw-bolder text-gray-500 text-hover-primary fs-5" htmlFor="Others">Others</label>
                                        </div>
                                        <div className='text-danger mt-2'>
                                            <ErrorMessage name='company_address_proof' />
                                        </div>
                                    </div>
                                </div>

                                <div data-kt-stepper-element='content'>
                                    <div className='d-none d-lg-block'>
                                        <div style={{ position: 'relative' }}>
                                            <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                                                <span className='required'>{selectedAddProof} Front Page</span>
                                            </label>
                                            {addProof_Front ? (
                                                <img src={addProof_Front} alt="Captured" />
                                            ) : (
                                                <>
                                                    <Webcam
                                                        audio={false}
                                                        ref={AddressPhoto}
                                                        screenshotFormat="image/jpeg"
                                                        width='50%'
                                                        style={{ borderRadius: '15px' }}
                                                    />
                                                </>
                                            )}
                                            <div className='capture-buttons' style={{ position: 'absolute', bottom: '10px', left: '145px', cursor: 'pointer' }}>
                                                {addProof_Front ? (
                                                    <img src={toAbsoluteUrl('/media/logos/reset.png')} onClick={retakeAddImage} className='w-40px' />
                                                ) : (
                                                    <img src={toAbsoluteUrl('/media/logos/camera.png')} onClick={captureAddImage} className='w-40px' />
                                                )}
                                            </div>
                                        </div>
                                        {/* {address_proof_img && <div className="text-danger mt-2">Please Capture a {selectedAddProof} Address Photo.</div>} */}
                                        <h5 className='d-flex align-items-center mt-3' onClick={handleFrontImage}> <KTIcon iconName='question-2' className='fs-1 text-dark me-1' /> How to capture image</h5>
                                    </div>

                                    <div className='d-sm-none d-block w-100'>
                                        <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                                            <span className='required'>{selectedAddProof} Front Page</span>
                                        </label>
                                        <input type="file" accept="image/*" capture={"camera" as boolean | "environment" | undefined}
                                            onChange={handleAddProofFront}
                                            style={{ display: 'none' }}
                                            name="Add_FrontImage"
                                        />
                                        <div className='camera_image_capture' onClick={openFileInputAddFront}>
                                            <div>
                                                <img src={toAbsoluteUrl('/media/logos/camera_capture.png')} alt="Open Camera" className='camera_image' />
                                                <h5 className='text-center'>Click to capture</h5>
                                            </div>
                                        </div>
                                        {addProof_Front && (
                                            <div className='mt-5'>
                                                <img src={addProof_Front} alt="Captured" style={{ maxWidth: '100%' }} />
                                            </div>
                                        )}
                                        {/* {address_proof_img && <div className="text-danger mt-2">Please Capture a {selectedAddProof} Address Photo.</div>} */}
                                        <h5 className='d-flex align-items-center mt-3' onClick={handleFrontImage}> <KTIcon iconName='question-2' className='fs-1 text-dark me-1' /> How to capture image</h5>
                                    </div>
                                </div>

                                <div data-kt-stepper-element='content'>
                                    <div className='d-none d-lg-block'>
                                        {/* <div className='d-flex'>
                                            <div> */}
                                                <div style={{ position: 'relative' }}>
                                                    <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                                                        <span className='required'>{selectedAddProof} Back Page</span>
                                                    </label>
                                                    {addProof_Back ? (
                                                        <img src={addProof_Back} alt="Captured" />
                                                    ) : (
                                                        <>
                                                            <Webcam
                                                                audio={false}
                                                                ref={AddressBackPhoto}
                                                                screenshotFormat="image/jpeg"
                                                                width='50%'
                                                                style={{ borderRadius: '15px' }}
                                                            />
                                                        </>
                                                    )}
                                                    <div className='capture-buttons' style={{ position: 'absolute', bottom: '10px', left: '145px', cursor: 'pointer' }}>
                                                        {addProof_Back ? (
                                                            <img src={toAbsoluteUrl('/media/logos/reset.png')} onClick={retakeAddBackImage} className='w-40px' />
                                                        ) : (
                                                            <img src={toAbsoluteUrl('/media/logos/camera.png')} onClick={captureAddImageBack} className='w-40px' />
                                                        )}
                                                    </div>
                                                </div>
                                                {/* {address_proofBack_img && <div className="text-danger mt-2">Please Capture a {selectedAddProof} Back Page.</div>} */}
                                                <h5 className='d-flex align-items-center mt-3' onClick={handleBackImage}> <KTIcon iconName='question-2' className='fs-1 text-dark me-1' /> How to capture image</h5>
                                            {/* </div>
                                            <div className='ms-3'>
                                                <div style={{ position: 'relative' }}>
                                                    <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                                                        <span className='required'>{selectedAddProof} Third Page</span>
                                                    </label>
                                                    {addProof_third ? (
                                                        <img src={addProof_third} alt="Captured" />
                                                    ) : (
                                                        <>
                                                            <Webcam
                                                                audio={false}
                                                                ref={AddressThirdPhoto}
                                                                screenshotFormat="image/jpeg"
                                                                width='100%'
                                                                style={{ borderRadius: '15px' }}
                                                            />
                                                        </>
                                                    )}
                                                    <div className='capture-buttons' style={{ position: 'absolute', bottom: '10px', left: '145px', cursor: 'pointer' }}>
                                                        {addProof_third ? (
                                                            <img src={toAbsoluteUrl('/media/logos/reset.png')} onClick={retakeAddThirdImage} className='w-40px' />
                                                        ) : (
                                                            <img src={toAbsoluteUrl('/media/logos/camera.png')} onClick={captureAddImageThird} className='w-40px' />
                                                        )}
                                                    </div>
                                                </div>
                                                <h5 className='d-flex align-items-center mt-3' onClick={handleBackImage}> <KTIcon iconName='question-2' className='fs-1 text-dark me-1' /> How to capture image</h5>
                                            </div>
                                        </div> */}
                                    </div>

                                    <div className='d-sm-none d-block w-100'>
                                        <div>
                                            <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                                                <span className='required'>{selectedAddProof} Back Page</span>
                                            </label>
                                            <input type="file" accept="image/*"
                                                capture={"camera" as boolean | "environment" | undefined}
                                                onChange={handleAddProofBack}
                                                style={{ display: 'none' }}
                                                name="Add_BackImage"
                                            />
                                            <div className='camera_image_capture' onClick={openFileInputAddBack}>
                                                <div>
                                                    <img src={toAbsoluteUrl('/media/logos/camera_capture.png')} alt="Open Camera" className='camera_image' />
                                                    <h5 className='text-center'>Click to capture</h5>
                                                </div>
                                            </div>
                                            {addProof_Back && (
                                                <div className='mt-5'>
                                                    <img src={addProof_Back} alt="Captured" style={{ maxWidth: '100%' }} />
                                                </div>
                                            )}
                                            {/* {address_proofBack_img && <div className="text-danger mt-2">Please Capture a {selectedAddProof} Back Page.</div>} */}
                                            <h5 className='d-flex align-items-center mt-3' onClick={handleBackImage}> <KTIcon iconName='question-2' className='fs-1 text-dark me-1' /> How to capture image</h5>
                                        </div>
                                        {/* <div className='mt-5'>
                                            <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                                                <span className='required'>{selectedAddProof} Third Page</span>
                                            </label>
                                            <input type="file" accept="image/*"
                                                capture={"camera" as boolean | "environment" | undefined}
                                                onChange={handleAddProofThird}
                                                style={{ display: 'none' }}
                                                name="Add_ThirdImage"
                                            />
                                            <div className='camera_image_capture' onClick={openFileInputAddThird}>
                                                <div>
                                                    <img src={toAbsoluteUrl('/media/logos/camera_capture.png')} alt="Open Camera" className='camera_image' />
                                                    <h5 className='text-center'>Click to capture</h5>
                                                </div>
                                            </div>
                                            {addProof_third && (
                                                <div className='mt-5'>
                                                    <img src={addProof_third} alt="Captured" style={{ maxWidth: '100%' }} />
                                                </div>
                                            )}
                                            <h5 className='d-flex align-items-center mt-3' onClick={handleBackImage}> <KTIcon iconName='question-2' className='fs-1 text-dark me-1' /> How to capture image</h5>
                                        </div> */}
                                    </div>
                                </div>

                                <div data-kt-stepper-element='content'>
                                    <div className='w-100'>
                                        <div className='mb-0 fv-row'>
                                            <label className='d-flex align-items-center form-label mb-5'>
                                                Please Select Id Proof
                                                <i
                                                    className='fas fa-exclamation-circle ms-2 fs-7'
                                                    data-bs-toggle='tooltip'
                                                    title='Monthly billing will be based on your account plan'
                                                ></i>
                                            </label>

                                            <div className='mb-0'>
                                                <label className='d-flex flex-stack mb-5 cursor-pointer' onClick={() => handleIdProofChange('Aadhar Card')}>
                                                    <span className='d-flex align-items-center me-2'>
                                                        <span className='symbol symbol-50px me-6'>
                                                            <span className='symbol-label'>
                                                                <img src={toAbsoluteUrl('/media/custom/aathar_card.png')} className='w-50px' />
                                                            </span>
                                                        </span>

                                                        <span className='d-flex flex-column'>
                                                            <span className='fw-bolder text-gray-800 text-hover-primary fs-5'>
                                                                Aadhar Card
                                                            </span>
                                                        </span>
                                                    </span>

                                                    <span className='form-check form-check-custom form-check-solid'>
                                                        <Field className='form-check-input' type='radio' name='company_id_proof' value='Aadhar Card' />
                                                    </span>
                                                </label>

                                                <label className='d-flex flex-stack mb-5 cursor-pointer' onClick={() => handleIdProofChange('Voter ID')}>
                                                    <span className='d-flex align-items-center me-2'>
                                                        <span className='symbol symbol-50px me-6'>
                                                            <span className='symbol-label'>
                                                                <img src={toAbsoluteUrl('/media/custom/voterid1.png')} className='w-50px' />
                                                            </span>
                                                        </span>

                                                        <span className='d-flex flex-column'>
                                                            <span className='fw-bolder text-gray-800 text-hover-primary fs-5'>
                                                                Voter ID
                                                            </span>
                                                        </span>
                                                    </span>

                                                    <span className='form-check form-check-custom form-check-solid'>
                                                        <Field className='form-check-input' type='radio' name='company_id_proof' value='Voter ID' />
                                                    </span>
                                                </label>

                                                <label className='d-flex flex-stack mb-0 cursor-pointer' onClick={() => handleIdProofChange('Passport')}>
                                                    <span className='d-flex align-items-center me-2'>
                                                        <span className='symbol symbol-50px me-6'>
                                                            <span className='symbol-label'>
                                                                <img src={toAbsoluteUrl('/media/custom/passport.png')} className='w-50px' />
                                                            </span>
                                                        </span>

                                                        <span className='d-flex flex-column'>
                                                            <span className='fw-bolder text-gray-800 text-hover-primary fs-5'>
                                                                Passport
                                                            </span>
                                                        </span>
                                                    </span>

                                                    <span className='form-check form-check-custom form-check-solid'>
                                                        <Field className='form-check-input' type='radio' name='company_id_proof' value='Passport' />
                                                    </span>
                                                </label>
                                            </div>
                                        </div>
                                        <div className='text-danger mt-2'>
                                            <ErrorMessage name='company_id_proof' />
                                        </div>
                                    </div>
                                </div>

                                <div data-kt-stepper-element='content'>
                                    <div className='d-none d-lg-block'>
                                        <div style={{ position: 'relative' }}>
                                            <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                                                <span className='required'>{selectedIdProof} Front Page</span>
                                            </label>
                                            {idProof_Front ? (
                                                <img src={idProof_Front} alt="Captured" />
                                            ) : (
                                                <>
                                                    <Webcam
                                                        audio={false}
                                                        ref={IdFrontPhoto}
                                                        screenshotFormat="image/jpeg"
                                                        width='50%'
                                                        style={{ borderRadius: '15px' }}
                                                    />
                                                </>
                                            )}
                                            <div className='capture-buttons' style={{ position: 'absolute', bottom: '10px', left: '145px', cursor: 'pointer' }}>
                                                {idProof_Front ? (
                                                    <img src={toAbsoluteUrl('/media/logos/reset.png')} onClick={retakeFrontImage} className='w-40px' />
                                                ) : (
                                                    <img src={toAbsoluteUrl('/media/logos/camera.png')} onClick={captureFrontImage} className='w-40px' />
                                                )}
                                            </div>
                                        </div>
                                        {/* {frontImageError && <div className="text-danger mt-2">Please Capture a {selectedIdProof} Front Page Photo.</div>} */}
                                        <h5 className='d-flex align-items-center mt-3' onClick={handleFrontImage}> <KTIcon iconName='question-2' className='fs-1 text-dark me-1' /> How to capture image</h5>
                                    </div>

                                    <div className='d-sm-none d-block w-100'>
                                        <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                                            <span className='required'>{selectedIdProof} Front Page</span>
                                        </label>
                                        <input type="file" accept="image/*" capture={"camera" as boolean | "environment" | undefined}
                                            onChange={handleIdProofFront}
                                            style={{ display: 'none' }}
                                            name="idFrontImage"
                                        />
                                        <div className='camera_image_capture' onClick={openFileInput}>
                                            <div>
                                                <img src={toAbsoluteUrl('/media/logos/camera_capture.png')} alt="Open Camera" className='camera_image' />
                                                <h5 className='text-center'>Click to capture</h5>
                                            </div>
                                        </div>
                                        {idProof_Front && (
                                            <div className='mt-5'>
                                                <img src={idProof_Front} alt="Captured" style={{ maxWidth: '100%' }} />
                                            </div>
                                        )}
                                        {/* {frontImageError && <div className="text-danger mt-2">Please Capture a {selectedIdProof} Front Page Photo.</div>} */}
                                        <h5 className='d-flex align-items-center mt-3' onClick={handleFrontImage}> <KTIcon iconName='question-2' className='fs-1 text-dark me-1' /> How to capture image</h5>
                                    </div>
                                </div>

                                <div data-kt-stepper-element='content'>
                                    <div className='d-none d-lg-block'>
                                        <div style={{ position: 'relative' }}>
                                            <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                                                <span className='required'>{selectedIdProof} Back Page</span>
                                            </label>
                                            {idProof_Back ? (
                                                <img src={idProof_Back} alt="Captured" />
                                            ) : (
                                                <>
                                                    <Webcam
                                                        audio={false}
                                                        ref={IdBackPhoto}
                                                        screenshotFormat="image/jpeg"
                                                        width='50%'
                                                        style={{ borderRadius: '15px' }}
                                                    />
                                                </>
                                            )}
                                            <div className='capture-buttons' style={{ position: 'absolute', bottom: '10px', left: '145px', cursor: 'pointer' }}>
                                                {idProof_Back ? (
                                                    <img src={toAbsoluteUrl('/media/logos/reset.png')} onClick={retakeBackImage} className='w-40px' />
                                                ) : (
                                                    <img src={toAbsoluteUrl('/media/logos/camera.png')} onClick={captureBackImage} className='w-40px' />
                                                )}
                                            </div>
                                        </div>
                                        {/* {backImageError && <div className="text-danger mt-2">Please Capture a {selectedIdProof} Back Page Photo.</div>} */}
                                        <h5 className='d-flex align-items-center mt-3' onClick={handleBackImage}> <KTIcon iconName='question-2' className='fs-1 text-dark me-1' /> How to capture image</h5>
                                    </div>

                                    <div className='d-sm-none d-block w-100'>
                                        <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                                            <span className='required'>{selectedIdProof} Back Page</span>
                                        </label>
                                        <input type="file" accept="image/*" capture={"camera" as boolean | "environment" | undefined}
                                            onChange={handleIdProofBack}
                                            style={{ display: 'none' }}
                                            name="idBackImage"
                                        />
                                        <div className='camera_image_capture' onClick={openFileInputBack}>
                                            <div>
                                                <img src={toAbsoluteUrl('/media/logos/camera_capture.png')} alt="Open Camera" className='camera_image' />
                                                <h5 className='text-center'>Click to capture</h5>
                                            </div>
                                        </div>
                                        {idProof_Back && (
                                            <div className='mt-5'>
                                                <img src={idProof_Back} alt="Captured" style={{ maxWidth: '100%' }} />
                                            </div>
                                        )}
                                        {/* {backImageError && <div className="text-danger mt-2">Please Capture a {selectedIdProof} Back Page Photo.</div>} */}
                                        <h5 className='d-flex align-items-center mt-3' onClick={handleBackImage}> <KTIcon iconName='question-2' className='fs-1 text-dark me-1' /> How to capture image</h5>
                                    </div>
                                </div>

                                <div data-kt-stepper-element='content'>
                                    <div className='d-none d-lg-block'>
                                        <div style={{ position: 'relative' }}>
                                            <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                                                <span className='required'>Your Photo</span>
                                            </label>
                                            {customer_photo ? (
                                                <img src={customer_photo} alt="Captured" />
                                            ) : (
                                                <>
                                                    <Webcam
                                                        audio={false}
                                                        ref={CustomerPhoto}
                                                        screenshotFormat="image/jpeg"
                                                        width='50%'
                                                        style={{ borderRadius: '15px' }}
                                                    />
                                                </>
                                            )}
                                            <div className='capture-buttons' style={{ position: 'absolute', bottom: '10px', left: '135px', cursor: 'pointer' }}>
                                                {customer_photo ? (
                                                    <img src={toAbsoluteUrl('/media/logos/reset.png')} onClick={retake} className='w-40px' />
                                                ) : (
                                                    <img src={toAbsoluteUrl('/media/logos/camera.png')} onClick={capturePhoto} className='w-40px' />
                                                )}
                                            </div>
                                        </div>
                                        {showWarning && <div className="text-danger mt-2">Please capture a photo.</div>}
                                        <h5 className='d-flex align-items-center mt-3' onClick={handleCustomer}> <KTIcon iconName='question-2' className='fs-1 text-dark me-1' /> How to capture image</h5>
                                    </div>

                                    <div className='d-sm-none d-block w-100'>
                                        <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                                            <span className='required'>Your Photo</span>
                                        </label>
                                        <input type="file" accept="image/*" capture={"camera" as boolean | "environment" | undefined}
                                            onChange={handleCustomerImage}
                                            style={{ display: 'none' }}
                                            name="CustomerImage"
                                        />
                                        <div className='camera_image_capture' onClick={openFileInputCustomer}>
                                            <div>
                                                <img src={toAbsoluteUrl('/media/logos/camera_capture.png')} alt="Open Camera" className='camera_image' />
                                                <h5 className='text-center'>Click to capture</h5>
                                            </div>
                                        </div>
                                        {customer_photo && (
                                            <div className='mt-5'>
                                                <img src={customer_photo} alt="Captured" style={{ maxWidth: '100%' }} />
                                            </div>
                                        )}
                                        {showWarning && <div className="text-danger mt-2">Please capture a photo.</div>}
                                        <h5 className='d-flex align-items-center mt-3' onClick={handleCustomer}> <KTIcon iconName='question-2' className='fs-1 text-dark me-1' /> How to capture image</h5>
                                    </div>
                                </div>

                                <div data-kt-stepper-element='content'>
                                    <div className='w-100'>
                                        <div className='d-flex flex-column mb-7 fv-row'>
                                            <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                                                <span className='required'>Authorised Signatory</span>
                                            </label>
                                            <div style={{ border: '2px solid black', borderRadius: '5px', padding: '5px', }}>
                                                <SignatureCanvas
                                                    ref={sigCanvas}
                                                    penColor="blue"
                                                    canvasProps={{ width: canvasWidth, height: 200, className: 'sigCanvas' }}
                                                />
                                            </div>
                                            <span className='btn btn-light-danger mt-5' onClick={clearCanvas}>Clear Signature</span>
                                        </div>
                                        {/* {showSignatureWarning && <div className="text-danger mt-2">Please provide a signature.</div>} */}
                                        <div><Declaration /></div>
                                        <div className="form-check form-check-solid mt-3 mb-3">
                                            <Field
                                                type="checkbox"
                                                name="terms_condition"
                                                value="terms_condition"
                                                className="form-check-input"
                                                id="terms_condition"
                                                onClick={openPrivacymodal}
                                            />
                                            <label className="form-check-label text-dark" htmlFor="terms_condition">I Accept Terms & Conditions</label>
                                        </div>
                                        {privacyWarning && <div className="text-danger mt-2">Please accept  terms condition.</div>}
                                    </div>
                                </div>

                                <div className='d-flex flex-stack pt-10'>
                                    <div className='mr-2'>
                                        <button
                                            onClick={prevStep}
                                            type='button'
                                            className='btn btn-lg btn-light-primary me-3'
                                            data-kt-stepper-action='previous'
                                        >
                                            <KTIcon iconName='arrow-left' className='fs-4 me-1' />
                                            Back
                                        </button>
                                    </div>
                                    <div>
                                        <button type="submit" className="btn btn-lg btn-primary me-3">
                                            <span className='indicator-label'>
                                                {loading && (
                                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                                        Please wait...{' '}
                                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                    </span>
                                                )}
                                                {!loading && stepper.current?.currentStepIndex == null && 'Continue'}
                                                {!loading && stepper.current?.currentStepIndex !== stepper.current?.totalStepsNumber && 'Continue'}
                                                {!loading && stepper.current?.currentStepIndex === stepper.current?.totalStepsNumber && stepper.current?.currentStepIndex != null && 'Submit'}
                                                {!loading && <KTIcon iconName='arrow-right' className='fs-3 ms-2 me-0' />}
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>

            <div className={`modal${showModalPrivacy ? ' show' : ''}`} role="dialog" style={{ display: showModalPrivacy ? 'block' : 'none' }}>
                <div className="modal-dialog modal-lg modal-dialog-centered" role="document" >
                    <div className="modal-content" >
                        <div className="modal-header">
                            <h5 className="modal-title">Terms & Conditions</h5>
                            <button type="button" className="btn-close" onClick={() => setShowModalPrivacy(false)} aria-label="Close"></button>
                        </div>
                        <div className="modal-body" style={{ overflowY: 'auto', maxHeight: '450px' }}>
                            <Privacy_Policy />
                        </div>
                        <div className="modal-footer">
                            <div className='card-footer d-flex justify-content-end py-6 px-9'>
                                <button type='submit' className='btn btn-primary' disabled={loading} onClick={() => setShowModalPrivacy(false)}>
                                    {!loading && 'Ok'}
                                    {loading && (
                                        <span className='indicator-progress' style={{ display: 'block' }}>
                                            Please wait...{' '}
                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                        </span>
                                    )}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`modal${showModal ? ' show' : ''}`} role="dialog" style={{ display: showModal ? 'block' : 'none' }}>
                <div className="modal-dialog  modal-dialog-centered" role="document" >
                    <div className="modal-content" >
                        <div className="modal-header">
                            <button type="button" className="btn-close" onClick={() => setShowModal(false)} aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="d-flex justify-content-center">
                                <div>
                                    <img src={toAbsoluteUrl('/media/stock/Guideline_image.png')} />
                                </div>
                            </div>
                            <h3>Instructions :</h3>
                            <ul className='fs-4'>
                                <li>Click to capture the front page of
                                    <span className='ms-1'>{!selectedIdProof ? selectedAddProof : selectedIdProof}.</span>
                                </li>
                                <li>Validate and submit the information.</li>
                                <li>Confirm the validated capture.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`modal${backImage ? ' show' : ''}`} role="dialog" style={{ display: backImage ? 'block' : 'none' }}>
                <div className="modal-dialog  modal-dialog-centered" role="document" >
                    <div className="modal-content" >
                        <div className="modal-header">
                            <button type="button" className="btn-close" onClick={() => setBackImage(false)} aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="d-flex justify-content-center">
                                <div>
                                    <img src={toAbsoluteUrl('/media/stock/Guideline_image.png')} />
                                </div>
                            </div>
                            <h3>Instructions :</h3>
                            <ul className='fs-4'>
                                <li>Click to capture the back page of
                                    <span className='ms-1'>{!selectedIdProof ? selectedAddProof : selectedIdProof}.</span>
                                </li>
                                <li>Validate and submit the information.</li>
                                <li>Confirm the validated capture.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`modal${custImage ? ' show' : ''}`} role="dialog" style={{ display: custImage ? 'block' : 'none' }}>
                <div className="modal-dialog  modal-dialog-centered" role="document" >
                    <div className="modal-content" >
                        <div className="modal-header">
                            <button type="button" className="btn-close" onClick={() => setCustImage(false)} aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="d-flex justify-content-center">
                                <div>
                                    <img src={toAbsoluteUrl('/media/stock/Guideline_image(2).png')} />
                                </div>
                            </div>
                            <h3>Instructions :</h3>
                            <ul className='fs-4'>
                                <li>Validate and submit the information.</li>
                                <li>Confirm the validated capture.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export { CompanyForm }