import React from 'react';
import { Template } from '../core/_models';
import { toAbsoluteUrl } from '../../../../_metronic/helpers'

interface FrontPageFormProps {
  apptemplate: Template[];
}

const ApplicationFooter: React.FC<FrontPageFormProps> = ({ apptemplate }) => {
  return (
    <>
      {apptemplate.map((template, index) => (
        <div key={index}>
          <img src={toAbsoluteUrl(template.template_header_logo2)} className='w-100' />
        </div>
      ))}
    </>
  );
};

export default ApplicationFooter;
