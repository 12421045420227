import { lazy, FC, Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import Dashboard from '../pages/dashboard/Dashboard'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import { Courses } from "../pages/UserCourses"
import Profile from '../pages/Profile'
import AccountCreation from '../pages/AccountCreation'
import Template from '../pages/Template'
import ApplicationDetails from '../pages/ApplicationDetails'
import TermsCondition from '../pages/TermsCondition'
const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const ProductsPage = lazy(() => import('../modules/products/ProductsPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))

  const UserDetails: any = localStorage.getItem('user-details')
  const userDetailsObject = JSON.parse(UserDetails);
  const userRole = userDetailsObject.role;

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path='admin/auth/*' element={<Navigate to='/dashboard' />} />
        <Route path='dashboard' element={<Dashboard />} />
        <Route path="/application-details" element={<ApplicationDetails />} />
        {userRole == '1' || userRole == '3' ? (
          <>
            <Route path="/template" element={<Template />} />
            <Route path="/terms-condition" element={<TermsCondition />} />
            <Route path="/account-creation" element={<AccountCreation />} />
          </>
        ) : null}
        <Route path="/application-status/:app_status" element={<ApplicationDetails />} />
        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='e-commerce/*'
          element={
            <SuspensedView>
              <ProductsPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/pages/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/pages/*'
          element={
            <SuspensedView>
              <Profile />
            </SuspensedView>
          }
        />
        <Route
          path='apps/Courses/*'
          element={
            <SuspensedView>
              <Courses />
            </SuspensedView>
          }
        />

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-success')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
