import React, { useState, useEffect } from 'react';
import { KTIcon, toAbsoluteUrl } from '../../_metronic/helpers'
import { Horizontal } from '../modules/wizards/components/Horizontal';
import { Template_GET, Template_ACTIVE } from '../modules/auth/core/_requests';
import Pagination from './Pagination';
import Swal from 'sweetalert2';

interface TemplateDetails {
  template_id: string;
  template_header: string;
  template_header_logo1: string;
  template_header_logo2: string;
  template_header_content: string;
  template_footer: string;
  template_footer_content_1: string;
  template_footer_content_2: string;
  template_name: string;
  template_status: string;
  template_created_on: string;
}

const Template = () => {
  const [showCard, setShowCard] = useState(true);
  const [showTabs, setShowTabs] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [templateData, setTemplateData] = useState<TemplateDetails[]>([])
  const [currentPage, setCurrentPage] = useState(1);

  const UserDetails: any = localStorage.getItem('user-details')
  const userDetailsObject = JSON.parse(UserDetails);
  const userId = userDetailsObject.id;

  const handleAddTemplate = () => {
    setShowCard(false);
    setShowTabs(true);
  };

  const handleBackButtonClick = () => {
    setShowCard(true); // Simulate going back by showing the card again
    setShowTabs(false); // Hide the tabs again
  };

  const fetchUserData = async (all_templates: any, userId: any) => {
    try {
      const response = await Template_GET(all_templates, userId);
      setTemplateData(response.data.template_details);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching members:', error);
    }
  };

  useEffect(() => {
    fetchUserData('0', userId);
  }, []);

  const handleTemplateActive = async (template_id_upd: any, template_user_id_upd: any) => {
    // Show confirmation popup
    const confirmResult = await Swal.fire({
      title: 'Confirmation',
      text: 'Are you sure you want to activate/deactivate this template?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes, proceed',
      cancelButtonText: 'No, cancel',
    });

    // If the user confirms
    if (confirmResult.isConfirmed) {
      try {
        const response = await Template_ACTIVE(template_id_upd, template_user_id_upd);
        fetchUserData('0', userId);
      } catch (error) {
        console.error('Error fetching members:', error);
      }
    }
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

// Ensure templateData is defined and is an array

  const itemsPerPage = 10;
  const totalItems = templateData.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const templateDetails = templateData.slice(startIndex, startIndex + itemsPerPage);

  return (
    <>
      {showCard && (
        <>
          <div className='card'>
            <div className='card-header border-0'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bold fs-3 mb-1'>Template</span>
              </h3>
              <div className='card-toolbar'>
                <button className='btn btn-primary' onClick={handleAddTemplate}>
                  <KTIcon iconName='plus' className='fs-2' />
                  Create Template
                </button>
              </div>
            </div>
          </div>
          {isLoading ? (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '200px' }}>
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (templateDetails.length > 0 ? (
            <div className='table-responsive bg-white p-5'>
              <table className='table align-middle gs-0 gy-4'>
                <thead>
                  <tr className='fw-bold fs-6 bg-light-primary'>
                    <th className='ps-5'>S.No</th>
                    <th>Template title</th>
                    <th>Created On</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {templateDetails.map((template, index) => (
                    <tr key={template.template_id}>
                      <td className='text-dark fw-bold text-hover-primary fs-6 ps-5'>{startIndex + index + 1}</td>
                      <td className='text-dark fw-bold text-hover-primary fs-6'>{template.template_name}</td>
                      {/* <td className='text-dark fw-bold text-hover-primary fs-6'>
                        <div className='d-flex justify-content-start flex-column'>
                          <span className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                            Header : {template.template_header}
                          </span>
                          <span className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                            Footer :{template.template_footer}
                          </span>
                        </div>
                      </td> */}
                      <td className='text-dark fw-bold text-hover-primary fs-6'>{template.template_created_on}</td>
                      <td className='text-dark fw-bold text-hover-primary fs-6'>{template.template_status == '1' ? (
                        <span className='badge bg-success text-white'>Active</span>
                      ) : <span className='badge bg-danger text-white'>Deactive</span>}
                      </td>
                      <td>
                        <div className="form-check form-switch">
                          <input
                            readOnly
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckChecked"
                            checked={template.template_status == '1'}
                            onClick={() => handleTemplateActive(template.template_id, userId)}
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                handlePageChange={handlePageChange}
              />
            </div>
          ) :
            <div className="d-flex justify-content-center p-10">
              <div>
                <img src={toAbsoluteUrl('/media/stock/coming_soon.png')} className='w-350px' />
                <h3 className="text-center">No template found</h3>
              </div>
            </div>
          )}
        </>
      )}

      {showTabs && (
        <>
          <div className='card p-10'>
            <h5><i className="bi bi-arrow-left fs-3 text-dark pe-3 cursor-pointer" onClick={handleBackButtonClick}></i> Select Template</h5>
            <Horizontal setShowCard={setShowCard} setShowTabs={setShowTabs} fetchUserData={fetchUserData}/>
          </div>
        </>
      )}
    </>
  )
}

export default Template