import React, { useState, useEffect } from 'react';
import { ChartsWidget6, ListsWidget5 } from '../../../_metronic/partials/widgets'
import { KTIcon } from '../../../_metronic/helpers'
import { Dashboard_stats, Dashboard_vendor_stats, GET_ALL_USERS } from '../../modules/auth/core/_requests'
import { Link } from 'react-router-dom'
import { AccountDetails } from '../../modules/auth';
import Select from 'react-select';

interface ApplicationStats {
  day: string;
  total_application: string;
}

interface ActivityLog {
  app_log_id: string;
  log_message: string;
  log_app_id: string;
  log_by: string;
  log_submitted_on: string;
}

interface ApplicationStatistics {
  total_application: string;
  pending_application: string;
  completed_application: string;
  progress_application: string;
  cancelled_application: string;
  application_stats: ApplicationStats[];
  activity_log?: ActivityLog[];
}

const Dashboard = () => {
  const [stats, setStats] = useState<ApplicationStatistics>();
  const [userData, setUserData] = useState<AccountDetails[]>([]);
  const [loading, setLoading] = useState(false)

  const UserDetails: any = localStorage.getItem('user-details')
  const userDetailsObject = JSON.parse(UserDetails);
  const userId = userDetailsObject.id;
  const userRole = userDetailsObject.role

  const filteredUsers = userData.filter(
    (user) => user.user_role == '1' || user.user_role == '3'
  );

  // Create options array
  const options = filteredUsers.map((user) => ({
    value: user.user_id,
    label: user.user_company_name,
  }));

  const handleChange = async (selectedOption: any) => {
    setLoading(true)
    try {
      const response = await Dashboard_vendor_stats(selectedOption.value);
      setStats(response.data);
    } catch (error) {
      console.error('Error fetching members:', error);
    } finally {
      setLoading(false)
    }
  };

  const fetchStatsData = async (userId: any, userRole: any) => {
    try {
      const response = await Dashboard_stats(userId, userRole);
      setStats(response.data);
    } catch (error) {
      console.error('Error fetching members:', error);
    }
  };

  useEffect(() => {
    fetchStatsData(userId, userRole);
  }, []);

  const fetchUserData = async (all_users: any) => {
    try {
      const response = await GET_ALL_USERS(all_users);
      setUserData(response.data.account_details);
    } catch (error) {
      console.error('Error fetching members:', error);
    }
  };

  useEffect(() => {
    fetchUserData("0");
  }, []);

  const LoadingSpinner = () => (
    <div className="d-flex justify-content-center align-items-center" style={{ height: '200px' }}>
      <div className="spinner-border text-primary" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );

  return (
    <>
      <div className='card'>
        <div className='card-header border-0'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Dashboard</span>
          </h3>
          {userRole == 1 ? (
            <>
              <div className='card-toolbar'>
                <Select
                  id="dropdown"
                  options={options}
                  onChange={handleChange}
                  placeholder="Search or select..."
                  isSearchable
                />
              </div>
            </>
          ) : ('')}
        </div>
      </div>

      {loading ? (
        <LoadingSpinner />
      ) : (

        <div className='row g-5 g-xl-8  mt-3'>
          <div className='col-xl-3'>
            <Link to={`/application-details`}>
              <span className={`card total_customers hoverable card-xl-stretch mb-5 mb-xl-8`}>
                <div className='card-body'>
                  <KTIcon iconName='people' className={`text-white fs-3x ms-n1`} />
                  <div className={`text-white fw-bold fs-2 mb-2 mt-5`}>Total Application</div>
                  <div className={`fw-semibold text-white fs-5`}>{stats?.total_application}</div>
                </div>
              </span>
            </Link>
          </div>

          <div className='col-xl-3'>
            <Link to={`/application-status/3/`}>
              <span className={`card bg-success hoverable card-xl-stretch mb-5 mb-xl-8 completed_application`}>
                <div className='card-body'>
                  <KTIcon iconName='archive-tick' className={`text-white fs-3x ms-n1`} />
                  <div className={`text-white fw-bold fs-2 mb-2 mt-5`}>Completed Application</div>
                  <div className={`fw-semibold text-white fs-5`}>{stats?.completed_application}</div>
                </div>
              </span>
            </Link>
          </div>

          <div className='col-xl-3'>
            <Link to={`/application-status/2/`}>
              <span className={`card hoverable card-xl-stretch mb-5 mb-xl-8 pending_application`}>
                <div className='card-body'>
                  <KTIcon iconName='question-2' className={`text-white fs-3x ms-n1`} />
                  <div className={`text-white fw-bold fs-2 mb-2 mt-5`}>Pending Applications</div>
                  <div className={`fw-semibold text-white fs-5`}>{stats?.pending_application}</div>
                </div>
              </span>
            </Link>
          </div>

          <div className='col-xl-3'>
            <Link to={`/application-status/1/`}>
              <span className={`card progress_application hoverable card-xl-stretch mb-5 mb-xl-8`}>
                <div className='card-body'>
                  <KTIcon iconName='chart-simple-3' className={`text-white fs-3x ms-n1`} />
                  <div className={`text-white fw-bold fs-2 mb-2 mt-5`}>New Application</div>
                  <div className={`fw-semibold text-white fs-5`}>{stats?.progress_application}</div>
                </div>
              </span>
            </Link>
          </div>
        </div>
      )}

      {userRole == 1 ? (
        loading ? (
         ""
        ) : (
        <div className='row g-5 g-xl-8'>
          <div className='col-xl-6'>
            <ChartsWidget6 className='card-xl-stretch mb-5 mb-xl-8' stats={stats} />
          </div>
          <div className='col-xl-6'>
            <div className='card card-xl-stretch mb-5 mb-xl-8'>
              <div className='card-header align-items-center border-0'>
                <h3 className='card-title align-items-start flex-column'>
                  <span className='fw-bold text-dark'>Activity Log</span>
                  <span className='text-muted fw-semibold fs-7'>Today</span>
                </h3>
              </div>
              <div className='card-body py-3' style={{ overflowY: 'auto', maxHeight: '350px' }}>
                <ListsWidget5 className='' stats={stats} loading={loading}/>
              </div>
            </div>
          </div>
        </div >
        )
      ) : ('')}
    </>
  )
}

export default Dashboard