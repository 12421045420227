import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import { HeaderUserMenu } from '../../../partials'
import { EDIT_USERS, Application_check_vendor } from '../../../../app/modules/auth/core/_requests'
import { AccountDetails } from '../../../../app/modules/auth'


const itemClass = 'ms-1 ms-lg-3'
const userAvatarClass = 'symbol-35px symbol-md-40px'

interface CreditDetails {
  vendor_credit: string;
  app_used: string;
  message: string;
}


const Navbar = () => {
  const [userData, setUserData] = useState<AccountDetails>();
  const [credit, setCredit] = useState<CreditDetails | undefined>();  
  const UserDetails: any = localStorage.getItem('user-details')
  const userDetailsObject = JSON.parse(UserDetails);
  const userId = userDetailsObject.id;
  const userRole = userDetailsObject.role;

  const fetchUserData = async (user_id: any) => {
    try {
      const response = await EDIT_USERS(user_id);
      setUserData(response.data.account_details[0]);
    } catch (error) {
      console.error('Error fetching members:', error);
    }
  };

  useEffect(() => {
    fetchUserData(userId);
  }, []);

  const vendorCredit = async (userId:any) => {
    try{
      const response = await  Application_check_vendor(userId)
      setCredit(response.data);
    } catch (error) {
      console.error('Error fetching credits:', error);
    }
  }

  useEffect(() => {
    vendorCredit(userId);
  }, []);

  // useEffect(() => {
  //   Application_check_vendor(userId)
  //     .then(response => {
  //       setCredit(response.data);
  //     })
  //     .catch(error => {
  //       console.error('API Error:', error);
  //     });
  // }, [credit]);

  return (
    <>
      <div className='app-navbar flex-shrink-0'>
        {userRole === "3" ? (
          <div className="align-self-center fs-7 me-5">
            <span className='badge bg-info text-white p-3'>Application Credit <br /><br />
              Used: {credit?.app_used || ''} / {credit?.vendor_credit || ''}
            </span>
          </div>
        ) : (null)}
        <div className={clsx('app-navbar-item', itemClass)}>
          <div
            className={clsx('cursor-pointer symbol', userAvatarClass)}
            data-kt-menu-trigger="{default: 'click'}"
            data-kt-menu-attach='parent'
            data-kt-menu-placement='bottom-end'
          >
            <div className="symbol symbol-50px symbol-circle">
              <span className="symbol-label fs-2 fw-bold bg-primary text-inverse-primary">{userData?.user_name.charAt(0)}</span>
            </div>
          </div>
          {<HeaderUserMenu />}
        </div>
      </div>
    </>
  )
}

export { Navbar }