import React from 'react'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'

const SuccessPage = () => {

  return (
    <>
      <div className="d-flex align-items-center justify-content-center vh-100 bg-light-success">
        <div className='col-md-8 p-20 card'>
          <div className="text-center mb-5">
            <img src={toAbsoluteUrl('/media/stock/check.png')} className='w-50px' alt="Check Icon" />
          </div>
          <h2 className='text-center'>Successfully Submitted</h2>
          <h4 className='text-center'>One of our representatives will get in touch with you shortly</h4>
        </div>
      </div> 
    </>

  )
}

export default SuccessPage