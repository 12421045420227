import { useDropzone } from "react-dropzone";
import Swal from 'sweetalert2';
import { ErrorMessage, Field } from 'formik'

const Step1 = ({ setHeader_image, header_image, footer_image,
  setFooter_image, header_image_error, setHeader_image_error, setFooter_image_error, footer_image_error }: any) => {


  const maxSize = 2 * 1024 * 1024; // 2 megabytes

  const displayErrorMessage = (title: string, text: string) => {
    Swal.fire({
      icon: 'error',
      title,
      text,
    });
  };

  const handleHeaderImage = (acceptedFiles: any, setImageFunction: any, setErrorFunction: any) => {
    const file = acceptedFiles[0];

    if (file) {
      if (file.size <= maxSize) {
        const image = new Image();
        image.src = URL.createObjectURL(file);

        image.onload = () => {
          const megapixels = (image.width * image.height) / 1000000;

          if (megapixels <= 2 && image.width === 1096 && image.height === 150) {
            setImageFunction(acceptedFiles);
            setErrorFunction(false);
          } else {
            setErrorFunction(true);
            displayErrorMessage(
              'Image Error',
              'The image must be exactly 1096 x 150 pixels and should not exceed 2 megapixels.'
            );
          }
        };
      } else {
        setErrorFunction(true);
        displayErrorMessage(
          'File Size Error',
          `File size exceeds the allowed limit of ${maxSize / (1024 * 1024)} MB.`
        );
      }
    }
  };

  const handleFooterImage = (acceptedFiles: any, setImageFunction: any, setErrorFunction: any) => {
    const file = acceptedFiles[0];

    if (file) {
      if (file.size <= maxSize) {
        const image = new Image();
        image.src = URL.createObjectURL(file);

        image.onload = () => {
          const megapixels = (image.width * image.height) / 1000000;

        
            if (megapixels <= 2 && image.width === 1098 && image.height === 100) {
            setImageFunction(acceptedFiles);
            setErrorFunction(false);
          } else {
            setErrorFunction(true);
            displayErrorMessage(
              'Image Error',
              'The image must be exactly 1098 x 100 pixels and should not exceed 2 megapixels.'
            );
          }
        };
      } else {
        setErrorFunction(true);
        displayErrorMessage(
          'File Size Error',
          `File size exceeds the allowed limit of ${maxSize / (1024 * 1024)} MB.`
        );
      }
    }
  };

  
  const { getRootProps: getRootProps1, getInputProps: getInputProps1 } = useDropzone({
    onDrop: acceptedFiles => handleHeaderImage(acceptedFiles, setHeader_image, setHeader_image_error),
    accept: {
      'image/jpeg': [],
      'image/png': [],
    },
  });

  const { getRootProps: getRootProps2, getInputProps: getInputProps2 } = useDropzone({
    onDrop: acceptedFiles => handleFooterImage(acceptedFiles, setFooter_image, setFooter_image_error),
    accept: {
      'image/jpeg': [],
      'image/png': [],
    },
  });


  return (
    <>
      <div className='w-100'>
        <div className='flex-row-fluid flex-center'>
          <div className='w-100'>
            <h4 className='mb-5'>Header Image</h4>
            <div {...getRootProps1({ className: "dropzone" })}>
              <input type="file" id="Front_image" {...getInputProps1()} name="Front_image" />
              <div className="text-center">
                <p className="dropzone-content">
                  Drag’n’drop some files here, or click to select files
                </p>
              </div>
            </div>
            <p className='mt-3'>Recommended Size : 1096 x 150</p>
            {header_image.length > 0 && (
              <>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  {header_image.map((file: File) => (
                    <div key={file.name} style={{ margin: '10px' }}>
                      <img
                        src={URL.createObjectURL(file)}
                        alt={file.name}
                        className='w-100'
                      />
                      <p>{file.name}</p>
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
          {header_image_error && <div className="text-danger mt-2">Please upload file size less then 2 MP front page.</div>}

          <div className='w-100'>
            <h4 className='mb-5 mt-5'>Footer Image</h4>
            <div {...getRootProps2({ className: "dropzone" })}>
              <input className="input-zone" {...getInputProps2()} name='back_image' />
              <div className="text-center">
                <p className="dropzone-content">
                  Drag’n’drop some files here, or click to select files
                </p>
              </div>
            </div>
            <p className='mt-3'>Recommended Size : 1098 x 100</p>
            {footer_image.length > 0 && (
              <>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  {footer_image.map((file: File) => (
                    <div key={file.name} style={{ margin: '10px' }}>
                      <img
                        src={URL.createObjectURL(file)}
                        alt={file.name}
                        className='w-100'
                      />
                      <p>{file.name}</p>
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
          {footer_image_error && <div className="text-danger mt-2">Please upload file size less then 2 MP back page.</div>}
        </div>
        <div className="form-group">
          <label htmlFor="template_bgColor" className='form-label mt-3'>Template Theme Background Color</label>
          <Field type="color" name="template_bgColor" className="form-control w-200px h-50px" />
          <ErrorMessage name="template_bgColor" component="div" className="text-danger" />
        </div>
        <div className="form-group">
          <label htmlFor="template_textColor" className='form-label mt-3'>Template Theme Text Color</label>
          <Field type="color" name="template_textColor" className="form-control w-200px h-50px" />
          <ErrorMessage name="template_textColor" component="div" className="text-danger" />
        </div>
      </div>
    </>
  )
}

export { Step1 }