// Step5 component
import React from 'react';
import { ErrorMessage, Field } from 'formik'
import { TemplateForm } from '../CreateAccountWizardHelper';

interface Step5Props {
  header: File[];
  footer: File[];
  values: TemplateForm;
}

const Step5: React.FC<Step5Props> = ({ header, footer,values }) => {
  const containerStyle: React.CSSProperties = {
    backgroundColor: values.template_bgColor || 'transparent', // Set a default color if template_bgColor is not available
  };
  const textStyle: React.CSSProperties = {
    color: values.template_textColor || 'transparent', // Set a default color if template_bgColor is not available
  };

  return (
    <>
      <div className='w-75'>
        <div className='pb-8 pb-lg-10'>
          <h2 className='fw-bolder text-dark'>Your Are Done!</h2>
        </div>
        <div className='card'>
          <div>
            {header.length > 0 && header[0] && (
              <img src={URL.createObjectURL(header[0])} className='w-100' alt="header-image" />
            )}
          </div>
          <div style={containerStyle} className='d-flex justify-content-center rounded-0 align-items-center p-3 mt-5'>
            <span className='text-center fs-4' style={textStyle}>Online Application Form</span>
          </div>
          <div className='card-body py-20 mt-5 bg-light'>
          </div>
          <div className='mt-5'>
            <div>
              {footer.length > 0 && footer[0] && (
                <img src={URL.createObjectURL(footer[0])} className='w-100' alt="footer_image" />
              )}
            </div>
          </div>
          <div className='mt-5'>
            <label className='form-label required'>Template Title</label>
            <Field type='text' name='template_title' className='form-control form-control-lg form-control-solid' />
            <div className='text-danger mt-2'>
              <ErrorMessage name='template_title' />
            </div>
          </div>
        </div>
      </div >
    </>
  );
};

export { Step5 };
